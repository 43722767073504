/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {FormHelperText, styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import SubmitButton from '../../common/SubmitButton';
import StyledSwitch from '../../common/input/StyledSwitch';
import FuelLockStore from '../../../stores/FuelLockStore';
import callPostAPI from '../../../controllers/callPostAPI';
import SuccessMessages from '../../../helper/SuccessMessages';
import Constants from '../../../helper/Constants';
import TextFieldInput from '../../common/input/TextFieldInput';
import { CustomFieldPojo } from '../../../types/components/ApiTypes';
import isPlainText from '../../../helper/validator/isPlainText';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const CustomFieldEntryPanel = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [ customFieldEnabled, setCustomFieldEnabled ] = useState(FuelLockStore.systemData.customField.enabled);
    const [ customFieldName, setCustomFieldName ] = useState(
        FuelLockStore.systemData.customField.name != null ? FuelLockStore.systemData.customField.name : '');
    // eslint-disable-next-line
    const [ customFieldEntryMandatory, setCustomFieldEntryMandatory ] = useState(FuelLockStore.systemData.customField.mandatoryEntry);
    const [ nameError, setNameError ] = useState('');
    const [ textFieldDisable, setTextFieldDisabled ] = useState (!FuelLockStore.systemData.customField.enabled);
    const [ switchFieldDisable, setSwitchFieldDisabled ] = useState (!FuelLockStore.systemData.customField.enabled);

    const isValid = () => {
        let valid = true;

        // eslint-disable-next-line
        const newNameError = customFieldName != null && customFieldName.trim() !== '' ? isPlainText(customFieldName == null ? '' : customFieldName, 1, 'name') : 'Please enter a valid name.';
        setNameError(newNameError);

        if (newNameError !== '') {
            valid = false;
        }

        return valid;
    };

    useEffect(() => {
        setTextFieldDisabled(!customFieldEnabled);
        setSwitchFieldDisabled(!customFieldEnabled);
        if ( !customFieldEnabled) {
            setCustomFieldEntryMandatory(false);
        }

    }, [ customFieldEnabled ]);

    const onSave = () => {
        if (isValid()) {
            if (customFieldEnabled !== FuelLockStore.systemData.customField.enabled ||
                customFieldName !== FuelLockStore.systemData.customField.name ||
                customFieldEntryMandatory !== FuelLockStore.systemData.customField.mandatoryEntry) {

                const newCustomField: CustomFieldPojo = {
                    enabled: customFieldEnabled,
                    name: customFieldName,
                    mandatoryEntry: customFieldEntryMandatory,
                };

                callPostAPI('fuellock/v1/customField', newCustomField,
                    (receivedData) => {
                        if (receivedData.status === 'success') {
                            if (!newCustomField.enabled) {
                                newCustomField.name = '';
                                newCustomField.mandatoryEntry = false;
                            }
                            FuelLockStore.setCustomField(newCustomField);
                        }
                    }, enqueueSnackbar, SuccessMessages.UPDATE_CUSTOM_FIELD_ENTRY
                );
            }
        }
    };

    return (
        <Paper>
            <Root>
                <div style={{marginBottom: '10px'}}>
                    <StyledSwitch
                        id="custom_field_entry_switch"
                        description="Custom Field Entry"
                        value={customFieldEnabled}
                        setValue={setCustomFieldEnabled}
                    />
                </div>
                <TextFieldInput
                    id="custom_field_name_input"
                    label={Constants.CUSTOM_FIELD_NAME}
                    field={{
                        value: customFieldName,
                        setValue: setCustomFieldName,
                        error: nameError,
                        setError: setNameError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 21,
                        },
                        disabled: textFieldDisable,
                    }}
                />
                <div>
                    <FormHelperText>
                        When enabled, Custom Field Entry will allow employees to input a value per transaction
                        and populate this value on the email reports.
                    </FormHelperText>
                </div>

                <div>
                    <StyledSwitch
                        id="custom_field_entry_mandatory_switch"
                        description="Entry Mandatory"
                        value={customFieldEntryMandatory}
                        setValue={setCustomFieldEntryMandatory}
                        disabled={switchFieldDisable}
                    />
                </div>
                <SubmitButton
                    id="save_custom_field_button"
                    text="Save Changes"
                    onClick={onSave}
                    disabled={FuelLockStore.awaitingApi}
                />
            </Root>
        </Paper>
    );
});

export default CustomFieldEntryPanel;
