/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import FuelLockStore from '../../stores/FuelLockStore';
import WebStore from '../../stores/WebStore';

const Root = styled('div')(({theme}) => ({
    // @ts-ignore
    backgroundColor: theme.palette.primary.drawerUserBackground,
    borderRadius: '10px',
    margin: '8px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '24px',
}));

const DrawerUserInformation = observer(() => (
    <Root>
        <Typography
            sx={{
                fontSize: '14px',
                fontWeight: 900,
            }}
        >
            {FuelLockStore.systemData.personalDetails.companyName}
        </Typography>
        <Typography sx={{fontSize: '12px'}}>{WebStore.dashboardDevice}</Typography>
    </Root>
));

export default DrawerUserInformation;
