/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import { Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { TankList } from '../../../types/components/ApiTypes';
import TitleTypography from '../../common/typography/TitleTypography';
import TankSummaryRow from '../../devices/tanks/TankSummaryRow';

interface Props {
    paperRef: any,
    paperStyle: object,
    tankLevelsDataPerDevice: { deviceId: Number; deviceName: String; monitoredTanks: TankList[]; };
}

const TankLevelSummaryTile = observer((props: Props) => {

    const [ monitoredTanks, setMonitoredTanks ] = useState(props.tankLevelsDataPerDevice.monitoredTanks);
    const [ deviceId, setDeviceId ] = useState(props.tankLevelsDataPerDevice.deviceId);
    const [ deviceName, setdeviceName ] = useState(props.tankLevelsDataPerDevice.deviceName);

    useEffect(() => {
        setMonitoredTanks(props.tankLevelsDataPerDevice.monitoredTanks);
        setDeviceId(props.tankLevelsDataPerDevice.deviceId);
        setdeviceName(props.tankLevelsDataPerDevice.deviceName);

    }, [ props.tankLevelsDataPerDevice ]);

    const renderTankLevels = () => {
        const tankLevels = [];

        for (let i = 0; i < monitoredTanks.length; i++) {
            tankLevels.push(
                <div key={`${ monitoredTanks[i].name }`}>
                    <TankSummaryRow
                        title={monitoredTanks[i].name}
                        percentage={monitoredTanks[i].fullness!}
                    />
                </div>
            );
        }
        return tankLevels;

    };

    const renderTankLevelsSummaryTile = () => {

        const tankLevelsSummaryTile = (
            <div key={`${ deviceId }`}>
                <Typography marginTop={1}>{`${ deviceName }`}</Typography>
                {renderTankLevels()}
            </div>
        );

        return tankLevelsSummaryTile ? tankLevelsSummaryTile
            : <Typography>No data found for the selected device(s).</Typography>;
    };

    return (
        <Paper
            sx={{
                ...props.paperStyle,
            }}
            elevation={6}
            ref={props.paperRef}
        >
            <TitleTypography
                title='Tank Levels'
            />
            {renderTankLevelsSummaryTile()}
        </Paper>
    );
});

export default TankLevelSummaryTile;
