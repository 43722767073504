/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getUnitNameAndSymbol = (unit: string) => {
    if (unit === 'litres') {
        return {
            name: 'Litres',
            symbol: 'L',
        };
    }

    return {
        name: unit,
        symbol: 'G',
    };
};

export default getUnitNameAndSymbol;
