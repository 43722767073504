/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import SelectFieldInput from './SelectFieldInput';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    options: {
        title: string,
        id: string
    }[],
    textFieldProps?: object,
    helperText?: string,
}

function GridSelectFieldInput(props: Props) {
    return (
        <Grid
            item
            xs={6}
        >
            <SelectFieldInput
                id={props.id}
                label={props.label}
                field={props.field}
                options={props.options}
                textFieldProps={props.textFieldProps}
                helperText={props.helperText}
            />
        </Grid>
    );
}

export default GridSelectFieldInput;
