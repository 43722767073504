/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Paper from '@mui/material/Paper';
import {useSnackbar} from 'notistack';
import Constants from '../../../helper/Constants';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TitleTypography from '../../common/typography/TitleTypography';
import {IGTResponse, InputFieldProps} from '../../../types/components/ApiTypes';
import TextFieldInput from '../../common/input/TextFieldInput';
import isValidSerialNumber from '../../../helper/validator/isValidSerialNumber';
import callAPI from '../../../controllers/callAPI';
import SubmitButton from '../../common/SubmitButton';
import FuelLockStore from '../../../stores/FuelLockStore';
import {observer} from 'mobx-react-lite';

interface Props {
    deviceName: InputFieldProps
    serialNumber: InputFieldProps
    autoLockTime: InputFieldProps
    serialNumberDisabled?: boolean,
    hideTitle?: boolean,
    onUpdate?: () => void,
    width?: string,
}

const AddNewDevice = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const snBlurHandler = (sn: string) => {
        const newSerialNumberError = isValidSerialNumber(sn);
        props.serialNumber.setError(newSerialNumberError);

        if (!newSerialNumberError && sn.length > 0) {
            callAPI(`fuellock/v1/device/validate/sn/${ sn }`, (response: IGTResponse<boolean>) => {
                props.serialNumber.setError(response.data ? ''
                    : 'A matching device could not be found. Device already in use, or entered SN was invalid.');
            }, enqueueSnackbar);
        } else {
            props.serialNumber.setError('');
        }
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: props.width ? props.width : '75%',
            }}
        >
            {!props.hideTitle && (
                <TitleTypography title={Constants.ADD_NEW_DEVICE}/>
            )}

            <TextFieldInput
                id="new_device_name_input"
                label={Constants.DEVICE_NAME}
                field={props.deviceName}
                textFieldProps={{
                    inputProps: {
                        maxLength: 16,
                    },
                }}
            />
            <FormControl>
                <TextFieldInput
                    id="new_serial_number_input"
                    label={Constants.SERIAL_NUMBER}
                    field={props.serialNumber}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 7,
                        },
                        disabled: props.serialNumberDisabled,
                    }}
                    onBlur={snBlurHandler}
                />
                {!props.serialNumberDisabled && (
                    <FormHelperText sx={{paddingBottom: '8px'}}>
                        The Serial number for your Fuel Lock device is seven (7) characters (alphanumeric) long, and
                        located on the back of the door (on the inside of the device).
                    </FormHelperText>
                )}
            </FormControl>

            <TextFieldInput
                id="new_auto_lock_time_input"
                label="Auto Lock Time (min)"
                field={props.autoLockTime}
                textFieldProps={{
                    type: 'number',
                    inputProps: {
                        min: 5,
                        max: 60,
                    },
                }}
            />

            {props.onUpdate && (
                <SubmitButton
                    id="update_device_button"
                    text="Update Device"
                    onClick={props.onUpdate}
                    disabled={FuelLockStore.awaitingApi}
                />
            )}
        </Paper>
    );
});

export default AddNewDevice;
