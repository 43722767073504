/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import Constants from '../Constants';

const getCountryList = () => {
    const countryOptions: { title: string; id: string; }[] = [];

    const countries = Object.keys(Constants.COUNTRY_LIST);
    countries.forEach(country => {
        countryOptions.push({
            title: country,
            id: `${ country.replace(/\s/g, '_').toLowerCase() }_country_option`,
        });
    });
    return countryOptions;
};

export default getCountryList;
