/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import { Paper, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import callAPI from '../../../controllers/callAPI';
import Constants from '../../../helper/Constants';
import { Device, InputFieldProps } from '../../../types/components/ApiTypes';
import SubmitButton from '../../common/SubmitButton';
import TitleTypography from '../../common/typography/TitleTypography';
import DeviceTransferNotificationModal from '../DeviceTransferNotificationModal';
import DeviceTransferModal from '../DeviceTransferModal';
import TransferredDeviceModal from '../TransferredDeviceModal';
import WebStore from '../../../stores/WebStore';
import FuelLockStore from '../../../stores/FuelLockStore';

interface Props {
    editingDevice: Device;
    width: string;
    serialNumber: InputFieldProps;
    serialNumberDisabled: boolean;
    toSerialNumber: InputFieldProps;
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const TransferDevice = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ showTransferNotification, setShowTransferNotification ] = useState(false);
    const [ showTransfer, setShowTransfer ] = useState(false);
    const [ transferSuccess, setTransferSuccess ] = useState(false);

    const onTransfer = (isTransfer: boolean) => {
        setShowTransferNotification(isTransfer);
    };

    const continueTransfer = () => {
        setShowTransferNotification(false);
        setShowTransfer(true);
    };

    const transferComplete = () => {
        setShowTransfer(false);
        setTransferSuccess(true);
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: props.width ? props.width : '75%',
            }}
        >
            <Row>
                <TitleTypography title={Constants.TRANSFER_DEVICE} />
                <SubmitButton
                    id="transfer_device_button"
                    text="Transfer"
                    onClick={() => onTransfer(true)}
                />
            </Row>

            {showTransferNotification && (
                <DeviceTransferNotificationModal
                    open
                    close={() => setShowTransferNotification(false)}
                    onSubmit={() => continueTransfer()}
                />
            )}
            {showTransfer && (
                <DeviceTransferModal
                    open
                    close={() => setShowTransfer(false)}
                    editingDevice={props.editingDevice}
                    serialNumberDisabled={false}
                    onSubmit={transferComplete}
                />
            )}
            {transferSuccess && (
                <TransferredDeviceModal
                    open
                    close={() => {
                        setTransferSuccess(false);
                    }}
                    submit={() => {
                        setTransferSuccess(false);
                        callAPI('fuellock/v2/dashboard', (receivedServerData) => {
                            WebStore.setSelectedDrawer(Constants.DASHBOARD);
                            FuelLockStore.setSystemData(receivedServerData.data);
                        }, enqueueSnackbar);
                    }}
                />
            )}
        </Paper>
    );
});

export default TransferDevice;