/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Typography} from '@mui/material';
import BaseModal from './BaseModal';
import getTestID from '../../../helper/functions/getTestID';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    submit: () => void,
    deviceName: string,
}

function AddedDeviceModal(props: Props) {
    const submit = () => {
        props.submit();
        props.close();
    };

    return (
        <BaseModal
            id="device_added"
            open={props.open}
            close={props.close}
            submit={submit}
            title="You've successfully added a new device!"
            submitButtonText="Go to Dashboard"
        >
            <Container>
                <Typography {...getTestID('popup_message')}>
                    A new device called "{props.deviceName}" has been added to your Device Settings.
                </Typography>
            </Container>
        </BaseModal>
    );
}

export default AddedDeviceModal;
