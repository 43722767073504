/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CenterScreen from '../../common/CenterScreen';
import CheckInbox from './CheckInbox';
import SubmitButton from '../../common/SubmitButton';
import isEmailValid from '../../../helper/validator/isEmailValid';
import TextFieldInput from '../../common/input/TextFieldInput';
import {useSnackbar} from 'notistack';
import getTestID from '../../../helper/functions/getTestID';
import FuelLockStore from '../../../stores/FuelLockStore';
import callPutAPI from '../../../controllers/callPutAPI';
import SuccessMessages from '../../../helper/SuccessMessages';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

interface Props {
    setForgotPasswordOpen: (value: boolean) => void,
}

function ForgotPassword(props: Props) {
    const {enqueueSnackbar} = useSnackbar();

    const [ checkInboxOpen, setCheckInboxOpen ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');

    const isValid = () => {
        let valid = true;
        const newEmailError = isEmailValid(email);
        if (newEmailError !== '') {
            valid = false;
        }
        setEmailError(newEmailError);
        return valid;
    };

    const onEmailCode = () => {
        const body = {
            accountEmail: email,
        };

        if (isValid()) {
            callPutAPI('fuellock/v2/password/reset', body,
                () => {
                    setCheckInboxOpen(true);
                },
                enqueueSnackbar, SuccessMessages.PASSWORD_RESET);
        }
    };

    if (checkInboxOpen) {
        return (
            <CheckInbox
                setForgotPasswordOpen={props.setForgotPasswordOpen}
            />
        );
    }

    return (
        <CenterScreen>
            <Paper
                sx={{
                    width: '33%',
                    minWidth: '444px',
                }}
            >
                <Container>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            paddingBottom: '32px',
                        }}
                    >
                        Forgot your password?
                    </Typography>

                    <Typography
                        sx={{
                            paddingBottom: '16px',
                        }}
                    >
                        Enter the email address you used for your Fuel Lock™ account.
                    </Typography>

                    <TextFieldInput
                        id="forgot_password_email_address_input"
                        label="Email Address"
                        field={{
                            value: email,
                            setValue: setEmail,
                            error: emailError,
                            setError: setEmailError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                maxLength: 60,
                            },
                        }}
                    />

                    <ButtonRow>
                        <Button
                            {...getTestID('cancel_forgot_password_button')}
                            color="secondary"
                            sx={{marginRight: '16px'}}
                            onClick={() => props.setForgotPasswordOpen(false)}
                        >
                            Cancel
                        </Button>
                        <SubmitButton
                            id="email_code_button"
                            text="Email Password Reset Link"
                            onClick={onEmailCode}
                            disabled={FuelLockStore.awaitingApi}
                        />
                    </ButtonRow>
                </Container>
            </Paper>
        </CenterScreen>
    );
}

export default ForgotPassword;
