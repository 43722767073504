/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {observer} from 'mobx-react-lite';
import FuelLockStore from '../../../stores/FuelLockStore';
import ConfirmationModal from './ConfirmationModal';

const ConfirmModal = observer(() => {
    const onSubmit = () => {
        FuelLockStore.confirm.onConfirm();
    };

    const close = () => {
        FuelLockStore.setConfirm({
            title: '',
            message: '',
            onConfirm: () => {
            },
        });
    };

    return (
        <ConfirmationModal
            id="confirm_modal"
            open
            close={close}
            submit={onSubmit}
            title={FuelLockStore.confirm.title}
            message={FuelLockStore.confirm.message}
        />
    );
});

export default ConfirmModal;
