/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, { useState } from 'react';
import {ContractorPin, Device} from '../../../types/components/ApiTypes';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import FuelLockStore from '../../../stores/FuelLockStore';
import { cloneDeep } from 'lodash';
import TitleTypography from '../../common/typography/TitleTypography';
import { Button, Divider, Paper } from '@mui/material';
import Constants from '../../../helper/Constants';
import CheckboxRow from '../../common/input/CheckboxRow';
import callPostAPI from '../../../controllers/callPostAPI';
import SuccessMessages from '../../../helper/SuccessMessages';
import SubmitButton from '../../common/SubmitButton';
import getTestID from '../../../helper/functions/getTestID';

const Center = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
});

const SubmitPadding = styled('div')({
    paddingTop: '16px',
});

interface ContractorPinChangeList {
    id: number;
    title: string;
    checked: boolean;
}

interface AccessList {
    added: number[],
    removed: number[]
}

interface Props {
    editingDevice: Device,
}

const EditContractorPinAccess = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ deviceContractorPinList, setDeviceContractorPinList ] = useState(props.editingDevice.contractorPinList);

    const checkDeviceContractorPinAccess = (contractorPin: ContractorPin, contractorPinList: ContractorPin[]) => {
        let checked = false;
        if (contractorPinList.length > 0) {
            contractorPinList.forEach((iContractorPin: ContractorPin) => {
                if (contractorPin.id === iContractorPin.id) {
                    checked = true;
                }
            });
        }
        return checked;
    };

    const getStartingData = () => {
        const allContractorPinList = FuelLockStore.systemData.contractorPinList;
        const data: ContractorPinChangeList[] = [];

        allContractorPinList.forEach(contractorPin => {
            data.push({
                id: contractorPin.id,
                title: contractorPin.name,
                checked: checkDeviceContractorPinAccess(contractorPin, deviceContractorPinList),
            });
        });

        return data;
    };

    const [ data, setData ] = useState(getStartingData());
    const [ priorData, setPriorData ] = useState<ContractorPinChangeList[]>([]);

    React.useEffect(() => {
        setData(getStartingData());
        setPriorData(getStartingData());
    }, [ FuelLockStore.systemData.contractorPinList, props.editingDevice.contractorPinList, deviceContractorPinList ]);

    const disableGold = () => {
        if (FuelLockStore.awaitingApi) {
            return true;
        } else if (data.length != priorData.length) {
            return false;
        } else if (data.length == priorData.length) {
            for (let i = 0; i < data.length; i += 1) {
                if (data[i].checked != priorData[i].checked) {
                    return false;
                }
            }
        }
        return true;
    };

    const allSelected = () => {
        let numSelected = 0;
        for (let i = 0; i < data.length; i += 1) {
            if (data[i].checked) {
                numSelected += 1;
            }
        }
        return numSelected === data.length;
    };

    const areAllSelected = allSelected();

    const onSelectUnselectAllContractorPins = () => {
        const updateChecked = !areAllSelected;
        setData((oldData) => {
            const newData = cloneDeep(oldData);
            for (let i = 0; i < newData.length; i += 1) {
                newData[i].checked = updateChecked;
            }
            return newData;
        });
    };

    const onCheckChange = (index: number) => {
        setData((oldData) => {
            const newData = cloneDeep(oldData);
            newData[index].checked = !newData[index].checked;
            return newData;
        });
    };

    const determineAddedOrRemovedContractorPin = () => {
        const contractorPinAccessList: AccessList = {
            added: [],
            removed: [],
        };

        for (let i = 0; i < data.length; i += 1) {
            if (!priorData[i].checked && data[i].checked) {
                contractorPinAccessList.added.push(data[i].id);
            } else if (priorData[i].checked && !data[i].checked) {
                contractorPinAccessList.removed.push(data[i].id);
            }
        }
        return contractorPinAccessList;
    };

    const deviceAccessChangeSuccessCallback = (accessList: AccessList) => {
        const newContractorPinList = cloneDeep(JSON.parse(JSON.stringify(deviceContractorPinList)));
        FuelLockStore.systemData.contractorPinList.map((contractorPin: ContractorPin) => {
            const isAdded = accessList.added.indexOf(contractorPin.id) != -1;
            if (isAdded) {
                newContractorPinList.push(contractorPin);
            }
            const isRemoved = !isAdded ? accessList.removed.indexOf(contractorPin.id) != -1 : false;
            if (isRemoved) {
                const index = newContractorPinList.findIndex((item: ContractorPin) => item.id === contractorPin.id);
                newContractorPinList.splice(index, 1);
            }
        });
        setDeviceContractorPinList(newContractorPinList);
        const newDeviceList = JSON.parse(JSON.stringify(FuelLockStore.systemData.deviceList));
        const deviceIndex = newDeviceList.findIndex((item: Device) => item.id === props.editingDevice.id);
        if (deviceIndex != -1) {
            newDeviceList[deviceIndex].contractorPinList = newContractorPinList;
            FuelLockStore.setDeviceData(newDeviceList);
        }
    };

    const callRemoveContractorPin = (contractorPinIds: number[], successCallback?: () => void) => {
        if (contractorPinIds.length > 0) {
            const body = {
                intSet: contractorPinIds,
            };
            callPostAPI(`fuellock/v2/contractorPin/access/device/remove/${ props.editingDevice.id }`, body,
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        if (successCallback != undefined) {
                            successCallback();
                        }
                    }
                }, enqueueSnackbar, SuccessMessages.REMOVE_CONTRACTOR_PIN_ACCESS);
        } else {
            if (successCallback != undefined) {
                successCallback();
            }
        }
    };

    const onUpdate = () => {
        const accessList: AccessList = determineAddedOrRemovedContractorPin();
        if (accessList.added.length > 0) {
            const body = {
                intSet: accessList.added,
            };
            callPostAPI(`fuellock/v2/contractorPin/access/device/add/${ props.editingDevice.id }`, body,
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        callRemoveContractorPin(accessList.removed,
                            () => deviceAccessChangeSuccessCallback(accessList));
                    }
                }, enqueueSnackbar, SuccessMessages.ADD_CONTRACTOR_PIN_ACCESS);
        } else {
            callRemoveContractorPin(accessList.removed, () => deviceAccessChangeSuccessCallback(accessList));
        }
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <TitleTypography title={Constants.ADD_CONTRACTOR_PIN}/>

            <Center>
                <Button
                    {...getTestID(areAllSelected ? 'unselect_contractor_pins_button'
                        : 'select_contractor_pins_button')}
                    variant="contained"
                    onClick={onSelectUnselectAllContractorPins}
                >
                    {areAllSelected ? `Unselect All Existing ${ Constants.CONTRACTOR_PIN }s`
                        : `Add All Existing ${ Constants.CONTRACTOR_PIN }s`}
                </Button>
            </Center>

            <TitleTypography title={`Existing ${ Constants.CONTRACTOR_PIN }s`}/>
            <Divider/>
            {data.map((contractorPin, index) => (
                <CheckboxRow
                    key={`${ contractorPin.title }_${ contractorPin.checked }`}
                    title={contractorPin.title}
                    checked={contractorPin.checked}
                    onChange={() => onCheckChange(index)}
                />
            ))}
            <SubmitPadding>
                <SubmitButton
                    id="update_contractor_pin_access_button"
                    text={`Update ${ Constants.CONTRACTOR_PIN }`}
                    onClick={onUpdate}
                    disabled={disableGold()}
                />
            </SubmitPadding>
        </Paper>
    );
});

export default EditContractorPinAccess;
