/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Typography} from '@mui/material';
import BaseModal from './BaseModal';
import getTestID from '../../../helper/functions/getTestID';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    setIgnoreFlowMeter: (value: boolean) => void,
    setUseFlowMeter: (value: boolean) => void,
}

function WarnFlowMeterModal(props: Props) {
    const submit = () => {
        props.setUseFlowMeter(true);
        props.close();
    };

    const cancel = () => {
        props.setIgnoreFlowMeter(true);
        props.close();
    };

    return (
        <BaseModal
            id="warn_flow_meter"
            open={props.open}
            close={props.close}
            submit={submit}
            title="Warning! Please Read:"
            submitButtonText="Add Flow Meter"
            cancelButtonText="Continue"
            onCancel={cancel}
        >
            <Container>
                <Typography {...getTestID('warning_text')}>
                    Are you sure you don't want to enable a Flow Meter? Without that Fuel Lock can't track Fuel.
                </Typography>
            </Container>
        </BaseModal>
    );
}

export default WarnFlowMeterModal;
