/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getAmPmHoursString = (hours: number) => {
    const h = hours % 12 || 12;
    const ampm = (hours < 12 || hours === 24) ? 'am' : 'pm';

    return `${ h }:00 ${ ampm }`;
};

export default getAmPmHoursString;
