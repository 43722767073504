// /*
//  * Copyright © 2021 Calian Ltd.  All rights reserved.
//  */

import React, {useEffect, useState} from 'react';
import {FormHelperText, styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import SubmitButton from '../../common/SubmitButton';
import StyledSwitch from '../../common/input/StyledSwitch';
import FuelLockStore from '../../../stores/FuelLockStore';
import SuccessMessages from '../../../helper/SuccessMessages';
import Constants from '../../../helper/Constants';
import { Device } from '../../../types/components/ApiTypes';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import callAPI from '../../../controllers/callAPI';

interface Props {
    selectedDevice: Device,
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const SmartLockPanel = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ selectedDevice, setSelectedDevice ] = useState(props.selectedDevice);
    const [ smartLockEnabled, setSmartLockEnabled ] = useState(props.selectedDevice.smartLockTime
            ? props.selectedDevice.smartLockTime > 0 : false);
    const [ smartLockTime, setSmartLockTime ] = useState(props.selectedDevice.smartLockTime ?? 0);
    const smartLockTimeList = [ 15, 30, 45, 60, 75, 90, 120 ];

    const getSelectedSmartLockTimeLabel = (time: number) => smartLockTimeList.includes(time) ? `${ time } Seconds` : '';

    const [ selectedSmartLockTimeLabel, setSelectedSmartLockTimeLabel ] = useState(
        getSelectedSmartLockTimeLabel(smartLockTime));

    const getSmartLockTimeOptions = () => {
        const options: { title: any; id: string; }[] = [];
        smartLockTimeList.forEach((time) => {
            options.push({
                title: `${ time } Seconds`,
                id: `${ time }_seconds_smart_lock_time_option`,
            });
        });
        return options;
    };

    const setSelectedSmartLockTimeFromOptions = (text: string) => {
        const time = Number(text.split(' ')[0].trim());
        setSmartLockTime(time);
        setSelectedSmartLockTimeLabel(getSelectedSmartLockTimeLabel(time));
    };

    useEffect(() => {
        setSmartLockEnabled(selectedDevice.smartLockTime ? selectedDevice.smartLockTime > 0 : false);
        setSmartLockTime(selectedDevice.smartLockTime ?? 0);
        setSelectedSmartLockTimeLabel(getSelectedSmartLockTimeLabel(selectedDevice.smartLockTime ?? 0));
    }, [ selectedDevice ]);

    useEffect(() => {
        if (!smartLockEnabled) {
            setSmartLockTime(0);
            setSelectedSmartLockTimeLabel(getSelectedSmartLockTimeLabel(0));
        }
    }, [ smartLockEnabled ]);

    const onSave = () => {
        if (smartLockTime !== selectedDevice.smartLockTime) {
            callAPI(`fuellock/v1/smartlocktime/${ selectedDevice.id }/${ smartLockTime }`, receivedServerData => {
                if (receivedServerData.status === 'success') {
                    const newData = JSON.parse(JSON.stringify(FuelLockStore.systemData.deviceList));
                    const selecetedDeviceIndex = FuelLockStore.systemData.deviceList.findIndex(
                        device => device.id === selectedDevice.id);
                    if (selecetedDeviceIndex !== -1) {
                        newData[selecetedDeviceIndex].smartLockTime = smartLockTime;
                        FuelLockStore.setDeviceData(newData);
                    }
                    enqueueSnackbar(SuccessMessages.UPDATE_SMART_LOCK_SETTINGS, {variant: 'success'});
                }
            }, enqueueSnackbar);
        }
    };

    const getSelectedDeviceTitle = (device: Device) => device ? `${ device.siteName } - ${ device.sn }` : '';

    const [ selectedDeviceTitle, setSelectedDeviceTitle ] = useState(getSelectedDeviceTitle(props.selectedDevice));

    const getDeviceOptions = () => {
        const options: { title: any; id: string; }[] = [];
        FuelLockStore.systemData.deviceList.forEach((device) => {
            options.push({
                title: getSelectedDeviceTitle(device),
                id: `${ device.id }_smart_lock_option`,
            });
        });
        return options;
    };

    const setSelectedDeviceFromOptions = (text: string) => {
        const name = text.split('-')[0].trim();
        const deviceOption = FuelLockStore.systemData.deviceList.find(device => device.siteName === name);
        if (deviceOption) {
            setSelectedDevice(deviceOption);
            setSelectedDeviceTitle(getSelectedDeviceTitle(deviceOption));
        }
    };

    return (
        <Paper>
            <Root>
                <div>
                    <SelectFieldInput
                        id="select_device"
                        label='Select Device'
                        field={{
                            value: selectedDeviceTitle,
                            setValue: (value: string) => setSelectedDeviceFromOptions(value),
                            error: '',
                            setError: () => {
                            },
                        }}
                        options={getDeviceOptions()}
                    />
                </div>
                <div style={{marginBottom: '10px'}}>
                    <StyledSwitch
                        id="smart_lock_enabled_switch"
                        description={`Enable ${ Constants.SMART_LOCK }`}
                        value={smartLockEnabled}
                        setValue={setSmartLockEnabled}
                    />
                </div>
                <div>
                    <FormHelperText>
                        Smart Lock is a feature that will automatically lock the device
                        if no flow has been detected for the below set period of time.
                    </FormHelperText>
                </div>
                <div style={{marginTop: '16px'}}>
                    <SelectFieldInput
                        id="select_smart_lock_time"
                        label='Select Smart Lock Time'
                        field={{
                            value: selectedSmartLockTimeLabel,
                            setValue: (value: string) => setSelectedSmartLockTimeFromOptions(value),
                            error: '',
                            setError: () => {
                            },
                        }}
                        options={getSmartLockTimeOptions()}
                        disable={!smartLockEnabled}
                    />
                </div>
                <SubmitButton
                    id="save_smart_lock_settings_button"
                    text="Save Changes"
                    onClick={onSave}
                    disabled={FuelLockStore.awaitingApi}
                />
            </Root>
        </Paper>
    );
});

export default SmartLockPanel;
