/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import ErrorMessages from '../helper/ErrorMessages';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import FuelLockStore from '../stores/FuelLockStore';
import errorSnackbarOptions from '../helper/errorSnackbarOptions';
import logout from '../helper/functions/logout';

const showError = (errorMessage: String,
                   response: Response,
                   enqueueSnackbar: EnqueueSnackbar) => {
    FuelLockStore.setAwaitingApi(false);
    response.json().then((data:any) =>{
        if (data.status && data.status === 'fail') {
            if (data.displayMessage && data.message) {
                enqueueSnackbar(data.message, errorSnackbarOptions);
            } else {
                enqueueSnackbar(`${ response.status }:${  errorMessage }`, errorSnackbarOptions);
            }
        } else {
            enqueueSnackbar(`${ response.status }:${  errorMessage }`, errorSnackbarOptions);
        }
    } );
};

const handleError = (oldResponse: Response,
                     enqueueSnackbar: EnqueueSnackbar,
                     on401Fail?: () => void) => {
    const response = oldResponse.clone();
    if (!response.ok) {
        switch (response.status) {
            case 401:
                logout();
                enqueueSnackbar(`${ response.status }:${  ErrorMessages.ERROR_401 }`, errorSnackbarOptions);
                if (on401Fail) {
                    on401Fail();
                }
                break;
            case 500:
                showError(ErrorMessages.ERROR_500, response, enqueueSnackbar);
                break;
            case 403:
                showError(ErrorMessages.ERROR_403, response, enqueueSnackbar);
                break;
            default:
                FuelLockStore.setAwaitingApi(false);
                enqueueSnackbar(`${ response.status }:${  ErrorMessages.UNEXPECTED }`, errorSnackbarOptions);

                throw new Error(JSON.stringify({
                    status: 'failed',
                    statusCode: response.status,
                    message: response.statusText,
                }));
        }
    }
};

export default handleError;
