/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Paper from '@mui/material/Paper';
import {styled, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import Colors from '../../../helper/Colors';
import Welcome from '../../../images/welcome.png';
import TooltipStore from '../../../stores/TooltipStore';
import getTestID from '../../../helper/functions/getTestID';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '212px',
    justifyContent: 'space-between',
});

const ButtonRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const WelcomeTile = observer(() => (
    <Paper
        elevation={6}
        sx={{
            backgroundColor: Colors.primary,
            marginBottom: '0px',
        }}
    >
        <Row>
            <Column>
                <div>
                    <Typography
                        sx={{
                            color: 'black',
                            fontSize: '24px',
                            fontWeight: 600,
                            paddingBottom: '16px',
                        }}
                    >
                        Welcome To Fuel Lock Web!
                    </Typography>
                    <Typography
                        sx={{
                            color: 'black',
                            paddingBottom: '32px',
                        }}
                    >
                        Click below to receive some tips on our new web platform.
                    </Typography>
                </div>

                <ButtonRow>
                    <Button
                        {...getTestID('get_tips_button')}
                        variant="contained"
                        sx={{
                            color: 'white',
                            backgroundColor: 'black',
                            marginRight: '16px',
                        }}
                        onClick={() => {
                            TooltipStore.setBeginTooltips(true, true);
                            TooltipStore.setAllMessages(true);
                        }}
                    >
                        Get Tips
                    </Button>
                    <Button
                        {...getTestID('dismiss_tips_button')}
                        color="secondary"
                        onClick={() => TooltipStore.setAllMessages(false)}
                    >
                        Dismiss
                    </Button>
                </ButtonRow>
            </Column>
            <img
                src={Welcome}
                alt="welcome image"
            />
        </Row>
    </Paper>
));

export default WelcomeTile;
