/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {Skeleton, Stack, styled} from '@mui/material';
import ContentPanel from './ContentPanel';
import DashboardGrid from '../dashboard/DashboardGrid';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import OverDuePaper from '../dashboard/OverDuePaper';
import getDataById from '../../helper/functions/getDataById';
import FuelLockStore from '../../stores/FuelLockStore';
import callAPI from '../../controllers/callAPI';
import determineMonthsPrior from '../../helper/functions/determineMonthsPrior';
import {useSnackbar} from 'notistack';
import {ProcessedUsagePojo} from '../../types/components/ApiTypes';
import processUsageData from '../../helper/functions/processUsageData';
import Grid from '@mui/material/Grid';
import LoginStore from '../../stores/LoginStore';
import WelcomeTile from '../dashboard/tiles/WelcomeTile';
import TooltipStore from '../../stores/TooltipStore';
import getTankLevelsData from '../../helper/functions/getTankLevelsData';

const Root = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
});

const Dashboard = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [ data, setData ] = useState<ProcessedUsagePojo[]>([]);
    const [ loading, setLoading ] = useState(true);
    const [ hide, setHide ] = useState(true);
    const [ tankLevelsData, setTankLevelsData ] = useState(getTankLevelsData());
    const unit = getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId);

    useEffect(() => {
        if (!LoginStore.loadingData) {
            const endDate = new Date().getTime();
            const startDate = determineMonthsPrior(1).getTime();
            setLoading(true);
            callAPI(`fuellock/v2/usage/${ startDate }/${ endDate }`,
                (receivedServerData) => {
                    setData(processUsageData(receivedServerData.data,
                        FuelLockStore.systemData.deviceList,
                        FuelLockStore.systemData.equipmentList,
                        FuelLockStore.systemData.workerList,
                        WebStore.dashboardDevice));
                    setLoading(false);
                }, enqueueSnackbar);
        }

        setTankLevelsData(getTankLevelsData());

    }, [ WebStore.dashboardDevice, LoginStore.loadingData ]);

    const renderFakeTile = () => (
        <Grid
            item
            xs={12}
            md={6}
        >
            <Stack spacing={1}>
                <div style={{display: 'flex'}}>
                    <Skeleton
                        variant="circular"
                        width={40}
                        height={40}
                        animation="wave"
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            width: '75%',
                            maxWidth: 'none',
                            marginLeft: '16px',
                        }}
                        animation="wave"
                    />
                </div>

                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                        width: '90%',
                        maxWidth: 'none',
                        height: '250px',
                        borderRadius: '12px',
                    }}
                />
            </Stack>
        </Grid>
    );

    const renderFakeGrid = () => (
        <Grid
            container
            spacing={2}
        >
            {renderFakeTile()}
            {renderFakeTile()}
            {renderFakeTile()}
            {renderFakeTile()}
        </Grid>
    );

    if (loading) {
        return (
            <ContentPanel>
                <Root>
                    {WebStore.overDue && (
                        <OverDuePaper/>
                    )}
                    {renderFakeGrid()}
                </Root>
            </ContentPanel>
        );
    }

    return (
        <ContentPanel>
            <Root>
                {WebStore.overDue && (
                    <OverDuePaper/>
                )}
                {hide && renderFakeGrid()}
                {(
                    !hide && (!TooltipStore.beginTooltips || TooltipStore.showIntroMessages) && (
                        TooltipStore.showDashboardMessages || TooltipStore.showLightModeMessages ||
                            TooltipStore.showEmployeeMessages || TooltipStore.showAddEmployeeMessages ||
                            TooltipStore.showEquipmentMessages || TooltipStore.showAddEquipmentMessages ||
                            TooltipStore.showDeviceMessages || TooltipStore.showAddDeviceMessages ||
                            TooltipStore.showTankDeviceMessages || TooltipStore.showReportsMessages ||
                            TooltipStore.showAccountPreferencesMessages)
                ) && (
                    <div>
                        <WelcomeTile/>
                    </div>
                )}

                <div style={{opacity: hide ? 0 : 1}}>
                    <DashboardGrid
                        unit={unit.name}
                        data={data}
                        tankLevelsData={tankLevelsData}
                        setHide={setHide}
                    />
                </div>
            </Root>
        </ContentPanel>
    );
});

export default Dashboard;
