/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    options: {
        title: string,
        id: string
    }[],
    textFieldProps?: object,
    helperText?: string,
    disable?: boolean,
}

const SelectFieldInput = (props: Props) => {
    const handleChange = (event: any) => {
        props.field.setValue(event.target.value);
    };

    return (
        <FormControl sx={{width: '100%'}}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                {...getTestID(props.id)}
                label={props.label}
                value={props.field.value}
                onChange={handleChange}
                MenuProps={{
                    sx: {maxHeight: '400px'},
                }}
                disabled={props.disable ?? false }
            >
                {props.options.map(option => (
                    <MenuItem
                        {...getTestID(option.id)}
                        key={option.id}
                        value={option.title}
                    >
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
            {(props.helperText || props.field.error) && (
                <FormHelperText error={props.field.error !== ''}>
                    {props.field.error ? props.field.error : props.helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default SelectFieldInput;
