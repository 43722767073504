/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import isHex from './isHex';
import fuelLockStore from '../../stores/FuelLockStore';

export default function isValidSerialNumber(str: string) {
    const formatError = isHex(str, 7, 'serial number');
    if (formatError !== '') {
        return 'Please enter a valid serial number';
    }
    const duplicate = fuelLockStore.systemData.deviceList.some(device => device.sn === str.toUpperCase());
    if (duplicate) {
        return 'You have already added a device with this serial number';
    }

    return '';
}
