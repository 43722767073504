/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import React from 'react';
import {Container, styled, TextField, Tooltip} from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import callAPI from '../../controllers/callAPI';
import {useSnackbar} from 'notistack';
import TitleTypography from '../common/typography/TitleTypography';
import SubmitButton from '../common/SubmitButton';
import determineMonthsPrior from '../../helper/functions/determineMonthsPrior';
import getTestID from '../../helper/functions/getTestID';
import TooltipWithButton from '../common/TooltipWithButton';
import TooltipStore from '../../stores/TooltipStore';
import {observer} from 'mobx-react-lite';
import TooltipMessages from '../../helper/TooltipMessages';
import downloadFileApi from '../../controllers/downloadFileAPI';
import { BalancePojo } from '../../types/components/ApiTypes';
import BaseModal from '../common/modal/BaseModal';
import Constants from '../../helper/Constants';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const UsageReport = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [ start, setStart ] = useState<any | null>(null);
    const [ end, setEnd ] = useState<any | null>(null);
    const [ activationDate, setActivationDate ] = useState<Date | null>(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ generateAction, setGenerateAction ] = useState('');

    useEffect(() => {
        callAPI<BalancePojo>('fuellock/v2/balance', (receivedData) => {
            if (receivedData.data.activationDate != null) {
                setActivationDate(new Date(receivedData.data.activationDate));
            }
        }, enqueueSnackbar);
    }, [])

    const determineMaxDate = () => {
        if (start) {
            const maxDate = new Date(start);
            maxDate.setMonth(maxDate.getMonth() + 12);
            if (maxDate > new Date()) {
                return new Date();
            }
            return maxDate;
        }
        return new Date();
    };

    const onSave = () => {
        //Making sure webapp is sending Time in UTC and 00:00 hrs
        const newStart = new Date(start);
        newStart.setUTCHours(0, 0, 0);
        const newEnd = new Date(end);
        newEnd.setUTCHours(0, 0, 0);
        callAPI(
            `fuellock/v1/generateUsageReport/${ newStart.getTime() / 1000 }/${
                newEnd.getTime() / 1000
            }`,
            () => {
                enqueueSnackbar('Report has been generated', {variant: 'success'});
            },
            enqueueSnackbar
        );
    };

    const onDownload = () => {
        //Making sure webapp is sending Time in UTC and 00:00 hrs
        const newStart = new Date(start);
        newStart.setUTCHours(0, 0, 0);
        const newEnd = new Date(end);
        newEnd.setUTCHours(0, 0, 0);
        downloadFileApi(
            `fuellock/v1/downloadUsageReport/${ newStart.getTime() / 1000 }/${
                newEnd.getTime() / 1000
            }`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'UsageReport',
            'xlsx',
            () => {
                enqueueSnackbar('Report has been downloaded', {variant: 'success'});
            }
        );
    };

    function monthsBetween(date1: Date, date2: Date): number {
        return (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
    }

    const addDays = (date: Date, days: number): Date => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    return (
        <Paper>
            <Root>
                <TitleTypography title="Usage Report"/>
                <Typography sx={{paddingBottom: '16px'}}>
                    Select a start and an end date for the report.
                </Typography>
                <Tooltip
                    open={
                        TooltipStore.beginTooltips &&
                        !TooltipStore.showDashboardMessages &&
                        !TooltipStore.showLightModeMessages &&
                        TooltipStore.showReportsMessages
                    }
                    arrow
                    title={
                        <TooltipWithButton
                            id="reports_tooltip_button"
                            message={TooltipMessages.REPORTS}
                            onClick={() => TooltipStore.setShowReportsMessages(false, true)}
                        />
                    }
                >
                    <div style={{width: '100%'}}>
                        <DatePicker
                            label="Start Date"
                            value={start}
                            onChange={(newValue) => setStart(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...getTestID('start_date_generate_report_calendar_input')}
                                    fullWidth
                                />
                            )}
                            minDate={activationDate != null && activationDate > determineMonthsPrior(24) ? moment(activationDate) : activationDate == null ?  new Date() : moment(determineMonthsPrior(24))}
                            maxDate={moment(new Date())}
                            InputAdornmentProps={{
                                ...getTestID('report_start_date_calendar_button'),
                            }}
                        />
                    </div>
                </Tooltip>

                <DatePicker
                    label="End Date"
                    value={end}
                    onChange={(newValue) => {
                        setEnd(newValue);
                    }}
                    disabled={start === null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...getTestID('end_date_generate_report_calendar_input')}
                        />
                    )}
                    minDate={start ? moment(new Date(start)) : moment(new Date())}
                    maxDate={moment(determineMaxDate())}
                    InputAdornmentProps={{
                        ...getTestID('report_end_date_calendar_button'),
                    }}
                />

                <SubmitButton
                    id="email_report_button"
                    text={ Constants.EMAIL_REPORT }
                    disabled={start === null || end === null || new Date(end) > addDays(determineMaxDate(), 1)}
                    onClick={() => {
                        if (monthsBetween(new Date(start), new Date(end)) >= 1) {
                            setShowModal(true);
                            setGenerateAction(Constants.EMAIL_REPORT);
                        } else {
                            onSave();
                        }
                    }}
                />
                <div style={{marginTop: 10}}>
                    <SubmitButton
                        id="download_report_button"
                        text={ Constants.DOWNLOAD_REPORT }
                        disabled={start === null || end === null || new Date(end) > addDays(determineMaxDate(), 1)}
                        onClick={() => {
                            if (monthsBetween(new Date(start), new Date(end)) >= 1) {
                                setShowModal(true);
                                setGenerateAction(Constants.DOWNLOAD_REPORT);
                            } else {
                                onDownload();
                            }
                        }}
                    />
                </div>
            </Root>

            {showModal && 
                <BaseModal
                    id="generate_large_report_modal"
                    open={showModal}
                    close={() => setShowModal(false)}
                    submit={() => {
                        if (generateAction === Constants.EMAIL_REPORT) {
                            onSave();
                        } else if (generateAction === Constants.DOWNLOAD_REPORT) {
                            onDownload();
                        }

                        setGenerateAction('');
                        setShowModal(false);
                    }}
                    title="Generating A Large Report"
                    submitButtonText="Generate"
                >
                    <Container>
                        <Typography {...getTestID('popup_message')}>
                            You are about to generate a large report. This may take several minutes. Please do not leave or refresh this page until the report has finished generating.
                        </Typography>
                    </Container>
                </BaseModal>
            }
        </Paper>
    );
});

export default UsageReport;
