/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import LabeledProgressTank from '../../devices/tanks/LabeledProgressTank';

interface Props {
    title: string,
    percentage: number,
}

const TankSummaryRow = observer ((props: Props) => {
    const percentage = props.percentage;

    return (
        <LabeledProgressTank
            key={`${ props.title }_${ percentage }`}
            title={props.title}
            percentage={percentage}
        />
    );
});

export default TankSummaryRow;