/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Paper from '@mui/material/Paper';
import Constants from '../../../helper/Constants';
import TitleTypography from '../../common/typography/TitleTypography';
import StyledSwitch from '../../common/input/StyledSwitch';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import TextFieldInput from '../../common/input/TextFieldInput';
import SubmitButton from '../../common/SubmitButton';
import {observer} from 'mobx-react-lite';
import FuelLockStore from '../../../stores/FuelLockStore';

interface Props {
    powerSupplyOne: InputFieldProps,
    powerSupplyTwo: InputFieldProps
    enableTwo: boolean
    setEnableTwo: (value: boolean) => void,
    width?: string,
    onUpdate?: () => void,
}

const PowerSupplyDetails = observer((props: Props) => (
    <Paper
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: props.width ? props.width : '75%',
        }}
    >
        <TitleTypography title={Constants.POWER_SUPPLY_DETAILS}/>
        <TextFieldInput
            id="power_supply_one_name_input"
            label={Constants.NAME}
            field={props.powerSupplyOne}
            textFieldProps={{
                inputProps: {
                    maxLength: 16,
                },
            }}
        />
        <StyledSwitch
            id="power_supply_2_switch"
            description="Power Supply 2"
            value={props.enableTwo}
            setValue={props.setEnableTwo}
        />
        <TextFieldInput
            id="power_supply_two_name_input"
            label={Constants.NAME}
            field={props.powerSupplyTwo}
            textFieldProps={{
                inputProps: {
                    maxLength: 16,
                },
                disabled: !props.enableTwo,
            }}
        />
        {props.onUpdate && (
            <SubmitButton
                id="update_power_supply_button"
                text="Update Power Supply"
                onClick={props.onUpdate}
                disabled={FuelLockStore.awaitingApi}
            />
        )}
    </Paper>
));

export default PowerSupplyDetails;
