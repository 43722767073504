/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import PaperTitle from '../common/typography/PaperTitle';
import Constants from '../../helper/Constants';
import BillingAddressForm from '../settings/adminAccount/personalDetails/BillingAddressForm';
import SubmitButton from '../common/SubmitButton';
import FuelLockStore from '../../stores/FuelLockStore';
import callAPI from '../../controllers/callAPI';
import isBillingAddressFormValid from '../../helper/complexValidators/isBillingAddressFormValid';
import callPostAPI from '../../controllers/callPostAPI';
import SuccessMessages from '../../helper/SuccessMessages';
import LoadingGrid from '../common/LoadingGrid';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const BillingInformationPanel = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const personalDetails = FuelLockStore.systemData.personalDetails;
    const [ loading, setLoading ] = useState(true);
    const [ postalCode, setPostalCode ] = useState(personalDetails.postalCode);
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ address, setAddress ] = useState(personalDetails.address);
    const [ addressError, setAddressError ] = useState('');
    const [ town, setTown ] = useState(personalDetails.city);
    const [ townError, setTownError ] = useState('');
    const [ country, setCountry ] = useState(personalDetails.country);
    const [ countryError, setCountryError ] = useState('');
    const [ province, setProvince ] = useState(personalDetails.province);
    const [ provinceError, setProvinceError ] = useState('');

    React.useEffect(() => {
        callAPI('fuellock/v2/billingAddress', (receivedData) => {
            setPostalCode(receivedData.data.postalCode);
            setAddress(receivedData.data.address);
            setTown(receivedData.data.city);
            setCountry(receivedData.data.country);
            setProvince(receivedData.data.province);
            setLoading(false);
        }, enqueueSnackbar);
    }, []);

    const isValid = () => isBillingAddressFormValid(
        {
            value: postalCode,
            setError: setPostalCodeError,
        },
        {
            value: address,
            setError: setAddressError,
        },
        {
            value: town,
            setError: setTownError,
        }, {
            value: country,
            setError: setCountryError,
        }, {
            value: province,
            setError: setProvinceError,
        });

    const onSave = () => {
        if (isValid()) {
            const body = {
                id: 0,
                address,
                city: town,
                province,
                postalCode,
                country,
            };

            callPostAPI('fuellock/v2/billingAddress', body, () => {
                }, enqueueSnackbar,
                SuccessMessages.UPDATE_BILLING_ADDRESS
            );
        }
    };

    if (loading) {
        return (
            <Paper>
                <Root>
                    <PaperTitle
                        title={Constants.BILLING_ADDRESS}
                    />
                    <LoadingGrid
                        numColumns={2}
                        numRows={3}
                    />
                </Root>
            </Paper>
        );
    }

    return (
        <Paper>
            <Root>
                <PaperTitle
                    title={Constants.BILLING_ADDRESS}
                />

                <BillingAddressForm
                    hideDivider
                    postalCode={{
                        value: postalCode,
                        setValue: setPostalCode,
                        error: postalCodeError,
                        setError: setPostalCodeError,
                    }}
                    address={{
                        value: address,
                        setValue: setAddress,
                        error: addressError,
                        setError: setAddressError,
                    }}
                    town={{
                        value: town,
                        setValue: setTown,
                        error: townError,
                        setError: setTownError,
                    }}
                    country={{
                        value: country,
                        setValue: setCountry,
                        error: countryError,
                        setError: setCountryError,
                    }}
                    province={{
                        value: province,
                        setValue: setProvince,
                        error: provinceError,
                        setError: setProvinceError,
                    }}
                />

                <SubmitButton
                    id="save_billing_address_button"
                    text="Save Billing Address"
                    disabled={loading || FuelLockStore.awaitingApi}
                    onClick={onSave}
                />
            </Root>
        </Paper>
    );
});

export default BillingInformationPanel;
