/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getArrayOfAllowedEmployeeOrEquipmentNewIndices = (data: {
    id: number; title: string; checked: boolean; isNew: boolean;
}[]) => {
    const allowedIndices: number[] = [];
    data.forEach(dataPoint => {
        if (dataPoint.checked && dataPoint.isNew) {
            allowedIndices.push(dataPoint.id * -1);
        }
    });
    return allowedIndices;
};

export default getArrayOfAllowedEmployeeOrEquipmentNewIndices;
