/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {styled} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import {parsePhoneNumber} from 'libphonenumber-js';
import Constants from '../../../helper/Constants';
import StartAndEndTimeSelect from '../../common/input/StartAndEndTimeSelect';
import PaperTitle from '../../common/typography/PaperTitle';
import SubmitButton from '../../common/SubmitButton';
import DeletableTextField from '../../common/input/DeletableTextField';
import FuelLockStore from '../../../stores/FuelLockStore';
import callAPI from '../../../controllers/callAPI';
import getAmPmHoursString from '../../../helper/functions/getAmPmHoursString';
import {Phone} from '../../../types/components/ApiTypes';
import isNumeric from '../../../helper/validator/isNumeric';
import callPostAPI from '../../../controllers/callPostAPI';
import getHours from '../../../helper/functions/getHours';
import StyledSwitch from '../../common/input/StyledSwitch';
import getTestID from '../../../helper/functions/getTestID';
import SuccessMessages from '../../../helper/SuccessMessages';
import LoadingGrid from '../../common/LoadingGrid';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const TextNotifications = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const personalDetails = FuelLockStore.systemData.personalDetails;
    const [ loading, setLoading ] = useState(true);
    const [ textEnabled, setTextEnabled ] = useState(true);
    const [ allDayEnabled, setAllDayEnabled ] = useState(true);
    const [ primaryPhoneNumber ] = useState(personalDetails.primaryPhone);
    const [ notificationStartTime, setNotificationStartTime ] = useState('6:00 am');
    const [ notificationEndTime, setNotificationEndTime ] = useState('6:00 pm');
    const [ phoneNumbers, setPhoneNumbers ] = useState<{
        id: number;
        userId?: number;
        phoneNumber: string;
        errorMsg: string;
    }[]>([]);

    React.useEffect(() => {
        callAPI('fuellock/v2/smsNotificationSettings', (receivedData) => {
            setTextEnabled(receivedData.data.notifUseText);
            setPhoneNumbers(receivedData.data.phones);
            const startTime = new Date(receivedData.data.startTime);
            const endTime = new Date(receivedData.data.endTime);

            if (startTime.getUTCHours() === 0 &&
                startTime.getUTCMinutes() === 0 &&
                endTime.getUTCHours() === 23 &&
                endTime.getUTCMinutes() === 59) {
                setAllDayEnabled(true);
            } else {
                setNotificationStartTime(getAmPmHoursString(startTime.getUTCHours()));
                setNotificationEndTime(getAmPmHoursString(endTime.getUTCHours()));
                setAllDayEnabled(false);
            }

            const newPhoneNumbers: { id: number; phoneNumber: string; errorMsg: string }[] = [];

            receivedData.data.phones.forEach((phone: Phone) => {
                newPhoneNumbers.push({
                    id: phone.id,
                    phoneNumber: `${ phone.areaCode }${ phone.phone }`,
                    errorMsg: '',
                });
            });
            setPhoneNumbers(newPhoneNumbers);

            setLoading(false);
        }, enqueueSnackbar);
    }, []);

    const isValid = () => {
        let valid = true;
        const newPhoneNumbers = cloneDeep(phoneNumbers);
        newPhoneNumbers.forEach((phone, index) => {
            const newNumberError = isNumeric(phone.phoneNumber, 10, '10-digit phone number');
            if (newNumberError !== '') {
                valid = false;
            }
            newPhoneNumbers[index].errorMsg = newNumberError;
        });
        setPhoneNumbers(newPhoneNumbers);

        return valid;
    };

    const getTime = (time: string, defaultHours: number, defaultMinutes: number) => {
        const date = new Date();
        if (allDayEnabled) {
            date.setUTCHours(defaultHours);
            date.setUTCMinutes(defaultMinutes);
        } else {
            date.setUTCHours(getHours(time));
            date.setUTCMinutes(0);
        }
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);

        return date.toISOString();
    };

    const getPhones = () => {
        const phones: {
            id: number;
            countryCode: string;
            areaCode: string;
            phone: string;
            phoneType: string;
        }[] = [];

        phoneNumbers.forEach(phone => {
            const parsedNumber = parsePhoneNumber(phone.phoneNumber, 'US');
            phones.push({
                id: phone.id,
                countryCode: parsedNumber.countryCallingCode.toString(),
                areaCode: parsedNumber.nationalNumber.slice(0, 3),
                phone: parsedNumber.nationalNumber.slice(3, parsedNumber.nationalNumber.length),
                phoneType: 'text',
            });
        });

        return phones;
    };

    const onSave = () => {
        if (isValid()) {
            const body = {
                notifUseText: textEnabled,
                hasUnlockAlert: true,
                startTime: getTime(notificationStartTime, 0, 0),
                endTime: getTime(notificationEndTime, 23, 59),
                phones: getPhones(),
            };
            callPostAPI('fuellock/v2/smsNotificationSettings',
                body,
                () => {
                },
                enqueueSnackbar, SuccessMessages.UPDATE_TEXT_NOTIFICATIONS
            );
        }
    };

    const onAddPhoneNumber = () => {
        setPhoneNumbers(oldData => {
            const newData = cloneDeep(oldData);
            newData.push({
                id: 0,
                phoneNumber: '',
                errorMsg: '',
            });

            return newData;
        });
    };

    const onRemovePhoneNumber = (index: number) => {
        setPhoneNumbers(oldData => {
            const newData = cloneDeep(oldData);
            newData.splice(index, 1);
            return newData;
        });
    };

    const editPhoneNumbers = (text: string, index: number) => {
        setPhoneNumbers(oldData => {
            const newData = cloneDeep(oldData);
            newData[index].phoneNumber = text;
            newData[index].errorMsg = '';

            return newData;
        });
    };

    if (loading) {
        return (
            <Paper>
                <Root>
                    <PaperTitle
                        title={Constants.TEXT_NOTIFICATIONS}
                    />
                    <LoadingGrid
                        numRows={4}
                        numColumns={1}
                    />
                </Root>
            </Paper>
        );
    }

    return (
        <Paper sx={{height: '100%'}}>
            <Root>
                <PaperTitle
                    title={Constants.TEXT_NOTIFICATIONS}
                />
                <StyledSwitch
                    id="enable_text_notifications_switch"
                    description="Enable text notifications"
                    value={textEnabled}
                    setValue={setTextEnabled}
                />
                <StyledSwitch
                    id="enable_all_day_notifications"
                    description="Enable 24-hour notifications"
                    value={allDayEnabled}
                    setValue={setAllDayEnabled}
                />
                {!allDayEnabled && (
                    <StartAndEndTimeSelect
                        id="text_notifications"
                        start={notificationStartTime}
                        handleChangeStart={setNotificationStartTime}
                        end={notificationEndTime}
                        handleChangeEnd={setNotificationEndTime}
                    />
                )}
                <Typography>{'Primary phone number: '}
                    <Box
                        component="span"
                        sx={{fontWeight: 600}}
                        {...getTestID('primary_phone_number')}
                    >
                        {primaryPhoneNumber}
                    </Box>
                </Typography>
                <Typography>Additional numbers to receive notifications:</Typography>
                {phoneNumbers.map((phoneNumber, index) => (
                    <DeletableTextField
                        id={`phone_number_${ index }_delete_input`}
                        key={phoneNumber.phoneNumber + index}
                        value={phoneNumber.phoneNumber}
                        errorMsg={phoneNumber.errorMsg}
                        onDeleteClick={() => onRemovePhoneNumber(index)}
                        onBlur={value => editPhoneNumbers(value, index)}
                    />
                ))}
                <Button
                    {...getTestID('add_phone_number_button')}
                    color="secondary"
                    onClick={onAddPhoneNumber}
                >
                    Add Another Phone Number
                </Button>
                <div style={{marginTop: 'auto'}}>
                    <SubmitButton
                        id="save_text_notifications_button"
                        text="Save Text Notifications"
                        onClick={onSave}
                    />
                </div>
            </Root>
        </Paper>
    );
});

export default TextNotifications;
