/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getHours = (time: string) => {
    const isAm = time.includes('am');
    const timeSplit = time.split(':');
    const hours = Number(timeSplit[0]);
    if (isAm) {
        return hours;
    }
    if (hours === 12) {
        return hours;
    }
    return hours + 12;
};

export default getHours;
