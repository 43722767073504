/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import { Device } from '../../types/components/ApiTypes';

const getAvailableFlowMeterPorts = (device: Device, tankId: any) => {
    const deviceTanks = device.tankList;

    const ports = [
        1, 2, 3, 4,
    ];

    deviceTanks.forEach(tank => {
        if (tank.flowMeter && tank.id !== tankId) {
            ports.splice(ports.indexOf(tank.flowMeter.flowMeterSlot), 1);
        }
    });
    return ports;
};

export default getAvailableFlowMeterPorts;
