/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isPasswordValid(password: string) {
    // eslint-disable-next-line no-control-regex,max-len
    const expression = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,50}$/i;

    if (!expression.test(String(password).toLowerCase()) || password.length < 8) {
        return 'Password must contain at least eight characters, at least one number, uppercase and lowercase letter';
    }

    return '';
}
