/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Typography from '@mui/material/Typography';

interface Props {
    memo: string,
}

function MemoTypography(props: Props) {
    return (
        <Typography
            sx={{
                fontSize: '14px',
            }}
        >
            {props.memo}
        </Typography>
    );
}

export default MemoTypography;
