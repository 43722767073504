/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import PowerSupplyDetails from '../wizard/PowerSupplyDetails';
import {Device, SupplyList} from '../../../types/components/ApiTypes';
import isPowerSupplyPageValid from '../../../helper/complexValidators/isPowerSupplyPageValid';
import cloneDeep from 'lodash/cloneDeep';
import callPostAPI from '../../../controllers/callPostAPI';
import Constants from '../../../helper/Constants';
import FuelLockStore from '../../../stores/FuelLockStore';
import callDeleteAPI from '../../../controllers/callDeleteAPI';
import callPutAPI from '../../../controllers/callPutAPI';
import SuccessMessages from '../../../helper/SuccessMessages';

interface Props {
    editingDevice: Device,
}

const EditPowerSupplyPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const findPowerSupplyList = () => JSON.parse(JSON.stringify(props.editingDevice.supplyList));

    const getPowerSupplyEnabled = (order: number, defaultData: boolean) => {
        const supplyList = findPowerSupplyList();
        for (let i = 0; i < supplyList.length; i += 1) {
            if (supplyList[i].order === order) {
                return true;
            }
        }
        return defaultData;
    };

    const getStartingPowerSupplyData = (order: number, key: string, defaultData: string | number) => {
        const supplyList = findPowerSupplyList();
        for (let i = 0; i < supplyList.length; i += 1) {
            if (supplyList[i].order === order) {
                return supplyList[i][key];
            }
        }
        return defaultData;
    };

    const [ powerSupplyPage, setPowerSupplyPage ] = useState({
        powerSupplyOne: getStartingPowerSupplyData(1, 'name', 'Power Supply 1'),
        powerSupplyOneError: '',
        enableOne: true,
        powerSupplyTwo: getStartingPowerSupplyData(2, 'name', 'Power Supply 2'),
        powerSupplyTwoError: '',
        enableTwo: getPowerSupplyEnabled(2, false),
    });

    const updatePowerSupplyPage = (value: string | boolean, key: string) => {
        setPowerSupplyPage({
            ...powerSupplyPage,
            [key]: value,
        });
    };

    const determinePowerSupplyState = (priorEnable: boolean, currentEnable: boolean) => {
        if (!priorEnable && currentEnable) {
            return Constants.POWER_SUPPLY_ADD;
        }
        if (priorEnable && !currentEnable) {
            return Constants.POWER_SUPPLY_DELETE;
        }
        if (priorEnable && currentEnable) {
            return Constants.POWER_SUPPLY_EDIT;
        }
        return 'No Operation';
    };

    const callPowerSupplyTwoOperations = () => {
        const priorEnabled = getPowerSupplyEnabled(2, false);
        const powerSupplyId = getStartingPowerSupplyData(2, 'id', -1);
        const priorDataName = getStartingPowerSupplyData(2, 'name', '');

        const powerSupplyState = determinePowerSupplyState(priorEnabled, powerSupplyPage.enableTwo);

        switch (powerSupplyState) {
            case Constants.POWER_SUPPLY_ADD:
                callPostAPI(`fuellock/v2/powerSupply/${ props.editingDevice.id }`,
                    {
                        id: 0,
                        name: powerSupplyPage.powerSupplyTwo,
                        order: 2,
                    }, (receivedServerData) => {
                        if (receivedServerData.status === 'success') {
                            const newData = cloneDeep(findPowerSupplyList());
                            newData.push({
                                id: receivedServerData.data,
                                name: powerSupplyPage.powerSupplyTwo,
                                order: 2,
                            });
                            FuelLockStore.setDeviceSupplyList(props.editingDevice.id, newData);
                        }
                    },
                    enqueueSnackbar, SuccessMessages.CREATE_POWER_SUPPLY);
                break;

            case Constants.POWER_SUPPLY_DELETE:
                callDeleteAPI(`fuellock/v2/powerSupply/${ props.editingDevice.id }/${ powerSupplyId }`,
                    (receivedServerData) => {
                        if (receivedServerData.status === 'success') {
                            const newData = cloneDeep(findPowerSupplyList());
                            const deletedIndex = newData.findIndex((a: SupplyList) => a.order === 2);
                            newData.splice(deletedIndex, 1);
                            FuelLockStore.setDeviceSupplyList(props.editingDevice.id, newData);
                        }
                    },
                    enqueueSnackbar, SuccessMessages.DELETE_POWER_SUPPLY);
                break;

            case Constants.POWER_SUPPLY_EDIT:
                if (priorDataName !== powerSupplyPage.powerSupplyTwo) {
                    callPutAPI(`fuellock/v2/powerSupply/${ props.editingDevice.id }/${ powerSupplyId }`, {
                            id: powerSupplyId,
                            name: powerSupplyPage.powerSupplyTwo,
                            order: 2,
                        },
                        (receivedServerData) => {
                            if (receivedServerData.status === 'success') {
                                const newData = cloneDeep(findPowerSupplyList());
                                const deletedIndex = newData.findIndex((a: SupplyList) => a.order === 2);
                                newData[deletedIndex].name = powerSupplyPage.powerSupplyTwo;
                                FuelLockStore.setDeviceSupplyList(props.editingDevice.id, newData);
                            }
                        },
                        enqueueSnackbar, SuccessMessages.UPDATE_POWER_SUPPLY);
                }
                break;

            default:
                break;
        }
    };

    const onUpdate = () => {
        if (isPowerSupplyPageValid(powerSupplyPage, setPowerSupplyPage)) {
            const priorEnabled = getPowerSupplyEnabled(1, true);
            const powerSupplyId = getStartingPowerSupplyData(1, 'id', -1);
            const priorDataName = getStartingPowerSupplyData(1, 'name', '');

            const powerSupplyState = determinePowerSupplyState(priorEnabled, true);

            switch (powerSupplyState) {
                case Constants.POWER_SUPPLY_ADD:
                    callPostAPI(`fuellock/v2/powerSupply/${ props.editingDevice.id }`,
                        {
                            id: 0,
                            name: powerSupplyPage.powerSupplyOne,
                            order: 1,
                        }, (receivedServerData) => {
                            if (receivedServerData.status === 'success') {
                                callPowerSupplyTwoOperations();
                                const newData = cloneDeep(findPowerSupplyList());
                                newData.push({
                                    id: receivedServerData.data,
                                    name: powerSupplyPage.powerSupplyOne,
                                    order: 1,
                                });
                                FuelLockStore.setDeviceSupplyList(props.editingDevice.id, newData);
                            }
                        }, enqueueSnackbar, SuccessMessages.CREATE_POWER_SUPPLY);

                    break;

                case Constants.POWER_SUPPLY_DELETE:
                    callDeleteAPI(`fuellock/v2/powerSupply/${ props.editingDevice.id }/${ powerSupplyId }`,
                        (receivedServerData) => {
                            if (receivedServerData.status === 'success') {
                                callPowerSupplyTwoOperations();
                                const newData = cloneDeep(findPowerSupplyList());
                                const deletedIndex = newData.findIndex((a: SupplyList) => a.order === 1);
                                newData.splice(deletedIndex, 1);
                                FuelLockStore.setDeviceSupplyList(props.editingDevice.id, newData);
                            }
                        }, enqueueSnackbar, SuccessMessages.DELETE_POWER_SUPPLY);

                    break;

                case Constants.POWER_SUPPLY_EDIT:
                    if (priorDataName !== powerSupplyPage.powerSupplyOne) {
                        callPutAPI(`fuellock/v2/powerSupply/${ props.editingDevice.id }/${ powerSupplyId }`, {
                                id: powerSupplyId,
                                name: powerSupplyPage.powerSupplyOne,
                                order: 1,
                            },
                            (receivedServerData) => {
                                if (receivedServerData.status === 'success') {
                                    callPowerSupplyTwoOperations();
                                    const newData = cloneDeep(findPowerSupplyList());
                                    const deletedIndex = newData.findIndex((a: SupplyList) => a.order === 1);
                                    newData[deletedIndex].name = powerSupplyPage.powerSupplyOne;
                                    FuelLockStore.setDeviceSupplyList(props.editingDevice.id, newData);
                                }
                            }, enqueueSnackbar, SuccessMessages.UPDATE_POWER_SUPPLY);
                    } else {
                        callPowerSupplyTwoOperations();
                    }
                    break;
                default:
                    callPowerSupplyTwoOperations();
                    break;
            }
        }
    };

    return (
        <PowerSupplyDetails
            powerSupplyOne={{
                value: powerSupplyPage.powerSupplyOne,
                setValue: (value: string) => updatePowerSupplyPage(value, 'powerSupplyOne'),
                error: powerSupplyPage.powerSupplyOneError,
                setError: (value: string) => updatePowerSupplyPage(value, 'powerSupplyOneError'),
            }}
            powerSupplyTwo={{
                value: powerSupplyPage.powerSupplyTwo,
                setValue: (value: string) => updatePowerSupplyPage(value, 'powerSupplyTwo'),
                error: powerSupplyPage.powerSupplyTwoError,
                setError: (value: string) => updatePowerSupplyPage(value, 'powerSupplyTwoError'),
            }}
            enableTwo={powerSupplyPage.enableTwo}
            setEnableTwo={(value: boolean) => updatePowerSupplyPage(value, 'enableTwo')}
            width="auto"
            onUpdate={onUpdate}
        />
    );
});

export default EditPowerSupplyPaper;
