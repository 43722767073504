/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import cloneDeep from 'lodash/cloneDeep';
import ContentPanel from './ContentPanel';
import DeviceOverview from '../devices/DeviceOverview';
import DeviceWizard from '../devices/DeviceWizard';
import WebStore from '../../stores/WebStore';
import {Device} from '../../types/components/ApiTypes';
import EditDevice from '../devices/EditDevice';
import getDataById from '../../helper/functions/getDataById';
import FuelLockStore from '../../stores/FuelLockStore';
import EditTank from '../devices/EditTank';
import TooltipStore from '../../stores/TooltipStore';
import TooltipAddFab from '../common/TooltipAddFab';
import TooltipMessages from '../../helper/TooltipMessages';
import Constants from '../../helper/Constants';

const Root = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
});

const Devices = observer(() => {
    const [ editingDevice, setEditingDevice ] = useState<Device | null>(null);
    const [ editingTankIds, setEditingTankIds ] = useState<{ deviceId: number, tankId: number } | null>(null);

    useEffect(() => {
        if (editingDevice) {
            const device = cloneDeep(toJS(getDataById(FuelLockStore.systemData.deviceList, editingDevice.id)));
            setEditingDevice({
                id: device.id,
                lastSync: device.lastSync,
                siteName: device.siteName,
                sn: device.sn,
                autolockTime: device.autolockTime,
                supplyList: device.supplyList,
                tankList: device.tankList,
                activeHours: device.activeHours,
                contractorPinSettings: device.contractorPinSettings,
                contractorPinList: device.contractorPinList,
                smartLockTime: device.smartLockTime,
            });
        }
    }, [ FuelLockStore.systemData.deviceList ]);

    const setOpen = (isOpen: boolean) => {
        if (isOpen) {
            WebStore.goToNewPage(Constants.ADD_NEW_DEVICE);
        }
        else {
            WebStore.goToPreviousPage();
        }
    };

    const renderContentPanel = () => {
        switch (WebStore.currentPage) {
            case Constants.MAIN:
                return (
                    <Root>
                        <DeviceOverview
                            setEditingDevice={setEditingDevice}
                            setEditingTankId={setEditingTankIds}
                        />
                    </Root>
                );
            case Constants.ADD_NEW_DEVICE:
                return (
                    <Root>
                        <DeviceWizard
                            setAdd={setOpen}
                        />
                    </Root>
                );
            default:
                return <div/>;
        }
    };

    if (editingDevice) {
        return (
            <ContentPanel>
                <Root>
                    <EditDevice
                        editingDevice={editingDevice}
                        close={() => setEditingDevice(null)}
                    />
                </Root>
            </ContentPanel>
        );
    }

    if (editingTankIds) {
        return (
            <ContentPanel>
                <Root>
                    <EditTank
                        editingIds={editingTankIds}
                        close={() => setEditingTankIds(null)}
                    />
                </Root>
            </ContentPanel>
        );
    }

    return (
        <ContentPanel>
            <Root>
                {renderContentPanel()}
            </Root>
            {WebStore.currentPage === Constants.MAIN && (
                <TooltipAddFab
                    tooltipId="add_new_device_tooltip_button"
                    tooltipOpen={TooltipStore.showAddDeviceMessages}
                    tooltipTitle={TooltipMessages.ADD_DEVICE}
                    closeTooltip={() => TooltipStore.setShowAddDeviceMessages(false, true)}
                    fabId="add_new_device_button"
                    onFabClick={() => WebStore.goToNewPage(Constants.ADD_NEW_DEVICE)}
                    fabTitle="Device"
                />
            )}
        </ContentPanel>
    );
});

export default Devices;
