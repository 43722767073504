/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';
import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import PaperTitle from '../../common/typography/PaperTitle';
import SubmitButton from '../../common/SubmitButton';
import DeletableTextField from '../../common/input/DeletableTextField';
import isEmailValid from '../../../helper/validator/isEmailValid';
import callAPI from '../../../controllers/callAPI';
import callPostAPI from '../../../controllers/callPostAPI';
import StyledSwitch from '../../common/input/StyledSwitch';
import LoginStore from '../../../stores/LoginStore';
import getTestID from '../../../helper/functions/getTestID';
import SuccessMessages from '../../../helper/SuccessMessages';
import Box from '@mui/material/Box';
import LoadingGrid from '../../common/LoadingGrid';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

interface Email {
    id?: number;
    email: string;
    errorMsg: string;
}

const EmailNotifications = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [ loading, setLoading ] = useState(true);
    const [ daily, setDaily ] = useState(false);
    const [ weekly, setWeekly ] = useState(false);
    const [ monthly, setMonthly ] = useState(true);
    const [ emails, setEmails ] = useState<Email[]>([]);

    React.useEffect(() => {
        callAPI('fuellock/v2/emailNotificationSettings', (receivedData) => {
            setDaily(receivedData.data.dailyReport);
            setWeekly(receivedData.data.weeklyReport);
            setMonthly(receivedData.data.monthlyReport);
            setEmails(receivedData.data.emails);
            setLoading(false);
        }, enqueueSnackbar);
    }, []);

    const isValid = () => {
        let valid = true;
        const newEmails = cloneDeep(emails);
        newEmails.forEach((email, index) => {
            const newEmailError = isEmailValid(email.email);
            if (newEmailError !== '') {
                valid = false;
            }
            newEmails[index].errorMsg = newEmailError;
        });
        setEmails(newEmails);

        return valid;
    };

    const saveEmail = () => {
        const body = {
            hasEmailReport: true,
            dailyReport: daily,
            weeklyReport: weekly,
            monthlyReport: monthly,
            emails,
        };
        if (isValid()) {
            callPostAPI('fuellock/v2/emailNotificationSettings', body,
                () => {
                },
                enqueueSnackbar, SuccessMessages.UPDATE_EMAIL_NOTIFICATIONS);
        }
    };

    const removeEmail = (index: number) => {
        setEmails(oldData => {
            const newData = cloneDeep(oldData);
            newData.splice(index, 1);
            return newData;
        });
    };

    const onAddNewEmail = () => {
        setEmails(oldData => {
            const newData = cloneDeep(oldData);
            newData.push({
                email: '',
                errorMsg: '',
            });

            return newData;
        });
    };

    const editEmails = (text: string, index: number) => {
        setEmails(oldData => {
            const newData = cloneDeep(oldData);
            newData[index].email = text;
            newData[index].errorMsg = '';

            return newData;
        });
    };

    if (loading) {
        return (
            <Paper>
                <Root>
                    <PaperTitle title={Constants.EMAIL_NOTIFICATIONS}/>
                    <LoadingGrid
                        numRows={4}
                        numColumns={1}
                    />
                </Root>
            </Paper>
        );
    }

    return (
        <Paper
            sx={{height: '100%'}}
        >
            <Root>
                <PaperTitle title={Constants.EMAIL_NOTIFICATIONS}/>
                <Typography>
                    Report emails will come with attachments you can open to view your Fuel Lock™ Device Usage.
                </Typography>
                <StyledSwitch
                    id="daily_switch"
                    description="Daily notifications"
                    value={daily}
                    setValue={setDaily}
                />
                <StyledSwitch
                    id="weekly_switch"
                    description="Weekly notifications"
                    value={weekly}
                    setValue={setWeekly}
                />
                <StyledSwitch
                    id="monthly_switch"
                    description="Monthly notifications"
                    value={monthly}
                    setValue={setMonthly}
                />

                <Typography>{'Primary email address: '}
                    <Box
                        component="span"
                        sx={{fontWeight: 600}}
                        {...getTestID('primary_email_address')}
                    >
                        {LoginStore.email}
                    </Box>
                </Typography>
                <Typography>Additional emails to receive reports:</Typography>
                {emails.map((email, index) => (
                    <DeletableTextField
                        id={`email_${ index }_delete_input`}
                        key={email.email + index}
                        value={email.email}
                        errorMsg={email.errorMsg}
                        onDeleteClick={() => removeEmail(index)}
                        onBlur={value => editEmails(value, index)}
                    />
                ))}

                <Button
                    {...getTestID('add_new_email_button')}
                    color="secondary"
                    onClick={onAddNewEmail}
                >
                    Add Another Email Address
                </Button>
                <div style={{marginTop: 'auto'}}>
                    <SubmitButton
                        id="save_email_notifications_button"
                        text="Save Email Notifications"
                        onClick={saveEmail}
                    />
                </div>
            </Root>
        </Paper>
    );
});

export default EmailNotifications;
