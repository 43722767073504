/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function makeNiceYInterval(yBottom: number, yTop: number) {
    if (yBottom === Infinity && yTop === -Infinity) {
        return {
            newYBottom: 0,
            newYTop: 10,
            gridSpacing: 1,
        };
    }

    const totalRange = yTop - yBottom;
    const gridRange = totalRange / 10.0;
    const gridRangeAsExponent = String(gridRange.toExponential());
    // 55.6789 will come back as 5.56789e+1
    const eLocationInString = gridRangeAsExponent.lastIndexOf('e');
    const mantissa = Number(gridRangeAsExponent.substr(0, eLocationInString));
    // mantissa = 5.56789
    const exp = Number(gridRangeAsExponent.substr(eLocationInString + 1));
    // exp = 1
    let newMantissa: number;
    const newExp = exp;
    if (mantissa < 1.0) {
        newMantissa = 1.0;
    } else if (mantissa < 2.0) {
        newMantissa = 2.0;
    } else if (mantissa < 3.0) {
        newMantissa = 3.0;
    } else if (mantissa < 5.0) {
        newMantissa = 5.0;
    } else {
        newMantissa = 10.0;
    }

    const newGridSpacing = newMantissa * (10 ** newExp);
    const newRange = 10 * newGridSpacing;
    // ctx.fillText(newRange, 10, 100);
    const deltaYOffset = (newRange - totalRange) / 2;
    // ctx.fillText(deltaYOffset, 10, 120);
    let y2 = yTop + deltaYOffset;
    // ctx.fillText(y2, 10, 140);
    y2 = Math.floor(y2);
    const y1 = y2 - newRange;
    return {
        newYBottom: y1,
        newYTop: y2,
        gridSpacing: newGridSpacing,
    };
};
