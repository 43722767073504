/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Chart from 'react-apexcharts';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import {Theme} from '../../../../types/components/ApiTypes';
import {observer} from 'mobx-react-lite';
import ChartMenu from '../ChartMenu';
import WebStore from '../../../../stores/WebStore';
import getTestID from '../../../../helper/functions/getTestID';

interface Props {
    title: string,
    id: string,
    selectedTimeFrame: string,
    setSelectedTimeFrame: (value: string) => void,
    categories: string[],
    series: {
        name: string,
        data: number[],
    }[],
    paperRef: any,
    paperStyle: object,
    unit: {
        name: string,
        symbol: string,
    },
}

const LineAreaBarChart = observer((props: Props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ options, setOptions ] = useState({
        chart: {
            id: 'combo-chart',
            toolbar: {
                show: false,
            },
            height: '100%',
            type: props.series.length === 1 ? 'line' : 'bar',
            stacked: false,
            fontFamily: 'Gibson',
            background: WebStore.lightMode ? '#fff' : '#2c2c2c',
        },
        colors: [ '#fdab29', '#ffe804', '#299aff', '#d8dcdb' ],
        title: {
            text: props.title,
            margin: 20,
            style: {
                fontSize: 20,
                fontWeight: 400,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
            },
        },
        fill: {
            opacity: [ 0.85, 0.25, 1 ],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: 'vertical',
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [ 0, 100, 100, 100 ],
            },
        },
        labels: props.categories,
        markers: {
            size: 5,
        },
        yaxis: {
            decimalsInFloat: 1,
            labels: {
                formatter(val: string | number) {
                    return `${ val }${ props.unit.symbol }`;
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter(y: any) {
                    if (typeof y !== 'undefined') {
                        return `${ y }${ props.unit.name }`;
                    }
                    return y;
                },
            },
        },
        theme: {
            mode: WebStore.lightMode ? Theme.light : Theme.dark,
        },
    });

    useEffect(() => {
        setOptions({
            ...options,
            chart: {
                ...options.chart,
                background: WebStore.lightMode ? '#fff' : '#2c2c2c',
            },
            labels: props.categories,
            theme: {
                mode: WebStore.lightMode ? Theme.light : Theme.dark,
            },
            tooltip: {
                ...options.tooltip,
                shared: true,
                intersect: false,
                y: {
                    formatter(y: any) {
                        if (typeof y !== 'undefined') {
                            return `${ y } ${ props.unit.symbol }`;
                        }
                        return y;
                    },
                },
            },
        });
    }, [ props.categories, WebStore.lightMode ]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const backgroundColor = !WebStore.lightMode ? {
        backgroundColor: '#2c2c2c',
        backgroundImage: 'none',
    } : {};

    return (
        <Paper
            sx={{
                padding: 0,
                ...props.paperStyle,
                ...backgroundColor,
            }}
            ref={props.paperRef}
        >
            <IconButton
                {...getTestID(props.id)}
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 16,
                    padding: 0,
                    zIndex: 9999,
                }}
                onClick={handleClick}
            >
                <MoreVert/>
            </IconButton>
            {Boolean(anchorEl) && (
                <ChartMenu
                    id={props.id}
                    anchorEl={anchorEl}
                    open
                    handleClose={handleClose}
                    selected={props.selectedTimeFrame}
                    setSelected={props.setSelectedTimeFrame}
                />
            )}
            <Chart
                //@ts-ignore
                options={options}
                series={props.series}
                type="line"
                width="100%"
                style={{minHeight: null}}
            />
        </Paper>
    );
});

export default LineAreaBarChart;
