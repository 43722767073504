/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import getTestID from '../../../helper/functions/getTestID';

interface Props{
    id: string,
    description: string,
    value: boolean,
    setValue: (value: boolean) => void;
    disabled?: boolean;
}

function StyledSwitch(props: Props) {
    return (
        <FormControlLabel
            control={
                <Switch
                    {...getTestID(props.id)}
                    checked={props.value}
                    onChange={() => props.setValue(!props.value)}
                    name="checked"
                    color="primary"
                    disabled={props.disabled}
                />
            }
            label={props.description}
        />
    );
}

export default StyledSwitch;
