/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BillingAddressForm from '../../settings/adminAccount/personalDetails/BillingAddressForm';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import PersonalDetailsForm from '../../settings/adminAccount/personalDetails/PersonalDetailsForm';
import StyledSwitch from '../../common/input/StyledSwitch';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

interface Props {
    email: InputFieldProps,
    password: InputFieldProps,
    confirmPassword: InputFieldProps,
    firstName: InputFieldProps,
    lastName: InputFieldProps,
    companyName: InputFieldProps,
    deviceAdminDisplayName: InputFieldProps,
    phoneNumber: InputFieldProps,
    postalCode: InputFieldProps,
    address: InputFieldProps,
    town: InputFieldProps,
    province: InputFieldProps,
    country: InputFieldProps,
    matchBillingAddress: boolean,
    setMatchBillingAddress: (newValue: boolean) => void,
    billingAddressPostalCode: InputFieldProps,
    billingAddressAddress: InputFieldProps,
    billingAddressTown: InputFieldProps,
    billingAddressProvince: InputFieldProps,
    billingAddressCountry: InputFieldProps,
    receiveEmail: boolean,
    setReceiveEmail: (newValue: boolean) => void,
}

function NewAccountInformation(props: Props) {
    return (
        <Paper
            sx={{
                width: '50%',
                minWidth: '444px',
                maxHeight: '80%',
                overflow: 'auto',
            }}
        >
            <Container>
                <Typography
                    sx={{
                        fontSize: '24px',
                        paddingBottom: '32px',
                    }}
                >
                    Register New User
                </Typography>

                <PersonalDetailsForm
                    showEmailAndPassword
                    email={props.email}
                    password={props.password}
                    confirmPassword={props.confirmPassword}
                    firstName={props.firstName}
                    lastName={props.lastName}
                    companyName={props.companyName}
                    deviceAdminDisplayName={props.deviceAdminDisplayName}
                    phoneNumber={props.phoneNumber}
                />
                <BillingAddressForm
                    postalCode={props.postalCode}
                    address={props.address}
                    town={props.town}
                    province={props.province}
                    country={props.country}
                />
                <StyledSwitch
                    id="use_above_billing_address_switch"
                    description="Use above as Billing Address"
                    value={props.matchBillingAddress}
                    setValue={props.setMatchBillingAddress}
                />
                {!props.matchBillingAddress && (
                    <BillingAddressForm
                        id="billing_address"
                        hasTitle
                        postalCode={props.billingAddressPostalCode}
                        address={props.billingAddressAddress}
                        town={props.billingAddressTown}
                        province={props.billingAddressProvince}
                        country={props.billingAddressCountry}
                    />
                )}

                <StyledSwitch
                    id="receive_email_switch"
                    description="Allow Calian Agriculture to send you news and product updates by email"
                    value={props.receiveEmail}
                    setValue={props.setReceiveEmail}
                />
            </Container>
        </Paper>
    );
}

export default NewAccountInformation;