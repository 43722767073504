/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Divider, ListItemIcon, MenuItem} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Logout, Person} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import PopupMenu from './PopupMenu';
import LoginStore from '../../stores/LoginStore';
import getTestID from '../../helper/functions/getTestID';
import logout from '../../helper/functions/logout';

interface Props {
    iconButton: object,
    disabled?: boolean,
}

const UserPopup = observer((props: Props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                {...getTestID('user_icon_button')}
                sx={props.iconButton}
                onClick={handleClick}
                disabled={props.disabled}
            >
                <Person/>
            </IconButton>
            <PopupMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
            >
                <MenuItem {...getTestID('email_user_button')}>
                    {LoginStore.email}
                </MenuItem>
                <Divider/>
                <MenuItem
                    {...getTestID('logout_button')}
                    onClick={logout}
                >
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </PopupMenu>
        </div>
    );
});

export default UserPopup;
