/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import TextFieldInput from './TextFieldInput';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import getTestID from '../../../helper/functions/getTestID';
import {Visibility, VisibilityOff} from '@mui/icons-material';

interface Props {
    field: InputFieldProps,
    id: string,
    label: string,
}

const PasswordField = (props: Props) => {
    const [ visible, setVisible ] = useState(false);

    return (
        <TextFieldInput
            id={props.id}
            label={props.label}
            field={props.field}
            textFieldProps={{
                inputProps: {
                    maxLength: 25,
                    type: visible ? 'text' : 'password',
                },
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                {...getTestID(`${ props.id }_visible`)}
                                onClick={() => setVisible(!visible)}
                            >
                                {visible ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
};

export default PasswordField;
