/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import Constants from '../helper/Constants';

class TooltipStore {
    beginTooltips = false;

    showIntroMessages = true;

    showDashboardMessages = true;

    showLightModeMessages = true;

    showAddEmployeeMessages = true;

    showEmployeeMessages = true;

    showAddEquipmentMessages = true;

    showEquipmentMessages = true;

    showAddContractorPinMessages = true;

    showContractorPinMessages = true;

    showReportsMessages = true;

    showAccountPreferencesMessages = true;

    showDeviceMessages = true;

    showAddDeviceMessages = true;

    showTankDeviceMessages = true;

    constructor() {
        makeObservable(this, {
            beginTooltips: observable,
            setBeginTooltips: action,
            showIntroMessages: observable,
            setShowIntroMessages: action,
            showDashboardMessages: observable,
            setShowDashboardMessages: action,
            showLightModeMessages: observable,
            setShowLightModeMessages: action,
            showContractorPinMessages: observable,
            setShowContractorPinMessages: action,
            showAddContractorPinMessages: observable,
            setShowAddContractorPinMessages: action,
            showEmployeeMessages: observable,
            setShowEmployeeMessages: action,
            showAddEmployeeMessages: observable,
            setShowAddEmployeeMessages: action,
            showEquipmentMessages: observable,
            setShowEquipmentMessages: action,
            showAddEquipmentMessages: observable,
            setShowAddEquipmentMessages: action,
            showAccountPreferencesMessages: observable,
            setShowAccountPreferencesMessages: action,
            showReportsMessages: observable,
            setShowReportsMessages: action,
            showDeviceMessages: observable,
            setShowDeviceMessages: action,
            showAddDeviceMessages: observable,
            setShowAddDeviceMessages: action,
            showTankDeviceMessages: observable,
            setShowTankDeviceMessages: observable,
            setAllMessages: action,
        });
        this.initializeFromAsyncStorage();
    };

    setBeginTooltips = (flag: boolean, sync: boolean) => {
        this.beginTooltips = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_BEGIN_TOOLTIPS, flag.toString());
        }
    };

    setShowIntroMessages = (flag: boolean, sync: boolean) => {
        this.showIntroMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_INTRO_MESSAGES, flag.toString());
        }
    };

    setShowDashboardMessages = (flag: boolean, sync: boolean) => {
        this.showDashboardMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_DASHBOARD_MESSAGES, flag.toString());
        }
    };

    setShowLightModeMessages = (flag: boolean, sync: boolean) => {
        this.showLightModeMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_LIGHT_MODE_MESSAGES, flag.toString());
        }
    };

    setShowContractorPinMessages = (flag: boolean, sync: boolean) => {
        this.showContractorPinMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_CONTRACTOR_PIN_MESSAGES, flag.toString());
        }
    };

    setShowAddContractorPinMessages = (flag: boolean, sync: boolean) => {
        this.showAddContractorPinMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_ADD_CONTRACTOR_PIN_MESSAGES, flag.toString());
        }
    };

    setShowEmployeeMessages = (flag: boolean, sync: boolean) => {
        this.showEmployeeMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_EMPLOYEE_MESSAGES, flag.toString());
        }
    };

    setShowAddEmployeeMessages = (flag: boolean, sync: boolean) => {
        this.showAddEmployeeMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_ADD_EMPLOYEE_MESSAGES, flag.toString());
        }
    };

    setShowEquipmentMessages = (flag: boolean, sync: boolean) => {
        this.showEquipmentMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_EQUIPMENT_MESSAGES, flag.toString());
        }
    };

    setShowAddEquipmentMessages = (flag: boolean, sync: boolean) => {
        this.showAddEquipmentMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_ADD_EQUIPMENT_MESSAGES, flag.toString());
        }
    };

    setShowReportsMessages = (flag: boolean, sync: boolean) => {
        this.showReportsMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_REPORTS_MESSAGES, flag.toString());
        }
    };

    setShowAccountPreferencesMessages = (flag: boolean, sync: boolean) => {
        this.showAccountPreferencesMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_ACCOUNT_PREFERENCES_MESSAGES, flag.toString());
        }
    };

    setShowDeviceMessages = (flag: boolean, sync: boolean) => {
        this.showDeviceMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_DEVICE_MESSAGES, flag.toString());
        }
    };

    setShowAddDeviceMessages = (flag: boolean, sync: boolean) => {
        this.showAddDeviceMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_ADD_DEVICE_MESSAGES, flag.toString());
        }
    };

    setShowTankDeviceMessages = (flag: boolean, sync: boolean) => {
        this.showTankDeviceMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_TANK_DEVICE_MESSAGES, flag.toString());
        }
    };

    setAllMessages = (flag: boolean) => {
        this.setShowIntroMessages(flag, true);
        this.setShowDashboardMessages(flag, true);
        this.setShowLightModeMessages(flag, true);
        this.setShowContractorPinMessages(flag, true);
        this.setShowAddContractorPinMessages(flag, true);
        this.setShowEmployeeMessages(flag, true);
        this.setShowAddEmployeeMessages(flag, true);
        this.setShowAddEquipmentMessages(flag, true);
        this.setShowEquipmentMessages(flag, true);
        this.setShowReportsMessages(flag, true);
        this.setShowAccountPreferencesMessages(flag, true);
        this.setShowDeviceMessages(flag, true);
        this.setShowAddDeviceMessages(flag, true);
        this.setShowTankDeviceMessages(flag, true);
    };

    syncWithAsyncStorage = (key: string, value: string) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    };

    initializeFromAsyncStorage = () => {
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_BEGIN_TOOLTIPS, this.setBeginTooltips);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_INTRO_MESSAGES, this.setShowIntroMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_DASHBOARD_MESSAGES, this.setShowDashboardMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_LIGHT_MODE_MESSAGES, this.setShowLightModeMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_CONTRACTOR_PIN_MESSAGES, this.setShowContractorPinMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_EMPLOYEE_MESSAGES, this.setShowEmployeeMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_EQUIPMENT_MESSAGES, this.setShowEquipmentMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_REPORTS_MESSAGES, this.setShowReportsMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_ACCOUNT_PREFERENCES_MESSAGES,
            this.setShowAccountPreferencesMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_DEVICE_MESSAGES, this.setShowDeviceMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_ADD_CONTRACTOR_PIN_MESSAGES,
            this.setShowAddContractorPinMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_ADD_EMPLOYEE_MESSAGES,
            this.setShowAddEmployeeMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_ADD_EQUIPMENT_MESSAGES,
            this.setShowAddEquipmentMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_ADD_DEVICE_MESSAGES, this.setShowAddDeviceMessages);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_SHOW_TANK_DEVICE_MESSAGES, this.setShowTankDeviceMessages);
    };

    getBooleanFromAsyncStorage = (key: string, setter: (value: boolean, sync: boolean) => void) => {
        const result = global.localStorage.getItem(key);
        if (result !== null) {
            setter(JSON.parse(result) === 'true', false);
        }
    };
}

export default new TooltipStore();
