/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getSelectOptions = (dataSource: any, titleKey: string, id: string) => {
    const options: { title: string; id: string; }[] = [];

    dataSource.forEach((item: object) => {
        options.push({
            title: item[titleKey],
            id: `${ item[titleKey] }_${ id }_option`,
        });
    });
    return options;
};

export default getSelectOptions;
