/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const createMsg = 'Successfully created';
const deleteMsg = 'Successfully deleted';
const transferMsg = 'Successfully transferred';
const updateMsg = 'Successfully updated';
const requestMsg = 'Successfully requested';
const addMsg = 'Successfully added';
const removeMsg = 'Successfully removed';

export default {
    ADD_CONTRACTOR_PIN_ACCESS: `${ addMsg } contractor ID access`,

    CREATE_CONTRACTOR_PIN: `${ createMsg } contractor ID`,
    CREATE_DEVICE: `${ createMsg } device`,
    CREATE_EMPLOYEE: `${ createMsg } employee`,
    CREATE_EQUIPMENT: `${ createMsg } equipment`,
    CREATE_FLOW_METER: `${ createMsg } flow meter`,
    CREATE_POWER_SUPPLY: `${ createMsg } power supply`,
    CREATE_TANK: `${ createMsg } tank`,

    DELETE_ACCOUNT: `${ deleteMsg } account`,
    DELETE_CONTRACTOR_PIN: `${ deleteMsg } contractor ID`,
    DELETE_DEVICE: `${ deleteMsg } device`,
    DELETE_EMPLOYEE: `${ deleteMsg } employee`,
    DELETE_EQUIPMENT: `${ deleteMsg } equipment`,
    DELETE_FLOW_METER: `${ deleteMsg } flow meter`,
    DELETE_LEVEL_SENSOR: `${ deleteMsg } level sensor`,
    DELETE_POWER_SUPPLY: `${ deleteMsg } power supply`,
    DELETE_TANK: `${ deleteMsg } tank`,

    REMOVE_CONTRACTOR_PIN_ACCESS: `${ removeMsg } contractor ID access`,

    TRANSFER_DEVICE: `${ transferMsg } device`,

    UPDATE_ACCOUNT_PREFERENCES: `${ updateMsg } account preferences`,
    UPDATE_ACTIVE_HOURS: `${ updateMsg } active hours`,
    UPDATE_BILLING_ADDRESS: `${ updateMsg } billing address`,
    UPDATE_CONTRACTOR_PIN: `${ updateMsg } contractor ID`,
    UPDATE_CUSTOM_FIELD_ENTRY: `${ updateMsg } custom field entry`,
    UPDATE_DEVICE: `${ updateMsg } device`,
    UPDATE_EMAIL_NOTIFICATIONS: `${ updateMsg } email notifications`,
    UPDATE_EMPLOYEE: `${ updateMsg } employee`,
    UPDATE_EQUIPMENT: `${ updateMsg } equipment`,
    UPDATE_FLOW_METER: `${ updateMsg } flow meter`,
    UPDATE_LEVEL_SENSOR: `${ updateMsg } level sensor`,
    UPDATE_PASSWORD: `${ updateMsg } password`,
    UPDATE_PERSONAL_DETAILS: `${ updateMsg } personal details`,
    UPDATE_PIN: `${ updateMsg } pin`,
    UPDATE_POWER_SUPPLY: `${ updateMsg } power supply`,
    UPDATE_SMART_LOCK_SETTINGS: `${ updateMsg } smart lock settings`,
    UPDATE_TANK: `${ updateMsg } tank`,
    UPDATE_TEXT_NOTIFICATIONS: `${ updateMsg } text notifications`,

    PASSWORD_RESET: `${ requestMsg } password reset`,
};
