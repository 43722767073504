/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Checkbox, Divider, IconButton, styled, Typography} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import {Delete, Edit} from '@mui/icons-material';

const Root = styled('div')({
    width: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const LeftRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    title: string,
    checked: boolean,
    onChange: () => void,
    isNew?: boolean,
    onEdit?: () => void,
    onDelete?: () => void,
}

export default function CheckboxRow(props: Props) {
    return (
        <Root>
            <Row>
                <LeftRow>
                    <Typography>{props.title}</Typography>
                    {props.isNew && props.onEdit && (
                        <IconButton onClick={props.onEdit}>
                            <Edit/>
                        </IconButton>
                    )}
                    {props.isNew && props.onDelete && (
                        <IconButton onClick={props.onDelete}>
                            <Delete/>
                        </IconButton>
                    )}
                </LeftRow>
                <Checkbox
                    {...getTestID(`${ props.title }_checkbox_${ props.checked }`)}
                    checked={props.checked}
                    onClick={props.onChange}
                />
            </Row>
            <Divider/>
        </Root>
    );
}
