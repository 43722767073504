/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import handleError from './handleError';
import loginStore from '../stores/LoginStore';
import fuelLockStore from '../stores/FuelLockStore';
import getBaseURL from './getBaseURL';
import urlStore from '../stores/urlStore';
import getUserAcceptHeader from './getUserAcceptHeader';
import {IGTResponse} from '../types/components/ApiTypes';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import showStatusFailMessage from './showStatusFailMessage';
import displaySuccessMessage from './displaySuccessMessage';

const callPostAPI = <T = any>(uri: string,
                              body: object,
                              onSuccess: (response: IGTResponse<T>) => void,
                              enqueueSnackbar: EnqueueSnackbar,
                              successMessage: string,
                              userAccept = false,
                              onFail?: () => void) => {
    setTimeout(() => {
        fuelLockStore.setAwaitingApi(true);
    }, 50);

    fetch(
        `${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: loginStore.token,
                udd: loginStore.udd.toString(),
                deviceID: loginStore.deviceID,
                'fl-platform': 'webapp',
                ...getUserAcceptHeader(userAccept),
            },
            body: JSON.stringify(body),
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar);
            return response.json();
        })
        .then((receivedServerData) => {
            setTimeout(() => {
                fuelLockStore.setAwaitingApi(false);
            }, 300);

            if (receivedServerData.status && receivedServerData.status === 'fail') {
                showStatusFailMessage(receivedServerData, enqueueSnackbar);
                if (onFail) {
                    onFail();
                }
                console.log('failed');
            } else if (receivedServerData.status && receivedServerData.status === 'confirm') {
                setTimeout(() => {
                    fuelLockStore.setConfirm({
                        title: receivedServerData.messageTitle,
                        message: receivedServerData.message,
                        onConfirm: () => {
                            //closing The modal as iOS doesn't close it.
                            fuelLockStore.setConfirm({
                                title: '',
                                message: '',
                                onConfirm: () => {
                                },
                            });
                            callPostAPI(uri, body, onSuccess, enqueueSnackbar, successMessage, true);
                        },
                    });
                    if (onFail) {
                        onFail();
                    }
                }, 450);
            } else {
                displaySuccessMessage(receivedServerData, successMessage, enqueueSnackbar);

                setTimeout(() => {
                    onSuccess(receivedServerData);
                }, 50);
            }
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.message);
            fuelLockStore.setAwaitingApi(false);
        });
};

export default callPostAPI;
