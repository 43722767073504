/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {Device} from '../../types/components/ApiTypes';
import TitleTypography from '../common/typography/TitleTypography';
import {IconButton, styled, Tab, Tabs} from '@mui/material';
import {Close} from '@mui/icons-material';
import EditDevicePaper from './editDevice/EditDevicePaper';
import EditPowerSupplyPaper from './editDevice/EditPowerSupplyPaper';
import EditDeviceActiveHours from './editDevice/EditDeviceActiveHours';
import EditEmployeeAccess from './editDevice/EditEmployeeAccess';
import EditEquipmentAccess from './editDevice/EditEquipmentAccess';
import getTestID from '../../helper/functions/getTestID';
import Constants from '../../helper/Constants';
import TransferDevicePaper from './editDevice/TransferDevicePaper';
import EditContractorPinAccess from './editDevice/EditContractorPinAccess';
import FuelLockStore from '../../stores/FuelLockStore';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

interface Props {
    editingDevice: Device;
    close: () => void;
}

export default function EditDevice(props: Props) {
    const [ selectedTab, setSelectedTab ] = useState(0);

    const handleChange = (event: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    const renderTab = (id: string, label: string) => (
        <Tab
            {...getTestID(id)}
            label={label}
        />
    );

    const renderTabContent = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <div>
                        <EditDevicePaper editingDevice={props.editingDevice}/>
                        <EditPowerSupplyPaper editingDevice={props.editingDevice}/>
                        <TransferDevicePaper editingDevice={props.editingDevice}/>
                    </div>
                );
            case 1:
                return (
                    <EditDeviceActiveHours editingDevice={props.editingDevice}/>
                );
            case 2:
                return (
                    <EditEmployeeAccess editingDevice={props.editingDevice}/>
                );
            case 3:
                return (
                    <EditEquipmentAccess editingDevice={props.editingDevice}/>
                );
            case 4:
                return (
                    <EditContractorPinAccess editingDevice={props.editingDevice}/>
                );
            default:
                return (<div/>);
        }
    };

    return (
        <Root>
            <Row>
                <TitleTypography title={`Editing Device: ${ props.editingDevice.siteName }`}/>
                <IconButton
                    {...getTestID('close_edit_device_button')}
                    onClick={props.close}
                >
                    <Close/>
                </IconButton>
            </Row>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                centered
            >
                {renderTab('device_settings_tab', Constants.SETTINGS)}
                {renderTab('device_active_hours_tab', Constants.ACTIVE_HOURS)}
                {renderTab('device_employee_access_tab', Constants.EMPLOYEE_ACCESS)}
                {renderTab('device_equipment_access_tab', Constants.EQUIPMENT_ACCESS)}
                {FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_CONTRACTOR_PIN &&
                    props.editingDevice.contractorPinSettings.enabled &&
                    renderTab('device_contractor_pin_access_tab', Constants.CONTRACTOR_PIN_ACCESS)}
            </Tabs>
            {renderTabContent()}
        </Root>
    );
}
