/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';

const Root = styled('div')({
    flex: 1,
    display: 'flex',
    minWidth: '800px',
});

const ScrollArea = styled('div')({
    height: '100%',
    width: '100%',
    overflow: 'auto',
});

const Content = styled('div')({
    padding: '16px',
});

interface Props {
    children: object,
}

function ContentPanel(props: Props) {
    return (
        <Root>
            <ScrollArea>
                <Content>
                    {props.children}
                </Content>
            </ScrollArea>
        </Root>
    );
}

export default ContentPanel;
