/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Skeleton, styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import callAPI from '../../controllers/callAPI';
import WebStore from '../../stores/WebStore';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const PayNowPanel = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [ loading, setLoading ] = React.useState(true);
    const [ html, setHtml ] = React.useState('');
    React.useEffect(() => {
        setTimeout(() => {
            callAPI('fuellock/v2/paymentForm', (receivedData) => {
                if (receivedData.status === 'success') {
                    window.history.pushState({}, '', '/'); // this allows back arrow to redirect to fuel lock from stripe
                    setLoading(false);
                    setHtml(receivedData.data);
                    console.log(receivedData.data);
                }
            }, enqueueSnackbar, false, () => {}, WebStore.goToPreviousPage);
        }, 800);
    }, []);

    if (loading) {
        return (
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                    width: '100%',
                    maxWidth: 'none',
                    height: '250px',
                    borderRadius: '12px',
                }}
            />
        );
    }

    return (
        <Paper>
            <Root>
                <div
                    className="content"
                    dangerouslySetInnerHTML={{__html: html}}
                />

            </Root>
        </Paper>
    );
});

export default PayNowPanel;
