/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import { FormControl, FormHelperText, styled, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import BaseModal from '../common/modal/BaseModal';
import TextFieldInput from '../common/input/TextFieldInput';
import Constants from '../../helper/Constants';
import { Device, IGTResponse } from '../../types/components/ApiTypes';
import isValidSerialNumber from '../../helper/validator/isValidSerialNumber';
import callAPI from '../../controllers/callAPI';
import { useSnackbar } from 'notistack';
import callPostAPI from '../../controllers/callPostAPI';
import SuccessMessages from '../../helper/SuccessMessages';
import getTestID from '../../helper/functions/getTestID';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    editingDevice: Device,
    serialNumberDisabled: boolean,
    onSubmit: () => void,
}

const DeviceTransferModal = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ fromSerial ] = useState(props.editingDevice ? props.editingDevice.sn : '');
    const [ toSerialError, setToSerialError ] = useState<string>('');
    const [ toSerialServerError, setToSerialServerError ] = useState<string>('');
    const [ toSerial, setToSerial ] = useState<string>('');

    const submit = () => {
        setToSerialError(isValidSerialNumber(toSerial));

        if (!toSerialError) {
            callAPI(`fuellock/v1/device/validate/sn/${ toSerial }`, (response: IGTResponse<boolean>) => {
                setToSerialServerError(response.data ? ''
                : 'A matching device could not be found. Device already in use, or entered SN was invalid.');
                if (response.data) {
                    callPostAPI(`fuellock/v2/device/transfer/${ fromSerial }/${ toSerial }`,
                    props.editingDevice, (transferResponse: IGTResponse<boolean>) => {
                    setToSerialError(transferResponse.data ? '' : 'Device transfer failed');
                    props.onSubmit();
                    }, enqueueSnackbar, SuccessMessages.TRANSFER_DEVICE);
                }
            }, enqueueSnackbar);
        } else {
            setToSerialServerError('');
        }
    };

    return (
        <BaseModal
            id="transfer_device_modal"
            open={props.open}
            close={props.close}
            submit={submit}
            title='Transfer Device'
            submitButtonText='Transfer'
            disabledButton={toSerial.length != 7}
        >
            <Container>
                <Typography sx={{ paddingBottom: '10px' }}>
                    Input New Device Serial
                </Typography>
                <FormControl>
                    <TextFieldInput
                        label={Constants.SERIAL_NUMBER}
                        field={{
                            value: toSerial,
                            setValue: setToSerial,
                            error: toSerialError || toSerialServerError,
                            setError: setToSerialError || setToSerialServerError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                maxLength: 7,
                            },
                            disabled: props.serialNumberDisabled,
                        }}
                        {...getTestID('device_to_transfer_serial_number_input')}
                    />
                    {!props.serialNumberDisabled && (
                        <FormHelperText sx={{ paddingBottom: '8px' }}>
                            The Serial number for your Fuel Lock device is seven (7) characters (alphanumeric) long, and
                            located on the back of the door (on the inside of the device).
                        </FormHelperText>
                    )}
                </FormControl>
            </Container>
        </BaseModal>
    );
});

export default DeviceTransferModal;