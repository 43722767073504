/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import handleError from './handleError';
import loginStore from '../stores/LoginStore';
import getBaseURL from './getBaseURL';
import urlStore from '../stores/urlStore';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import showStatusFailMessage from './showStatusFailMessage';

const callPostAPIReturnPromise = (uri: string, body: object, enqueueSnackbar: EnqueueSnackbar) => fetch(
    `${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
    {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: loginStore.token,
            udd: loginStore.udd.toString(),
            deviceID: loginStore.deviceID,
            'fl-platform': 'webapp',
        },
        body: JSON.stringify(body),
    }
)
    .then((response) => {
        handleError(response, enqueueSnackbar);
        return response.json();
    })
    .then((receivedServerData) => {
        if (receivedServerData.status && receivedServerData.status === 'fail') {
            showStatusFailMessage(receivedServerData, enqueueSnackbar);
        }
        return receivedServerData;
    })
    .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.message);
    });

export default callPostAPIReturnPromise;
