/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import Colors from '../../helper/Colors';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import getTestID from '../../helper/functions/getTestID';
import Constants from '../../helper/Constants';

const menuItem = {
    marginRight: '8px',
    marginLeft: '8px',
};

interface Props {
    id: string,
    icon: object,
    text: string,
    drawerOpen: boolean,
    expandable?: boolean,
    expandOpen?: boolean,
    setExpandedState?: () => void,
    paddingLeft?: string,
    disabled?: boolean,
}

const DrawerButton = observer((props: Props) => {
    // const paddingLeft = props.paddingLeft ? {paddingLeft: props.paddingLeft} : {};
    const selected = props.text === WebStore.selectedDrawer;
    const color = selected ? {
        color: Colors.primary,
        fontWeight: 800,
    } : {};

    const onClick = () => {
        if (props.expandable && props.setExpandedState) {
            props.setExpandedState();
        } else if (!WebStore.overDue && WebStore.currentPage === Constants.ADD_NEW_DEVICE &&
            WebStore.activeStep !== 0) {
            WebStore.setSelectedDrawerAfterConfirmation(props.text);
            WebStore.setIsLoseChangesConfirmationOpen(true);
        } else if (!WebStore.overDue) {
            WebStore.setCurrentPage(Constants.MAIN);
            WebStore.setPageQueue([] as string[]);
            WebStore.setActiveStep(0);
            WebStore.setSelectedDrawer(props.text);
        }
    };

    return (
        <Tooltip
            title={props.text}
            placement="right"
            disableFocusListener={props.drawerOpen}
            disableTouchListener={props.drawerOpen}
            disableHoverListener={props.drawerOpen}
        >
            <MenuItem
                {...getTestID(`${ props.id }_drawer_button`)}
                sx={{
                    ...menuItem,
                //     ...paddingLeft,
                    ...color,
                }}
                selected={selected}
                onClick={onClick}
                disabled={props.disabled}
            >
                <ListItemIcon sx={{...color}}>
                    {props.icon}
                </ListItemIcon>
                {props.drawerOpen && (
                    <ListItemText
                        primary={props.text}
                        primaryTypographyProps={{
                            sx: {...color},
                        }}
                    />
                )}
                {props.expandable && (
                    <ListItemIcon
                        sx={{
                            minWidth: '24px !important',
                            ...color,
                        }}
                        {...getTestID(props.expandOpen
                            ? `${ props.id }_collapse`
                            : `${ props.id }_expand`)}
                    >
                        {props.expandOpen
                            ? <ExpandLess sx={{...color}}/>
                            : <ExpandMore sx={{...color}}/>
                        }
                    </ListItemIcon>
                )}
            </MenuItem>
        </Tooltip>
    );
});

export default DrawerButton;
