/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import LineAreaBarChart from '../charts/lineAreaBar/LineAreaBarChart';
import {ProcessedUsagePojo} from '../../../types/components/ApiTypes';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import determineUsageDataInRange from '../../../helper/functions/determineUsageDataInRange';
import getMonth from '../../../helper/functions/getMonth';
import getLiquidVolumeInfo from '../../../helper/functions/getLiquidVolumeInfo';
import getUnitNameAndSymbol from '../../../helper/functions/getUnitNameAndSymbol';
import getDataById from '../../../helper/functions/getDataById';
import FuelLockStore from '../../../stores/FuelLockStore';

interface Props {
    paperRef: any,
    paperStyle: object,
    data: ProcessedUsagePojo[],
}

const TankUsageTile = observer((props: Props) => {
    const [ dataInRange, setDataInRange ] = useState<ProcessedUsagePojo[]>([]);
    const [ unit ] = useState(getUnitNameAndSymbol(getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId).name));

    useEffect(() => {
        setDataInRange(determineUsageDataInRange(WebStore.tankUsageTimeRange, props.data));
    }, [ props.data, WebStore.tankUsageTimeRange ]);

    const getLabelValue = (data: ProcessedUsagePojo) => ({
        label: `${ getMonth(data.transactionStartDate.getMonth()) } ${ data.transactionStartDate.getDate() }`,
        time: data.transactionStartDate.getTime(),
        value: data.litres,
    });

    const getData = () => {
        const stepOneData = {};

        dataInRange.forEach(data => {
            const gatheredInfo = getLabelValue(data);
            if (stepOneData[data.tank] === undefined || stepOneData[data.tank][gatheredInfo.label] === undefined) {
                stepOneData[data.tank] = {
                    ...stepOneData[data.tank],
                    [gatheredInfo.label]: {
                        time: gatheredInfo.time,
                        value: data.litres,
                    },
                };
            } else if (stepOneData[data.tank][gatheredInfo.label]) {
                stepOneData[data.tank][gatheredInfo.label] = {
                    ...stepOneData[data.tank][gatheredInfo.label],
                    value: stepOneData[data.tank][gatheredInfo.label].value + gatheredInfo.value,
                };
            }
        });

        const finalData: { name: string; type: string; data: any; }[] = [];

        const keys = Object.keys(stepOneData);

        const labelsAndTime: { label: string; time: any; }[] = [];

        keys.forEach(key => {
            const tankDataDates = Object.keys(stepOneData[key]);
            tankDataDates.forEach(tankDataDate => {
                if (labelsAndTime.findIndex(
                    (item: { label: string, time: number }) => item.label === tankDataDate) === -1) {
                    labelsAndTime.push({
                        label: tankDataDate,
                        time: stepOneData[key][tankDataDate].time,
                    });
                }
            });
        });

        labelsAndTime.sort((a, b) =>
            // @ts-ignore
            a.time - b.time
        );

        const dataForEachTank = {};
        const finalLabels: string[] = [];

        labelsAndTime.forEach(labelAndTime => {
            keys.forEach((key) => {
                const value = stepOneData[key][labelAndTime.label] === undefined
                    ? 0
                    :  stepOneData[key][labelAndTime.label].value;
                if (dataForEachTank[key] === undefined) {
                    dataForEachTank[key] = [ value ];
                } else {
                    dataForEachTank[key] = [ ...dataForEachTank[key], value ];
                }
            });
            finalLabels.push(labelAndTime.label);
        });

        keys.forEach((key) => {
            const valueData = dataForEachTank[key].map((value: number) =>
                Number(getLiquidVolumeInfo(value).value.toFixed(1)));
            finalData.push({
                name: key,
                type: 'column',
                data: valueData,
            });
        });

        return {
            data: finalData,
            labels: finalLabels,
        };
    };

    const data = getData();

    return (
        <LineAreaBarChart
            title="Tank Usage"
            unit={unit}
            id="tank_usage_menu_button"
            selectedTimeFrame={WebStore.tankUsageTimeRange}
            setSelectedTimeFrame={WebStore.setTankUsageTimeRange}
            categories={data.labels}
            series={data.data}
            paperRef={props.paperRef}
            paperStyle={props.paperStyle}
        />
    );
});

export default TankUsageTile;
