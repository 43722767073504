/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isPlainText(str: string, minLength: number, type: string) {
    const expression = /^[a-zA-Z0-9_ -]*$/;
    if (str === '' || str.length < minLength || !expression.test(str)) {
        return `Please enter a valid ${ type }`;
    }

    return '';
}
