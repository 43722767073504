/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import ChangePinModal from '../changePin/ChangePinModal';
import cloneDeep from 'lodash/cloneDeep';
import {useSnackbar} from 'notistack';
import callPutAPI from '../../controllers/callPutAPI';
import ErrorMessages from '../../helper/ErrorMessages';
import FuelLockStore from '../../stores/FuelLockStore';
import { Employee } from '../../types/components/ApiTypes';
import SuccessMessages from '../../helper/SuccessMessages';

interface Props {
    open: boolean,
    close: () => void,
    employee: Employee,
    pin: number | string,
}

function ChangeEmployeePinModal(props: Props) {
    const {enqueueSnackbar} = useSnackbar();

    const submit = (pin: string) => {
        const employeeCopy = cloneDeep(props.employee);
        const body = { newPin: pin };
        employeeCopy.pin = pin;

        callPutAPI(`fuellock/v2/workerPin/${ employeeCopy.id }`, body, (receivedServerData) => {
            if (receivedServerData.status && receivedServerData.status !== 'success') {
                FuelLockStore.setError(ErrorMessages.UNEXPECTED);
            } else {
                const newData: Employee[] = JSON.parse(JSON.stringify(FuelLockStore.systemData.workerList));
                const index = newData.findIndex(item => item.id === employeeCopy.id);
                if (index !== -1) {
                    newData[index] = employeeCopy;
                    FuelLockStore.setEmployeeData(newData);
                }
            }
        }, enqueueSnackbar, SuccessMessages.UPDATE_PIN);
        props.close();
    };

    return (
        <ChangePinModal
            open={props.open}
            close={props.close}
            submit={submit}
            employeeName={props.employee.name}
            currentPin={props.pin}
        />
    );
}

export default ChangeEmployeePinModal;
