/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {Close} from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string,
    onDeleteClick: () => void,
    value: string,
    errorMsg: string,
    onBlur: (value: string) => void,
}

function DeletableCalendarField(props: Props) {
    const [ value, setValue ] = useState(props.value === '' ? null : props.value);

    return (
        <DatePicker
            {...getTestID(props.id)}
            label=""
            value={value}
            onChange={(newValue) => setValue(newValue ? newValue.toString() : '')}
            onAccept={(newValue) => props.onBlur(newValue ? newValue.toString() : '')}
            renderInput={(params) => (
                <TextField
                    {...getTestID(`${ props.id }_text_field`)}
                    {...params}
                    InputProps={{
                        startAdornment: params.InputProps ? params.InputProps.endAdornment : null,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    {...getTestID(`${ props.id }_delete_button`)}
                                    onClick={props.onDeleteClick}
                                >
                                    <Close/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            paddingLeft: '16px',
                        },
                    }}
                    onBlur={() => props.onBlur(value ? value : '')}
                    helperText={props.errorMsg}
                    error={props.errorMsg !== ''}
                />
            )}
        />
    );
}

export default DeletableCalendarField;
