/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getUserAcceptHeader = (userAccept: boolean) => {
    let acceptHeader = {};
    if (userAccept) {
        acceptHeader = {
            'X-User-Accept': true,
        };
    }
    return acceptHeader;
};

export default getUserAcceptHeader;
