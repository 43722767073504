/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

export default {
    FILTER: 'Use this feature to filter your data by device',
    THEME: 'Use this feature to change between light or dark mode',
    ADD_EQUIPMENT: 'Use this button to add new equipment',
    EDIT_EQUIPMENT: 'Use this tool to edit existing equipment',
    ADD_EMPLOYEE: 'Use this button to add a new employee',
    EDIT_EMPLOYEE: 'Use this tool to edit existing employees and their device access',
    ADD_CONTRACTOR_PIN: 'Use this button to add new contractor ID',
    EDIT_CONTRACTOR_PIN: 'Use this tool to edit existing contractor ID',
    ADD_DEVICE: 'Use this button to add a new Fuel Lock device',
    EDIT_DEVICE: 'Use this tool to edit device settings such as auto-lock time and employee access',
    TANK_CONFIG: 'Use this tool to manage tank settings such as flow meter type and tank name',
    ODOMETER: 'Use this tool to make odometer and equipment entry mandatory at time of fill',
    REPORTS: 'Use this feature to generate a report with custom dates',
};
