/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import Constants from '../../../../helper/Constants';
import PaperTitle from '../../../common/typography/PaperTitle';
import SubmitButton from '../../../common/SubmitButton';
import BillingAddressForm from './BillingAddressForm';
import PersonalDetailsForm from './PersonalDetailsForm';
import FuelLockStore from '../../../../stores/FuelLockStore';
import isPersonalDetailsFormValid from '../../../../helper/complexValidators/isPersonalDetailsFormValid';
import isBillingAddressFormValid from '../../../../helper/complexValidators/isBillingAddressFormValid';
import callPostAPI from '../../../../controllers/callPostAPI';
import StyledSwitch from '../../../common/input/StyledSwitch';
import SuccessMessages from '../../../../helper/SuccessMessages';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const PersonalDetails = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const personalDetails = FuelLockStore.systemData.personalDetails;

    const [ firstName, setFirstName ] = useState(personalDetails.firstName);
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastName, setLastName ] = useState(personalDetails.lastName);
    const [ lastNameError, setLastNameError ] = useState('');
    const [ companyName, setCompanyName ] = useState(personalDetails.companyName);
    const [ companyNameError, setCompanyNameError ] = useState('');
    const [ deviceAdminDisplayName, setDeviceAdminDisplayName ] = useState(personalDetails.displayName);
    const [ deviceAdminDisplayNameError, setDeviceAdminDisplayNameError ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState(personalDetails.primaryPhone);
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const [ postalCode, setPostalCode ] = useState(personalDetails.postalCode);
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ address, setAddress ] = useState(personalDetails.address);
    const [ addressError, setAddressError ] = useState('');
    const [ town, setTown ] = useState(personalDetails.city);
    const [ townError, setTownError ] = useState('');
    const [ country, setCountry ] = useState(personalDetails.country);
    const [ countryError, setCountryError ] = useState('');
    const [ province, setProvince ] = useState(personalDetails.province);
    const [ provinceError, setProvinceError ] = useState('');
    const [ receiveEmail, setReceiveEmail ] = useState(personalDetails.subscribedToEmails);

    const isValid = () => {
        const personalDetailsFormValid = isPersonalDetailsFormValid(
            {
                value: firstName,
                setError: setFirstNameError,
            },
            {
                value: lastName,
                setError: setLastNameError,
            },
            {
                value: companyName,
                setError: setCompanyNameError,
            },
            {
                value: deviceAdminDisplayName,
                setError: setDeviceAdminDisplayNameError,
            },
            {
                value: phoneNumber,
                setError: setPhoneNumberError,
            });
        const addressValid = isBillingAddressFormValid(
            {
                value: postalCode,
                setError: setPostalCodeError,
            },
            {
                value: address,
                setError: setAddressError,
            },
            {
                value: town,
                setError: setTownError,
            }, {
                value: country,
                setError: setCountryError,
            }, {
                value: province,
                setError: setProvinceError,
            });

        return personalDetailsFormValid && addressValid;
    };

    const onSave = () => {
        if (isValid()) {
            const body = {
                firstName,
                lastName,
                companyName,
                deviceAdminDisplayName,
                phoneNumber,
                postalZipCode: postalCode,
                address,
                city: town,
                provinceState: province,
                country,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                emailOptIn: receiveEmail,
            };

            callPostAPI('fuellock/v2/editAccountInformation', body, (receivedData) => {
                // @ts-ignore
                body.subscribedToEmails = body.emailOptIn;
                // @ts-ignore
                body.primaryPhone = body.phoneNumber;
                // @ts-ignore
                body.postalCode = body.postalZipCode;
                // @ts-ignore
                body.province = body.provinceState;
                // @ts-ignore
                body.displayName = body.deviceAdminDisplayName;
                if (receivedData.status === 'success') {
                    FuelLockStore.setPersonalDetails({
                        ...FuelLockStore.systemData.personalDetails,
                        ...body,
                        customerID: receivedData.data,
                    });
                }
            }, enqueueSnackbar, SuccessMessages.UPDATE_PERSONAL_DETAILS);
        }
    };

    return (
        <Paper>
            <Root>
                <PaperTitle
                    title={Constants.PERSONAL_DETAILS}
                />
                <PersonalDetailsForm
                    firstName={{
                        value: firstName,
                        setValue: setFirstName,
                        error: firstNameError,
                        setError: setFirstNameError,
                    }}
                    lastName={{
                        value: lastName,
                        setValue: setLastName,
                        error: lastNameError,
                        setError: setLastNameError,
                    }}
                    companyName={{
                        value: companyName,
                        setValue: setCompanyName,
                        error: companyNameError,
                        setError: setCompanyNameError,
                    }}
                    deviceAdminDisplayName={{
                        value: deviceAdminDisplayName,
                        setValue: setDeviceAdminDisplayName,
                        error: deviceAdminDisplayNameError,
                        setError: setDeviceAdminDisplayNameError,
                    }}
                    phoneNumber={{
                        value: phoneNumber,
                        setValue: setPhoneNumber,
                        error: phoneNumberError,
                        setError: setPhoneNumberError,
                    }}
                />
                <BillingAddressForm
                    postalCode={{
                        value: postalCode,
                        setValue: setPostalCode,
                        error: postalCodeError,
                        setError: setPostalCodeError,
                    }}
                    address={{
                        value: address,
                        setValue: setAddress,
                        error: addressError,
                        setError: setAddressError,
                    }}
                    town={{
                        value: town,
                        setValue: setTown,
                        error: townError,
                        setError: setTownError,
                    }}
                    country={{
                        value: country,
                        setValue: setCountry,
                        error: countryError,
                        setError: setCountryError,
                    }}
                    province={{
                        value: province,
                        setValue: setProvince,
                        error: provinceError,
                        setError: setProvinceError,
                    }}
                />
                <StyledSwitch
                    id="receive_news_switch"
                    description="Allow Calian Agriculture to send you news and product updates by email"
                    value={receiveEmail}
                    setValue={setReceiveEmail}
                />
                <SubmitButton
                    id="save_personal_details_button"
                    text="Save Personal Details"
                    onClick={onSave}
                    disabled={FuelLockStore.awaitingApi}
                />
            </Root>
        </Paper>
    );
});

export default PersonalDetails;
