/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import TermsOfService from '../../settings/termsOfService/TermsOfService';
import NewAccountInformation from './NewAccountInformation';
import Constants from '../../../helper/Constants';
import Paper from '@mui/material/Paper';
import TitleTypography from '../../common/typography/TitleTypography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CenterScreen from '../../common/CenterScreen';
import callPostAPI from '../../../controllers/callPostAPI';
import callLoginAPI from '../../../controllers/callLoginAPI';
import callAPI from '../../../controllers/callAPI';
import {observer} from 'mobx-react-lite';
import loginStore from '../../../stores/LoginStore';
import fuelLockStore from '../../../stores/FuelLockStore';
import {Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import isPersonalDetailsFormValid from '../../../helper/complexValidators/isPersonalDetailsFormValid';
import isBillingAddressFormValid from '../../../helper/complexValidators/isBillingAddressFormValid';
import isEmailAndPasswordValid from '../../../helper/complexValidators/isEmailAndPasswordValid';
import getTestID from '../../../helper/functions/getTestID';
import FuelLockStore from '../../../stores/FuelLockStore';
import runAnalytics from '../../../helper/analytics/runAnalytics';

const steps = [
    Constants.TERMS_OF_SERVICE,
    Constants.REGISTER,
];

interface Props {
    setCreateAccountOpen: (value: boolean) => void,
}

const CreateNewAccount = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ activeStep, setActiveStep ] = useState(0);
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ confirmPasswordError, setConfirmPasswordError ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ lastNameError, setLastNameError ] = useState('');
    const [ companyName, setCompanyName ] = useState('');
    const [ companyNameError, setCompanyNameError ] = useState('');
    const [ deviceAdminDisplayName, setDeviceAdminDisplayName ] = useState('');
    const [ deviceAdminDisplayNameError, setDeviceAdminDisplayNameError ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const [ postalCode, setPostalCode ] = useState('');
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ addressError, setAddressError ] = useState('');
    const [ town, setTown ] = useState('');
    const [ townError, setTownError ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ countryError, setCountryError ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ provinceError, setProvinceError ] = useState('');
    const [ receiveEmail, setReceiveEmail ] = useState(false);
    const [ matchBillingAddress, setMatchBillingAddress ] = useState(true);
    const [ billingAddressPostalCode, setBillingAddressPostalCode ] = useState('');
    const [ billingAddressPostalCodeError, setBillingAddressPostalCodeError ] = useState('');
    const [ billingAddressAddress, setBillingAddressAddress ] = useState('');
    const [ billingAddressAddressError, setBillingAddressAddressError ] = useState('');
    const [ billingAddressTown, setBillingAddressTown ] = useState('');
    const [ billingAddressTownError, setBillingAddressTownError ] = useState('');
    const [ billingAddressCountry, setBillingAddressCountry ] = useState('');
    const [ billingAddressCountryError, setBillingAddressCountryError ] = useState('');
    const [ billingAddressProvince, setBillingAddressProvince ] = useState('');
    const [ billingAddressProvinceError, setBillingAddressProvinceError ] = useState('');
    const [ error, setError ] = useState('');

    useEffect(() => {
        runAnalytics('Create Account');
    }, []);

    const isValid = () => {
        let valid = true;
        if (password !== confirmPassword) {
            setError('Password fields do not match.');
            valid = false;
        }

        const emailAndPasswordValid = isEmailAndPasswordValid({
                value: email,
                setError: setEmailError,
            }, {
                value: password,
                setError: setPasswordError,
            },
            {
                value: confirmPassword,
                setError: setConfirmPasswordError,
            });

        const personalDetailsFormValid = isPersonalDetailsFormValid({
            value: firstName,
            setError: setFirstNameError,
        }, {
            value: lastName,
            setError: setLastNameError,
        }, {
            value: companyName,
            setError: setCompanyNameError,
        }, {
            value: deviceAdminDisplayName,
            setError: setDeviceAdminDisplayNameError,
        }, {
            value: phoneNumber,
            setError: setPhoneNumberError,
        });
        const addressValid = isBillingAddressFormValid({
            value: postalCode,
            setError: setPostalCodeError,
        }, {
            value: address,
            setError: setAddressError,
        }, {
            value: town,
            setError: setTownError,
        }, {
            value: country,
            setError: setCountryError,
        }, {
            value: province,
            setError: setProvinceError,
        });

        let billingAddressValid = addressValid;

        if (!matchBillingAddress) {
            billingAddressValid = isBillingAddressFormValid({
                value: billingAddressPostalCode,
                setError: setBillingAddressPostalCodeError,
            }, {
                value: billingAddressAddress,
                setError: setBillingAddressAddressError,
            }, {
                value: billingAddressTown,
                setError: setBillingAddressTownError,
            }, {
                value: billingAddressCountry,
                setError: setBillingAddressCountry,
            }, {
                value: billingAddressProvince,
                setError: setBillingAddressProvinceError,
            });
        }

        if (!emailAndPasswordValid || !personalDetailsFormValid || !addressValid ||
            (!matchBillingAddress && !billingAddressValid)) {
            valid = false;
        }
        return valid;
    };

    const submit = () => {
        if (isValid()) {
            const body = {
                emailAddress: email,
                password,
                firstName,
                lastName,
                companyName,
                deviceAdminDisplayName,
                phoneNumber,
                postalZipCode: postalCode,
                address,
                city: town,
                provinceState: province,
                country,
                billingAddressAddress: matchBillingAddress ? address : billingAddressAddress,
                billingAddressCity: matchBillingAddress ? town : billingAddressTown,
                billingAddressProvinceState: matchBillingAddress ? province : billingAddressProvince,
                billingAddressPostalZipCode: matchBillingAddress ? postalCode : billingAddressPostalCode,
                billingAddressCountry: matchBillingAddress ? country : billingAddressCountry,
                emailOptIn: receiveEmail,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
            callPostAPI('fuellock/v2/createAccount', body, async () => {
                const successful = await callLoginAPI(email, password, enqueueSnackbar);
                if (successful) {
                    await callAPI('fuellock/v2/dashboard',
                        receivedServerData => {
                            loginStore.setEmail(email, true);
                            fuelLockStore.setSystemData(receivedServerData.data);
                            fuelLockStore.setTermsOfServiceFlag(true);
                            // if (receivedServerData.data.deviceList.length === 0) {
                            //     navigationStore.setIndex(1);
                            //     navigationStore.addSettingsScreen(Constants.ADD_NEW_DEVICE);
                            // }
                            loginStore.setLoadingData(false);
                        }, enqueueSnackbar);
                    loginStore.setLoggedIn(true);
                }
            }, enqueueSnackbar, '');
        }
    };

    const handleNext = () => {
        if ((activeStep === 1 && isValid())) {
            submit();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const renderPaper = () => {
        switch (steps[activeStep]) {
            case Constants.TERMS_OF_SERVICE:
                return (
                    <TermsOfService
                        onDecline={() => props.setCreateAccountOpen(false)}
                        onAccept={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                        paperSx={{
                            width: '50%',
                            minWidth: '444px',
                            maxHeight: '80%',
                            overflow: 'auto',
                        }}
                    />
                );
            case Constants.REGISTER:
                return (
                    <NewAccountInformation
                        email={{
                            value: email,
                            setValue: setEmail,
                            error: emailError,
                            setError: setEmailError,
                        }}
                        password={{
                            value: password,
                            setValue: setPassword,
                            error: passwordError,
                            setError: setPasswordError,
                        }}
                        confirmPassword={{
                            value: confirmPassword,
                            setValue: setConfirmPassword,
                            error: confirmPasswordError,
                            setError: setConfirmPasswordError,
                        }}
                        firstName={{
                            value: firstName,
                            setValue: setFirstName,
                            error: firstNameError,
                            setError: setFirstNameError,
                        }}
                        lastName={{
                            value: lastName,
                            setValue: setLastName,
                            error: lastNameError,
                            setError: setLastNameError,
                        }}
                        companyName={{
                            value: companyName,
                            setValue: setCompanyName,
                            error: companyNameError,
                            setError: setCompanyNameError,
                        }}
                        deviceAdminDisplayName={{
                            value: deviceAdminDisplayName,
                            setValue: setDeviceAdminDisplayName,
                            error: deviceAdminDisplayNameError,
                            setError: setDeviceAdminDisplayNameError,
                        }}
                        phoneNumber={{
                            value: phoneNumber,
                            setValue: setPhoneNumber,
                            error: phoneNumberError,
                            setError: setPhoneNumberError,
                        }}
                        postalCode={{
                            value: postalCode,
                            setValue: setPostalCode,
                            error: postalCodeError,
                            setError: setPostalCodeError,
                        }}
                        address={{
                            value: address,
                            setValue: setAddress,
                            error: addressError,
                            setError: setAddressError,
                        }}
                        town={{
                            value: town,
                            setValue: setTown,
                            error: townError,
                            setError: setTownError,
                        }}
                        country={{
                            value: country,
                            setValue: setCountry,
                            error: countryError,
                            setError: setCountryError,
                        }}
                        province={{
                            value: province,
                            setValue: setProvince,
                            error: provinceError,
                            setError: setProvinceError,
                        }}
                        matchBillingAddress={matchBillingAddress}
                        setMatchBillingAddress={setMatchBillingAddress}
                        billingAddressPostalCode={{
                            value: billingAddressPostalCode,
                            setValue: setBillingAddressPostalCode,
                            error: billingAddressPostalCodeError,
                            setError: setBillingAddressPostalCodeError,
                        }}
                        billingAddressAddress={{
                            value: billingAddressAddress,
                            setValue: setBillingAddressAddress,
                            error: billingAddressAddressError,
                            setError: setBillingAddressAddressError,
                        }}
                        billingAddressTown={{
                            value: billingAddressTown,
                            setValue: setBillingAddressTown,
                            error: billingAddressTownError,
                            setError: setBillingAddressTownError,
                        }}
                        billingAddressProvince={{
                            value: billingAddressProvince,
                            setValue: setBillingAddressProvince,
                            error: billingAddressProvinceError,
                            setError: setBillingAddressProvinceError,
                        }}
                        billingAddressCountry={{
                            value: billingAddressCountry,
                            setValue: setBillingAddressCountry,
                            error: billingAddressCountryError,
                            setError: setBillingAddressCountryError,
                        }}
                        receiveEmail={receiveEmail}
                        setReceiveEmail={setReceiveEmail}
                    />
                );
            default:
                return (
                    <Paper>
                        <TitleTypography title={steps[activeStep]}/>
                    </Paper>
                );
        }
    };

    return (
        <CenterScreen>
            <Stepper
                sx={{
                    paddingBottom: '24px',
                }}
                activeStep={activeStep}
            >
                {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step
                            key={label}
                            {...stepProps}
                        >
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {renderPaper()}

            {error !== '' && (
                <Typography color="error">{error}</Typography>
            )}

            {activeStep !== 0 && (
                <div>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Button
                            {...getTestID('create_account_back_button')}
                            color="secondary"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                            variant="contained"
                        >
                            Back
                        </Button>
                        <Button
                            {...getTestID('create_account_cancel_button')}
                            color="secondary"
                            onClick={() => props.setCreateAccountOpen(false)}
                            sx={{mr: 1}}
                        >
                            Cancel
                        </Button>

                        <Button
                            {...getTestID('create_account_next_button')}
                            onClick={handleNext}
                            variant="contained"
                            disabled={FuelLockStore.awaitingApi}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </div>
            )}
        </CenterScreen>
    );
});

export default CreateNewAccount;
