/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import callPostAPIReturnPromise from '../../controllers/callPostAPIReturnPromise';
import {FlowMeterType, IGTResponse} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import {Tanks} from '../../types/components/EquipmentTypes';
import getFlowMeterType from './getFlowMeterType';
import callPutAPIReturnPromise from '../../controllers/callPutAPIReturnPromise';
import {EnqueueSnackbar} from '../../types/SnackbarTypes';

const postCustomFlowMeter = async (customFlowMeterBody: FlowMeterType, enqueueSnackbar: EnqueueSnackbar) => (
    callPostAPIReturnPromise('fuellock/v2/customFlowMeter', customFlowMeterBody, enqueueSnackbar)
        .then((receivedServerData: IGTResponse<number>) => {
            if (receivedServerData.status === 'success') {
                customFlowMeterBody.id = receivedServerData.data;
                return customFlowMeterBody;
            }
            return {
                name: '',
                id: 0,
                custom: false,
                pulsesPerLitre: 0,
            };
        })
);

const putCustomFlowMeter = async (customFlowMeterBody: FlowMeterType, enqueueSnackbar: EnqueueSnackbar) => (
    callPutAPIReturnPromise(`fuellock/v2/customFlowMeter/${ customFlowMeterBody.id }`, customFlowMeterBody,
        enqueueSnackbar)
        .then((receivedServerData: IGTResponse<null>) => {
            if (receivedServerData.status === 'success') {
                return customFlowMeterBody;
            }
            return {
                name: '',
                id: 0,
                custom: false,
                pulsesPerLitre: 0,
            };
        })
);

const determineTankList = async (tanks: Tanks[], enqueueSnackbar: EnqueueSnackbar) => {
    const tankList: {
        name: string;
        capacity: number;
        flowMeter: {
            flowMeterTypeId: number;
            flowMeterSlot: number;
            name: string;
            litreLimit: string;
            custom: boolean;
        } | null;
        fuelSensor: {
            serial: string;
            tankHeight: string;
            capacity: string;
            density: string;
            tankType: string;
            brand: string;
            portNumber: number;
            accountType: string;
        } | null;
        recentRecordsByWorkerId: null;
        fullness: number | null;
        lowThreshold: number | null;
    }[] = [];

    for (let i = 0; i < tanks.length; i += 1) {
        const tank = tanks[i];
        let flowMeter = null;
        let fuelSensor = null;
        let fullness = null;

        if (tank.useFlowMeter) {
            let flowMeterType: FlowMeterType = {
                name: '',
                id: 0,
                custom: false,
                pulsesPerLitre: 0,
            };

            if (tank.model === Constants.CUSTOM_FLOW_METER) {
                const customFlowMeterBody = {
                    id: 0,
                    name: tank.flowMeterName,
                    pulsesPerLitre: Number(tank.pulsesPerLitre),
                    custom: true,
                };

                // eslint-disable-next-line no-await-in-loop
                await postCustomFlowMeter(customFlowMeterBody, enqueueSnackbar)
                    .then((newFlowMeterType: FlowMeterType) => {
                        flowMeterType = newFlowMeterType;
                    });
            } else {
                flowMeterType = getFlowMeterType(tank.model);
                //in case there are updates to the custom flow meter, send them
                if (flowMeterType.custom) {
                    const customFlowMeterBody = {
                        id: flowMeterType.id,
                        name: tank.flowMeterName,
                        pulsesPerLitre: Number(tank.pulsesPerLitre),
                        custom: true,
                    };

                    // eslint-disable-next-line no-await-in-loop
                    await putCustomFlowMeter(customFlowMeterBody, enqueueSnackbar)
                        .then((newFlowMeterType: FlowMeterType) => {
                            flowMeterType = newFlowMeterType;
                        });
                }
            }
            flowMeter = {
                flowMeterTypeId: flowMeterType.id,
                flowMeterSlot: Number(tank.flowMeterSlot),
                name: flowMeterType.name,
                litreLimit: tank.limit,
                custom: flowMeterType.custom,
            };
        }
        if (tank.useLevelSensor) {
            fuelSensor = {
                serial: tank.levelSensorProduct === Constants.OTODATA ? tank.otodataDevice : tank.tattleID,
                tankHeight: tank.height,
                capacity: tank.capacity,
                density: tank.density,
                tankType: tank.type,
                brand: tank.levelSensorProduct === Constants.OTODATA ? 'otodata' : 'tattle',
                portNumber: Number(tank.tattlePort),
                accountType: tank.levelSensorConfiguration === Constants.FUEL_LOCK_COMMERCIAL ? 'commercial'
                    : 'business',
                sensorType: tank.levelSensorConfiguration === Constants.FUEL_LOCK_COMMERCIAL ? 'pressure' : 'floatArm',
            };
        }
        if (tank.virtualTankMonitoring) {
            fullness = tank.fullness;
        }

        const tankBody = {
            name: tank.name,
            capacity: Number(tank.capacity),
            flowMeter,
            fuelSensor,
            recentRecordsByWorkerId: null,
            fullness,
            lowThreshold: null,
        };
        tankList.push(tankBody);
    }

    return tankList;
};

export default determineTankList;
