/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Device, Employee, Equipment, ProcessedUsagePojo, TankList, UsagePojo} from '../../types/components/ApiTypes';
import getDataById from './getDataById';
import Constants from '../Constants';

const processUsageData = (rawData: UsagePojo[],
                          deviceList: Device[],
                          equipmentList: Equipment[],
                          workerList: Employee[],
                          dashboardDevice: string) => {
    const devices = {};
    const tanks = {};
    const equipment = {};
    const employees = {};
    const processedData: ProcessedUsagePojo[] = [];

    rawData.forEach(raw => {
        if (raw.authorized && raw.litres >= 1) {
            if (devices[raw.deviceId] === undefined) {
                const device = getDataById(deviceList, raw.deviceId);

                devices[device.id] = device.siteName;
                device.tankList.forEach((tank: TankList) => {
                    tanks[tank.id] = tank.name;
                });
            }

            if (equipment[raw.equipmentId] === undefined && raw.equipmentId) {
                const equip = getDataById(equipmentList, raw.equipmentId);
                if (equip) {
                    equipment[equip.id] = equip.name;
                } else {
                    equipment[raw.equipmentId] = Constants.REMOVED_EQUIPMENT;
                }
            }

            if (employees[raw.workerId] === undefined) {
                const employee = getDataById(workerList, raw.workerId);
                if (employee) {
                    employees[employee.id] = employee.name;
                } else {
                    employees[raw.workerId] = Constants.REMOVED_USER;
                }
            }

            if (dashboardDevice === Constants.ALL_DEVICES ||
                dashboardDevice === devices[raw.deviceId]) {
                const processed = {
                    authorized: raw.authorized,
                    device: devices[raw.deviceId],
                    equipment: raw.equipmentId ? equipment[raw.equipmentId] : 'Skipped',
                    litres: raw.litres,
                    odometerReading: raw.odometerReading,
                    tank: tanks[raw.tankId],
                    transactionEndDate: new Date(raw.transactionEndDate),
                    transactionStartDate: new Date(raw.transactionStartDate),
                    worker: employees[raw.workerId],
                };

                processedData.push(processed);
            }
        }
    });
    return processedData;
};

export default processUsageData;
