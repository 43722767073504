/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';

class FcmStore {
    fcmToken = '';

    constructor() {
        makeObservable(this, {
            fcmToken: observable,
            setFcmToken: action,
        });
    };

    setFcmToken = (fcmToken: string) => {
        this.fcmToken = fcmToken;
    };
}

export default new FcmStore();
