/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {useSnackbar} from 'notistack';
import {observer} from 'mobx-react-lite';
import DeviceActiveHours from '../wizard/DeviceActiveHours';
import determineActiveHours from '../../../helper/functions/determineActiveHours';
import determineInactiveDays from '../../../helper/functions/determineInactiveDays';
import {ActiveHours, Device, IGTResponse, InActiveDay} from '../../../types/components/ApiTypes';
import getAmPmHoursString from '../../../helper/functions/getAmPmHoursString';
import callAPI from '../../../controllers/callAPI';
import FuelLockStore from '../../../stores/FuelLockStore';
import callPutAPI from '../../../controllers/callPutAPI';
import SuccessMessages from '../../../helper/SuccessMessages';

interface Props {
    editingDevice: Device,
}

const EditDeviceActiveHours = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const determineHours = (key: string, startEndKey: string) => {
        const dayHours = props.editingDevice.activeHours[key];
        const start = dayHours[startEndKey].split(':');
        const hours = Number(start[0]);
        return getAmPmHoursString(hours);
    };

    const [ activeHoursPage, setActiveHoursPage ] = useState({
        mondayStart: determineHours('mondayHours', 'start'),
        mondayEnd: determineHours('mondayHours', 'end'),
        tuesdayStart: determineHours('tuesdayHours', 'start'),
        tuesdayEnd: determineHours('tuesdayHours', 'end'),
        wednesdayStart: determineHours('wednesdayHours', 'start'),
        wednesdayEnd: determineHours('wednesdayHours', 'end'),
        thursdayStart: determineHours('thursdayHours', 'start'),
        thursdayEnd: determineHours('thursdayHours', 'end'),
        fridayStart: determineHours('fridayHours', 'start'),
        fridayEnd: determineHours('fridayHours', 'end'),
        saturdayStart: determineHours('saturdayHours', 'start'),
        saturdayEnd: determineHours('saturdayHours', 'end'),
        sundayStart: determineHours('sundayHours', 'start'),
        sundayEnd: determineHours('sundayHours', 'end'),
        inactiveDays: props.editingDevice.activeHours.inactiveDays,
    });

    const updateActiveHoursPage = (value: string | InActiveDay[], key: string) => {
        setActiveHoursPage({
            ...activeHoursPage,
            [key]: value,
        });
    };

    const determineActiveHoursId = (key: string) => props.editingDevice.activeHours[key].id;

    const onUpdate = () => {
        const body = {
            mondayHours: {
                id: determineActiveHoursId('mondayHours'),
                start: determineActiveHours(activeHoursPage.mondayStart),
                end: determineActiveHours(activeHoursPage.mondayEnd),
            },
            tuesdayHours: {
                id: determineActiveHoursId('tuesdayHours'),
                start: determineActiveHours(activeHoursPage.tuesdayStart),
                end: determineActiveHours(activeHoursPage.tuesdayEnd),
            },
            wednesdayHours: {
                id: determineActiveHoursId('wednesdayHours'),
                start: determineActiveHours(activeHoursPage.wednesdayStart),
                end: determineActiveHours(activeHoursPage.wednesdayEnd),
            },
            thursdayHours: {
                id: determineActiveHoursId('thursdayHours'),
                start: determineActiveHours(activeHoursPage.thursdayStart),
                end: determineActiveHours(activeHoursPage.thursdayEnd),
            },
            fridayHours: {
                id: determineActiveHoursId('fridayHours'),
                start: determineActiveHours(activeHoursPage.fridayStart),
                end: determineActiveHours(activeHoursPage.fridayEnd),
            },
            saturdayHours: {
                id: determineActiveHoursId('saturdayHours'),
                start: determineActiveHours(activeHoursPage.saturdayStart),
                end: determineActiveHours(activeHoursPage.saturdayEnd),
            },
            sundayHours: {
                id: determineActiveHoursId('sundayHours'),
                start: determineActiveHours(activeHoursPage.sundayStart),
                end: determineActiveHours(activeHoursPage.sundayEnd),
            },
            inactiveDays: determineInactiveDays(activeHoursPage.inactiveDays),
        };

        callPutAPI(`fuellock/v2/device/activeHours/${ props.editingDevice.id }`,
            body,
            () => {
                const newData = cloneDeep(JSON.parse(JSON.stringify(FuelLockStore.systemData.deviceList)));
                const index = newData.findIndex((item: Device) => item.id === props.editingDevice.id);
                if (index !== -1) {
                    //need to replace active hours data because new IDs could be created for inactive days
                    setTimeout(() => {
                        callAPI(`fuellock/v2/device/activeHours/${ props.editingDevice.id }`,
                            (receivedServerData: IGTResponse<ActiveHours>) => {
                                newData[index].activeHours = receivedServerData.data;
                                FuelLockStore.setDeviceData(newData);
                            }, enqueueSnackbar);
                    }, 250);
                }
            }, enqueueSnackbar, SuccessMessages.UPDATE_ACTIVE_HOURS);
    };

    return (
        <DeviceActiveHours
            mondayStart={{
                value: activeHoursPage.mondayStart,
                setValue: (value: string) => updateActiveHoursPage(value, 'mondayStart'),
            }}
            mondayEnd={{
                value: activeHoursPage.mondayEnd,
                setValue: (value: string) => updateActiveHoursPage(value, 'mondayEnd'),
            }}
            tuesdayStart={{
                value: activeHoursPage.tuesdayStart,
                setValue: (value: string) => updateActiveHoursPage(value, 'tuesdayStart'),
            }}
            tuesdayEnd={{
                value: activeHoursPage.tuesdayEnd,
                setValue: (value: string) => updateActiveHoursPage(value, 'tuesdayEnd'),
            }}
            wednesdayStart={{
                value: activeHoursPage.wednesdayStart,
                setValue: (value: string) => updateActiveHoursPage(value, 'wednesdayStart'),
            }}
            wednesdayEnd={{
                value: activeHoursPage.wednesdayEnd,
                setValue: (value: string) => updateActiveHoursPage(value, 'wednesdayEnd'),
            }}
            thursdayStart={{
                value: activeHoursPage.thursdayStart,
                setValue: (value: string) => updateActiveHoursPage(value, 'thursdayStart'),
            }}
            thursdayEnd={{
                value: activeHoursPage.thursdayEnd,
                setValue: (value: string) => updateActiveHoursPage(value, 'thursdayEnd'),
            }}
            fridayStart={{
                value: activeHoursPage.fridayStart,
                setValue: (value: string) => updateActiveHoursPage(value, 'fridayStart'),
            }}
            fridayEnd={{
                value: activeHoursPage.fridayEnd,
                setValue: (value: string) => updateActiveHoursPage(value, 'fridayEnd'),
            }}
            saturdayStart={{
                value: activeHoursPage.saturdayStart,
                setValue: (value: string) => updateActiveHoursPage(value, 'saturdayStart'),
            }}
            saturdayEnd={{
                value: activeHoursPage.saturdayEnd,
                setValue: (value: string) => updateActiveHoursPage(value, 'saturdayEnd'),
            }}
            sundayStart={{
                value: activeHoursPage.sundayStart,
                setValue: (value: string) => updateActiveHoursPage(value, 'sundayStart'),
            }}
            sundayEnd={{
                value: activeHoursPage.sundayEnd,
                setValue: (value: string) => updateActiveHoursPage(value, 'sundayEnd'),
            }}
            inactiveDays={JSON.parse(JSON.stringify(activeHoursPage.inactiveDays))}
            setInactiveDays={(value: InActiveDay[]) => updateActiveHoursPage(value, 'inactiveDays')}
            width="auto"
            onUpdate={onUpdate}
        />
    );
});

export default EditDeviceActiveHours;
