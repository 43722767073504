/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ReactGA from 'react-ga';

export default function runAnalytics(page: string) {
    if (process.env.REACT_APP_SIM === undefined) {
        // @ts-ignore
        // window.gtag('event', 'page_view', {
        //     page_title: `Fuel Lock - ${ page }`,
        //     page_location: `/fuel-lock/${ page }`,
        //     page_path: page,
        // });

        ReactGA.set({ page });
        ReactGA.pageview(page);
    } else {
        console.log(page);
    }
}
