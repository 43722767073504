/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {Grid, Paper, styled} from '@mui/material';
import ContentPanel from './ContentPanel';
import AccountPreferencesPanel from '../settings/panels/AccountPreferencesPanel';
import VirtualTankMonitoringPanel from '../settings/panels/VirtualTankMonitoringPanel';
import FuelLockStore from '../../stores/FuelLockStore';
import { Device, TankList } from '../../types/components/ApiTypes';
import VirtualTankMonitoringPopup from '../banner/VirtualTankMonitoringPopup';
import Constants from '../../helper/Constants';
import getDataById from '../../helper/functions/getDataById';
import WebStore from '../../stores/WebStore';
import PaperTitle from '../common/typography/PaperTitle';
import CustomFieldEntryPanel from '../settings/panels/CustomFieldEntryPanel';
import ContractorPinPanel from '../settings/panels/ContractorPinPanel';
import SmartLockPanel from '../settings/panels/SmartLockPanel';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const BodyText = styled('div')({
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',

});

const iconButton = {
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 1,
    zIndex: 0,
};

const getMonitoredTanks = (device: Device) => {
    const tanks: TankList[] = [];
    const allTanks: TankList[] = device.tankList;
    allTanks.forEach((iTank: TankList) => {
        if (iTank.fullness != null) {
            tanks.push(iTank);
        }
    });

    return tanks;
};

function AccountPreferences() {
    const [ dashboardDevice, setDashboardDevice ] = useState(WebStore.dashboardDevice);
    const [ selectedDevice, setSelectedDevice ] = useState<Device | null>(null);
    const [ selectedDeviceId, setSelectedDeviceId ] = useState(0);
    const [ selectedDeviceName, setSelectedDeviceName ] = useState<string>('');
    const [ monitoredTanks, setMonitoredTanks ] = useState<TankList[]>([]);
    const [ hasMonitoredTanks, setHasMonitoredTanks ] = React.useState(false);

    useEffect(() => {
        if (dashboardDevice) {
            if (dashboardDevice !== '' &&
                dashboardDevice !== Constants.ALL_DEVICES ) {

                FuelLockStore.systemData.deviceList.forEach((d: Device) => {
                    if (d.siteName === dashboardDevice) {
                        const device = getDataById(FuelLockStore.systemData.deviceList, d.id);
                        setSelectedDevice(device);
                        setSelectedDeviceId(device.id);
                        setSelectedDeviceName(device.siteName);
                    }
                });
            } else {
                if (FuelLockStore.systemData.deviceList !== null && FuelLockStore.systemData.deviceList.length > 0) {
                    //Set the first device as default
                    setSelectedDevice(FuelLockStore.systemData.deviceList[0]);
                    setSelectedDeviceId(FuelLockStore.systemData.deviceList[0].id);
                    setSelectedDeviceName(FuelLockStore.systemData.deviceList[0].siteName);
                }
            }
        }

    }, [ FuelLockStore.systemData.deviceList, dashboardDevice ]);

    useEffect(() => {
        if (selectedDevice) {
            const tanks = getMonitoredTanks(selectedDevice);

            if (tanks.length !== 0) {
                setHasMonitoredTanks(true);
            } else {
                setHasMonitoredTanks(false);
            }

            setMonitoredTanks(tanks);
        }
    }, [ selectedDevice, selectedDeviceId, selectedDeviceName, selectedDevice?.tankList ]);

    const renderVirtualTankMonitoringPanel = () => {
        if (selectedDevice === null) {
            return (
                <BodyText>
                    This account does not have any devices associated yet.
                </BodyText>
            );
        }
        else {
            if (hasMonitoredTanks) {
                return (
                    <VirtualTankMonitoringPanel
                        selectedDeviceId={selectedDeviceId}
                        selectedDeviceName={selectedDeviceName}
                        monitoredTanks={monitoredTanks}
                    />
                );
            }
            else {
                return (
                    <BodyText>
                        This device does not have any tanks associated yet.
                    </BodyText>
                );
            }

        }
    };

    return (
        <ContentPanel>
            <Root>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <AccountPreferencesPanel/>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <Paper
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <PaperTitle
                                title= {` ${ Constants.VIRTUAL_TANK_MONITORING }: ${ selectedDeviceName }`}
                            />
                            <VirtualTankMonitoringPopup
                                iconButton={iconButton}
                                setDashboardDevice={setDashboardDevice}
                            />
                            {renderVirtualTankMonitoringPanel()}
                        </Paper>
                    </Grid>
                    { FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_CUSTOM_FIELD &&
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <CustomFieldEntryPanel/>
                        </Grid>
                    }
                    { FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_CONTRACTOR_PIN &&
                        FuelLockStore.systemData.deviceList.length > 0 &&
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <ContractorPinPanel
                                selectedDevice={selectedDevice ?? FuelLockStore.systemData.deviceList[0]}
                            />
                        </Grid>
                    }
                    { FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_SMART_LOCK &&
                        FuelLockStore.systemData.deviceList.length > 0 &&
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <SmartLockPanel
                                selectedDevice={selectedDevice ?? FuelLockStore.systemData.deviceList[0]}
                            />
                        </Grid>
                    }
                </Grid>
            </Root>
        </ContentPanel>
    );
}

export default AccountPreferences;
