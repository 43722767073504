/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Skeleton, styled, Typography} from '@mui/material';
import PaperTitle from '../common/typography/PaperTitle';
import Constants from '../../helper/Constants';
import callAPI from '../../controllers/callAPI';
import {useSnackbar} from 'notistack';
import formatDate from '../../helper/functions/formatDate';
import getTestID from '../../helper/functions/getTestID';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
});

const Grid = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto auto 50px',
    alignItems: 'center',
});

interface TransactionData {
    amount: string;
    date: string;
}

function InvoiceHistory() {
    const {enqueueSnackbar} = useSnackbar();
    const [ loading, setLoading ] = React.useState(true);
    const [ transactions, setTransactions ] = React.useState<TransactionData[]>([]);

    React.useEffect(() => {
        callAPI('fuellock/v2/transactions', (receivedData) => {
            const newData: TransactionData[] = [];

            receivedData.data.forEach((item: any) => {
                newData.push({
                    amount: `$${  item.amount  }(${  item.currency  })`,
                    date: formatDate(new Date(item.date), true),
                });
            });

            setTransactions(newData);
            setLoading(false);
        }, enqueueSnackbar);
    }, []);

    if (loading) {
        return (
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                    width: '30%',
                    maxWidth: 'none',
                    height: '250px',
                    borderRadius: '12px',
                }}
            />
        );
    }

    return (
        <div >
            <Root>
                <PaperTitle
                    title={Constants.INVOICE_HISTORY}
                />
                {transactions.map((dataPoint, index) => (
                    <Grid key={dataPoint.date + dataPoint.amount}>
                        <Typography {...getTestID(`invoice_date_${ index + 1 }`)}>
                            {dataPoint.date}
                        </Typography>
                        <Typography {...getTestID(`invoice_amount_${ index + 1 }`)}>
                            {dataPoint.amount}
                        </Typography>
                        <div/>
                    </Grid>
                ))}
            </Root>
        </div>
    );
}

export default InvoiceHistory;
