/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Constants from '../../../helper/Constants';
import TitleTypography from '../../common/typography/TitleTypography';
import StartAndEndTimeSelect from '../../common/input/StartAndEndTimeSelect';
import DeletableCalendarField from '../../common/input/DeletableCalendarField';
import cloneDeep from 'lodash/cloneDeep';
import {ActiveHoursProps, InActiveDay} from '../../../types/components/ApiTypes';
import SubmitButton from '../../common/SubmitButton';
import getTestID from '../../../helper/functions/getTestID';
import FuelLockStore from '../../../stores/FuelLockStore';
import {observer} from 'mobx-react-lite';

interface Props {
    mondayStart: ActiveHoursProps,
    mondayEnd: ActiveHoursProps,
    tuesdayStart: ActiveHoursProps,
    tuesdayEnd: ActiveHoursProps,
    wednesdayStart: ActiveHoursProps,
    wednesdayEnd: ActiveHoursProps,
    thursdayStart: ActiveHoursProps,
    thursdayEnd: ActiveHoursProps,
    fridayStart: ActiveHoursProps,
    fridayEnd: ActiveHoursProps,
    saturdayStart: ActiveHoursProps,
    saturdayEnd: ActiveHoursProps,
    sundayStart: ActiveHoursProps,
    sundayEnd: ActiveHoursProps,
    inactiveDays: InActiveDay[],
    setInactiveDays: (value: InActiveDay[]) => void,
    width?: string,
    onUpdate?: () => void,
}

const DeviceActiveHours = observer((props: Props) => {
    const onAddInactiveDay = () => {
        const newData = cloneDeep(props.inactiveDays);
        newData.push({
            blockDay: '',
            deleting: false,
        });
        props.setInactiveDays(newData);
    };

    const editInactiveDay = (text: string, index: number) => {
        const newData = cloneDeep(props.inactiveDays);
        newData[index].blockDay = text;
        props.setInactiveDays(newData);
    };

    const onRemoveInactiveDay = (index: number) => {
        const newData = cloneDeep(props.inactiveDays);
        if (newData[index].id) {
            newData[index].deleting = true;
        } else {
            newData.splice(index, 1);
        }
        props.setInactiveDays(newData);
    };

    const renderActiveHours = (
        day: string,
        start: ActiveHoursProps,
        end: ActiveHoursProps
    ) => (
        <div>
            <Typography
                sx={{paddingBottom: '10px'}}
            >
                {day}: {start.value} - {end.value}
            </Typography>
            <StartAndEndTimeSelect
                id={`${ day }_device_active_hours`}
                start={start.value}
                handleChangeStart={value => start.setValue(value)}
                end={end.value}
                handleChangeEnd={value => end.setValue(value)}
            />
        </div>
    );

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: props.width ? props.width : '75%',
            }}
        >
            <TitleTypography title={Constants.DEVICE_ACTIVE_HOURS}/>
            {renderActiveHours(Constants.MONDAY, props.mondayStart, props.mondayEnd)}
            {renderActiveHours(Constants.TUESDAY, props.tuesdayStart, props.tuesdayEnd)}
            {renderActiveHours(Constants.WEDNESDAY, props.wednesdayStart, props.wednesdayEnd)}
            {renderActiveHours(Constants.THURSDAY, props.thursdayStart, props.thursdayEnd)}
            {renderActiveHours(Constants.FRIDAY, props.fridayStart, props.fridayEnd)}
            {renderActiveHours(Constants.SATURDAY, props.saturdayStart, props.saturdayEnd)}
            {renderActiveHours(Constants.SUNDAY, props.sundayStart, props.sundayEnd)}

            <TitleTypography title="Inactive Days (holidays, etc.)"/>

            {props.inactiveDays.filter(day => !day.deleting).map((inactiveDay, index) => (
                <DeletableCalendarField
                    id={`inactive_day_${ index }_calendar_input`}
                    key={inactiveDay.blockDay + index}
                    onDeleteClick={() => onRemoveInactiveDay(index)}
                    value={inactiveDay.blockDay}
                    errorMsg=""
                    onBlur={(newValue) => editInactiveDay(newValue, index)}
                />
            ))}

            <Button
                {...getTestID('add_inactive_day_button')}
                color="secondary"
                onClick={onAddInactiveDay}
            >
                Add Another Inactive Day
            </Button>

            {props.onUpdate && (
                <SubmitButton
                    id="update_active_hours_button"
                    text="Update Active Hours"
                    onClick={props.onUpdate}
                    disabled={FuelLockStore.awaitingApi}
                />
            )}
        </Paper>
    );
});

export default DeviceActiveHours;
