/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {MenuOpen} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import FuelLockIconHorizontal from '../../images/fuelLockLogoHorizontal.png';
import FuelLockIcon from '../../images/fuelLockLogo.png';
import getTestID from '../../helper/functions/getTestID';
import WebStore from '../../stores/WebStore';
import FuelLockIconHorizontalWhite from '../../images/fuelLockHorizontalLogo_White.png';
import FuelLockIconWhite from '../../images/fuelLockLogo-White.png';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '6px',
    paddingTop: '6px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '30px',
});

const ImageContainer = styled('div')({
    display: 'inline-flex',
    minWidth: '36px',
    flexShrink: 0,
});

interface Props {
    open: boolean,
    setOpen: () => void,
}

const DrawerHeader = observer((props: Props) => {
    const getImage = () => {
        if (WebStore.lightMode) {
            return props.open ? FuelLockIconHorizontal : FuelLockIcon;
        }
        return props.open ? FuelLockIconHorizontalWhite : FuelLockIconWhite;
    };

    return (
        <Root
            onClick={props.setOpen}
        >
            <ImageContainer>
                <img
                    style={{
                        height: '35px',
                        width: 'auto',
                    }}
                    src={getImage()}
                    alt="icon"
                />
            </ImageContainer>
            <IconButton
                {...getTestID('open_close_drawer_button')}
                sx={{
                    padding: 0,
                }}
            >
                <MenuOpen/>
            </IconButton>
        </Root>
    );
});

export default DrawerHeader;
