/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Tanks} from '../../types/components/EquipmentTypes';
import isStringNotEmpty from './isStringNotEmpty';
import isNumericInRange from './isNumericInRange';

const isFlowMeterValid = (tank: Tanks) => {
    const isValid = {
        valid: true,
        flowMeterNameError: tank.custom ? isStringNotEmpty(tank.flowMeterName, 'flow meter name') : '',
        limitError: isNumericInRange(tank.limit, 0, 65534, 0, 'limit'),
        pulsesPerLitreError: tank.custom
            ? isNumericInRange(tank.pulsesPerLitre, 0.1, 255.99, 2, 'pulses per litre')
            : '',
    };

    if (isValid.flowMeterNameError !== '' ||
        isValid.limitError !== '' ||
        isValid.pulsesPerLitreError !== '') {
        isValid.valid = false;
    }

    return isValid;
};

export default isFlowMeterValid;
