/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isNumericInRange(str: string, minValue: number, maxValue: number, numDecimals: number,
                                         type: string, allowLeadingZero = true) {
    if (numDecimals > 0) {
        const expression = new RegExp(`^\\d{1,6}(\\.\\d{1,${ numDecimals.toString() }})?$`);
        if (str === '' || !expression.test(str)) {
            return `Please enter a valid number with up to ${ numDecimals } decimals`;
        }
    } else {
        const expression = /^[0-9]*$/;
        if (str === '' || !expression.test(str)) {
            return `Please enter a valid ${ type }`;
        }
    }

    if (Number(str) < minValue || Number(str) > maxValue) {
        // specific error message for 32-bit equipment IDs
        if (type === 'ID' && maxValue === 999999999) {
            return `Please enter a valid ${ type } between 1 and 9 digits`;
        }
        return `Please enter a valid ${ type } between ${ minValue } and ${ maxValue }`;
    }

    if (!allowLeadingZero) {
        const expression = /^[1-9][0-9]*$/;
        if (str === '' || !expression.test(str)) {
            return `Please enter a valid ${ type } without leading zero`;
        }
    }

    return '';
}
