/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import TextField from '@mui/material/TextField';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string,
    label: string,
    value: string,
}

function DisabledTextField(props: Props) {
    return (
        <TextField
            {...getTestID(props.id)}
            fullWidth
            label={props.label}
            value={props.value}
            inputProps={{
                disabled: true,
            }}
            disabled
        />
    );
}

export default DisabledTextField;
