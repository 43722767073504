/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import {Add} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import cloneDeep from 'lodash/cloneDeep';
import {useSnackbar} from 'notistack';
import {Divider, styled} from '@mui/material';
import Constants from '../../../helper/Constants';
import TitleTypography from '../../common/typography/TitleTypography';
import CheckboxRow from '../../common/input/CheckboxRow';
import AddEditEmployeeModal from '../../employees/AddEditEmployeeModal';
import FuelLockStore from '../../../stores/FuelLockStore';
import callAPI from '../../../controllers/callAPI';
import {Employee} from '../../../types/components/ApiTypes';
import getArrayOfAllowedEmployeeOrEquipmentIDs from '../../../helper/functions/getArrayOfAllowedEmployeeOrEquipmentIDs';
import getArrayOfAllowedEmployeeOrEquipmentNewIndices
    from '../../../helper/functions/getArrayOfAllowedEmployeeOrEquipmentNewIndices';
import getTestID from '../../../helper/functions/getTestID';
import SubmitButton from '../../common/SubmitButton';
import callPostAPI from '../../../controllers/callPostAPI';
import LoadingGrid from '../../common/LoadingGrid';

const Center = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
});

const SubmitPadding = styled('div')({
    paddingTop: '16px',
});

interface NewEmployee {
    name: string,
    receiveNotifications: boolean,
    holiday: null,
}

interface Props {
    allowedEmployeeIDs?: number[],
    setAllowedEmployeeIDs?: (value: number[]) => void,
    allowedEmployeeIndices?: number[],
    setAllowedEmployeeIndices?: (value: number[]) => void,
    newEmployeeList?: NewEmployee[],
    setNewEmployeeList?: (value: {
        name: string,
        receiveNotifications: boolean,
        holiday: null,
    }[]) => void,
    editingDeviceId?: number,
    width?: string,
    update?: boolean,
}

interface EmployeeChangeList {
    id: number;
    title: string;
    checked: boolean;
    isNew: boolean;
}

interface AccessList {
    added: number[],
    removed: number[]
}

const AddEmployeesToDevice = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ open, setOpen ] = useState(false);
    const [ loading, setLoading ] = React.useState(false);
    const [ editing, setEditing ] = React.useState<{ index: number, employee: NewEmployee } | null>(null);

    const getStartingData = () => {
        const workerList = FuelLockStore.systemData.workerList;
        const newData: EmployeeChangeList[] = [];

        workerList.forEach(worker => {
            if (!worker.admin) {
                newData.push({
                    id: worker.id,
                    title: worker.name,
                    checked: props.allowedEmployeeIDs ? props.allowedEmployeeIDs.includes(worker.id) : true,
                    isNew: false,
                });
            }
        });

        if (props.newEmployeeList) {
            props.newEmployeeList.forEach((worker, index) => {
                newData.push({
                    id: index * -1,
                    title: worker.name,
                    checked: props.allowedEmployeeIndices ? props.allowedEmployeeIndices.includes(index) : true,
                    isNew: true,
                });
            });
        }

        return newData;
    };

    const [ data, setData ] = React.useState(getStartingData());
    const [ priorData, setPriorData ] = React.useState<EmployeeChangeList[]>([]);

    const retrieveEmployeeAccessData = () => {
        const workerList = FuelLockStore.systemData.workerList;
        const newData: { id: number; title: string; checked: boolean; isNew: boolean; }[] = [];
        const receivedId: { id: number; }[] = [];

        workerList.forEach(worker => {
            if (!worker.admin) {
                newData.push({
                    id: worker.id,
                    title: worker.name,
                    checked: false,
                    isNew: false,
                });
            }
        });

        callAPI(`fuellock/v2/employee/access/${ props.editingDeviceId }`, (receivedData) => {
            for (let i = 0; i < receivedData.data.length; i += 1) {
                const tempEmployee = receivedData.data[i] as Employee;
                receivedId.push({
                    id: tempEmployee.id,
                });
            }

            for (let x = 0; x < newData.length; x++) {
                for (let y = 0; y < receivedId.length; y++) {
                    if (newData[x].id === receivedId[y].id) {
                        newData[x].checked = true;
                    }
                }
            }
            setLoading(false);
            setData(newData);
            setPriorData(newData);
        }, enqueueSnackbar);
    };

    React.useEffect(() => {
        if (!props.allowedEmployeeIDs) {
            setLoading(true);
            retrieveEmployeeAccessData();
        } else {
            setData(getStartingData());
        }
    }, [ FuelLockStore.systemData.workerList ]);

    React.useEffect(() => {
        setData(getStartingData());
    }, [ props.newEmployeeList ]);

    const disableGold = () => {
        if (FuelLockStore.awaitingApi) {
            return true;
        } else if (data.length != priorData.length) {
            return false;
        } else if (data.length == priorData.length) {
            for (let i = 0; i < data.length; i += 1) {
                if (data[i].checked != priorData[i].checked) {
                    return false;
                }
            }
        }
        return true;
    };

    const allSelected = () => {
        let numSelected = 0;
        for (let i = 0; i < data.length; i += 1) {
            if (data[i].checked) {
                numSelected += 1;
            }
        }
        return numSelected === data.length;
    };

    const areAllSelected = allSelected();

    const onSelectUnselectAllEmployees = () => {
        const updateChecked = !areAllSelected;
        setData((oldData) => {
            const newData = cloneDeep(oldData);
            for (let i = 0; i < newData.length; i += 1) {
                newData[i].checked = updateChecked;
            }
            if (props.setAllowedEmployeeIDs) {
                props.setAllowedEmployeeIDs(getArrayOfAllowedEmployeeOrEquipmentIDs(newData));
            }
            if (props.setAllowedEmployeeIndices) {
                props.setAllowedEmployeeIndices(getArrayOfAllowedEmployeeOrEquipmentNewIndices(newData));
            }
            return newData;
        });
    };

    const onCheckChange = (index: number) => {
        setData((oldData) => {
            const newData = cloneDeep(oldData);
            newData[index].checked = !newData[index].checked;
            if (props.setAllowedEmployeeIDs) {
                props.setAllowedEmployeeIDs(getArrayOfAllowedEmployeeOrEquipmentIDs(newData));
            }
            if (props.setAllowedEmployeeIndices) {
                props.setAllowedEmployeeIndices(getArrayOfAllowedEmployeeOrEquipmentNewIndices(newData));
            }
            return newData;
        });
    };

    const closeEditingEmployee = () => {
        setEditing(null);
    };

    const updateNewEmployeesAllowedIndices = () => {
        if (props.setAllowedEmployeeIndices && props.allowedEmployeeIndices) {
            const newData = cloneDeep(props.allowedEmployeeIndices);
            newData.push(newData.length);
            props.setAllowedEmployeeIndices(newData);
        }
    };

    const onNewEmployeeSubmit = (newEmployee: NewEmployee) => {
        updateNewEmployeesAllowedIndices();
        if (props.setNewEmployeeList && props.newEmployeeList) {
            const newData = cloneDeep(props.newEmployeeList);
            newData.push(newEmployee);
            props.setNewEmployeeList(newData);
        }
    };

    const onNewEmployeeEdit = (newEmployee: NewEmployee) => {
        if (props.setNewEmployeeList && props.newEmployeeList && editing) {
            const newData = cloneDeep(props.newEmployeeList);
            newData[editing.index] = newEmployee;
            props.setNewEmployeeList(newData);
            closeEditingEmployee();
        }
    };

    const deleteNewEmployee = (index: number) => {
        if (props.setNewEmployeeList && props.newEmployeeList) {
            const newData = cloneDeep(props.newEmployeeList);
            newData.splice(index, 1);
            props.setNewEmployeeList(newData);
        }
    };

    const determineAddedOrRemovedEmployees = () => {
        const employeeAccessList: AccessList = {
            added: [],
            removed: [],
        };

        for (let i = 0; i < data.length; i += 1) {
            // (!priorData[i].checked && data[i].checked) || ((priorData[i].checked && data[i].checked)) - keep condtion if want to call webservice even if there isn't any changes
            if (!priorData[i].checked && data[i].checked) {
                employeeAccessList.added.push(data[i].id);
            } else if (priorData[i].checked && !data[i].checked) {
                employeeAccessList.removed.push(data[i].id);
            }
        }
        return employeeAccessList;
    };

    const deviceAccessChangeSuccessCallback = (accessList: AccessList) => {
        const oldWorkerList = JSON.parse(JSON.stringify(FuelLockStore.systemData.workerList));
        oldWorkerList.map((employee: Employee) => {
            const wdaIndex = employee.deviceAccessList.findIndex(wda => wda.deviceID === props.editingDeviceId);
            const isAdded = accessList.added.indexOf(employee.id) != -1;
            if (isAdded) {
                employee.deviceAccessList[wdaIndex].allowedToAccess = true;
            }
            const isRemoved = !isAdded ? accessList.removed.indexOf(employee.id) != -1 : false;
            if (isRemoved) {
                employee.deviceAccessList[wdaIndex].allowedToAccess = false;
            }
        });
        FuelLockStore.setWorkerList(oldWorkerList);
    };

    const callRemoveEmployee = (employeeIds: number[], successCallback?: () => void) => {
        if (employeeIds.length > 0) {
            const body = {
                intSet: employeeIds,
            };
            callPostAPI(`fuellock/v2/employee/access/remove/${ props.editingDeviceId }`, body,
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        if (successCallback != undefined) {
                            successCallback();
                        }
                    }
                }, enqueueSnackbar, 'Successfully removed employee(s) access');
        } else {
            if (successCallback != undefined) {
                successCallback();
            }
        }
    };

    const onUpdate = () => {
        const accessList: AccessList = determineAddedOrRemovedEmployees();
        const index = FuelLockStore.systemData.deviceList.findIndex(a => a.id === props.editingDeviceId);
        const newData = FuelLockStore.systemData.deviceList[index].supplyList;
        let powersupply = 0;
        let supplyString = 'one';
        newData.forEach(item => {
            powersupply += item.order;
        });
        switch (powersupply) {
            case 1:
                supplyString = 'one';
                break;
            case 2:
                supplyString = 'two';
                break;
            case 3:
                supplyString = 'both';
                break;
            default:
                supplyString = 'one';
        }
        if (accessList.added.length > 0) {
            const body = {
                intSet: accessList.added,
            };
            callPostAPI(`fuellock/v2/employee/access/add/${ supplyString }/${ props.editingDeviceId }`, body,
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        callRemoveEmployee(accessList.removed,
                            () => deviceAccessChangeSuccessCallback(accessList));
                    }
                }, enqueueSnackbar, 'Successfully added employee(s) access');
        } else {
            callRemoveEmployee(accessList.removed, () => deviceAccessChangeSuccessCallback(accessList));
        }
    };

    if (loading) {
        return (
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: props.width ? props.width : '75%',
                }}
            >
                <TitleTypography title={Constants.ADD_EMPLOYEES}/>

                <LoadingGrid
                    numColumns={1}
                    numRows={8}
                />
            </Paper>
        );
    }

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: props.width ? props.width : '75%',
            }}
        >
            <TitleTypography title={Constants.ADD_EMPLOYEES}/>

            <Center>
                <Button
                    {...getTestID(areAllSelected ? 'unselect_employees_button'
                        : 'select_employees_button')}
                    variant="contained"
                    onClick={onSelectUnselectAllEmployees}
                >
                    {areAllSelected ? 'Unselect All Existing Employees' : 'Add All Existing Employees'}
                </Button>
            </Center>

            <TitleTypography title="Existing Employees"/>
            <Divider/>

            {data.map((employee, index) => (
                <CheckboxRow
                    key={`${ employee.title }_${ employee.checked }`}
                    title={employee.title}
                    checked={employee.checked}
                    onChange={() => onCheckChange(index)}
                    isNew={employee.isNew}
                    onEdit={() => {
                        if (props.newEmployeeList) {
                            const employeeIndex = employee.id * -1;
                            setEditing({
                                index: employeeIndex,
                                employee: props.newEmployeeList[employeeIndex],
                            });
                        }
                    }}
                    onDelete={() => deleteNewEmployee(employee.id * -1)}
                />
            ))}
            {props.update && (
                <SubmitPadding>
                    <SubmitButton
                        id="update_employee_access_button"
                        text="Update Employee Access"
                        onClick={onUpdate}
                        disabled={disableGold()}
                    />
                </SubmitPadding>
            )}
            {!props.update && (
                <Fab
                    {...getTestID('add_new_employee_button')}
                    color="primary"
                    variant="extended"
                    onClick={() => setOpen(true)}
                    sx={{position: 'fixed'}}
                >
                    <Add/>
                    Employee
                </Fab>
            )}
            {open && (
                <AddEditEmployeeModal
                    open
                    close={() => setOpen(false)}
                    hideLinkedDevices
                    customSubmit={onNewEmployeeSubmit}
                    hideContractorPinOptions
                />
            )}
            {editing && (
                <AddEditEmployeeModal
                    open
                    close={closeEditingEmployee}
                    editing
                    hideLinkedDevices
                    hideHoliday
                    employeeName={editing.employee.name}
                    holiday={editing.employee.holiday}
                    receiveNotifications={editing.employee.receiveNotifications}
                    customSubmit={onNewEmployeeEdit}
                    hideContractorPinOptions
                />
            )}
        </Paper>
    );
});

export default AddEmployeesToDevice;
