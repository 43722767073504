/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const determineSupplyList = (powerSupplyPage: {
    powerSupplyOne: string,
    enableOne: boolean
    powerSupplyOneError: string,
    powerSupplyTwo: string,
    enableTwo: boolean,
    powerSupplyTwoError: string,
}) => {
    const supplyList = [];
    if (powerSupplyPage.enableOne) {
        supplyList.push({
            name: powerSupplyPage.powerSupplyOne,
            order: 1,
        });
    }
    if (powerSupplyPage.enableTwo) {
        supplyList.push({
            name: powerSupplyPage.powerSupplyTwo,
            order: 2,
        });
    }
    return supplyList;
};

export default determineSupplyList;
