/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import React, {useEffect} from 'react';
import {styled} from '@mui/material';
import Drawer from '../drawer/Drawer';
import Constants from '../../helper/Constants';
import Banner from '../banner/Banner';
import Dashboard from '../contentPanels/Dashboard';
import Employees from '../contentPanels/Employees';
import Equipment from '../contentPanels/Equipment';
import AdminAccount from '../contentPanels/AdminAccount';
import Notifications from '../contentPanels/Notifications';
import AccountPreferences from '../contentPanels/AccountPreferences';
import Information from '../contentPanels/Information';
import Devices from '../contentPanels/Devices';
import Reports from '../contentPanels/Reports';
import BillingInformation from '../contentPanels/BillingInformation';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import FuelLockStore from '../../stores/FuelLockStore';
import ConfirmModal from '../common/modal/ConfirmModal';
import runAnalytics from '../../helper/analytics/runAnalytics';
import ContractorPins from '../contentPanels/ContractorPins';

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    // @ts-ignore
    backgroundColor: theme.palette.primary.background,
    height: '100vh',
    width: '100%',
    flexDirection: 'row',
}));

const Container = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

interface Props {
    disableButtons?: boolean,
}

const Main = observer((props: Props) => {
    useEffect(() => {
        if (!props.disableButtons) {
            runAnalytics(WebStore.selectedDrawer);
        }
    }, [ WebStore.selectedDrawer ]);

    const renderContentPanel = () => {
        switch (WebStore.selectedDrawer) {
            case Constants.DASHBOARD:
                return <Dashboard/>;
            case Constants.EQUIPMENT:
                return <Equipment/>;
            case Constants.EMPLOYEES:
                return <Employees/>;
            case Constants.CONTRACTOR_PIN:
                return FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_CONTRACTOR_PIN &&
                    <ContractorPins/>;
            case Constants.DEVICES:
                return <Devices/>;
            case Constants.ADMIN_ACCOUNT:
                return <AdminAccount/>;
            case Constants.NOTIFICATIONS:
                return <Notifications/>;
            case Constants.ACCOUNT_PREFERENCES:
                return <AccountPreferences/>;
            case Constants.INFORMATION:
                return <Information/>;
            case Constants.REPORTS:
                return <Reports/>;
            case Constants.BILLING_AND_PAYMENT:
                return <BillingInformation/>;
            default:
                return <div/>;
        }
    };

    return (
        <Root>
            <Drawer
                disableButtons={props.disableButtons}
            />
            <Container>
                <Banner
                    disableButtons={props.disableButtons}
                />
                {renderContentPanel()}
                {(FuelLockStore.confirm.title !== '' && FuelLockStore.confirm.message !== '') && (
                    <ConfirmModal/>
                )}
            </Container>
        </Root>
    );

});

export default Main;
