/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Chart from 'react-apexcharts';
import Colors from '../../../helper/Colors';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import {Theme, XAxisType} from '../../../types/components/ApiTypes';
import {observer} from 'mobx-react-lite';
import ChartMenu from './ChartMenu';
import WebStore from '../../../stores/WebStore';
import getTestID from '../../../helper/functions/getTestID';
import TileSkeleton from '../TileSkeleton';

interface Props {
    title: string,
    id: string,
    selectedTimeFrame: string,
    setSelectedTimeFrame: (value: string) => void,
    series: {
        name: string,
        type: string,
        data: number[][],
    }[],
    paperRef: any,
    paperStyle: object,
    unit: string,
}

const BarChart = observer((props: Props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ options, setOptions ] = useState({
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: false,
            },
            selection: {
                enabled: false,
            },
            height: '100%',
            fontFamily: 'Gibson',
            background: WebStore.lightMode ? '#fff' : '#2c2c2c',
        },
        colors: [ Colors.primary ],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            type: XAxisType.dateTime,
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            decimalsInFloat: 1,
            labels: {
                formatter(val: string | number) {
                    return `${ val }${ props.unit }`;
                },
            },
        },
        title: {
            text: props.title,
            margin: 20,
            style: {
                fontSize: '20px',
                fontWeight: 400,
            },
        },
        theme: {
            mode: WebStore.lightMode ? Theme.light : Theme.dark,
        },
    });

    useEffect(() => {
        setTimeout(() => setLoading(false), 100);
    }, []);

    useEffect(() => {
        setLoading(true);
        setOptions({
            ...options,
            chart: {
                ...options.chart,
                background: WebStore.lightMode ? '#fff' : '#2c2c2c',
            },
            theme: {
                mode: WebStore.lightMode ? Theme.light : Theme.dark,
            },
        });
        setTimeout(() => setLoading(false), 100);
    }, [ WebStore.lightMode ]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (loading) {
        return <TileSkeleton/>;
    }

    return (
        <Paper
            sx={{
                padding: 0,
                ...props.paperStyle,
            }}
            elevation={6}
            ref={props.paperRef}
        >
            <div>
                <IconButton
                    {...getTestID(props.id)}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 16,
                        padding: 0,
                        zIndex: 9999,
                    }}
                    onClick={handleClick}
                >
                    <MoreVert/>
                </IconButton>
                {Boolean(anchorEl) && (
                    <ChartMenu
                        id={props.id}
                        anchorEl={anchorEl}
                        open
                        handleClose={handleClose}
                        selected={props.selectedTimeFrame}
                        setSelected={props.setSelectedTimeFrame}
                    />
                )}
                <Chart
                    options={options}
                    series={props.series}
                    type="bar"
                    width="100%"
                />
            </div>
        </Paper>
    );
});

export default BarChart;
