import * as React from 'react';
import {Responsive, WidthProvider} from 'react-grid-layout';
import EmployeeLitersTile from './tiles/EmployeeLitersTile';
import TransactionsTile from './tiles/TransactionsTile';
import EquipmentFuelUsageTile from './tiles/EquipmentFuelUsageTile';
import RecentTransactionsTile from './tiles/RecentTransactionsTile';
import TankUsageTile from './tiles/TankUsageTile';
import EmployeeFuelUsageTile from './tiles/EmployeeFuelUsageTile';
import cloneDeep from 'lodash/cloneDeep';
import {ProcessedUsagePojo, TankList} from '../../types/components/ApiTypes';
import TankLevelSummaryTile from './tiles/TankLevelSummaryTile';
// import generateDashboardOriginalLayout from '../../helper/functions/generateDashboardOriginalLayout';

const ROW_HEIGHT = 30;
const MARGIN = 25;
const PAPER_STYLE = {
    marginLeft: '0px',
    marginRight: '0px',
    borderRadius: '18px',
};

const generateOriginalLayouts = (tankLevelsData: Object[], numberOfTankLevelTiles: number) => {

    const xIncrement = 6;
    const yIncrement = 8;

    const lg: Object[] = [];
    const originalLayout = {lg};

    if (tankLevelsData && tankLevelsData.length > 0) {
        tankLevelsData.map((data, index) => {
            lg.push(
                {
                    i: `tankLevels_${ index }`,
                    x: index % 2 * xIncrement,
                    y: index / 2 * yIncrement,
                    w: xIncrement,
                    h: yIncrement,
                }
            );
        });
    }

    lg.push(
        {
            i: 'employeeLiters',
            x: numberOfTankLevelTiles % 2 * xIncrement,
            y: numberOfTankLevelTiles / 2 * yIncrement,
            w: xIncrement,
            h: yIncrement,
        }
    );

    lg.push(
        {
            i: 'transactions',
            x: (numberOfTankLevelTiles + 1) % 2 * xIncrement,
            y: (numberOfTankLevelTiles + 1) / 2 * yIncrement,
            w: xIncrement,
            h: yIncrement,
        }
    );

    lg.push(
        {
            i: 'equipmentFuelUsage',
            x: (numberOfTankLevelTiles + 2) % 2 * xIncrement,
            y: (numberOfTankLevelTiles + 2) / 2 * yIncrement,
            w: xIncrement,
            h: yIncrement,
        }
    );

    lg.push(
        {
            i: 'employeeFuelUsage',
            x: (numberOfTankLevelTiles + 3) % 2 * xIncrement,
            y: (numberOfTankLevelTiles + 3) / 2 * yIncrement,
            w: xIncrement,
            h: yIncrement,
        }
    );

    lg.push(
        {
            i: 'recentTransactions',
            x: 0,
            y: (numberOfTankLevelTiles + 4) / 2 * yIncrement,
            w: xIncrement * 2,
            h: 10,
        }
    );

    lg.push(
        {
            i: 'tankUsage',
            x: 0,
            y: (numberOfTankLevelTiles + 4) / 2 * yIncrement + 10, // y position of recentTransaction + height of recentTransaction
            w: xIncrement * 2,
            h: 15,
        }
    );

    return originalLayout;
};

const ResponsiveGridLayout = WidthProvider(Responsive);

interface LayoutItem {
    i: string,
    x: number,
    y: number,
    w: number,
    h: number,
    minW?: number,
    minH?: number,
}

interface Layouts {
    lg: LayoutItem[],
}

interface IProps {
    unit: string,
    data: ProcessedUsagePojo[],
    tankLevelsData: { deviceId: Number, deviceName: String, monitoredTanks: TankList[],}[],
    setHide: (value: boolean) => void,
}

interface IState {
    layouts: any,
    classNames: string,
}

export default class DashboardGrid extends React.Component<IProps, IState> {
    private readonly equipmentVolumeRef: React.RefObject<unknown>;

    private readonly employeeVolumeRef: React.RefObject<unknown>;

    private readonly transactionsRef: React.RefObject<unknown>;

    private readonly equipmentUsageRef: React.RefObject<unknown>;

    private readonly employeeUsageRef: React.RefObject<unknown>;

    private readonly recentTransactionsRef: React.RefObject<unknown>;

    private readonly tankUsageRef: React.RefObject<unknown>;

    private readonly tankLevelsRef: React.RefObject<unknown>[];

    private timeoutFunctionId: NodeJS.Timeout | null;

    constructor(props: IProps | Readonly<IProps>) {
        // @ts-ignore
        super(props);
        this.state = {
            layouts: JSON.parse(JSON.stringify(
                generateOriginalLayouts(this.props.tankLevelsData, this.props.tankLevelsData.length))),
            classNames: 'flex layout bg-white shadow rounded-sm',
        };
        this.employeeVolumeRef = React.createRef();
        this.equipmentVolumeRef = React.createRef();
        this.transactionsRef = React.createRef();
        this.equipmentUsageRef = React.createRef();
        this.employeeUsageRef = React.createRef();
        this.recentTransactionsRef = React.createRef();
        this.tankUsageRef = React.createRef();
        this.tankLevelsRef = [];

        for (let i = 0; i < this.props.tankLevelsData.length; i++) {
            this.tankLevelsRef.push(React.createRef());
        }

        this.timeoutFunctionId = null;
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            if (this.timeoutFunctionId) {
                clearTimeout(this.timeoutFunctionId);
            }
            this.timeoutFunctionId = setTimeout(this.updateSize, 500);
        });
        setTimeout(this.updateSize, 700);
        this.setState({classNames: 'flex layout bg-white shadow rounded-sm animated'});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize);
    }

    getHeight = (height: number) => (height + MARGIN) / (ROW_HEIGHT + MARGIN);

    getIndex = (state: { layouts: Layouts }, key: string) => state.layouts.lg.findIndex(item => item.i === key);

    updateSize = () => {
        this.setState(prevState => {
            const newState = cloneDeep(prevState);
            if (this.employeeVolumeRef && this.employeeVolumeRef.current) {
                const employeeVolume = {
                    // @ts-ignore
                    height: this.getHeight(this.employeeVolumeRef.current.clientHeight),
                    index: this.getIndex(newState, 'employeeLiters'),
                };
                newState.layouts.lg[employeeVolume.index].h = employeeVolume.height;
                newState.layouts.lg[employeeVolume.index].minH = employeeVolume.height;
            }
            if (this.equipmentVolumeRef && this.equipmentVolumeRef.current) {
                const equipmentVolume = {
                    // @ts-ignore
                    height: this.getHeight(this.equipmentVolumeRef.current.clientHeight),
                    index: this.getIndex(newState, 'equipmentLiters'),
                };
                newState.layouts.lg[equipmentVolume.index].h = equipmentVolume.height;
                newState.layouts.lg[equipmentVolume.index].minH = equipmentVolume.height;
            }
            if (this.transactionsRef && this.transactionsRef.current) {
                const transactions = {
                    // @ts-ignore
                    height: this.getHeight(this.transactionsRef.current.clientHeight),
                    index: this.getIndex(newState, 'transactions'),
                };
                newState.layouts.lg[transactions.index].h = transactions.height;
                newState.layouts.lg[transactions.index].minH = transactions.height;
            }
            if (this.tankUsageRef && this.tankUsageRef.current) {
                const tankUsage = {
                    // @ts-ignore
                    height: this.getHeight(this.tankUsageRef.current.clientHeight),
                    index: this.getIndex(newState, 'tankUsage'),
                };
                newState.layouts.lg[tankUsage.index].h = tankUsage.height;
                newState.layouts.lg[tankUsage.index].minH = tankUsage.height;
            }
            if (this.equipmentUsageRef && this.equipmentUsageRef.current) {
                const equipmentUsage = {
                    // @ts-ignore
                    height: this.getHeight(this.equipmentUsageRef.current.clientHeight),
                    index: this.getIndex(newState, 'equipmentFuelUsage'),
                };
                newState.layouts.lg[equipmentUsage.index].h = equipmentUsage.height;
                newState.layouts.lg[equipmentUsage.index].minH = equipmentUsage.height;
            }
            if (this.employeeUsageRef && this.employeeUsageRef.current) {
                const employeeUsage = {
                    // @ts-ignore
                    height: this.getHeight(this.employeeUsageRef.current.clientHeight),
                    index: this.getIndex(newState, 'employeeFuelUsage'),
                };
                newState.layouts.lg[employeeUsage.index].h = employeeUsage.height;
                newState.layouts.lg[employeeUsage.index].minH = employeeUsage.height;
            }
            if (this.recentTransactionsRef && this.recentTransactionsRef.current) {
                const recentTransaction = {
                    // @ts-ignore
                    height: this.getHeight(this.recentTransactionsRef.current.clientHeight),
                    index: this.getIndex(newState, 'recentTransactions'),
                };
                newState.layouts.lg[recentTransaction.index].h = recentTransaction.height;
                newState.layouts.lg[recentTransaction.index].minH = recentTransaction.height;
            }

            for (let i = 0; i < this.props.tankLevelsData.length; i++) {
                if (this.tankLevelsRef[i] && this.tankLevelsRef[i].current) {
                    const tankLevels = {
                        // @ts-ignore
                        height: this.getHeight(this.tankLevelsRef[i].current.clientHeight),
                        index: this.getIndex(newState, `tankLevels_${ i }`),
                    };
                    newState.layouts.lg[tankLevels.index].h = tankLevels.height;
                    newState.layouts.lg[tankLevels.index].minH = tankLevels.height;
                }
            }

            return newState;
        }, () => {this.props.setHide(false);});
    };

    onLayoutChange(layout: any, layouts: any) {
        // saveToLS('layouts', layouts);
        this.setState({layouts});
    }

    renderTankLevels () {
        const tankLevels: any[] = [];
        if (this.props.tankLevelsData && this.props.tankLevelsData.length > 0) {
            this.props.tankLevelsData.map((tankLevel, index) => {
                tankLevels.push(
                    (
                        <TankLevelSummaryTile
                            paperRef={this.tankLevelsRef[index]}
                            paperStyle={PAPER_STYLE}
                            tankLevelsDataPerDevice={tankLevel}
                        />
                    )
                );
            });
        }
        return tankLevels;
    }

    render() {
        const renderedTankLevels = this.renderTankLevels();
        const data: {
            employeeLiters: object,
            transactions: object,
            equipmentFuelUsage: object,
            employeeFuelUsage: object,
            recentTransactions: object,
            tankUsage: object,
        } =
        {
            employeeLiters: (
                <EmployeeLitersTile
                    paperRef={this.employeeVolumeRef}
                    paperStyle={PAPER_STYLE}
                    data={this.props.data}
                />
            ),
            transactions: (
                <TransactionsTile
                    paperRef={this.transactionsRef}
                    paperStyle={PAPER_STYLE}
                    data={this.props.data}
                />
            ),
            tankUsage: (
                <TankUsageTile
                    paperRef={this.tankUsageRef}
                    paperStyle={PAPER_STYLE}
                    data={this.props.data}
                />
            ),
            equipmentFuelUsage: (
                <EquipmentFuelUsageTile
                    paperRef={this.equipmentUsageRef}
                    paperStyle={PAPER_STYLE}
                    data={this.props.data}
                    updateSize={this.updateSize}
                />
            ),
            employeeFuelUsage: (
                <EmployeeFuelUsageTile
                    paperRef={this.employeeUsageRef}
                    paperStyle={PAPER_STYLE}
                    data={this.props.data}
                    updateSize={this.updateSize}
                />
            ),
            recentTransactions: (
                <RecentTransactionsTile
                    paperRef={this.recentTransactionsRef}
                    paperStyle={PAPER_STYLE}
                    data={this.props.data}
                    updateSize={this.updateSize}
                />
            ),

        };

        return (
            <div>
                <ResponsiveGridLayout
                    className={this.state.classNames}
                    layouts={this.state.layouts}
                    onLayoutChange={(layout, layouts) =>
                        this.onLayoutChange(layout, layouts)
                    }
                    breakpoints={{
                        lg: 1200,
                    }}
                    cols={{
                        lg: 12,
                    }}
                    rowHeight={ROW_HEIGHT}
                    resizeHandles={[]}
                    autoSize={false}
                    margin={[ MARGIN, MARGIN ]}
                >
                    {renderedTankLevels.map((tankLevels, index) => (
                        <div key={`tankLevels_${ index }`}>{tankLevels}</div>
                    ))}
                    <div key="employeeLiters">{data.employeeLiters}</div>
                    <div key="transactions">{data.transactions}</div>
                    <div key="tankUsage">{data.tankUsage}</div>
                    <div key="equipmentFuelUsage">{data.equipmentFuelUsage}</div>
                    <div key="employeeFuelUsage">{data.employeeFuelUsage}</div>
                    <div key="recentTransactions">{data.recentTransactions}</div>
                </ResponsiveGridLayout>
            </div>
        );
    }
};
