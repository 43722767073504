/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */
import loginStore from '../stores/LoginStore';
import fuelLockStore from '../stores/FuelLockStore';
import urlStore from '../stores/urlStore';
import getBaseURL from './getBaseURL';
import getUserAcceptHeader from './getUserAcceptHeader';

const download = (path: string, filename: string, onSuccess: () => void) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.style.display = 'none';
    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);

    onSuccess();

    setTimeout(() => {
        fuelLockStore.setAwaitingApi(false);
    }, 50);
};

const downloadFileApi = async (uri: string,
                               responseType: string,
                               fileName: string,
                               fileType: string,
                               onSuccess: () => void) => {
    setTimeout(() => {
        fuelLockStore.setAwaitingApi(true);
    }, 50);
    fetch(`${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
        {
            headers: {
                Accept: `${ responseType }`,
                'Content-Type': 'application/json',
                token: loginStore.token,
                udd: loginStore.udd.toString(),
                deviceID: loginStore.deviceID,
                ...getUserAcceptHeader(false),
            },
        }
    ).then(async resp => {
        // onSuccess(resp);
        // Download it
        // Create a Blob object
        const blob = await resp.blob();

        // Create an object URL
        const url = URL.createObjectURL(blob);
        console.log(url);
        // Download file
        download(url, `${ fileName }.${ fileType }`, onSuccess);

        // Release the object URL
        URL.revokeObjectURL(url);
        // console.log(resp.body)
        return resp;

    }).catch(e => {
        console.log(e);
        setTimeout(() => {
            fuelLockStore.setError('Error occurred while saving usage report.');
        }, 600);
    });
};

export default downloadFileApi;
