/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import handleError from './handleError';
import loginStore from '../stores/LoginStore';
import fuelLockStore from '../stores/FuelLockStore';
import getBaseURL from './getBaseURL';
import urlStore from '../stores/urlStore';
import getUserAcceptHeader from './getUserAcceptHeader';
import {IGTResponse} from '../types/components/ApiTypes';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import showStatusFailMessage from './showStatusFailMessage';
import displaySuccessMessage from './displaySuccessMessage';

const callDeleteAPI = <T = any>(uri: string,
                                onSuccess: (response: IGTResponse<T>) => void,
                                enqueueSnackbar: EnqueueSnackbar,
                                successMessage: string,
                                userAccept = false) => {
    fuelLockStore.setAwaitingApi(true);
    fetch(
        `${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
        {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: loginStore.token,
                udd: loginStore.udd.toString(),
                deviceID: loginStore.deviceID,
                'fl-platform': 'webapp',
                ...getUserAcceptHeader(userAccept),
            },
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar);
            return response.json();
        })
        .then((receivedServerData) => {
            fuelLockStore.setAwaitingApi(false);
            if (receivedServerData.status && receivedServerData.status === 'fail') {
                showStatusFailMessage(receivedServerData, enqueueSnackbar);
            } else if (receivedServerData.status && receivedServerData.status === 'confirm') {
                fuelLockStore.setConfirm({
                    title: receivedServerData.messageTitle,
                    message: receivedServerData.message,
                    onConfirm: () => {
                        callDeleteAPI(uri, onSuccess, enqueueSnackbar, successMessage, true);
                    },
                });
            } else {
                displaySuccessMessage(receivedServerData, successMessage, enqueueSnackbar);
                onSuccess(receivedServerData);
            }
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.message);
            fuelLockStore.setAwaitingApi(false);
        });
};

export default callDeleteAPI;
