/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import Grid from '@mui/material/Grid';
import ContentPanel from './ContentPanel';
import TextNotifications from '../settings/notifications/TextNotifications';
import EmailNotifications from '../settings/notifications/EmailNotifications';
// not functional right now.
// import LevelNotifications from '../settings/notifications/LevelNotifications';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

function Notifications() {
    return (
        <ContentPanel>
            <Root>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <TextNotifications/>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <EmailNotifications/>
                    </Grid>
                </Grid>
                {/*<LevelNotifications/>*/}
            </Root>
        </ContentPanel>
    );
}

export default Notifications;
