/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useMemo, useState} from 'react';
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro';
import {Delete, Edit} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import DeleteModal from '../common/modal/DeleteModal';
import callDeleteAPI from '../../controllers/callDeleteAPI';
import FuelLockStore from '../../stores/FuelLockStore';
import {Equipment, TrackUnit} from '../../types/components/ApiTypes';
import AddEditEquipmentModal from './AddEditEquipmentModal';
import Constants from '../../helper/Constants';
import getTestID from '../../helper/functions/getTestID';
import SuccessMessages from '../../helper/SuccessMessages';
import {styled, Tooltip, Typography} from '@mui/material';
import TooltipStore from '../../stores/TooltipStore';
import TooltipWithButton from '../common/TooltipWithButton';
import TooltipMessages from '../../helper/TooltipMessages';

const StyledTypography = styled(Typography)(() => ({
    fontSize: '14px',
}));

const EquipmentTable = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const getStartingData = () => {
        const equipmentList = FuelLockStore.systemData.equipmentList;
        const newData: Equipment[] = [];

        equipmentList.forEach((equipment) => {
            newData.push({
                ...equipment,
            });
        });

        return newData;
    };

    const [ rows, setRows ] = useState<Equipment[]>(getStartingData());
    const [ remove, setRemove ] = useState<{ id: number | null, name: string }>({
        id: null,
        name: '',
    });

    const [ editingId, setEditingId ] = useState(-1);

    useEffect(() => {
        setRows(getStartingData());
    }, [ FuelLockStore.systemData.equipmentList ]);

    const onDeleteClick = (id: number, name: string) => {
        setRemove({
            id,
            name,
        });
    };

    const onCloseDelete = () => {
        setRemove({
            id: null,
            name: '',
        });
    };

    const onDelete = () => {
        if (remove) {
            callDeleteAPI(`fuellock/v2/equipment/${ remove.id }`,
                (receivedServerData) => {
                    FuelLockStore.setAwaitingApi(false);
                    if (receivedServerData.status === 'success') {
                        const oldData = FuelLockStore.systemData.equipmentList;
                        const index = oldData.findIndex(item => item.id === remove.id);
                        const newData = JSON.parse(JSON.stringify(oldData));
                        newData.splice(index, 1);
                        FuelLockStore.setEquipmentData(newData);
                    }
                },
                enqueueSnackbar, SuccessMessages.DELETE_EQUIPMENT);
            onCloseDelete();
        }
    };

    const onEdit = (id: number) => {
        setEditingId(id);
    };

    const columns: GridColDef[] = useMemo(() => ([
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            disableReorder: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
                >
                    <Tooltip
                        open={rows.length > 0 && params.row.id === rows[0].id &&
                        TooltipStore.beginTooltips &&
                        !TooltipStore.showDashboardMessages &&
                        !TooltipStore.showLightModeMessages &&
                        !TooltipStore.showAddEquipmentMessages &&
                        TooltipStore.showEquipmentMessages}
                        arrow
                        title={(
                            <TooltipWithButton
                                id="edit_equipment_tooltip_button"
                                message={TooltipMessages.EDIT_EQUIPMENT}
                                onClick={() => TooltipStore.setShowEquipmentMessages(false, true)}
                            />
                        )}
                    >
                        <IconButton
                            {...getTestID(`${ params.row.name }_edit`)}
                            color="secondary"
                            onClick={() => onEdit(params.row.id)}
                        >
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        {...getTestID(`${ params.row.name }_delete`)}
                        color="secondary"
                        onClick={() => onDeleteClick(params.row.id, params.row.name)}
                    >
                        <Delete/>
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => (
                <StyledTypography {...getTestID(params.row.name)}>
                    {params.row.name}
                </StyledTypography>
            ),
        },
        {
            field: 'hwID',
            headerName: 'Equipment ID',
            width: 150,
            renderCell: (params) => (
                <StyledTypography {...getTestID(`${ params.row.name }_id`)}>
                    {params.row.hwID}
                </StyledTypography>
            ),
        },
        {
            field: 'trackUnit',
            headerName: 'Tracking',
            width: 150,
            renderCell: (params) => (
                <StyledTypography {...getTestID(`${ params.row.name }_tracking`)}>
                    { params.row.trackUnit === TrackUnit.km ? Constants.TRACK_KMS
                        : (params.row.trackUnit === TrackUnit.hours ? Constants.TRACK_HOURS : Constants.TRACK_MILES) }
                </StyledTypography>
            ),
        },
    ]), [
        TooltipStore.beginTooltips,
        TooltipStore.showDashboardMessages,
        TooltipStore.showLightModeMessages,
        TooltipStore.showAddEquipmentMessages,
        TooltipStore.showEquipmentMessages,
    ]) ;

    return (
        <div
            style={{
                height: window.innerHeight - 150,
                width: '100%',
            }}
        >
            <DataGridPro
                rows={rows}
                columns={columns}
                disableColumnSelector
            />

            {remove.id !== null && (
                <DeleteModal
                    id="delete_equipment_modal"
                    open
                    close={onCloseDelete}
                    delete={onDelete}
                    description={remove.name}
                    secondaryDescription={Constants.HISTORICAL_DATA_MESSAGE}
                />
            )}

            {editingId !== -1 && (
                <AddEditEquipmentModal
                    open
                    close={() => setEditingId(-1)}
                    isEdit
                    editingId={editingId}
                />
            )}
        </div>
    );
});

export default EquipmentTable;
