/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import fcmStore from '../stores/fcmStore';
import loginStore from '../stores/LoginStore';
import getBaseURL from './getBaseURL';
import handleError from './handleError';
import {EnqueueSnackbar} from '../types/SnackbarTypes';

export interface LoginApiResponse {
    success: boolean,
    code: number
}

const callLoginAPI = (username: string,
                      password: string,
                      enqueueSnackbar: EnqueueSnackbar): Promise<LoginApiResponse> => (
    fetch(
        `${ getBaseURL() }Fuellock_Oauth2-2.0.0/webresources/Users/simpleOauthLogin`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                clientkey: 'fuellock',
                clientSecret: '12345678',
                redirectURI: 'fuellock.com',
                deviceID: loginStore.deviceID,
            },
            body: JSON.stringify({
                userUsername: username,
                'userPassword': password,
                'fcmToken': fcmStore.fcmToken,
            }),
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar);
            return response.json();
        })
        .then((receivedServerData) => {
            if (receivedServerData.token.startsWith('-')) {
                return {
                    success: false,
                    code: 401,
                };
            }
            loginStore.setToken(receivedServerData.token, true);
            loginStore.setUdd(receivedServerData.udd, true);
            loginStore.setTemporaryPassword(receivedServerData.isTemporary);
            return {
                success: true,
                code: 200,
            };
        })
        .catch((error) => {
            try {
                return {
                    success: false,
                    code: JSON.parse(error.message).statusCode,
                };
            } catch (parseError: any) {
                // the error handler errored out because the message was not a json object
                return {
                    success: false,
                    code: 0,
                };
            }
        })
);

export default callLoginAPI;
