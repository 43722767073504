/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const COUNTRY_PROVINCE_LIST_CA = [ 'Alberta', 'British Columbia', 'Manitoba',
    'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut',
    'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory' ];

const COUNTRY_PROVINCES_USA = [ 'Alabama', 'Alaska', 'Arizona', 'Arkansas',
    'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
    'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
    'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
    'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
    'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia',
    'Washington', 'West Virginia', 'Wisconsin', 'Wyoming' ];

export default {
    ACCOUNT_PREFERENCES: 'Account Preferences',
    ACTIVATION: 'Activation',
    ACTIVE_HOURS: 'Active Hours',
    ADD: 'Add',
    ADD_CONTRACTOR_PIN: 'Add Contractor ID',
    ADD_EMPLOYEES: 'Add Employees',
    ADD_EQUIPMENT: 'Add Equipment',
    ADD_NEW_DEVICE: 'Add New Device',
    ADDRESS: 'Address',
    ADMIN_ACCOUNT: 'Admin Account',
    ALL_DEVICES: 'All Devices',
    AVAILABLE_PORT_NUMBER: 'Available Port Number',

    BILLING_ADDRESS: 'Billing Address',
    BILLING_AND_PAYMENT: 'Billing & Payment',
    BOTH: 'Both',

    CAPACITY: 'Capacity',
    CHANGE_PIN: 'Change PIN',
    COMPANY_NAME: 'Company Name',
    CONFIRM_PASSWORD: 'Confirm Password',
    CONTRACTOR_PIN: 'Contractor ID',
    CONTRACTOR_PIN_ACCESS: 'Contractor ID Access',
    COUNTRY: 'Country',
    COUNTRY_ABBREVIATION: {
        'CAN': 'Canada',
        'USA': 'United States',
        'Canada': 'CAN',
        'United States': 'USA',
    },
    COUNTRY_LIST: {
        'Canada': COUNTRY_PROVINCE_LIST_CA,
        'United States': COUNTRY_PROVINCES_USA,
    },
    CURRENT_PLAN: 'Current Plan',
    CUSTOM_FIELD_NAME: 'Field Name',
    CUSTOM_FLOW_METER: 'Custom Flow Meter',

    DAILY: 'Daily',
    DASHBOARD: 'Dashboard',
    DELETE: 'Delete',
    DELETE_ACCOUNT_CODE_ENTRY: 'Deleting your account will permanently delete all your account information ' +
        'and cannot be undone.\nTo proceed, type the four-digit number below into the confirmation code field ' +
        'and tap \"Delete Account\".',
    DELETE_ACCOUNT_CONFIRM: 'Deleting your Fuel Lock account will remove all data history, as well as ' +
        'your Fuel Lock Device.\nAre you sure you want to delete your account?',
    DELETE_ACCOUNT_ERROR: 'Enter the correct pin to proceed',
    DELETE_ACCOUNT: 'Delete Account',
    DENSITY: 'Density',
    DEVICE_ACTIVE_HOURS: 'Device Active Hours',
    DEVICE_ADMIN_DISPLAY_NAME: 'Device Admin Display Name',
    DEVICE_NAME: 'Device Name',
    DEVICES: 'Devices',
    DOWNLOAD_REPORT: 'Download Report',

    EDIT: 'Edit',
    ELECTRICAL_AND_USER_GUIDE: 'Electrical & User Guide',
    EMAIL: 'Email',
    EMAIL_NOTIFICATIONS: 'Email Notifications',
    EMAIL_REPORT: 'Email Report',
    EMPLOYEE_ACCESS: 'Employee Access',
    EMPLOYEES: 'Employees',
    EQUIPMENT: 'Equipment',
    EQUIPMENT_ACCESS: 'Equipment Access',
    EQUIPMENT_ID: 'Equipment ID',

    FIRST_NAME: 'First Name',
    FLOW_METER: 'Flow Meter',
    FLOW_METER_SETTINGS: 'Flow Meter Settings',
    FRIDAY: 'Friday',
    FUEL_LOCK: 'Fuel Lock',
    FUEL_LOCK_BUSINESS: 'Fuel Lock Business',
    FUEL_LOCK_COMMERCIAL: 'Fuel Lock Commercial',

    INFORMATION: 'Information',
    INVOICE_HISTORY: 'Invoice History',

    LAST_NAME: 'Last Name',
    LEVEL_NOTIFICATIONS: 'Level Notifications',
    LEVEL_SENSOR: 'Level Sensor',
    LEVEL_SENSOR_CONFIGURATION: 'Level Sensor Configuration',
    LEVEL_SENSOR_PRODUCT: 'Level Sensor Product',

    MAIN: 'Main',
    MIN_FW_VERSION_32BIT_EQUIPMENT_ID: 21,
    MIN_FW_VERSION_CONTRACTOR_PIN: 21,
    MIN_FW_VERSION_CUSTOM_FIELD: 20,
    MIN_FW_VERSION_SMART_LOCK: 20,
    MODEL: 'Model',
    MONDAY: 'Monday',
    MONTHLY: 'Monthly',

    NAME: 'Name',
    NEXT: 'Next',
    NOTIFICATIONS: 'Notifications',

    OTODATA: 'Otodata',
    OTODATA_DEVICE: 'Otodata Device',
    OVERDUE_WARNING:'You currently have an outstanding balance and are unable to manage your device, equipment and ' +
        'employees.  Please make payment to regain this functionality.',

    PASSWORD: 'Password',
    PAY_NOW: 'Pay Now',
    PERSONAL_DETAILS: 'Personal Details',
    PHONE_NUMBER: 'Phone Number',
    PLAN_DOWNGRADE: 'Plan downgrade',
    PLAN_UPGRADE: 'Plan upgrade',
    POSTAL_CODE_OR_ZIP_CODE: 'Postal Code or Zip Code',

    POWER_SUPPLY_ADD: 'Add New Power Supply',
    POWER_SUPPLY_DELETE: 'Delete Power Supply',
    POWER_SUPPLY_DETAILS: 'Power Supply Details',
    POWER_SUPPLY_EDIT: 'Edit Power Supply',
    POWER_SUPPLY_ONE: 'Power Supply 1',
    POWER_SUPPLY_TWO: 'Power Supply 2',
    PRIVACY_POLICY: 'Privacy Policy',
    PROVINCE_OR_STATE: 'Province or State',

    REGISTER: 'Register',
    REMOVED_EQUIPMENT: 'Removed Equipment',
    REMOVED_USER: 'Removed User',
    REPORTS: 'Reports',

    SATURDAY: 'Saturday',
    SERIAL_NUMBER: 'Serial Number',
    SETTINGS: 'Settings',
    SMART_LOCK: 'Smart Lock',
    SUNDAY: 'Sunday',

    TANK_CONFIGURATION: 'Tank Configuration',
    TANK_NAME: 'Tank Name',
    TANK_TYPE: 'Tank Type',
    TANKS: 'Tanks',
    VIRTUAL_TANK_MONITORING: 'Virtual Tank Monitoring',
    TATTLE: 'Tattle',
    TATTLE_ID: 'Tattle ID',
    TERMS_OF_SERVICE: 'Terms of Service',
    TEXT_NOTIFICATIONS: 'Text Notifications',
    THURSDAY: 'Thursday',
    TOWN_OR_CITY: 'Town or City',
    TRACK_HOURS: 'Track Hours',
    TRACK_KMS: 'Track KMs',
    TRACK_MILES: 'Track Miles',
    TRANSFER_DEVICE: 'Transfer Device',
    TUESDAY: 'Tuesday',

    UK_GALLONS: 'UK gallons',
    US_GALLONS: 'US gallons',
    UNLOCK: 'Unlock',

    WEDNESDAY: 'Wednesday',
    WEEKLY: 'Weekly',

    YEARLY: 'Yearly',

    STORE_KEY_PROTOCOL: '@FuelLockStore_protocol',
    STORE_KEY_IP_ADDRESS: '@FuelLockStore_ip_address',
    STORE_KEY_PORT: '@FuelLockStore_port',
    STORE_KEY_BASE_DIRECTORY: '@FuelLockStore_base_directory',
    STORE_KEY_EMAIL: '@FuelLockStore_email',
    STORE_KEY_TOKEN: '@FuelLockStore_token',
    STORE_KEY_UDD: '@FuelLockStore_udd',
    STORE_KEY_BIOMETRIC_AUTHENTICATION: '@FuelLockStore_biometric',
    STORE_KEY_DEVICEID: '@FuelLockStore_deviceID',
    STORE_KEY_LIGHT_MODE: '@FuelLockStore_lightMode',
    STORE_KEY_SHOW_INTRO_MESSAGES: '@FuelLockStore_showIntroMessages',
    STORE_KEY_SHOW_DASHBOARD_MESSAGES: '@FuelLockStore_showDashboardMessages',
    STORE_KEY_SHOW_LIGHT_MODE_MESSAGES: '@FuelLockStore_showLightModeMessages',
    STORE_KEY_SHOW_EMPLOYEE_MESSAGES: '@FuelLockStore_showEmployeeMessages',
    STORE_KEY_SHOW_EQUIPMENT_MESSAGES: '@FuelLockStore_showEquipmentMessages',
    STORE_KEY_SHOW_CONTRACTOR_PIN_MESSAGES: '@FuelLockStore_showContractorPinMessages',
    STORE_KEY_SHOW_REPORTS_MESSAGES: '@FuelLockStore_showReportsMessages',
    STORE_KEY_SHOW_ACCOUNT_PREFERENCES_MESSAGES: '@FuelLockStore_showAccountPreferencesMessages',
    STORE_KEY_SHOW_DEVICE_MESSAGES: '@FuelLockStore_showDeviceMessages',
    STORE_KEY_SHOW_ADD_EMPLOYEE_MESSAGES: '@FuelLockStore_showAddEmployeeMessages',
    STORE_KEY_SHOW_ADD_EQUIPMENT_MESSAGES: '@FuelLockStore_showAddEquipmentMessages',
    STORE_KEY_SHOW_ADD_CONTRACTOR_PIN_MESSAGES: '@FuelLockStore_showAddContractorPinMessages',
    STORE_KEY_SHOW_ADD_DEVICE_MESSAGES: '@FuelLockStore_showAddDeviceMessages',
    STORE_KEY_SHOW_TANK_DEVICE_MESSAGES: '@FuelLockStore_showTankDeviceMessages',
    STORE_KEY_BEGIN_TOOLTIPS: '@FuelLockStore_showBeginTooltips',

    REMOTE_UNLOCK_DEVICE_MODAL_TITLE: 'REMOTE UNLOCK DEVICE',
    REMOTE_UNLOCK_DEVICE_MODAL_MESSAGE: 'Unlock Device will Unlock all pumps of the Fuel Lock™ Device.' +
        '\n\nOnce the Unlock Command is sent, the Fuel Lock™ Device will need to be synced.\n\nAre you sure' +
        ' you want to remote unlock the device?',

    HISTORICAL_DATA_MESSAGE: 'Deleting this will cause you to lose historical data.',

    YES: 'Yes',
    NO: 'No',
    CANCEL: 'Cancel',

    WARNING_TITLE: 'Warning! Please Read:',
};
