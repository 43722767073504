/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Paper, styled, Typography} from '@mui/material';
import {TankList} from '../../../types/components/ApiTypes';
import TextFieldInput from '../../common/input/TextFieldInput';
import Constants from '../../../helper/Constants';
import getFlowMeterName from '../../../helper/functions/getFlowMeterName';
import SubmitButton from '../../common/SubmitButton';
import isStringNotEmpty from '../../../helper/validator/isStringNotEmpty';
import isNumericInRange from '../../../helper/validator/isNumericInRange';
import FuelLockStore from '../../../stores/FuelLockStore';
import ErrorMessages from '../../../helper/ErrorMessages';
import callPutAPI from '../../../controllers/callPutAPI';
import {useSnackbar} from 'notistack';
import SuccessMessages from '../../../helper/SuccessMessages';
import getTestID from '../../../helper/functions/getTestID';
import getUnitNameAndSymbol from '../../../helper/functions/getUnitNameAndSymbol';
import getDataById from '../../../helper/functions/getDataById';
import MemoTypography from '../../common/typography/MemoTypography';

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

interface Props {
    editingDeviceId: number,
    tank: TankList
}

const EditTankSettings = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ name, setName ] = useState(props.tank.name);
    const [ nameError, setNameError ] = useState('');
    const [ capacity, setCapacity ] = useState(props.tank.capacity.toString());
    const [ capacityError, setCapacityError ] = useState('');
    const [ unit ] = useState(getUnitNameAndSymbol(getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId).name));

    const isValid = () => {
        let valid = true;

        const newNameError = isStringNotEmpty(name, 'name');
        setNameError(newNameError);

        const newCapacityError = isNumericInRange(capacity, 0, 4294967295, 0,
            'capacity');
        setCapacityError(newCapacityError);

        if (newNameError !== '' || newCapacityError !== '') {
            valid = false;
        }

        return valid;
    };

    const saveTankConfiguration = () => {
        if (isValid()) {
            const deviceIndex = FuelLockStore.systemData.deviceList.findIndex(a =>
                a.id === props.editingDeviceId);
            const tankIndex = FuelLockStore.systemData.deviceList[deviceIndex].tankList.findIndex(a =>
                a.id === props.tank.id);

            const tank = JSON.parse(JSON.stringify((
                FuelLockStore.systemData
                    .deviceList[deviceIndex]
                    .tankList[tankIndex])));

            tank.name = name;
            tank.capacity = Number(capacity);

            callPutAPI(`fuellock/v2/tank/${ props.tank.id }`, tank, (receivedServerData) => {
                if (receivedServerData.status && receivedServerData.status !== 'success') {
                    FuelLockStore.setError(ErrorMessages.UNEXPECTED);
                } else {
                    const oldData = FuelLockStore.systemData.deviceList;
                    const newData = JSON.parse(JSON.stringify(oldData));
                    newData[deviceIndex].tankList[tankIndex] = tank;
                    FuelLockStore.setDeviceData(newData);
                }
            }, enqueueSnackbar, SuccessMessages.UPDATE_TANK);
        }
    };

    return (
        <Paper>
            <TextFieldInput
                id="tank_name_input"
                label={Constants.TANK_NAME}
                field={{
                    value: name,
                    setValue: setName,
                    error: nameError,
                    setError: setNameError,
                }}
                textFieldProps={{
                    inputProps: {
                        maxLength: 16,
                    },
                }}
            />
            <TextFieldInput
                id="tank_capacity_input"
                label={`${ Constants.CAPACITY } (${ unit.name })`}
                field={{
                    value: capacity,
                    setValue: setCapacity,
                    error: capacityError,
                    setError: setCapacityError,
                }}
                textFieldProps={{
                    inputProps: {
                        maxLength: 10,
                    },
                }}
            />
            <div style={{marginBottom: '20px'}}>
                <MemoTypography
                    memo={'Enter tank capacity correctly to ensure more accurate tank level monitoring'}
                />
            </div>
            <Row>
                <Typography>Flow meter:</Typography>
                <Typography {...getTestID('tanksettings_flowmeter')}>
                    {props.tank.flowMeter
                        ? getFlowMeterName(props.tank.flowMeter.flowMeterTypeId)
                        : 'Not Specified'}
                </Typography>
            </Row>
            <SubmitButton
                id="save_tank_configuration_button"
                text="Update Tank"
                onClick={saveTankConfiguration}
                disabled={FuelLockStore.awaitingApi}
            />
        </Paper>
    );
});

export default EditTankSettings;
