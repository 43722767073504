/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */
import fuelLockStore from '../../stores/FuelLockStore';
import { FlowMeterType } from '../../types/components/ApiTypes';

const getFlowMeterType = (model: string): FlowMeterType => {
    const index = fuelLockStore.systemData.flowMeterTypeList.findIndex((item: FlowMeterType) => item.name === model);
    const flowMeterType = fuelLockStore.systemData.flowMeterTypeList[index];
    return {
        id: flowMeterType.id,
        name: flowMeterType.name,
        custom: flowMeterType.custom,
        pulsesPerLitre: flowMeterType.pulsesPerLitre,
    };
};

export default getFlowMeterType;
