/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import BaseModal from '../common/modal/BaseModal';
import getTestID from '../../helper/functions/getTestID';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    onSubmit: () => void,
}

const DeviceTransferNotificationModal = observer((props: Props) => (
    <BaseModal
        id='transfer_device_notification_modal'
        open={props.open}
        close={props.close}
        submit={props.onSubmit}
        title='Transferring Device'
        submitButtonText='Continue with Transfer'
        disableSubmitOnAwait
    >
        <Container>
            <Typography {...getTestID('transfer_device_notification_message')}>
                Transferring this device will move all
                data and settings to the new device.
                Your old Fuel Lock device will be
                no longer associated with
                this account. Do you wish to continue
                with the transfer?
            </Typography>
        </Container>
    </BaseModal>
));

export default DeviceTransferNotificationModal;
