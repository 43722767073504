/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isHex(str: string, minLength: number, type: string) {
    const expression = /^[A-Fa-f0-9]+$/;
    if (str === '' || str.length < minLength || !expression.test(str)) {
        return `Please enter a valid ${ type }`;
    }

    return '';
}
