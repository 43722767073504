/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';
import CenterScreen from '../common/CenterScreen';
import ForgotPassword from './forgotPassword/ForgotPassword';
import CreateNewAccount from './createNewAccount/CreateNewAccount';
import SubmitButton from '../common/SubmitButton';
import LoginStore from '../../stores/LoginStore';
import TextFieldInput from '../common/input/TextFieldInput';
import callAPI from '../../controllers/callAPI';
import FuelLockStore from '../../stores/FuelLockStore';
import callLoginAPI from '../../controllers/callLoginAPI';
import UrlConfiguration from './urlConfiguration/UrlConfiguration';
import Constants from '../../helper/Constants';
import WebStore from '../../stores/WebStore';
import getTestID from '../../helper/functions/getTestID';
import Desktop from '../main/Desktop';
import PasswordField from '../common/input/PasswordField';
import Colors from '../../helper/Colors';
import FuelLockLogo from '../../images/fuelLockLogoHorizontal.png';
import runAnalytics from '../../helper/analytics/runAnalytics';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

const Title = styled('div')({
    backgroundColor: Colors.primary,
    padding: '16px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    paddingTop: '25px',
    paddingRight: '20px',
    paddingLeft: '20px',
});

const Content = styled('div')({
    paddingTop: '16px',
    paddingRight: '20px',
    paddingBottom: '25px',
    paddingLeft: '20px',
});

const Login = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ forgotPasswordOpen, setForgotPasswordOpen ] = useState(false);
    const [ createAccountOpen, setCreateAccountOpen ] = useState(false);
    const [ urlConfigurationOpen, setUrlConfigurationOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ sendingLogin, setSendingLogin ] = useState(false);

    const getDashboard = (sendEmail: boolean) => {
        LoginStore.setLoadingData(true);
        callAPI('fuellock/v2/dashboard',
            receivedServerData => {
                if (sendEmail) {
                    LoginStore.setEmail(email, true);
                }
                FuelLockStore.setSystemData(receivedServerData.data);
                if (receivedServerData.data.personalDetails.accountLockedOut) {
                    WebStore.setOverDue(true);
                    WebStore.setSelectedDrawer(Constants.DASHBOARD);
                } else {
                    WebStore.setOverDue(false);
                    /*
                        Only navigate and allow users to add devices if they are not locked out
                        and have no devices on their account
                    */
                    if (receivedServerData.data.deviceList.length === 0) {
                        WebStore.setSelectedDrawer(Constants.DEVICES);
                        WebStore.setCurrentPage(Constants.ADD_NEW_DEVICE);
                    }
                }
                LoginStore.setLoadingData(false);
                LoginStore.setLoggedIn(true);
            }, enqueueSnackbar, false, () => setLoading(false));
    };

    useEffect(() => {
        runAnalytics('Login');
        const url = new URL(window.location.href);
        const accountEmail = url.searchParams.get('accountEmail');
        const token = url.searchParams.get('token');

        if (accountEmail !== null && token !== null) {
            LoginStore.setTemporaryPassword(true);
        } else if (LoginStore.token !== '') {
            setLoading(true);
            getDashboard(false);
        }
    }, []);

    const onLogin = async () => {
        if (email === 'wsenv' && password === 'igt') {
            setUrlConfigurationOpen(true);
        } else {
            setLoading(true);
            setSendingLogin(true);
            const successful = await callLoginAPI(email, password, enqueueSnackbar);
            setSendingLogin(false);
            if (successful.success) {
                getDashboard(true);
            } else if (successful.code === 401) {
                setLoading(false);
                enqueueSnackbar('Login failed. Incorrect username or password', {variant: 'error'});
            } else {
                setLoading(false);
                enqueueSnackbar('Unable to log in.', {variant: 'error'});
            }
        }
    };

    const onEnterPress = (e: any) => {
        if (e.key === 'Enter') {
            onLogin();
        }
    };

    if (forgotPasswordOpen) {
        return (
            <ForgotPassword
                setForgotPasswordOpen={setForgotPasswordOpen}
            />
        );
    }

    if (createAccountOpen) {
        return (
            <CreateNewAccount
                setCreateAccountOpen={setCreateAccountOpen}
            />
        );
    }

    if (urlConfigurationOpen) {
        return (
            <UrlConfiguration
                setUrlConfigurationOpen={setUrlConfigurationOpen}
            />
        );
    }

    if (loading && !sendingLogin) {
        return (
            <Desktop disableButtons/>
        );
    }

    return (
        <CenterScreen>
            <Paper
                sx={{
                    width: '33%',
                    minWidth: '444px',
                    padding: '0px',
                }}
            >
                <Container onKeyDown={onEnterPress}>
                    <Title>
                        <img
                            src={FuelLockLogo}
                            alt="login logo"
                            style={{height: '50px'}}
                        />
                    </Title>
                    <Content>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                paddingBottom: '32px',
                            }}
                        >
                            Log in
                        </Typography>

                        <TextFieldInput
                            id="login_email_address_input"
                            label="Email Address"
                            field={{
                                value: email,
                                setValue: setEmail,
                                error: '',
                                setError: () => {
                                },
                            }}
                            textFieldProps={{
                                inputProps: {
                                    maxLength: 60,
                                },
                            }}
                        />
                        <PasswordField
                            id="login_password_input"
                            label="Password"
                            field={{
                                value: password,
                                setValue: setPassword,
                                error: '',
                                setError: () => {
                                },
                            }}
                        />

                        <SubmitButton
                            id="login_button"
                            text="Login"
                            onClick={onLogin}
                            disabled={sendingLogin}
                        />

                        <Divider
                            sx={{
                                marginTop: '16px',
                                marginBottom: '16px',
                            }}
                        />
                        <div>
                            <Button
                                {...getTestID('create_new_account_button')}
                                onClick={() => setCreateAccountOpen(true)}
                            >
                                Create new account
                            </Button>
                        </div>
                        <div>
                            <Button
                                {...getTestID('forgot_password_button')}
                                onClick={() => setForgotPasswordOpen(true)}
                            >
                                Forgot password
                            </Button>
                        </div>
                    </Content>

                </Container>
            </Paper>
        </CenterScreen>
    );
});

export default Login;
