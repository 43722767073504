/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isNumeric(str: string, minLength: number, type: string) {
    const expression = /^[0-9]*$/;
    if (str === '' || str.length < minLength || !expression.test(str)) {
        return `Please enter a valid ${ type }`;
    }

    return '';
}

export function isNumericPIN(str: string, minLength: number, maxLength: number) {
    const expression = /^[0-9]*$/;
    if (str === '' || str.length < minLength || str.length > maxLength || !expression.test(str)) {
        return `Please enter a valid pin between ${ minLength } - ${ maxLength } digits`;
    }

    return '';
}