/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Paper} from '@mui/material';
import {Delete} from '@mui/icons-material';
import Button from '@mui/material/Button';
import {useSnackbar} from 'notistack';
import {
    Device,
    FlowMeter,
    FlowMeterType,
    IGTResponse,
    TankList
} from '../../../types/components/ApiTypes';
import Constants from '../../../helper/Constants';
import SubmitButton from '../../common/SubmitButton';
import isStringNotEmpty from '../../../helper/validator/isStringNotEmpty';
import isNumericInRange from '../../../helper/validator/isNumericInRange';
import FuelLockStore from '../../../stores/FuelLockStore';
import callPutAPI from '../../../controllers/callPutAPI';
import getDataById from '../../../helper/functions/getDataById';
import getFlowMeterTypeById from '../../../helper/functions/getFlowMeterTypeById';
import getAvailableFlowMeterPorts from '../../../helper/functions/getAvailableFlowMeterPorts';
import callPostAPI from '../../../controllers/callPostAPI';
import FlowMeterSettings from '../tanks/FlowMeterSettings';
import getTestID from '../../../helper/functions/getTestID';
import callDeleteAPI from '../../../controllers/callDeleteAPI';
import DeleteModal from '../../common/modal/DeleteModal';
import getFlowMeterType from '../../../helper/functions/getFlowMeterType';
import SuccessMessages from '../../../helper/SuccessMessages';

interface Props {
    editingDeviceId: number,
    tank: TankList
}

const EditFlowMeter = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ model, setModel ] = useState('');
    const [ limit, setLimit ] = useState('');
    const [ limitError, setLimitError ] = useState('');
    const [ name, setName ] = useState('');
    const [ nameError, setNameError ] = useState('');
    const [ pulsesPerLitre, setPulsesPerLitre ] = useState('');
    const [ pulsesPerLitreError, setPulsesPerLitreError ] = useState('');
    const [ slot, setSlot ] = useState('');
    const [ flowMeterId, setFlowMeterId ] = useState(0);
    const [ deleteID, setDeleteID ] = useState(-1);
    const [ availablePorts, setAvailablePorts ] = useState([ 1, 2, 3, 4 ]);
    const [ custom, setCustom ] = useState(false);
    const [ flowMeterType, setFlowMeterType ] = useState<FlowMeterType | null>(null);

    const getFlowMeter = (tankId: number, device: Device) => {
        const tankIndex = device.tankList.findIndex(tank => tank.id === tankId);
        if (tankIndex !== -1) {
            return device.tankList[tankIndex].flowMeter;
        }
        return null;
    };

    const getStartingData = () => {
        const device: Device = getDataById(FuelLockStore.systemData.deviceList, props.editingDeviceId);
        const flowMeter: FlowMeter | null = props.tank ? getFlowMeter(props.tank.id, device) : null;

        if (flowMeter) {
            //flow meter type properties
            const flType: FlowMeterType = getFlowMeterTypeById(flowMeter.flowMeterTypeId);
            setFlowMeterType(flType);
            setModel(flType.name);
            setName(flType.name);
            setCustom(flType.custom);
            setPulsesPerLitre(flType.pulsesPerLitre.toString());
            //flow meter properties
            setFlowMeterId(flowMeter.id);
            setSlot(flowMeter.flowMeterSlot.toString());
            setLimit(flowMeter.litreLimit.toString());
            setAvailablePorts(getAvailableFlowMeterPorts(device, props.tank.id));
        } else {
            setAvailablePorts(getAvailableFlowMeterPorts(device, -1));
        }
    };

    React.useEffect(() => {
        getStartingData();
    }, [ FuelLockStore.systemData.deviceList ]);

    const isValid = () => {
        let valid = true;

        if (custom) {
            const newNameError = isStringNotEmpty(name, 'name');
            setNameError(newNameError);
            const newPulsesPerLitreError = isNumericInRange(pulsesPerLitre, 0.1, 255.99,
                2, 'pulses per litre');
            setPulsesPerLitreError(newPulsesPerLitreError);

            if (newNameError !== '' || newPulsesPerLitreError !== '') {
                valid = false;
            }
        }

        const newLimitError = isNumericInRange(limit, 0, 65534, 0, 'limit');
        setLimitError(newLimitError);

        if (newLimitError !== '') {
            valid = false;
        }

        return valid;
    };

    const updateTankListWithFlowMeter = (flowMeter: FlowMeter) => {
        const oldData = FuelLockStore.systemData.deviceList;
        const deviceIndex = oldData.findIndex(item => item.id === props.editingDeviceId);
        const tankIndex = oldData[deviceIndex].tankList.findIndex(item => item.id === props.tank.id);
        const newData = JSON.parse(JSON.stringify(oldData));
        newData[deviceIndex].tankList[tankIndex].flowMeter = flowMeter;
        FuelLockStore.setDeviceData(newData);
    };

    const createUpdateFlowMeter = (body: FlowMeter) => {
        if (flowMeterId === 0) {
            callPostAPI(`fuellock/v2/flowMeter/${ props.tank.id }`, body,
                (receivedServerData: IGTResponse<number>) => {
                    body.id = receivedServerData.data;
                    updateTankListWithFlowMeter(body);
                },
                enqueueSnackbar, SuccessMessages.CREATE_FLOW_METER);
        } else {
            callPutAPI(`fuellock/v2/flowMeter/${ flowMeterId }`, body,
                () => {
                    updateTankListWithFlowMeter(body);
                },
                enqueueSnackbar, SuccessMessages.UPDATE_FLOW_METER);
        }
    };

    const createCustomFlowMeter = (customType: FlowMeterType, body: FlowMeter) => {
        callPostAPI('fuellock/v2/customFlowMeter', customType,
            (receivedServerData: IGTResponse<number>) => {
                const newFlowMeterList = JSON.parse(JSON.stringify(FuelLockStore.systemData.flowMeterTypeList));
                customType.id = receivedServerData.data;
                newFlowMeterList.push(customType);
                body.flowMeterTypeId = receivedServerData.data;
                createUpdateFlowMeter(body);
                FuelLockStore.setFlowMeterTypeList(newFlowMeterList);
            },
            enqueueSnackbar, SuccessMessages.CREATE_FLOW_METER);
    };

    const updateCustomFlowMeter = (customType: FlowMeterType, body: FlowMeter) => {
        callPutAPI(`fuellock/v2/customFlowMeter/${ customType.id }`, customType,
            () => {
                const newFlowMeterList = JSON.parse(JSON.stringify(FuelLockStore.systemData.flowMeterTypeList));
                const index = FuelLockStore.systemData.flowMeterTypeList
                    .findIndex((item: any) => item.id === customType.id);
                newFlowMeterList[index] = customType;
                createUpdateFlowMeter(body);
                FuelLockStore.setFlowMeterTypeList(newFlowMeterList);
            },
            enqueueSnackbar, '');
    };

    const update = () => {
        if (isValid()) {
            const flowMeterTypeId = flowMeterType !== null ? Number(flowMeterType.id) : 0;
            const customType: FlowMeterType = {
                id: flowMeterTypeId,
                name,
                pulsesPerLitre: Number(pulsesPerLitre),
                custom,
            };

            const body: FlowMeter = {
                id: flowMeterId,
                flowMeterTypeId,
                flowMeterSlot: Number(slot),
                name,
                litreLimit: Number(limit),
                custom,
            };

            if (model === Constants.CUSTOM_FLOW_METER) {
                //CREATE NEW FLOW METER TYPE
                createCustomFlowMeter(customType, body);
            } else if (flowMeterType !== null && flowMeterType.id >= 10000) {
                //EDIT FLOW METER TYPE
                updateCustomFlowMeter(customType, body);
            } else {
                //NON-CUSTOM FLOW METER TYPE
                createUpdateFlowMeter(body);
            }
        }
    };

    const closeDeleteConfirmation = () => {
        setDeleteID(-1);
    };

    const handleDelete = () => {
        setDeleteID(flowMeterId);
    };

    const onSubmitDelete = () => {
        callDeleteAPI(`fuellock/v2/flowMeter/${ flowMeterId }`,
            () => {
                const oldData = FuelLockStore.systemData.deviceList;
                const deviceIndex = oldData.findIndex(item => item.id === props.editingDeviceId);
                const tankIndex = oldData[deviceIndex].tankList.findIndex(item => item.id === props.tank.id);
                const newData = JSON.parse(JSON.stringify(oldData));
                newData[deviceIndex].tankList[tankIndex].flowMeter = null;
                FuelLockStore.setDeviceData(newData);
                closeDeleteConfirmation();

                setModel('');
                setLimit('');
                setLimitError('');
                setName('');
                setNameError('');
                setPulsesPerLitre('');
                setPulsesPerLitreError('');
                setSlot('');
                setFlowMeterId(0);
                setCustom(false);
            }, enqueueSnackbar, SuccessMessages.DELETE_FLOW_METER);
    };

    const onModelChange = (text: string) => {
        setModel(text);
        if (text !== Constants.CUSTOM_FLOW_METER) {
            const flType = getFlowMeterType(text);
            setFlowMeterType(flType);
            setPulsesPerLitre(flType.pulsesPerLitre.toString());
            setCustom(flType.custom);
            setName(text);
        } else {
            setFlowMeterType(null);
            setPulsesPerLitre('');
            setCustom(true);
            setName('');
        }
    };

    return (
        <div>
            <Paper>
                <FlowMeterSettings
                    model={{
                        value: model,
                        setValue: onModelChange,
                        error: '',
                        setError: () => {
                        },
                    }}
                    limit={{
                        value: limit,
                        setValue: setLimit,
                        error: limitError,
                        setError: setLimitError,
                    }}
                    name={{
                        value: name,
                        setValue: setName,
                        error: nameError,
                        setError: setNameError,
                    }}
                    pulsesPerLitre={{
                        value: pulsesPerLitre,
                        setValue: setPulsesPerLitre,
                        error: pulsesPerLitreError,
                        setError: setPulsesPerLitreError,
                    }}
                    custom={custom}
                    port={{
                        value: slot,
                        setValue: setSlot,
                        error: '',
                        setError: () => {
                        },
                    }}
                    availablePorts={availablePorts}
                />
                <SubmitButton
                    id="update_flow_meter_button"
                    text="Update Flow Meter"
                    onClick={update}
                    disabled={FuelLockStore.awaitingApi}
                />
            </Paper>
            {flowMeterId !== 0 && (
                <div
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        {...getTestID('delete_flow_meter_button')}
                        color="secondary"
                        startIcon={<Delete/>}
                        onClick={handleDelete}
                        disabled={FuelLockStore.awaitingApi}
                    >
                        Delete Flow Meter
                    </Button>
                </div>

            )}
            {deleteID !== -1 && (
                <DeleteModal
                    id="delete_flow_meter_modal"
                    open
                    close={closeDeleteConfirmation}
                    delete={onSubmitDelete}
                    description="this flow meter"
                    secondaryDescription={Constants.HISTORICAL_DATA_MESSAGE}
                />
            )}
        </div>

    );
});

export default EditFlowMeter;
