/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import isStringNotEmpty from '../validator/isStringNotEmpty';

const isPowerSupplyPageValid = (
    powerSupplyPage: {
        powerSupplyOne: string,
        enableOne: boolean
        powerSupplyOneError: string,
        powerSupplyTwo: string,
        enableTwo: boolean,
        powerSupplyTwoError: string,
    }, setPowerSupplyPage: (value: {
        powerSupplyOne: string,
        enableOne: boolean,
        powerSupplyOneError: string,
        powerSupplyTwo: string,
        enableTwo: boolean,
        powerSupplyTwoError: string,
    }) => void
) => {
    let valid = true;
    let newPowerSupplyOneError = '';
    let newPowerSupplyTwoError = '';

    newPowerSupplyOneError = isStringNotEmpty(powerSupplyPage.powerSupplyOne, 'name');
    if (newPowerSupplyOneError !== '') {
        valid = false;
    }

    if (powerSupplyPage.enableTwo) {
        newPowerSupplyTwoError = isStringNotEmpty(powerSupplyPage.powerSupplyTwo, 'name');
        if (newPowerSupplyTwoError !== '') {
            valid = false;
        }
    }

    setPowerSupplyPage({
        ...powerSupplyPage,
        powerSupplyOneError: newPowerSupplyOneError,
        powerSupplyTwoError: newPowerSupplyTwoError,
    });

    return valid;
};

export default isPowerSupplyPageValid;
