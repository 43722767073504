/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Paper, styled, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import {WarningAmber} from '@mui/icons-material';
import Constants from '../../helper/Constants';
import getTestID from '../../helper/functions/getTestID';
import SubmitButton from '../common/SubmitButton';
import WebStore from '../../stores/WebStore';
import logout from '../../helper/functions/logout';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

const OverDuePaper = observer(() => {
    const onPayNow = () => {
        //TODO
        WebStore.setSelectedDrawer(Constants.BILLING_AND_PAYMENT);
        WebStore.goToNewPage(Constants.PAY_NOW);
    };

    return (
        <Paper>
            <Root>
                <Row>
                    <WarningAmber
                        color="primary"
                        fontSize="large"
                    />
                    <Typography
                        sx={{
                            paddingLeft: '16px',
                            fontSize: '20px',
                            fontWeight: 800,
                        }}
                    >
                        Warning! Please read
                    </Typography>
                </Row>

                <Typography {...getTestID('overdue_message')}>
                    {Constants.OVERDUE_WARNING}
                </Typography>

                <ButtonRow>
                    <Button
                        {...getTestID('logout_dashboard')}
                        color="error"
                        sx={{marginRight: '16px'}}
                        onClick={logout}
                    >
                        Log Out
                    </Button>
                    <SubmitButton
                        id={'make_payment_button'}
                        text={Constants.PAY_NOW}
                        onClick={onPayNow}
                    />
                </ButtonRow>
            </Root>

        </Paper>
    );
});

export default OverDuePaper;
