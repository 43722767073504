/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import TitleTypography from '../../common/typography/TitleTypography';
import Constants from '../../../helper/Constants';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import TextFieldInput from '../../common/input/TextFieldInput';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import {observer} from 'mobx-react-lite';
import getSelectOptions from '../../../helper/functions/getSelectOptions';
import FuelLockStore from '../../../stores/FuelLockStore';

interface Props {
    model: InputFieldProps,
    port: InputFieldProps,
    limit: InputFieldProps,
    name: InputFieldProps,
    pulsesPerLitre: InputFieldProps,
    custom: boolean,
    availablePorts: number[],
}

const FlowMeterSettings = observer((props: Props) => {
    const getModalOptions = () => [ ...getSelectOptions(
        FuelLockStore.systemData.flowMeterTypeList,
        'name',
        'model'
    ), {
        title: Constants.CUSTOM_FLOW_METER,
        id: 'custom_flow_meter_modal_option',
    } ];

    const renderCustomItems = () => (
        <div>
            <TextFieldInput
                id="flow_meter_name_input"
                label={Constants.NAME}
                field={props.name}
                textFieldProps={{
                    inputProps: {
                        maxLength: 21,
                    },
                }}
            />
            <TextFieldInput
                id="flow_meter_pulses_per_litre_input"
                label="Pulses/Litre"
                field={props.pulsesPerLitre}
                textFieldProps={{
                    inputProps: {
                        maxLength: 6,
                    },
                }}
            />
        </div>
    );

    const availablePorts = () => {
        const ports: { title: string; id: string; }[] = [];

        props.availablePorts.forEach(port => {
            ports.push({
                title: `Port ${ port }`,
                id: `port_${ port }_button`,
            });
        });
        return ports;
    };

    const onPortChange = (newPort: string) => {
        props.port.setValue(newPort.charAt(newPort.length - 1));
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div style={{paddingBottom: '16px'}}>
                <TitleTypography title={Constants.FLOW_METER_SETTINGS}/>
            </div>

            <SelectFieldInput
                id="model_selection"
                label={Constants.MODEL}
                field={props.model}
                options={getModalOptions()}
            />

            {props.custom && (renderCustomItems())}

            <SelectFieldInput
                id="flow_meter_port_selection"
                label={Constants.AVAILABLE_PORT_NUMBER}
                field={{
                    ...props.port,
                    value: props.port.value !== '' ? `Port ${ props.port.value }` : '',
                    setValue: onPortChange,
                }}
                options={availablePorts()}
            />

            <TextFieldInput
                id="limit_per_unlock_input"
                label="Litre limit per Unlock (0 = no limit)"
                field={props.limit}
                textFieldProps={{
                    inputProps: {
                        maxLength: 6,
                    },
                }}
            />
        </div>
    );
});

export default FlowMeterSettings;
