/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default {
    UNEXPECTED: 'An unexpected error occurred, please try again.',
    ERROR_500 : 'Whoops! We couldn’t save your changes.  Please try again or contact your dealer for support',
    ERROR_403 : 'Whoops! We couldn’t save your changes.  Please try again or contact your dealer for support',
    ERROR_401 : 'Your current session has expired, please login again!',
};
