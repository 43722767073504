/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import ContentPanel from './ContentPanel';
import InformationPanel from '../settings/panels/InformationPanel';

function Information() {
    return (
        <ContentPanel>
            <InformationPanel/>
        </ContentPanel>
    );
}

export default Information;
