/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getArrayOfAllowedEmployeeOrEquipmentIDs = (data: {
    id: number; title: string; checked: boolean; isNew: boolean;
}[]) => {

    const allowedIDs: number[] = [];
    data.forEach(dataPoint => {
        if (dataPoint.checked && !dataPoint.isNew) {
            allowedIDs.push(dataPoint.id);
        }
    });
    return allowedIDs;
};

export default getArrayOfAllowedEmployeeOrEquipmentIDs;
