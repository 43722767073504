/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import Constants from '../helper/Constants';
import Colors from '../helper/Colors';

class WebStore {
    lightMode = true;

    selectedDrawer = Constants.DASHBOARD;

    selectedDrawerAfterConfirmation = '';

    isLoseChangesConfirmationOpen = false;

    currentPage = Constants.MAIN;

    pageQueue = [] as string[];

    activeStep = 0;

    overDue = false;

    dashboardDevice = Constants.ALL_DEVICES;

    employeeLitersTimeRange = Constants.MONTHLY;

    equipmentLitersTimeRange = Constants.MONTHLY;

    transactionsTimeRange = Constants.MONTHLY;

    employeeUsageTimeRange = Constants.MONTHLY;

    equipmentUsageTimeRange = Constants.MONTHLY;

    recentTransactionsTimeRange = Constants.MONTHLY;

    tankUsageTimeRange = Constants.MONTHLY;

    constructor() {
        makeObservable(this, {
            lightMode: observable,
            setLightMode: action,
            selectedDrawer: observable,
            setSelectedDrawer: action,
            selectedDrawerAfterConfirmation: observable,
            setSelectedDrawerAfterConfirmation: action,
            isLoseChangesConfirmationOpen: observable,
            setIsLoseChangesConfirmationOpen: action,
            currentPage: observable,
            setCurrentPage: action,
            pageQueue: observable,
            setPageQueue: action,
            activeStep: observable,
            setActiveStep: action,
            goToNewPage: action,
            goToPreviousPage: action,
            overDue: observable,
            setOverDue: action,
            dashboardDevice: observable,
            setDashboardDevice: action,
            employeeLitersTimeRange: observable,
            setEmployeeLitersTimeRange: action,
            equipmentLitersTimeRange: observable,
            setEquipmentLitersTimeRange: action,
            employeeUsageTimeRange: observable,
            setEmployeeUsageTimeRange: action,
            equipmentUsageTimeRange: observable,
            setEquipmentUsageTimeRange: action,
            transactionsTimeRange: observable,
            setTransactionsTimeRange: action,
            recentTransactionsTimeRange: observable,
            setRecentTransactionsTimeRange: action,
            tankUsageTimeRange: observable,
            setTankUsageTimeRange: action,
            setAllTilesTimeRange: action,
        });
        this.initializeFromAsyncStorage();
    };

    setLightMode = () => {
        this.lightMode = !this.lightMode;
        if (this.lightMode) {
            document.body.style.backgroundColor = Colors.background;
        } else {
            document.body.style.backgroundColor = Colors.backgroundDark;
        }
        this.syncWithAsyncStorage(Constants.STORE_KEY_LIGHT_MODE, this.lightMode.toString());
    };

    setInitialLightMode = (flag: boolean) => {
        this.lightMode = flag;
        if (this.lightMode) {
            document.body.style.backgroundColor = Colors.background;
        } else {
            document.body.style.backgroundColor = Colors.backgroundDark;
        }
    };

    setSelectedDrawer = (newDrawer: string) => {
        this.selectedDrawer = newDrawer;
    };

    setSelectedDrawerAfterConfirmation = (newDrawer: string) => {
        this.selectedDrawerAfterConfirmation = newDrawer;
    };

    setIsLoseChangesConfirmationOpen = (value: boolean) => {
        this.isLoseChangesConfirmationOpen = value;
    };

    setCurrentPage = (newPage: string | undefined) => {
        this.currentPage = newPage ? newPage : Constants.MAIN;
    };

    setPageQueue = (newQueue: string[]) => {
        this.pageQueue = newQueue;
    };

    setActiveStep = (newStep: number) => {
        this.activeStep = newStep;
    };

    goToPreviousPage = () => {
        if (this.pageQueue.length > 0) {
            this.setCurrentPage(this.pageQueue.pop());
        }
    };

    goToNewPage = (newPage: string) => {
        this.pageQueue.push(this.currentPage);
        this.setCurrentPage(newPage);
    };

    setOverDue = (newOverDue: boolean) => {
        this.overDue = newOverDue;
    };

    setDashboardDevice = (key: string) => {
        this.dashboardDevice = key;
    };

    setEmployeeLitersTimeRange = (key: string) => {
        this.employeeLitersTimeRange = key;
    };

    setEquipmentLitersTimeRange = (key: string) => {
        this.equipmentLitersTimeRange = key;
    };

    setEquipmentUsageTimeRange = (key: string) => {
        this.equipmentUsageTimeRange = key;
    };

    setEmployeeUsageTimeRange = (key: string) => {
        this.employeeUsageTimeRange = key;
    };

    setTransactionsTimeRange = (key: string) => {
        this.transactionsTimeRange = key;
    };

    setRecentTransactionsTimeRange = (key: string) => {
        this.recentTransactionsTimeRange = key;
    };

    setTankUsageTimeRange = (key: string) => {
        this.tankUsageTimeRange = key;
    };

    setAllTilesTimeRange = (key: string) => {
        this.setEmployeeLitersTimeRange(key);
        this.setEquipmentLitersTimeRange(key);
        this.setEquipmentUsageTimeRange(key);
        this.setTransactionsTimeRange(key);
        this.setEmployeeUsageTimeRange(key);
        this.setRecentTransactionsTimeRange(key);
        this.setTankUsageTimeRange(key);
    };

    syncWithAsyncStorage = (key: string, value: string) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    };

    initializeFromAsyncStorage = () => {
        const result = global.localStorage.getItem(Constants.STORE_KEY_LIGHT_MODE);
        if (result !== null) {
            this.setInitialLightMode(JSON.parse(result) === 'true');
        }
    };
}

export default new WebStore();
