/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import { TankList } from '../../../types/components/ApiTypes';
import { useSnackbar } from 'notistack';
import FuelLockStore from '../../../stores/FuelLockStore';
import callPutAPI from '../../../controllers/callPutAPI';
import ErrorMessages from '../../../helper/ErrorMessages';
import SuccessMessages from '../../../helper/SuccessMessages';
import SubmitButton from '../../common/SubmitButton';
import StyledSwitch from '../../common/input/StyledSwitch';
import TankLevelPercentSlider from '../tanks/TankLevelPercentSlider';
import Paper from '@mui/material/Paper';
import MemoTypography from '../../common/typography/MemoTypography';
import { useEffect } from 'react';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    editingDeviceId: number,
    tank: TankList
}

const EditVirtualTankMonitoring = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ updatedTank, setUpdatedTank ] = useState<TankList>();
    const [ fullness, setFullness ] = useState(props.tank.fullness);
    const [ virtualTankMonitoring, setVirtualTankMonitoring ] = useState(props.tank.fullness != null ? true : false);
    const [ dataChanged, setDataChanged ] = useState(false);
    const deviceIndex = FuelLockStore.systemData.deviceList.findIndex(a =>
        a.id === props.editingDeviceId);
    const tankIndex = FuelLockStore.systemData.deviceList[deviceIndex].tankList.findIndex(a =>
        a.id === props.tank.id);

    const updateTankMonitoringButtonOnClick = () => {

        const tank = JSON.parse(JSON.stringify((
            FuelLockStore.systemData
                .deviceList[deviceIndex]
                .tankList[tankIndex]
            ))
        );
        tank.fullness = virtualTankMonitoring ? fullness : null;

        setUpdatedTank(tank);
    };

    useEffect(() => {
        const tank = FuelLockStore.systemData.deviceList[deviceIndex].tankList[tankIndex];

        if (tank.fullness === null) {
            if (virtualTankMonitoring && fullness !== null) {
                setDataChanged(true);
            }
        }
        else {
            if (!virtualTankMonitoring || tank.fullness !== fullness) {
                setDataChanged(true);
            }
        }

    }, [ virtualTankMonitoring, fullness ]);

    useEffect(() => {
        if (updatedTank) {
            setTimeout(() => {
                callPutAPI(`fuellock/v2/tank/${ updatedTank.id }`, updatedTank, (receivedServerData) => {
                    if (receivedServerData.status && receivedServerData.status !== 'success') {
                        FuelLockStore.setError(ErrorMessages.UNEXPECTED);
                    } else {
                        const oldData = FuelLockStore.systemData.deviceList;
                        const newData = JSON.parse(JSON.stringify(oldData));
                        newData[deviceIndex].tankList[tankIndex] = updatedTank;
                        FuelLockStore.setDeviceData(newData);
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_TANK);

            }, 100);
        }
    }, [ updatedTank ]);

    return (
        <Paper>
            <StyledSwitch
                id="virtual_tank_monitoring_switch"
                description="Virtual Tank Monitoring"
                value={virtualTankMonitoring}
                setValue={setVirtualTankMonitoring}
            />
            {virtualTankMonitoring && (
                <div>
                    <MemoTypography
                        memo="Set your current tank level percent"
                    />
                    <TankLevelPercentSlider
                        {...getTestID('tag_tank_level_slider')}
                        name={props.tank.name}
                        value={fullness != null ? fullness : 0}
                        setValue={setFullness}
                    />
                </div>
            )}
            <SubmitButton
                id="save_tank_monitoring_button"
                text="Update"
                onClick={updateTankMonitoringButtonOnClick}
                disabled={FuelLockStore.awaitingApi || !dataChanged}
            />
        </Paper>
    );
});

export default EditVirtualTankMonitoring;
