/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import {ProcessedUsagePojo} from '../../../types/components/ApiTypes';
import determineUsageDataInRange from '../../../helper/functions/determineUsageDataInRange';
import getMonth from '../../../helper/functions/getMonth';
import BarChart from '../charts/BarChart';

interface Props {
    paperRef: object,
    paperStyle: object,
    data: ProcessedUsagePojo[],
}

const TransactionsTile = observer((props: Props) => {
    const [ dataInRange, setDataInRange ] = useState<ProcessedUsagePojo[]>([]);

    useEffect(() => {
        setDataInRange(determineUsageDataInRange(WebStore.transactionsTimeRange, props.data));
    }, [ props.data, WebStore.transactionsTimeRange ]);

    const getData = () => {
        const tempData = {};

        dataInRange.forEach(dataPoint => {
            const date = `${ getMonth(dataPoint.transactionStartDate.getMonth()) } ${
                dataPoint.transactionStartDate.getDate() }`;
            if (tempData[date] === undefined) {
                tempData[date] = {
                    time: dataPoint.transactionStartDate.getTime(),
                    value: 1,
                };
            } else {
                tempData[date] = {
                    ...tempData[date],
                    value: tempData[date].value + 1,
                };
            }
        });

        const data: number[][] = [];
        const keys = Object.keys(tempData);
        keys.forEach(key => {
            data.push([ tempData[key].time, tempData[key].value ]);
        });
        return data;
    };

    return (
        <BarChart
            title="Transactions"
            id="transactions_menu_button"
            unit=""
            selectedTimeFrame={WebStore.transactionsTimeRange}
            setSelectedTimeFrame={WebStore.setTransactionsTimeRange}
            series={[
                {
                    name: 'Fills',
                    type: 'line',
                    data: getData(),
                },
            ]}
            paperRef={props.paperRef}
            paperStyle={props.paperStyle}
        />
    );
});

export default TransactionsTile;
