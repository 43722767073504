/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import ContentPanel from './ContentPanel';
import UsageReport from '../reports/UsageReport';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

function Reports() {
    return (
        <ContentPanel>
            <Root>
                <UsageReport/>
            </Root>
        </ContentPanel>
    );
}

export default Reports;
