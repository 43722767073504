/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import getDataById from './getDataById';
import FuelLockStore from '../../stores/FuelLockStore';
import Constants from '../Constants';

const getLiquidVolumeInfo = (amountInLitres: number) => {
    const preferredUnit = getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId);

    switch (preferredUnit.name) {
        case Constants.UK_GALLONS:
            return {
                value: Number((amountInLitres * 0.219969).toFixed(2)),
                unit: 'G',
            };
        case Constants.US_GALLONS:
            return {
                value: Number((amountInLitres * 0.264172).toFixed(2)),
                unit: 'G',
            };
        default:
            return {
                value: Number(amountInLitres),
                unit: 'L',
            };
    }
};

export default getLiquidVolumeInfo;
