/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import fuelLockStore from '../../stores/FuelLockStore';
import { FlowMeterType } from '../../types/components/ApiTypes';

const getFlowMeterName = (id: number): string => {
    const index = fuelLockStore.systemData.flowMeterTypeList.findIndex((item: FlowMeterType) => item.id === id);
    if (index > -1) {
        return fuelLockStore.systemData.flowMeterTypeList[index].name;
    }
    return '';
};

export default getFlowMeterName;
