/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
// import {useSnackbar} from 'notistack';
import TitleTypography from '../../common/typography/TitleTypography';
import Constants from '../../../helper/Constants';
import FlowMeterSettings from '../tanks/FlowMeterSettings';
// import LevelSensorSettings from '../tanks/LevelSensorSettings';
import StyledSwitch from '../../common/input/StyledSwitch';
import {Tanks} from '../../../types/components/EquipmentTypes';
// import callAPI from '../../../controllers/callAPI';
// import FuelLockStore from '../../../stores/FuelLockStore';
import TextFieldInput from '../../common/input/TextFieldInput';
import getTestID from '../../../helper/functions/getTestID';
import getUnitNameAndSymbol from '../../../helper/functions/getUnitNameAndSymbol';
import getDataById from '../../../helper/functions/getDataById';
import FuelLockStore from '../../../stores/FuelLockStore';
import TankLevelPercentSlider from '../tanks/TankLevelPercentSlider';
import MemoTypography from '../../common/typography/MemoTypography';

interface Props {
    tank: Tanks;
    index: number;
    onEditTank: (value: any, key: string, index: number) => void;
    onRemoveTank?: (index: number) => void;
    onSubmit?: () => void;
    onCancel?: () => void;
    submitDisabled?: boolean;
    availableFlowMeterPorts: number[];
    disableChecker?: () => boolean;
    hideUseFlowMeterModal?: () => void;
}

const TankConfigurationPaper = observer((props: Props) => {
    // const {enqueueSnackbar} = useSnackbar();
    // const [ otodataDeviceOptions, setOtodataDeviceOptions ] = useState<Options[]>([]);
    // const [ tattlePortButtons, setTattleButtons ] = useState<Options[]>([]);

    // const getStartingData = () => {
    //     if (FuelLockStore.systemData.otodataKey !== null) {
    //         callAPI('fuellock/v2/otodata', (receivedData) => {
    //             const newOptions: Options[] = [];
    //             receivedData.data.forEach((otodataDeviceItem: any) => {
    //                 if (otodataDeviceItem.tankId === null || otodataDeviceItem.tankId === undefined) {
    //                     newOptions.push({
    //                         title: otodataDeviceItem.serialNumber,
    //                         id: `${ otodataDeviceItem.serialNumber }_otodata_device_option`,
    //                     });
    //                 }
    //             });
    //             setOtodataDeviceOptions(newOptions);
    //         }, enqueueSnackbar);
    //     }
    //
    //     if (props.tank.tattleID !== '') {
    //         callAPI(`fuellock/v2/tattle/${ props.tank.tattleID }`, (receivedServerData) => {
    //             const newButtons: Options[] = [];
    //             receivedServerData.data.ports.forEach((port: {
    //                 tankId: null | undefined;
    //                 portNumber: any; portId: any;
    //             }) => {
    //                 if (port.tankId === null || port.tankId === undefined) {
    //                     newButtons.push({
    //                         title: port.portNumber,
    //                         id: `${ port.portId }_sensor_button`,
    //                     });
    //                 }
    //             });
    //             setTattleButtons(newButtons);
    //         }, enqueueSnackbar);
    //     }
    // };

    // React.useEffect(() => {
    //     getStartingData();
    // }, [ FuelLockStore.systemData.otodataKey ]);
    const [ unit ] = useState(getUnitNameAndSymbol(getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId).name));

    const onChange = (value: string | boolean | number, key: string) => {
        props.onEditTank(value, key, props.index);
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '75%',
                overflow: 'auto',
            }}
        >
            <TitleTypography title={Constants.TANK_CONFIGURATION}/>
            <TextFieldInput
                id="tank_name_input"
                label={Constants.TANK_NAME}
                field={{
                    value: props.tank.name,
                    setValue: (value: string) => onChange(value, 'name'),
                    error: props.tank.nameError,
                    setError: (value: string) => onChange(value, 'nameError'),
                }}
                textFieldProps={{
                    inputProps: {
                        maxLength: 16,
                    },
                }}
            />
            <TextFieldInput
                id="tank_capacity_input"
                label={`${ Constants.CAPACITY } (${ unit.name })`}
                field={{
                    value: props.tank.capacity,
                    setValue: (value: string) => onChange(value, 'capacity'),
                    error: props.tank.capacityError,
                    setError: (value: string) => onChange(value, 'capacityError'),
                }}
                textFieldProps={{
                    inputProps: {
                        maxLength: 10,
                    },
                }}
            />
            <div style={{marginBottom: '20px'}}>
                <MemoTypography
                    memo={'Enter tank capacity correctly to ensure more accurate tank level monitoring'}
                />
            </div>
            <StyledSwitch
                id="use_flow_meter_switch"
                description="Use Flow Meter"
                value={props.tank.useFlowMeter}
                setValue={(value) => onChange(value, 'useFlowMeter')}
            />
            {/*<StyledSwitch*/}
            {/*    id="use_level_sensor_switch"*/}
            {/*    description="Use Level Sensor"*/}
            {/*    value={props.tank.useLevelSensor}*/}
            {/*    setValue={value => onChange(value, 'useLevelSensor')}*/}
            {/*/>*/}
            {props.tank.useFlowMeter && (
                <FlowMeterSettings
                    model={{
                        value: props.tank.model,
                        setValue: (value: string) => onChange(value, 'model'),
                        error: '',
                        setError: () => {
                        },
                    }}
                    port={{
                        value: props.tank.flowMeterSlot,
                        setValue: (value: string) => onChange(value, 'flowMeterSlot'),
                        error: '',
                        setError: () => {
                        },
                    }}
                    limit={{
                        value: props.tank.limit,
                        setValue: (value: string) => onChange(value, 'limit'),
                        error: props.tank.limitError,
                        setError: (value: string) => onChange(value, 'limitError'),
                    }}
                    name={{
                        value: props.tank.flowMeterName,
                        setValue: (value: string) => onChange(value, 'flowMeterName'),
                        error: props.tank.flowMeterNameError,
                        setError: (value: string) => onChange(value, 'flowMeterNameError'),
                    }}
                    custom={props.tank.custom}
                    pulsesPerLitre={{
                        value: props.tank.pulsesPerLitre,
                        setValue: (value: string) => onChange(value, 'pulsesPerLitre'),
                        error: props.tank.pulsesPerLitreError,
                        setError: (value: string) => onChange(value, 'pulsesPerLitreError'),
                    }}
                    availablePorts={props.availableFlowMeterPorts}
                />
            )}
            {/*{props.tank.useLevelSensor && (*/}
            {/*    <LevelSensorSettings*/}
            {/*        levelSensorConfiguration={{*/}
            {/*            value: props.tank.levelSensorConfiguration,*/}
            {/*            setValue: value => onChange(value, 'levelSensorConfiguration'),*/}
            {/*            error: '',*/}
            {/*            setError: () => {},*/}
            {/*        }}*/}
            {/*        levelSensorProduct={props.tank.levelSensorProduct}*/}
            {/*        setLevelSensorProduct={value => onChange(value, 'levelSensorProduct')}*/}
            {/*        tankType={{*/}
            {/*            value: props.tank.type,*/}
            {/*            setValue: value => onChange(value, 'type'),*/}
            {/*            error: '',*/}
            {/*            setError: () => {},*/}
            {/*        }}*/}
            {/*        density={{*/}
            {/*            value: props.tank.density,*/}
            {/*            setValue: value => onChange(value, 'density'),*/}
            {/*            error: '',*/}
            {/*            setError: () => {},*/}
            {/*        }}*/}
            {/*        tattleID={{*/}
            {/*            value: props.tank.tattleID,*/}
            {/*            setValue: value => onChange(value, 'tattleID'),*/}
            {/*            error: props.tank.tattleIDError,*/}
            {/*            setError: value => onChange(value, 'tattleIDError'),*/}
            {/*        }}*/}
            {/*        selectedButton={{*/}
            {/*            value: props.tank.tattlePort,*/}
            {/*            setValue: value => onChange(value, 'tattlePort'),*/}
            {/*            error: '',*/}
            {/*            setError: () => {},*/}
            {/*        }}*/}
            {/*        height={{*/}
            {/*            value: props.tank.height,*/}
            {/*            setValue: value => onChange(value, 'height'),*/}
            {/*            error: props.tank.heightError,*/}
            {/*            setError: value => onChange(value, 'heightError'),*/}
            {/*        }}*/}
            {/*        otodataApiKey={FuelLockStore.systemData.otodataKey}*/}
            {/*        setOtodataApiKey={value => onChange(value, 'otodataApiKey')}*/}
            {/*        otodataDevice={props.tank.otodataDevice}*/}
            {/*        setOtodataDevice={value => onChange(value, 'otodataDevice')}*/}
            {/*        otodataDeviceOptions={otodataDeviceOptions}*/}
            {/*        setOtodataDeviceOptions={setOtodataDeviceOptions}*/}
            {/*        tattlePortButtons={tattlePortButtons}*/}
            {/*        setTattlePortButtons={setTattleButtons}*/}
            {/*        editingSensor={false}*/}
            {/*        editingType=''*/}
            {/*        otodataApiKeyError={props.tank.otodataApiKeyError}*/}
            {/*        setOtodataApiKeyError={value => onChange(value, 'otodataApiKeyError')}*/}
            {/*    />*/}
            {/*)}*/}
            <div>
                <StyledSwitch
                    id="virtual_tank_monitoring_switch"
                    description="Virtual Tank Monitoring"
                    value={props.tank.virtualTankMonitoring}
                    setValue={(value: boolean) => onChange(value, 'virtualTankMonitoring')}
                />
                {props.tank.virtualTankMonitoring && (
                    <TankLevelPercentSlider
                        {...getTestID('tag_tank_level_slider')}
                        name={props.tank.name}
                        value={props.tank.fullness}
                        setValue={(value: number) => onChange(value, 'fullness')}
                    />
                )}
            </div>
            {props.onRemoveTank && (
                <Button
                    {...getTestID(`remove_tank_${ props.index }_button`)}
                    onClick={() => props.onRemoveTank && props.onRemoveTank(props.index)}
                >
                    Remove Tank
                </Button>
            )}
        </Paper>
    );
});

export default TankConfigurationPaper;
