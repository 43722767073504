/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import LoginStore from '../../stores/LoginStore';
import WebStore from '../../stores/WebStore';
import Constants from '../Constants';
import FuelLockStore from '../../stores/FuelLockStore';

const logout = () => {
    LoginStore.setLoggedIn(false);
    WebStore.setSelectedDrawer(Constants.DASHBOARD);
    LoginStore.setEmail('', true);
    LoginStore.setToken('', true);
    LoginStore.setUdd(-1, true);
    FuelLockStore.presetFuelLockStore();
    WebStore.setAllTilesTimeRange(Constants.MONTHLY);
    WebStore.setDashboardDevice(Constants.ALL_DEVICES);
    LoginStore.setLoadingData(true);
};

export default logout;
