/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import handleError from './handleError';
import loginStore from '../stores/LoginStore';
import fuelLockStore from '../stores/FuelLockStore';
import urlStore from '../stores/urlStore';
import getBaseURL from './getBaseURL';
import getUserAcceptHeader from './getUserAcceptHeader';
import { IGTResponse } from '../types/components/ApiTypes';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import showStatusFailMessage from './showStatusFailMessage';

const callAPI = <T = any>(uri: string,
                          onSuccess: (response: IGTResponse<T>) => void,
                          enqueueSnackbar: EnqueueSnackbar,
                          userAccept = false,
                          on401Fail?: () => void,
                          onFail?: () => void) => {
    setTimeout(() => {
        fuelLockStore.setAwaitingApi(true);
    }, 50);

    fetch(
        `${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: loginStore.token,
                udd: loginStore.udd.toString(),
                deviceID: loginStore.deviceID,
                'fl-platform': 'webapp',
                ...getUserAcceptHeader(userAccept),
            },
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar, on401Fail);
            return response.json();
        })
        .then((receivedServerData) => {
            setTimeout(() => {
                fuelLockStore.setAwaitingApi(false);
            }, 100);

            if (receivedServerData.status && receivedServerData.status === 'fail') {
                showStatusFailMessage(receivedServerData, enqueueSnackbar);
                if (onFail) {
                    onFail();
                }
            }  else if (receivedServerData.status && receivedServerData.status === 'confirm') {
                fuelLockStore.setConfirm({
                    title: receivedServerData.messageTitle,
                    message: receivedServerData.message,
                    onConfirm: () => {
                        callAPI(uri, onSuccess, enqueueSnackbar, true);
                    },
                });
            } else {
                if (receivedServerData.displayMessage && receivedServerData.message &&
                    receivedServerData.messageTitle) {
                    enqueueSnackbar(receivedServerData.message, {variant: 'success'});
                }
                onSuccess(receivedServerData);
            }
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.message);
            fuelLockStore.setAwaitingApi(false);
        });
};

export default callAPI;
