/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import TankConfigurationPaper from './TankConfigurationPaper';
import Fab from '@mui/material/Fab';
import {Add} from '@mui/icons-material';
import {styled} from '@mui/material';
import {Tanks} from '../../../types/components/EquipmentTypes';
import Constants from '../../../helper/Constants';
import getTestID from '../../../helper/functions/getTestID';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
});

interface Props {
    tanks: Tanks[],
    updateTank: (value: any, key: string, index: number) => void,
    addTank: () => void,
    deleteTank: (index: number) => void,
    showUseFlowMeterModal: boolean,
    setShowUseFlowMeterModal: (value: boolean) => void,
    modalTank: number,
    setModalTank: (value: number) => void,
    setReasonFlowMeterModal: (value: string) => void,
    setVirtualTankMonitoring: (value: boolean) => void,
    setFullness: (value: number) => void,
}

export default function TankConfiguration(props: Props) {
    const updateTank = (value: any, key: string, index: number) => {
        props.updateTank(value, key, index);
    };

    const onAddAnotherTank = () => {
        if (props.tanks.length > 0) {
            const lastTank = props.tanks[props.tanks.length - 1];
            if (!lastTank.useFlowMeter && !lastTank.ignoreFlowMeter) {
                props.setModalTank(props.tanks.length - 1);
                props.setReasonFlowMeterModal(Constants.ADD);
                props.setShowUseFlowMeterModal(true);
            } else {
                props.addTank();
            }
        } else {
            props.addTank();
        }
    };

    const availableFlowMeterPorts = (index: number) => {
        const ports = [
            1, 2, 3, 4,
        ];

        props.tanks.forEach((tank, tankIndex) => {
            if (index !== tankIndex && tank.useFlowMeter) {
                const portIndex = ports.findIndex((p) => p === Number(tank.flowMeterSlot));
                ports.splice(portIndex, 1);
            }
        });
        return ports;
    };

    return (
        <Root>
            {props.tanks.map((tank, index) => (
                <TankConfigurationPaper
                    key={index}
                    tank={tank}
                    index={index}
                    onEditTank={updateTank}
                    availableFlowMeterPorts={availableFlowMeterPorts(index)}
                    hideUseFlowMeterModal={() => props.setShowUseFlowMeterModal(false)}
                    onRemoveTank={() => props.deleteTank(index)}
                />
            ))}
            <Fab
                {...getTestID('add_new_tank_button')}
                color="primary"
                variant="extended"
                onClick={onAddAnotherTank}
                disabled={props.tanks.length >= 4}
                sx={{position: 'fixed'}}
            >
                <Add/>
                Tank
            </Fab>
        </Root>
    );
}
