/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getTimeOptions = (id: string) => [
    {
        title: '12:00 am',
        id: `12_am_${ id }_option`,
    },
    {
        title: '1:00 am',
        id: `1_am_${ id }_option`,
    },
    {
        title: '2:00 am',
        id: `2_am_${ id }_option`,
    },
    {
        title: '3:00 am',
        id: `3_am_${ id }_option`,
    },
    {
        title: '4:00 am',
        id: `4_am_${ id }_option`,
    },
    {
        title: '5:00 am',
        id: `5_am_${ id }_option`,
    },
    {
        title: '6:00 am',
        id: `6_am_${ id }_option`,
    },
    {
        title: '7:00 am',
        id: `7_am_${ id }_option`,
    },
    {
        title: '8:00 am',
        id: `8_am_${ id }_option`,
    },
    {
        title: '9:00 am',
        id: `9_am_${ id }_option`,
    },
    {
        title: '10:00 am',
        id: `10_am_${ id }_option`,
    },
    {
        title: '11:00 am',
        id: `11_am_${ id }_option`,
    },
    {
        title: '12:00 pm',
        id: `12_pm_${ id }_option`,
    },
    {
        title: '1:00 pm',
        id: `1_pm_${ id }_option`,
    },
    {
        title: '2:00 pm',
        id: `2_pm_${ id }_option`,
    },
    {
        title: '3:00 pm',
        id: `3_pm_${ id }_option`,
    }, {
        title: '4:00 pm',
        id: `4_pm_${ id }_option`,
    },
    {
        title: '5:00 pm',
        id: `5_pm_${ id }_option`,
    },
    {
        title: '6:00 pm',
        id: `6_pm_${ id }_option`,
    },
    {
        title: '7:00 pm',
        id: `7_pm_${ id }_option`,
    },
    {
        title: '8:00 pm',
        id: `8_pm_${ id }_option`,
    },
    {
        title: '9:00 pm',
        id: `9_pm_${ id }_option`,
    },
    {
        title: '10:00 pm',
        id: `10_pm_${ id }_option`,
    },
    {
        title: '11:00 pm',
        id: `11_pm_${ id }_option`,
    },
];

export default getTimeOptions;
