/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

function LoadingModal() {
    return (
        <Modal
            open
        >
            <CircularProgress
                sx={{
                    position: 'absolute',
                    outline: 'none',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                variant="indeterminate"
            />
        </Modal>
    );
}

export default LoadingModal;
