/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */
import { InActiveDay } from '../../types/components/ApiTypes';
import addLeading from './addLeading';

const determineInactiveDays = (inactiveDays: InActiveDay[]) => {
    const days: InActiveDay[] = [];

    inactiveDays.forEach((inactiveDay: InActiveDay) => {
        if (inactiveDay.blockDay !== '') {
            const date = new Date(inactiveDay.blockDay);
            days.push({
                id: inactiveDay.id,
                blockDay: `${ date.getUTCFullYear() }-${ addLeading(date.getUTCMonth() + 1,
                    2) }-${ addLeading(date.getUTCDate(), 2) }`,
                deleting: inactiveDay.deleting,
            });
        }
    });

    return days;
};

export default determineInactiveDays;
