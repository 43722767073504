/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import ProgressBarChart from '../charts/progressBar/ProgressBarChart';
import {observer} from 'mobx-react-lite';
import {ProcessedUsagePojo} from '../../../types/components/ApiTypes';
import determineUsageDataInRange from '../../../helper/functions/determineUsageDataInRange';
import WebStore from '../../../stores/WebStore';
import getDataEmployeeAndEquipmentFuelUsage from '../../../helper/functions/getDataEmployeeAndEquipmentFuelUsage';

interface Props {
    paperRef: any,
    paperStyle: object,
    data: ProcessedUsagePojo[],
    updateSize: () => void,
}

const EmployeeFuelUsageTile = observer((props: Props) => {
    const [ dataInRange, setDataInRange ] = useState<ProcessedUsagePojo[]>([]);

    useEffect(() => {
        setDataInRange(determineUsageDataInRange(WebStore.employeeUsageTimeRange, props.data));
        setTimeout(props.updateSize, 200);
    }, [ props.data, WebStore.employeeUsageTimeRange ]);

    return (
        <ProgressBarChart
            paperRef={props.paperRef}
            paperStyle={props.paperStyle}
            title="Employee Fuel Usage"
            id="employee_fuel_usage_menu_button"
            data={getDataEmployeeAndEquipmentFuelUsage(dataInRange, 'worker')}
            selectedTimeFrame={WebStore.employeeUsageTimeRange}
            setSelectedTimeFrame={WebStore.setEmployeeUsageTimeRange}
            rowId="emp_fu"
        />
    );
});

export default EmployeeFuelUsageTile;
