/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import {ProcessedUsagePojo} from '../../../types/components/ApiTypes';
import determineUsageDataInRange from '../../../helper/functions/determineUsageDataInRange';
import getLiquidVolumeInfo from '../../../helper/functions/getLiquidVolumeInfo';
import getMonth from '../../../helper/functions/getMonth';
import getDataById from '../../../helper/functions/getDataById';
import FuelLockStore from '../../../stores/FuelLockStore';
import BarChart from '../charts/BarChart';
import getUnitNameAndSymbol from '../../../helper/functions/getUnitNameAndSymbol';

interface Props {
    paperRef: any,
    paperStyle: object,
    data: ProcessedUsagePojo[],
}

const EmployeeLitersTile = observer((props: Props) => {
    const [ dataInRange, setDataInRange ] = useState<ProcessedUsagePojo[]>([]);
    const [ unit ] = useState(getUnitNameAndSymbol(getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId).name));

    useEffect(() => {
        setDataInRange(determineUsageDataInRange(WebStore.employeeLitersTimeRange, props.data));
    }, [ props.data, WebStore.employeeLitersTimeRange ]);

    const getData = () => {
        const tempData = {};

        dataInRange.forEach(dataPoint => {
            const date = `${ getMonth(dataPoint.transactionStartDate.getMonth()) } ${
                dataPoint.transactionStartDate.getDate() }`;
            if (tempData[date] === undefined) {
                tempData[date] = {
                    time: dataPoint.transactionStartDate.getTime(),
                    value: dataPoint.litres,
                };
            } else {
                tempData[date] = {
                    ...tempData[date],
                    value: tempData[date].value + dataPoint.litres,
                };
            }
        });

        const data: number[][] = [];
        const keys = Object.keys(tempData);
        keys.forEach(key => {
            data.push([ tempData[key].time, Number(getLiquidVolumeInfo(tempData[key].value).value.toFixed(1)) ]);
        });
        return data;
    };

    return (
        <BarChart
            title={unit.name}
            unit={unit.symbol}
            id="employee_liters_menu_button"
            selectedTimeFrame={WebStore.employeeLitersTimeRange}
            setSelectedTimeFrame={WebStore.setEmployeeLitersTimeRange}
            series={[
                {
                    name: unit.name,
                    type: 'line',
                    data: getData(),
                },
            ]}
            paperRef={props.paperRef}
            paperStyle={props.paperStyle}
        />
    );
});

export default EmployeeLitersTile;
