/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {Close} from '@mui/icons-material';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string,
    onDeleteClick: () => void,
    value: string,
    errorMsg: string,
    onBlur: (value: string) => void,
}

function DeletableTextField( props: Props) {
    const [ value, setValue ] = useState(props.value);

    return (
        <TextField
            {...getTestID(props.id)}
            value={value}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            {...getTestID(`${ props.id  }_delete_button`)}
                            onClick={props.onDeleteClick}
                        >
                            <Close/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onChange={event => setValue(event.target.value)}
            onBlur={() => props.onBlur(value)}
            helperText={props.errorMsg}
            error={props.errorMsg !== undefined && props.errorMsg !== ''}
        />
    );
}

export default DeletableTextField;
