/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {Button, Skeleton, styled} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useSnackbar} from 'notistack';
import PaperTitle from '../common/typography/PaperTitle';
import Constants from '../../helper/Constants';
import DisabledTextField from '../common/input/DisabledTextField';
import callAPI from '../../controllers/callAPI';
import {BalancePojo} from '../../types/components/ApiTypes';
import formatDate from '../../helper/functions/formatDate';
import WebStore from '../../stores/WebStore';
import getTestID from '../../helper/functions/getTestID';
import LoadingGrid from '../common/LoadingGrid';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const Center = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
});

function CurrentPlanPanel() {
    const {enqueueSnackbar} = useSnackbar();
    const [ loadingBalance, setLoadingBalance ] = useState(true);
    const [ loadingPlan, setLoadingPlan ] = useState(true);

    const [ balance, setBalance ] = useState('');
    const [ currency, setCurrency ] = useState('');
    const [ activationDate, setActivationDate ] = useState('');
    const [ expiryDate, setExpiryDate ] = useState('');

    const [ totalDevices, setTotalDevices ] = useState('');
    const [ costPerYear, setCostPerYear ] = useState(0);

    useEffect(() => {
        callAPI<BalancePojo>('fuellock/v2/balance', (receivedData) => {
            setBalance(`${ receivedData.data.balance }`);
            setCurrency(receivedData.data.currency);
            if (receivedData.data.activationDate != null) {
                setActivationDate(`Account activation date: ${ formatDate(
                    new Date(receivedData.data.activationDate), true, false) }`);
            }
            if (receivedData.data.expiryDate != null) {
                setExpiryDate(`Account expiry date: ${ formatDate(
                    new Date(receivedData.data.expiryDate), true, false) }`);
            }
            setLoadingBalance(false);
        }, enqueueSnackbar);

        callAPI('fuellock/v2/dataPlan', (receivedData) => {
            setTotalDevices(receivedData.data.totalDevices);
            setCostPerYear(receivedData.data.costPerYear);

            setLoadingPlan(false);
        }, enqueueSnackbar);
    }, []);

    const payNowClicked = () => {
        WebStore.goToNewPage(Constants.PAY_NOW);
    };

    if (loadingBalance || loadingPlan) {
        return (
            <Paper>
                <Root>
                    <PaperTitle
                        title={Constants.CURRENT_PLAN}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            width: '100%',
                            maxWidth: 'none',
                            height: '50px',
                        }}
                        animation="wave"
                    />

                    <LoadingGrid
                        numColumns={2}
                        numRows={3}
                    />
                </Root>
            </Paper>
        );
    }

    return (
        <Paper>
            <Root>
                <PaperTitle
                    title={Constants.CURRENT_PLAN}
                />

                <DisabledTextField
                    id="billing_and_payment_Current_balance_prorated"
                    label="Current Balance (prorated)"
                    value={`$${ balance }${ currency != null ? '('.concat(currency).concat(')') : '' }`}
                />
                <Center>
                    <Button
                        {...getTestID('pay_now_button')}
                        color="primary"
                        variant="contained"
                        onClick={payNowClicked}
                    >
                        {Constants.PAY_NOW}
                    </Button>
                </Center>

                <Grid
                    container
                    spacing={2}
                    sx={{marginTop: '8px'}}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <DisabledTextField
                            id="billing_and_payment_account_activation_date"
                            label="Account activation date"
                            value={activationDate}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <DisabledTextField
                            id="billing_and_payment_account_expiry_date"
                            label="Account expiry date"
                            value={expiryDate}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <DisabledTextField
                            id="billing_and_payment_total_fuel_lock_devices"
                            label="Total Fuel Lock™ Devices"
                            value={`${ totalDevices } devices`}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <DisabledTextField
                            id="billing_and_payment_current_fuel_lock_plan"
                            label="Current Fuel Lock™ Plan"
                            value={`$${ costPerYear }/year`}
                        />
                    </Grid>
                </Grid>
            </Root>
        </Paper>
    );
}

export default CurrentPlanPanel;
