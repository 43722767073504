/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CenterScreen from '../../common/CenterScreen';
import SubmitButton from '../../common/SubmitButton';
import TextFieldInput from '../../common/input/TextFieldInput';
import StyledSwitch from '../../common/input/StyledSwitch';
import {observer} from 'mobx-react-lite';
import urlStore from '../../../stores/urlStore';
import getTestID from '../../../helper/functions/getTestID';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

interface Props {
    setUrlConfigurationOpen: (value: boolean) => void,
}

const UrlConfiguration = observer((props: Props) => {
    const [ sslEnabled, setSslEnabled ] = useState(urlStore.protocol === 'https');
    const [ server, setServer ] = React.useState(urlStore.ipAddress);
    const [ port, setPort ] = React.useState(urlStore.port);
    const [ baseDirectory, setBaseDirectory ] = React.useState(urlStore.baseDirectory);

    const submit = () => {
        urlStore.setProtocol(sslEnabled ? 'https' : 'http', true);
        urlStore.setIpAddress(server, true);
        urlStore.setPort(port, true);
        urlStore.setBaseDirectory(baseDirectory, true);
        props.setUrlConfigurationOpen(false);
    };

    return (
        <CenterScreen>
            <Paper
                sx={{
                    width: '33%',
                    minWidth: '444px',
                }}
            >
                <Container>
                    <StyledSwitch
                        id="url_configuration_ssl_enabled_switch"
                        description="Enable SSL"
                        value={sslEnabled}
                        setValue={setSslEnabled}
                    />

                    <TextFieldInput
                        id="url_configuration_server_address_input"
                        label="Server Address"
                        field={{
                            value: server,
                            setValue: setServer,
                            error: '',
                            setError: () => {},
                        }}
                    />
                    <TextFieldInput
                        id="url_configuration_port_input"
                        label="Port"
                        field={{
                            value: port,
                            setValue: setPort,
                            error: '',
                            setError: () => {},
                        }}
                    />
                    <TextFieldInput
                        id="url_configuration_base_directory_input"
                        label="Base Directory"
                        field={{
                            value: baseDirectory,
                            setValue: setBaseDirectory,
                            error: '',
                            setError: () => {},
                        }}
                    />

                    <ButtonRow>
                        <Button
                            {...getTestID('cancel_url_configuration_button')}
                            color="secondary"
                            sx={{marginRight: '16px'}}
                            onClick={() => props.setUrlConfigurationOpen(false)}
                        >
                            Cancel
                        </Button>
                        <SubmitButton
                            id="url_configuration_submit_button"
                            text="Submit"
                            onClick={submit}
                        />
                    </ButtonRow>
                </Container>
            </Paper>
        </CenterScreen>
    );
});

export default UrlConfiguration;
