/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import { styled, Typography } from '@mui/material';
import Wave from 'react-wavify';

interface Props {
    title: string;
    percentage: number;
}

const Row = styled('div')({
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    height: 90,
});

const Container = styled('div')({
    display: 'inline-block',
    height: 90,
    width: 90,
    overflow: 'hidden',
    paddingTop: 10,
    paddingBottom: 10,
});

const TextTypography = styled(Typography)(() => ({
    display: 'inline-block',
    fontSize: '14px',
    textAlign: 'justify',
    height: 90,
    width: '60%',
    verticalAlign: 'middle',
    overflow: 'hidden',
}));

const PercentageTypography = styled(Typography)(() => ({
    display: 'inline-block',
    fontSize: '14px',
    textAlign: 'right',
    height: 90,
    verticalAlign: 'middle',
    overflow: 'hidden',

}));

export default function LabeledProgressTank(props: Props) {
    const waveOptions = {
        height: 60 - (props.percentage * 0.6), // 60 = r * 2 (circle's r in line 71 )
        amplitude: 5,
        speed: 0.3,
        point: 15,
    };

    return (
        <Row>
            <Container>
                <Wave
                    fill='#0087dc'
                    options={waveOptions}
                    mask='url(#mask)'
                >
                    <mask
                        id='mask'
                    >
                        <circle
                            cx='30'
                            cy='30'
                            r='30'
                            fill='#EEE'
                        />
                    </mask>
                </Wave>
            </Container>
            <TextTypography>{props.title}</TextTypography>
            {/* the tank level percents showed up on the device screen seems to be truncated to integer portion,
             so trunc here for consistency*/}
            <PercentageTypography>{`${ Math.trunc(props.percentage) }%`}</PercentageTypography>
        </Row>
    );
}
