/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import Constants from '../../../../helper/Constants';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import GridTextFieldInput from '../../../common/input/GridTextFieldInput';

interface Props {
    showEmailAndPassword?: boolean
    email?: InputFieldProps,
    password?: InputFieldProps,
    confirmPassword?: InputFieldProps,
    firstName: InputFieldProps,
    lastName: InputFieldProps,
    companyName: InputFieldProps,
    deviceAdminDisplayName: InputFieldProps,
    phoneNumber: InputFieldProps,
}

function PersonalDetailsForm(props: Props) {
    return (
        <Grid
            container
            spacing={2}
        >
            {(props.showEmailAndPassword && props.email) && (
                <GridTextFieldInput
                    id="email_input"
                    label={Constants.EMAIL}
                    field={props.email}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 60,
                        },
                    }}
                />
            )}
            {props.showEmailAndPassword && (
                <Grid
                    item
                    xs={6}
                >
                    <div/>
                </Grid>
            )}
            {(props.showEmailAndPassword && props.password) && (
                <GridTextFieldInput
                    id="password_input"
                    label={Constants.PASSWORD}
                    field={props.password}
                    type="password"
                />
            )}
            {(props.showEmailAndPassword && props.confirmPassword) && (
                <GridTextFieldInput
                    id="confirm_password_input"
                    label={Constants.CONFIRM_PASSWORD}
                    field={props.confirmPassword}
                    type="password"
                />
            )}

            <GridTextFieldInput
                id="first_name_input"
                label={Constants.FIRST_NAME}
                field={props.firstName}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="last_name_input"
                label={Constants.LAST_NAME}
                field={props.lastName}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="company_name_input"
                label={Constants.COMPANY_NAME}
                field={props.companyName}
                textFieldProps={{
                    inputProps: {
                        maxLength: 30,
                    },
                }}
            />

            <GridTextFieldInput
                id="device_admin_display_name_input"
                label={Constants.DEVICE_ADMIN_DISPLAY_NAME}
                field={props.deviceAdminDisplayName}
                textFieldProps={{
                    inputProps: {
                        maxLength: 21,
                    },
                }}
            />
            <GridTextFieldInput
                id="phone_number_input"
                label={Constants.PHONE_NUMBER}
                field={props.phoneNumber}
                textFieldProps={{
                    inputProps: {
                        maxLength: 10,
                    },
                    type: 'tel',
                }}
            />
        </Grid>
    );
}

export default PersonalDetailsForm;
