/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Typography} from '@mui/material';
import BaseModal from './BaseModal';
import getTestID from '../../../helper/functions/getTestID';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

interface Props {
    id: string,
    open: boolean,
    close: () => void,
    submit: () => void,
    title: string,
    message: string,
}

function ConfirmationModal(props: Props) {
    const submit = () => {
        props.submit();
        props.close();
    };

    return (
        <BaseModal
            id={props.id}
            open={props.open}
            close={props.close}
            submit={submit}
            title={props.title}
        >
            <Container>
                <Typography {...getTestID('popup_message')}>
                    {props.message}
                </Typography>
            </Container>
        </BaseModal>
    );
}

export default ConfirmationModal;
