/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import SettingsDrawerButton from '../drawer/SettingsDrawerButton';
import {Assignment, Info, List} from '@mui/icons-material';
import Constants from '../../../helper/Constants';
import Paper from '@mui/material/Paper';
import TermsOfService from '../termsOfService/TermsOfService';
import WebStore from '../../../stores/WebStore';
import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';

const Root = styled('div')({
    height: '100%',
});

const InformationPanel = observer(() => {
    const open = (url: string) => {
        window.open(url, '_blank');
    };

    const onTermsOfServiceClick = () => {
        open('https://www.fuellock.ca/terms-of-service');
    };

    const onPrivacyPolicyClick = () => {
        open('https://www.fuellock.ca/privacy-policy');
    };

    const onResourcesClick = () => {
        open('https://linktr.ee/FuelLock');
    };

    const renderContentPanel = () => {
        switch (WebStore.currentPage) {
            case Constants.MAIN:
                return (
                    <Paper sx={{width: 'fit-content'}}>
                        <div style={{width: '345px'}}>
                            <SettingsDrawerButton
                                icon={<Assignment/>}
                                text={Constants.TERMS_OF_SERVICE}
                                selectedDrawer=""
                                onClick={onTermsOfServiceClick}
                            />
                            <SettingsDrawerButton
                                icon={<List/>}
                                text={Constants.PRIVACY_POLICY}
                                selectedDrawer=""
                                onClick={onPrivacyPolicyClick}
                            />
                            <SettingsDrawerButton
                                icon={<Info/>}
                                text="Resources"
                                selectedDrawer=""
                                onClick={onResourcesClick}
                            />
                        </div>
                    </Paper>
                );
            case Constants.TERMS_OF_SERVICE:
                return <TermsOfService hideButtons/>;
            default:
                return <div/>;
        }
    };

    return (
        <Root>
            {renderContentPanel()}
        </Root>
    );
});

export default InformationPanel;
