/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {observer} from 'mobx-react-lite';
import {Device} from '../../../types/components/ApiTypes';
import TransferDevice from './TransferDevice';

interface Props {
    editingDevice: Device;
}

const TransferDevicePaper = observer((props: Props) => (
    <TransferDevice
        editingDevice={props.editingDevice}
        width="auto"
        serialNumber={{
            value: props.editingDevice.sn,
            setValue: () => {
            },
            error: '',
            setError: () => {
            },
        }}
        toSerialNumber={{
            value: props.editingDevice.sn,
            setValue: () => {
            },
            error: '',
            setError: () => {
            },
        }}
        serialNumberDisabled
    />
));

export default TransferDevicePaper;
