/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import LabeledProgressBar from './LabeledProgressBar';
import makeNiceYInterval from '../../../../helper/functions/makeNiceYInterval';
import {styled, Typography} from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ChartMenu from '../ChartMenu';
import TitleTypography from '../../../common/typography/TitleTypography';
import getTestID from '../../../../helper/functions/getTestID';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '16px',
});

interface Data {
    title: string,
    value: number,
    unit: string,
}

interface Props {
    paperRef: any,
    id: string,
    title: string,
    data: Data[];
    selectedTimeFrame: string,
    setSelectedTimeFrame: (value: string) => void,
    paperStyle: object,
    rowId: string,
}

const ProgressBarChart = (props: Props)  => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ endOfBar, setEndOfBar ] = React.useState(props.data.length > 0
        ? makeNiceYInterval(0, Math.max.apply(Math, props.data.map(o => o.value))).newYTop : 0);

    useEffect(() => {
        setEndOfBar(props.data.length > 0
            ? makeNiceYInterval(0, Math.max.apply(Math, props.data.map(o => o.value))).newYTop : 0);
    }, [ props.data ]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Paper
            sx={{
                ...props.paperStyle,
            }}
            elevation={6}
            ref={props.paperRef}
        >
            <Row>
                <TitleTypography title={props.title}/>
                <IconButton
                    {...getTestID(props.id)}

                    sx={{
                        padding: 0,
                        zIndex: 9999,
                    }}
                    onClick={handleClick}
                >
                    <MoreVert/>
                </IconButton>
            </Row>

            {props.data.length > 0 ? (
                props.data.map((dataPoint, index) => {
                    const value = `${ dataPoint.value.toFixed(2) }${ dataPoint.unit }`;
                    return (
                        <LabeledProgressBar
                            key={dataPoint.title + index}
                            title={dataPoint.title}
                            value={value.trim()}
                            percentage={(dataPoint.value / endOfBar) * 100}
                            color={index % 2 === 0 ? 'primary' : 'secondary'}
                            noPadding={index === props.data.length - 1}
                            id={props.rowId}
                        />
                    );
                })
            ) : (
                <Typography>No data found for the selected device(s) in the selected time range</Typography>
            )}

            {Boolean(anchorEl) && (
                <ChartMenu
                    id={props.id}
                    anchorEl={anchorEl}
                    open
                    handleClose={handleClose}
                    selected={props.selectedTimeFrame}
                    setSelected={props.setSelectedTimeFrame}
                />
            )}
        </Paper>
    );
};

export default ProgressBarChart;
