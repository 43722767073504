/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {observer} from 'mobx-react-lite';
import {SnackbarProvider} from 'notistack';
import Colors from './helper/Colors';
import Main from './components/main/Main';
import WebStore from './stores/WebStore';
import CssBaseline from '@mui/material/CssBaseline';
import {darkScrollbar} from '@mui/material';
// import { getApps } from 'firebase/app';

const PAPER_BORDER_RADIUS = '12px';
const PAPER_BOX_SHADOW = 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px';
const PAPER_BOX_SHADOW_DARK = 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 12px 24px -4px';

const themeColors = {
    palette: {
        primary: {
            main: Colors.primary,
            background: Colors.background,
            drawerBackground: Colors.background,
            drawerUserBackground: Colors.drawerUserBackgroundLight,
            titleText: Colors.textTitle,
            boldText: Colors.textBold,
            paper: '#FFFFFF',
            modalTitle: Colors.modalTitleLightMode,
        },
        secondary: {
            main: Colors.accent,
        },
    },
    typography: {
        fontFamily: [ 'Gibson' ].join(','),
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Gibson',
                    color: Colors.text,
                    fontSize: '16px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: PAPER_BORDER_RADIUS,
                },
                popper: {
                    color: 'white',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    paddingTop: '25px',
                    paddingRight: '20px',
                    paddingBottom: '25px',
                    paddingLeft: '20px',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '18px',
                    borderRadius: PAPER_BORDER_RADIUS,
                    boxShadow: PAPER_BOX_SHADOW,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Gibson',
                    borderRadius: '8px',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingBottom: '12px',
                    paddingTop: '12px',
                    '&.Mui-selected': {
                        fontWeight: 800,
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: '#faecd9',
                        },
                    },
                    '&:hover': {
                        backgroundColor: '#f2f3f5',
                        borderRadius: '10px',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: darkScrollbar({
                    track: Colors.background,
                    thumb: '#d3d5d7',
                    active: '#aeb0b3',
                }),
            },
        },
        MuiSlider: {
            styleOverrides: {
                valueLabel: {
                    backgroundColor: Colors.primary,
                    color: Colors.textTitle,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 27,
                    height: 27,
                    borderRadius: '50% 50% 45% 45%',
                    fontSize: '9px',
                },
            },
        },
    },
};

const darkThemeColors = {
    palette: {
        primary: {
            main: Colors.primary,
            background: Colors.backgroundDark,
            drawerBackground: Colors.backgroundDark,
            drawerUserBackground: Colors.drawerUserBackgroundDark,
            titleText: Colors.textTitleLight,
            boldText: Colors.textBoldLight,
            paper: Colors.paperDarkMode,
            modalTitle: '#1e1e1e',
        },
        secondary: {
            main: '#FFFFFF',
        },
        mode: 'dark',
    },
    typography: {
        fontFamily: [ 'Gibson' ].join(','),
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: Colors.textLight,
                    fontSize: '16px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: PAPER_BORDER_RADIUS,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    paddingTop: '25px',
                    paddingRight: '20px',
                    paddingBottom: '25px',
                    paddingLeft: '20px',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '18px',
                    backgroundColor: '#2c2c2c',
                    backgroundImage: 'none',
                    borderRadius: PAPER_BORDER_RADIUS,
                    boxShadow: PAPER_BOX_SHADOW_DARK,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Gibson',
                    borderRadius: '8px',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingBottom: '12px',
                    paddingTop: '12px',
                    '&.Mui-selected': {
                        fontWeight: 800,
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: '#463826',
                        },
                    },
                    '&:hover': {
                        backgroundColor: '#19212a',
                        borderRadius: '10px',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: darkScrollbar({
                    track: Colors.backgroundDark,
                    thumb: '#6b6b6b',
                    active: '#959595',
                }),
            },
        },
        MuiSlider: {
            styleOverrides: {
                valueLabel: {
                    backgroundColor: Colors.primary,
                    color: Colors.textTitle,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 27,
                    height: 27,
                    borderRadius: '50% 50% 45% 45%',
                    fontSize: '9px',
                },
            },
        },
    },
};

// @ts-ignore
const theme = createTheme(themeColors);
// @ts-ignore
const darkTheme = createTheme(darkThemeColors);

const App = observer(() =>
    // const firebaseApp = getApps()[0];
    //
    // console.log(firebaseApp.options);

     (
         <ThemeProvider theme={WebStore.lightMode ? theme : darkTheme}>
             <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
             >
                 <CssBaseline/>
                 <Main/>
             </SnackbarProvider>
         </ThemeProvider>
    )
);

export default App;
