/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const determineActiveHours = (timeStr: string) => {
    const isAm = timeStr.includes('am');
    const split = timeStr.split(':');
    if (isAm && Number(split[0]) === 12) {
        return '00:00:00';
    }

    return (isAm || Number(split[0]) === 12)
        ? (Number(split[0]) < 10 ? `0${ split[0] }:00:00`
            : `${ split[0] }:00:00`) : `${ Number(split[0]) + 12 }:00:00`;
};

export default determineActiveHours;
