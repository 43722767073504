/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import FuelLockStore from '../../stores/FuelLockStore';
import WebStore from '../../stores/WebStore';
import { Device, TankList } from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getTankLevelsData = () => {

    let devices: Device[] = [];

    if (WebStore.dashboardDevice === Constants.ALL_DEVICES ||
        WebStore.dashboardDevice === '' ||
        WebStore.dashboardDevice === null) {

        devices = FuelLockStore.systemData.deviceList;
    }
    else {
        FuelLockStore.systemData.deviceList.forEach((device: Device) => {
            if (device.siteName === WebStore.dashboardDevice) {
                devices.push(device);
            }
        });
    }

    const data: { deviceId: number; deviceName: string; monitoredTanks: TankList[]; }[] = [];

    devices.forEach((device: Device) => {
        const tanks: TankList[] = [];

        const dataPerDevice = {
            deviceId: device.id,
            deviceName: device.siteName,
            monitoredTanks: tanks,
        };

        device.tankList.forEach((tank: TankList) => {
            if (tank.fullness !== null) {
                dataPerDevice.monitoredTanks.push(tank);
            }
        });

        data.push(dataPerDevice);
    });

    return data;
};

export default getTankLevelsData;
