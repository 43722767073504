/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CenterScreen from '../../common/CenterScreen';
import SubmitButton from '../../common/SubmitButton';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

interface Props {
    setForgotPasswordOpen: (value: boolean) => void,
}

function CheckInbox(props: Props) {
    const message = 'A link to reset your password has been sent to the email address you provided. ' +
        'Please check your inbox or junk mail. This link will expire in 1 hour.';

    return (
        <CenterScreen>
            <Paper
                sx={{
                    width: '33%',
                    minWidth: '444px',
                }}
            >
                <Container>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            paddingBottom: '32px',
                        }}
                    >
                        Check your inbox
                    </Typography>

                    <Typography
                        sx={{
                            paddingBottom: '16px',
                        }}
                    >
                        {message}
                    </Typography>

                    <SubmitButton
                        id="continue_login_button"
                        text="Continue to Login"
                        onClick={() => props.setForgotPasswordOpen(false)}
                    />
                </Container>
            </Paper>
        </CenterScreen>
    );
}

export default CheckInbox;
