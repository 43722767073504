/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import Constants from '../helper/Constants';

declare const window: Window;
declare global {
    interface Window { globalConfig: Config }
}

export interface Config {
    protocol: string,
    ipAddress: string,
    port: string
}

class UrlStore {
    baseDirectory = 'FuellockExternalService-2.0.0/webresources/';

    protocol = '';

    ipAddress = '';

    port = '';

    constructor() {
        makeObservable(this, {
            protocol: observable,
            setProtocol: action,
            ipAddress: observable,
            setIpAddress: action,
            port: observable,
            setPort: action,
            initializeFromAsyncStorage: action,
            baseDirectory: observable,
            setBaseDirectory: action,
        });
        try {
            this.protocol = window.globalConfig.protocol;
            this.ipAddress = window.globalConfig.ipAddress;
            this.port = window.globalConfig.port;
        } catch (e) {
            this.protocol = 'http';
            this.ipAddress = 'office.intragrain.com';
            this.port = '46541';
        }
        this.initializeFromAsyncStorage();
    };

    setProtocol = (protocol: string, sync: boolean) => {
        this.protocol = protocol;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_PROTOCOL, protocol);
        }
    };

    setIpAddress = (ipAddress: string, sync: boolean) => {
        this.ipAddress = ipAddress;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_IP_ADDRESS, ipAddress);
        }
    };

    setPort = (port: string, sync: boolean) => {
        this.port = port;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_PORT, port);
        }
    };

    setBaseDirectory = (directory: string, sync: boolean) => {
        this.baseDirectory = directory;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_BASE_DIRECTORY, directory);
        }
    };

    syncWithAsyncStorage = (key: string, value: string) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    };

    initializeFromAsyncStorage = () => {
        const result = global.localStorage.getItem(Constants.STORE_KEY_PROTOCOL);
        if (result !== null) {
            this.setProtocol(JSON.parse(result), false);
        }
        const ipResult = global.localStorage.getItem(Constants.STORE_KEY_IP_ADDRESS);
        if (ipResult !== null) {
            this.setIpAddress(JSON.parse(ipResult), false);
        }
        const portResult = global.localStorage.getItem(Constants.STORE_KEY_PORT);
        if (portResult !== null) {
            this.setPort(JSON.parse(portResult), false);
        }
        const baseDirectoryResult = global.localStorage.getItem(Constants.STORE_KEY_BASE_DIRECTORY);
        if (baseDirectoryResult !== null) {
            this.setBaseDirectory(JSON.parse(baseDirectoryResult), false);
        }
    };
}

export default new UrlStore();
