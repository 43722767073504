/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import getTestID from '../../../helper/functions/getTestID';

const menuItem = {
    '&:focus': {
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: '#727374',
        },
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#727374',
    },
} as const;

interface Props {
    icon: object,
    text: string,
    selectedDrawer: string,
    onClick: () => void,
}

function SettingsDrawerButton(props: Props) {
    return (
        <MenuItem
            {...getTestID(`${ props.text }_settings_drawer_button`)}
            sx={menuItem}
            selected={props.text === props.selectedDrawer}
            onClick={props.onClick}
        >
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.text}/>
        </MenuItem>
    );
}

export default SettingsDrawerButton;
