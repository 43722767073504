/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useMemo} from 'react';
import Grid from '@mui/material/Grid';
import Constants from '../../../../helper/Constants';
import {Divider} from '@mui/material';
import TitleTypography from '../../../common/typography/TitleTypography';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import GridTextFieldInput from '../../../common/input/GridTextFieldInput';
import GridSelectFieldInput from '../../../common/input/GridSelectFieldInput';
import getCountryList from '../../../../helper/functions/getCountryList';
import getProvinceList from '../../../../helper/functions/getProvinceList';

interface Props {
    hasTitle?: boolean,
    hideDivider?: boolean,
    id?: string,
    postalCode: InputFieldProps,
    address: InputFieldProps,
    town: InputFieldProps,
    province: InputFieldProps,
    country: InputFieldProps,
}

function BillingAddressForm(props: Props) {
    const updateCountry = (country: string) => {
        props.province.setValue('');
        props.country.setValue(country);
    };

    const prefixId = useMemo(() => {
        if (props.id) {
            return `${ props.id }_`;
        }
        return '';

    }, [ props.id ]);

    return (
        <div>
            {!props.hideDivider && (
                <Divider
                    sx={{
                        marginTop: '8px',
                        marginBottom: '16px',
                    }}
                />
            )}

            {props.hasTitle && (
                <TitleTypography title={Constants.BILLING_ADDRESS}/>
            )}
            <Grid
                container
                spacing={2}
            >
                <GridTextFieldInput
                    id={`${ prefixId }postal_code_input`}
                    label={Constants.POSTAL_CODE_OR_ZIP_CODE}
                    field={props.postalCode}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                    }}
                />
                <GridTextFieldInput
                    id={`${ prefixId }address_input`}
                    label={Constants.ADDRESS}
                    field={props.address}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 150,
                        },
                    }}
                />
                <GridTextFieldInput
                    id={`${ prefixId }town_input`}
                    label={Constants.TOWN_OR_CITY}
                    field={props.town}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                    }}
                />
                <GridSelectFieldInput
                    id={`${ prefixId }country_input`}
                    label={Constants.COUNTRY}
                    field={{
                        ...props.country,
                        setValue: updateCountry,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                    }}
                    options={getCountryList()}
                />
                <GridSelectFieldInput
                    id={`${ prefixId }province_input`}
                    label={Constants.PROVINCE_OR_STATE}
                    field={props.province}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                    }}
                    options={getProvinceList(props.country.value)}
                />
            </Grid>
        </div>
    );
}

export default BillingAddressForm;
