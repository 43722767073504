/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import {
    Device,
    Employee,
    Equipment,
    FlowMeterType,
    PersonalDetails,
    Preferences,
    SupplyPojo,
    SystemData,
    TankPojo,
    CustomFieldPojo,
    ContractorPin
} from '../types/components/ApiTypes';
import { configure } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

configure({
    useProxies: 'never',
});

const presetData = {
    deviceList: [],
    workerList: [],
    equipmentList: [],
    contractorPinList: [],
    flowMeterTypeList: [],
    personalDetails: {
        customerID: -1,
        firstName: '',
        lastName: '',
        companyName: '',
        displayName: '',
        primaryPhone: '',
        postalCode: '',
        address: '',
        city: '',
        country: '',
        province: '',
        subscribedToEmails: true,
        accountIsActive: true,
        accountLockedOut: false,
        termsOfServiceAccepted: false,
    },
    preferences: {
        liquidUnitId: 1,
        equipmentEntryMandatory: false,
        odometerEntryMandatory: false,
    },
    customField: {
        enabled: false,
        name: '',
        mandatoryEntry: false,
    },
    minFirmwareVersion: 19,
    tankTypes: [],
    densityList: [],
    liquidUnitList: [],
    otodataKey: null,
};

class FuelLockStore {
    error = '';

    success = {
        title: '',
        message: '',
    };

    confirm: { title: string; message: string; onConfirm: () => void } = {
        title: '',
        message: '',
        onConfirm: () => {
        },
    };

    awaitingApi = false;

    systemData: SystemData = cloneDeep(presetData);

    constructor() {
        makeObservable(this, {
            setEquipmentData: action,
            setEmployeeData: action,
            setContractorPinData: action,
            error: observable,
            setError: action,
            success: observable,
            setSuccess: action,
            confirm: observable,
            setConfirm: action,
            awaitingApi: observable,
            setAwaitingApi: action,
            systemData: observable,
            setSystemData: action,
            setDeviceData: action,
            setPreferences: action,
            setCustomField: action,
            setMinFirmwareVersion: action,
            setAccountIsActive: action,
            setTermsOfServiceFlag: action,
            setFlowMeterTypeList: action,
            setDeviceSupplyList: action,
            setDeviceTankList: action,
            setPersonalDetails: action,
            setOtodataApiKey: action,
            setWorkerList: action,
            presetFuelLockStore: action,
        });
    }

    setEquipmentData = (equipmentData: Equipment[]) => {
        this.systemData.equipmentList = equipmentData;
    };

    setEmployeeData = (employeeData: Employee[]) => {
        this.systemData.workerList = employeeData;
    };

    setContractorPinData = (contractorPinData: ContractorPin[]) => {
        this.systemData.contractorPinList = contractorPinData;
    };

    setError = (error: string) => {
        this.error = error;
    };

    setSuccess = (success: { title: string, message: string }) => {
        this.success = success;
    };

    setConfirm = (confirm: {
        title: string,
        message: string,
        onConfirm: () => void,
    }) => {
        this.confirm = confirm;
    };

    setAwaitingApi = (awaitingApi: boolean) => {
        this.awaitingApi = awaitingApi;
    };

    setSystemData = (systemData: any) => {
        this.systemData = systemData;
    };

    setDeviceData = (deviceData: Device[]) => {
        this.systemData.deviceList = deviceData;
    };

    setPreferences = (preference: Preferences) => {
        this.systemData.preferences = preference;
    };

    setCustomField = (customField: CustomFieldPojo) => {
        this.systemData.customField = customField;
    };

    setMinFirmwareVersion = (version: number) => {
        this.systemData.minFirmwareVersion = version;
    };

    setAccountIsActive = (flag: boolean) => {
        this.systemData.personalDetails.accountIsActive = flag;
    };

    setTermsOfServiceFlag = (flag: boolean) => {
        this.systemData.personalDetails.termsOfServiceAccepted = flag;
    };

    setFlowMeterTypeList = (flowMeterTypeList: FlowMeterType[]) => {
        this.systemData.flowMeterTypeList = flowMeterTypeList;
    };

    setDeviceSupplyList = (deviceId: number, supplyList: SupplyPojo[]) => {
        const index = this.systemData.deviceList.findIndex(a => a.id === deviceId);
        this.systemData.deviceList[index].supplyList = supplyList;
    };

    setDeviceTankList = (deviceId: number, tankList: TankPojo[]) => {
        const index = this.systemData.deviceList.findIndex(a => a.id === deviceId);
        this.systemData.deviceList[index].tankList = tankList;
    };

    setPersonalDetails = (personalDetails: PersonalDetails) => {
        this.systemData.personalDetails = personalDetails;
    };

    setOtodataApiKey = (key: string | null) => {
        this.systemData.otodataKey = key;
    };

    setWorkerList = (workerList : Employee[]) => {
        this.systemData.workerList = workerList;
    };

    presetFuelLockStore = () => {
        this.systemData = cloneDeep(presetData);
    };
}

export default new FuelLockStore();
