/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {ProcessedUsagePojo} from '../../types/components/ApiTypes';
import getLiquidVolumeInfo from './getLiquidVolumeInfo';

const getDataEmployeeAndEquipmentFuelUsage = (usageData: ProcessedUsagePojo[],
                                              titleKey: string,
                                              ignoreSkipped = false
) => {
    const displayData = {};

    usageData.forEach(usage => {
        const currentUsage = displayData[usage[titleKey]] ? displayData[usage[titleKey]] : 0;
        displayData[usage[titleKey]] = currentUsage + usage.litres;
    });

    const keys = Object.keys(displayData);
    const data: {
        title: string,
        value: number,
        unit: string,
    }[] = [];
    keys.forEach(key => {
        if (ignoreSkipped && key === 'Skipped') {} else {
            const amount = getLiquidVolumeInfo(displayData[key]);
            data.push({
                title: key,
                value: amount.value,
                unit: amount.unit,
            });
        }
    });

    data.sort((a, b) => b.value - a.value);

    return data.slice(0, 5);
};

export default getDataEmployeeAndEquipmentFuelUsage;
