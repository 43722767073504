/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import getTestID from '../../../../helper/functions/getTestID';
import Constants from '../../../../helper/Constants';

const Root = styled('div')({
    width: '100%',
    paddingBottom: 22,
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 9,
});

interface Props {
    title: string;
    value: string;
    percentage: number;
    color: 'primary' | 'secondary';
    noPadding?: boolean,
    id: string,
}

export default function LabeledProgressBar(props: Props) {
    return (
        <Root style={props.noPadding ? {paddingBottom: 0} : {}}>
            <Row>
                <Typography
                    {...getTestID(`${ props.id }_${ props.title }`)}
                    sx={{ fontStyle: props.title === Constants.REMOVED_USER ||
                        props.title === Constants.REMOVED_EQUIPMENT
                            ? 'italic' : 'normal' }}
                >
                    {props.title}
                </Typography>
                <Typography
                    {...getTestID(`${ props.id }_${ props.title }_amount`)}
                >
                    {props.value}
                </Typography>
            </Row>
            <LinearProgress
                {...getTestID(`${ props.id }_${ props.title }_linegraph`)}
                variant="determinate"
                value={props.percentage > 100 ? 100 : props.percentage}
                color={props.color}
            />
        </Root>
    );
}
