/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
// @ts-ignore
import uuid from 'react-uuid';
import Paper from '@mui/material/Paper';
import {styled, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import TitleTypography from '../../common/typography/TitleTypography';
import WebStore from '../../../stores/WebStore';
import {ProcessedUsagePojo} from '../../../types/components/ApiTypes';
import formatDate from '../../../helper/functions/formatDate';
import getLiquidVolumeInfo from '../../../helper/functions/getLiquidVolumeInfo';
import getTestID from '../../../helper/functions/getTestID';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ChartMenu from '../charts/ChartMenu';
import determineUsageDataInRange from '../../../helper/functions/determineUsageDataInRange';
import Colors from '../../../helper/Colors';
import Constants from '../../../helper/Constants';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '16px',
});

interface Props {
    paperRef: any,
    paperStyle: object,
    data: ProcessedUsagePojo[],
    updateSize: () => void,
}

const RecentTransactionsTile = observer((props: Props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ dataInRange, setDataInRange ] = useState<ProcessedUsagePojo[]>([]);

    useEffect(() => {
        const newData = determineUsageDataInRange(WebStore.recentTransactionsTimeRange, props.data);
        newData.sort((a, b) =>
            // @ts-ignore
            b.transactionStartDate - a.transactionStartDate
        );
        setDataInRange(newData.slice(0, 12));

        setTimeout(props.updateSize, 200);
    }, [ props.data, WebStore.recentTransactionsTimeRange ]);

    const getBackgroundColor = () => {
        if (WebStore.lightMode) {
            return Colors.drawerUserBackgroundLight;
        }
        return '#424242';
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderGridItem = (label: string | number, id: string, header = false) => (
        <Grid
            item
            xs={2}
            sx={header ? {
                paddingBottom: '16px',
            } : {marginTop: '16px'}}
        >
            <Typography
                {...getTestID(`rt_${ id }`)}
                sx={{ fontStyle: label === Constants.REMOVED_USER ||
                    label === Constants.REMOVED_EQUIPMENT
                        ? 'italic' : 'normal' }}
            >
                {label}
            </Typography>
        </Grid>
    );

    const renderGrid = () => (
        <div style={{marginTop: '16px'}}>
            <Grid
                container
                spacing={2}
                sx={{
                    backgroundColor: getBackgroundColor(),
                    borderRadius: '12px',
                    marginLeft: '0px',
                    width: 'auto',
                }}
            >
                {renderGridItem('Employee', 'employee_header', true)}
                {renderGridItem('Device', 'device_header', true)}
                {renderGridItem('Tank', 'tank_header', true)}
                {renderGridItem('Equipment', 'equipment_header', true)}
                {renderGridItem('Date', 'date_header', true)}
                {renderGridItem('Amount', 'amount_header', true)}
            </Grid>
            {dataInRange.sort((a, b) =>
                // @ts-ignore
                b.transactionStartDate - a.transactionStartDate
            ).map((dataPoint: ProcessedUsagePojo) => {
                const amount = getLiquidVolumeInfo(dataPoint.litres);
                return (
                    <Grid
                        container
                        spacing={2}
                        key={uuid()}
                        sx={{
                            marginLeft: '0px',
                            width: 'auto',
                        }}
                    >
                        {renderGridItem(dataPoint.worker, dataPoint.worker)}
                        {renderGridItem(dataPoint.device, `${ dataPoint.worker }_device`)}
                        {renderGridItem(dataPoint.tank, `${ dataPoint.worker }_tank`)}
                        {renderGridItem(dataPoint.equipment, `${ dataPoint.worker }_equipment`)}
                        {renderGridItem(formatDate(dataPoint.transactionStartDate), `${ dataPoint.worker }_date`)}
                        {renderGridItem(amount.value.toFixed(2) + amount.unit,
                            `${ dataPoint.worker }_amount`)}
                    </Grid>
                );
            })}
        </div>
    );

    return (
        <Paper
            sx={{
                ...props.paperStyle,
                minWidth: '550px',
            }}
            elevation={6}
            ref={props.paperRef}
        >
            <Row>
                <TitleTypography title="Recent Transactions"/>
                <IconButton
                    {...getTestID('recent_transactions_menu_button')}
                    onClick={handleClick}
                >
                    <MoreVert/>
                </IconButton>
            </Row>

            {dataInRange.length === 0
                ? (
                    <Typography>No data found for the selected device(s) in the selected time range</Typography>
                ) : renderGrid()
            }

            {Boolean(anchorEl) && (
                <ChartMenu
                    id='recent_transactions'
                    anchorEl={anchorEl}
                    open
                    handleClose={handleClose}
                    selected={WebStore.recentTransactionsTimeRange}
                    setSelected={WebStore.setRecentTransactionsTimeRange}
                />
            )}
        </Paper>
    );
});

export default RecentTransactionsTile;
