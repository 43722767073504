/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import getTimeOptions from '../../../helper/getTimeOptions';
import SelectFieldInput from './SelectFieldInput';

interface Props {
    id: string,
    start: string,
    handleChangeStart: (event: any) => void,
    end: string,
    handleChangeEnd: (event: any) => void,
}

function StartAndEndTimeSelect(props: Props) {
    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={6}
            >
                <SelectFieldInput
                    id={`start_${ props.id }_selection`}
                    label="Start Time"
                    field={{
                        value: props.start,
                        setValue: props.handleChangeStart,
                        error: '',
                        setError: () => {
                        },
                    }}
                    options={getTimeOptions('start')}
                />
            </Grid>
            <Grid
                item
                xs={6}
            >
                <SelectFieldInput
                    id={`end_${ props.id }_selection`}
                    label="End Time"
                    field={{
                        value: props.end,
                        setValue: props.handleChangeEnd,
                        error: '',
                        setError: () => {
                        },
                    }}
                    options={getTimeOptions('end')}
                />
            </Grid>
        </Grid>
    );
}

export default StartAndEndTimeSelect;
