/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default {
    accent: '#718096',

    background: '#F8FAFC',
    backgroundDark: '#0e151f',

    dataColors: [
        '#E43E3E',
        '#3182CE',
        '#D79D2E',
        '#37A169',
        '#4a37a1',
    ],

    drawerUserBackgroundLight: '#f2f3f5',
    drawerUserBackgroundDark: '#19212a',

    paperDarkMode: '#2c2c2c',
    primary: '#FEC52D',

    modalTitleLightMode: '#718096',
    modalTitleDarkMode: '#393d4b',

    text: '#4A5668',
    textLight: 'rgba(255,255,255,0.7)',
    textTitle: '#1A202C',
    textTitleLight: '#F8FAFC',
    textBold: '#2C3849',
    textBoldLight: '#6280a3',
};
