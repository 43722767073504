/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Typography from '@mui/material/Typography';

interface Props {
    title: string,
}

function PaperTitle(props: Props) {
    return (
        <Typography
            sx={{
                fontSize: '20px',
                paddingBottom: '16px',
            }}
        >
            {props.title}
        </Typography>
    );
}

export default PaperTitle;
