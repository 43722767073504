/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import BaseModal from '../common/modal/BaseModal';
import FuelLockStore from '../../stores/FuelLockStore';
import {IGTResponse} from '../../types/components/ApiTypes';
import callPutAPI from '../../controllers/callPutAPI';
import callAPI from '../../controllers/callAPI';
import callPostAPI from '../../controllers/callPostAPI';
import determineStartingState from '../../helper/functions/determineStartingState';
import isPlainText from '../../helper/validator/isPlainText';
import TextFieldInput from '../common/input/TextFieldInput';
import SuccessMessages from '../../helper/SuccessMessages';
import Constants from '../../helper/Constants';
import { isNumericPIN } from '../../helper/validator/isNumeric';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
    overflow:'scroll',
    height: '100%',
    maxHeight:'65vh',
});

interface Props {
    open: boolean,
    close: () => void,
    editing?: boolean,
    contractorPinName?: string;
    pin?: string | null;
    contractorPinID?: number;
}

const AddEditContractorPinModal = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ name, setName ] = useState(determineStartingState(props.editing, props.contractorPinName, ''));
    const [ nameError, setNameError ] = useState('');
    const [ pin, setPin ] = useState(determineStartingState(props.editing, props.pin, ''));
    const [ pinError, setPinError ] = useState('');

    const isValid = () => {
        let valid = true;

        const newNameError = name.trim() !== '' ? isPlainText(name, 1, 'name') : 'Please enter a valid name.';
        setNameError(newNameError);

        const newPinError = isNumericPIN(pin, 4, 10);
        setPinError(newPinError);

        if (newNameError !== '' || newPinError !== '') {
            valid = false;
        }

        return valid;
    };

    const submit = () => {
        if (isValid()) {
            const body = {
                id: -1,
                name,
                pin,
            };
            if (props.editing) {
                if (props.contractorPinID && pin) {
                    body.id = props.contractorPinID;
                }
                callPutAPI(`fuellock/v2/contractorPin/${ body.id }`, body, (putContractorPinResponse) => {
                    if (putContractorPinResponse.status && putContractorPinResponse.status === 'success') {
                        callAPI('fuellock/v2/dashboard', (receivedServerData) => {
                            FuelLockStore.setSystemData(receivedServerData.data);
                            props.close();
                            FuelLockStore.setAwaitingApi(false);
                        }, enqueueSnackbar);
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_CONTRACTOR_PIN);
            } else {
                callPostAPI('fuellock/v2/contractorPin', body, (postContractorPinResponse: IGTResponse<number>) => {
                    if (postContractorPinResponse.status && postContractorPinResponse.status === 'success') {
                        callAPI('fuellock/v2/dashboard', (receivedServerData) => {
                            FuelLockStore.setSystemData(receivedServerData.data);
                            props.close();
                            FuelLockStore.setAwaitingApi(false);
                        }, enqueueSnackbar);
                    }
                }, enqueueSnackbar, SuccessMessages.CREATE_CONTRACTOR_PIN);
            }
        }
    };

    return (
        <BaseModal
            id={props.editing ? 'edit_contractor_pin_modal' : 'add_contractor_pin_modal'}
            open={props.open}
            close={props.close}
            submit={submit}
            title={props.editing ? `Edit ${ Constants.CONTRACTOR_PIN }` : `Add new ${ Constants.CONTRACTOR_PIN }`}
            disableSubmitOnAwait
        >
            <Container>
                <TextFieldInput
                    id="contractor_pin_name_input"
                    label={`${ Constants.CONTRACTOR_PIN } name`}
                    field={{
                        value: name,
                        setValue: setName,
                        error: nameError,
                        setError: setNameError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 21,
                        },
                    }}
                />
                <TextFieldInput
                    id="contractor_pin_input"
                    label={`${ Constants.CONTRACTOR_PIN }`}
                    field={{
                        value: pin,
                        setValue: setPin,
                        error: pinError,
                        setError: setPinError,
                    }}
                    textFieldProps={{
                        placeholder: 'e.g., 8675',
                        inputProps: {
                            minLength: 4,
                            maxLength: 10,
                        },
                    }}
                />
            </Container>
        </BaseModal>
    );
});

export default AddEditContractorPinModal;
