/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useMemo, useState} from 'react';
import {styled} from '@mui/material';
import {useSnackbar} from 'notistack';
import Constants from '../../../helper/Constants';
import FlowMeterSettings from '../tanks/FlowMeterSettings';
// import LevelSensorSettings from '../tanks/LevelSensorSettings';
import BaseModal from '../../common/modal/BaseModal';
import StyledSwitch from '../../common/input/StyledSwitch';
import FuelLockStore from '../../../stores/FuelLockStore';
// import {Options} from '../../../types/components/EquipmentTypes';
import isStringNotEmpty from '../../../helper/validator/isStringNotEmpty';
import isNumericInRange from '../../../helper/validator/isNumericInRange';
import isLevelSensorValid from '../../../helper/validator/isLevelSensorValid';
import isFlowMeterValid from '../../../helper/validator/isFlowMeterValid';
import TextFieldInput from '../../common/input/TextFieldInput';
import WarnFlowMeterModal from '../../common/modal/WarnFlowMeterModal';
import {observer} from 'mobx-react-lite';
import {Device, FlowMeterType, IGTResponse, TankPojo} from '../../../types/components/ApiTypes';
import getDataById from '../../../helper/functions/getDataById';
import getAvailableFlowMeterPorts from '../../../helper/functions/getAvailableFlowMeterPorts';
import determineTankList from '../../../helper/functions/determineTankList';
import callPostAPI from '../../../controllers/callPostAPI';
import callAPI from '../../../controllers/callAPI';
import ErrorMessages from '../../../helper/ErrorMessages';
import callPutAPI from '../../../controllers/callPutAPI';
import SuccessMessages from '../../../helper/SuccessMessages';
import isTankNameUnique from '../../../helper/validator/isTankNameUnique';
import getUnitNameAndSymbol from '../../../helper/functions/getUnitNameAndSymbol';
import TankLevelPercentSlider from './TankLevelPercentSlider';
import MemoTypography from '../../common/typography/MemoTypography';
import getTestID from '../../../helper/functions/getTestID';

const Container = styled('div')({
    width: '500px',
    maxHeight: window.innerHeight * 0.7,
    overflow: 'auto',
    padding: '16px',
});

interface Props {
    deviceID: number,
    id: string,
    open: boolean,
    close: () => void,
}

const AddTankModal = observer((props: Props) => {
    const availableFlowMeterPorts = useMemo(() => {
        const device: Device = getDataById(FuelLockStore.systemData.deviceList,
            props.deviceID);
        return getAvailableFlowMeterPorts(device, -1);
    }, [ FuelLockStore.systemData.deviceList, props.deviceID ]);

    const {enqueueSnackbar} = useSnackbar();
    const [ useFlowMeter, setUseFlowMeter ] = useState(false);
    const [ useLevelSensor ] = useState(false);
    const [ name, setName ] = useState('');
    const [ nameError, setNameError ] = useState('');
    const [ capacity, setCapacity ] = useState('');
    const [ capacityError, setCapacityError ] = useState('');
    const [ model, setModel ] = useState('');
    const [ flowMeterSlot, setFlowMeterSlot ] = useState(availableFlowMeterPorts[0].toString());
    const [ limit, setLimit ] = useState('');
    const [ limitError, setLimitError ] = useState('');
    const [ flowMeterName, setFlowMeterName ] = useState('');
    const [ flowMeterNameError, setFlowMeterNameError ] = useState('');
    const [ custom, setCustom ] = useState(false);
    const [ pulsesPerLitre, setPulsesPerLitre ] = useState('');
    const [ pulsesPerLitreError, setPulsesPerLitreError ] = useState('');
    const [ levelSensorConfiguration ] = useState('');
    const [ levelSensorProduct ] = useState<'' | 'Otodata' | 'Tattle'>('');
    const [ tankType ] = useState('');
    const [ density ] = useState('');
    const [ otodataDevice ] = useState('');
    const [ height ] = useState('');
    const [ heightError, setHeightError ] = useState('');
    const [ tattleID ] = useState('');
    const [ tattleIDError ] = useState('');
    const [ tattlePort ] = useState('');
    const [ ignoreFlowMeter, setIgnoreFlowMeter ] = useState(false);
    const [ showUseFlowMeterModal, setShowUseFlowMeterModal ] = useState(false);
    const [ otodataApiKey ] = useState('');
    const [ otodataApiKeyError ] = useState('');
    const [ virtualTankMonitoring, setVirtualTankMonitoring ] = useState(false);
    const [ fullness, setFullness ] = useState(0);

    const [ unit ] = useState(getUnitNameAndSymbol(getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId).name));
    // const [ otodataDeviceOptions, setOtodataDeviceOptions ] = React.useState<Options[]>([]);
    // const [ tattlePortButtons, setTattleButtons ] = React.useState<Options[]>([]);

    const generateTank = () => ({
        name,
        nameError,
        model,
        limit,
        limitError,
        flowMeterName,
        flowMeterNameError,
        pulsesPerLitre,
        pulsesPerLitreError,
        custom,
        tattleID,
        tattleIDError,
        tattlePort,
        type: tankType,
        density,
        height,
        heightError,
        capacity,
        capacityError,
        flowMeterSlot,
        useFlowMeter,
        useLevelSensor,
        levelSensorConfiguration,
        levelSensorProduct,
        otodataApiKey,
        otodataApiKeyError,
        otodataDevice,
        ignoreFlowMeter,
        showUseFlowMeterModal,
        virtualTankMonitoring,
        fullness,
        lowThreshold: null,
    });

    const isValid = () => {
        let valid = true;
        const tank = generateTank();
        let newNameError = isStringNotEmpty(name, 'name');

        if (newNameError === '') {
            newNameError = isTankNameUnique(name, props.deviceID);
        }
        setNameError(newNameError);
        if (newNameError !== '') {
            valid = false;
        }

        const newCapacityError = isNumericInRange(capacity, 0, 4294967295, 0,
            'capacity');
        setCapacityError(newCapacityError);

        if (newCapacityError !== '') {
            valid = false;
        }

        if (useLevelSensor) {
            const levelSensorValid = isLevelSensorValid(tank);
            setHeightError(levelSensorValid.heightError);
            if (!levelSensorValid.valid) {
                valid = false;
            }
        }

        if (useFlowMeter) {
            const flowMeterValid = isFlowMeterValid(tank);
            setFlowMeterNameError(flowMeterValid.flowMeterNameError);
            setPulsesPerLitreError(flowMeterValid.pulsesPerLitreError);
            setLimitError(flowMeterValid.limitError);

            if (!flowMeterValid.valid) {
                valid = false;
            }
        } else if (!ignoreFlowMeter && valid) {
            valid = false;
            setShowUseFlowMeterModal(true);
        }

        return valid;
    };

    const sendLevelSensorAPI = (currentTank: TankPojo, sensorbody: object,
                                getTanksResponse: IGTResponse<TankPojo[]>) => {
        callPutAPI(`fuellock/v2/levelSensor/${ currentTank.id }`,
            sensorbody,
            (receivedServerData) => {
                if (receivedServerData.status &&
                    receivedServerData.status !== 'success') {
                    FuelLockStore.setError(ErrorMessages.UNEXPECTED);
                    FuelLockStore.setDeviceTankList(props.deviceID,
                        getTanksResponse.data);
                    props.close();
                } else {
                    setTimeout(() => {
                        callAPI('fuellock/v2/dashboard',
                            (receivedDashboardData) => {
                                if (receivedDashboardData.status === 'success') {
                                    FuelLockStore.setSystemData(receivedDashboardData.data);
                                }
                            }, enqueueSnackbar);
                        props.close();
                    }, 100);
                }
            }, enqueueSnackbar, SuccessMessages.UPDATE_LEVEL_SENSOR);
    };

    const sendApiRequest = async () => {
        await determineTankList([ generateTank() ], enqueueSnackbar).then((tankList) => {
            const currentTankName = tankList[0].name;
            const tempsensor = tankList[0].fuelSensor;
            const tankCapacity = tankList[0].capacity;
            const sensorbody = tempsensor ? {
                serial: tempsensor.serial,
                tankHeight: tempsensor.tankHeight,
                capacity: `${ tankCapacity }`,
                density: tempsensor.density,
                tankType: tempsensor.tankType,
                brand: tempsensor.brand,
                portNumber: tempsensor.serial,
                accountType: 'business',
                sensorType: 'floatArm',
            } : null;

            setTimeout(() => {
                callPostAPI(`fuellock/v2/tank/${ props.deviceID }`, tankList[0],
                    (postTankResponse: IGTResponse<number>) => {
                        if (postTankResponse.status === 'success') {
                            setTimeout(() => {
                                // eslint-disable-next-line max-len
                                callAPI(`fuellock/v2/tank/${ props.deviceID }`,
                                    (getTanksResponse: IGTResponse<TankPojo[]>) => {
                                        if (sensorbody) {
                                            const currentTank = getTanksResponse.data.find(
                                                tank => tank.name === currentTankName);
                                            if (currentTank !== undefined) {
                                                setTimeout(() => {
                                                    sendLevelSensorAPI(currentTank, sensorbody, getTanksResponse);
                                                }, 100);
                                            }
                                        } else {
                                            callAPI('fuellock/v2/dashboard',
                                                (receivedDashboardData) => {
                                                    if (receivedDashboardData.status === 'success') {
                                                        FuelLockStore.setSystemData(receivedDashboardData.data);
                                                    }
                                                }, enqueueSnackbar);
                                            props.close();
                                        }
                                    }, enqueueSnackbar);
                            }, 100);
                        }
                    }, enqueueSnackbar, SuccessMessages.CREATE_TANK);
            }, 400);
        });
    };

    const submit = () => {
        if (isValid()) {
            sendApiRequest();
        }
    };

    const handleIgnoreFlowMeter = (value: boolean) => {
        setIgnoreFlowMeter(value);
        sendApiRequest();
    };

    const onModalChange = (value: string) => {
        setModel(value);

        if (value === 'Custom Flow Meter') {
            setCustom(true);
        } else {

            const flowMeterTypeList = JSON.parse(JSON.stringify(FuelLockStore.systemData.flowMeterTypeList));

            const index = flowMeterTypeList.map((x: FlowMeterType) => x.name).indexOf(value);
            const selectedModal = flowMeterTypeList[index];

            if (selectedModal.custom) {
                setCustom(true);
                setFlowMeterName(selectedModal.name);
                setPulsesPerLitre(selectedModal.pulsesPerLitre);
            } else {
                setCustom(false);
            }
        }
    };

    return (
        <BaseModal
            id={props.id}
            open={props.open}
            close={props.close}
            submit={submit}
            title={Constants.TANK_CONFIGURATION}
            disableSubmitOnAwait
        >
            <Container>
                <TextFieldInput
                    id="tank_name_input"
                    label={Constants.TANK_NAME}
                    field={{
                        value: name,
                        setValue: setName,
                        error: nameError,
                        setError: setNameError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 16,
                        },
                    }}
                />
                <TextFieldInput
                    id="tank_capacity_input"
                    label={`${ Constants.CAPACITY } (${ unit.name })`}
                    field={{
                        value: capacity,
                        setValue: setCapacity,
                        error: capacityError,
                        setError: setCapacityError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 10,
                        },
                    }}
                />
                <div style={{marginBottom: '20px'}}>
                    <MemoTypography
                        memo={'Enter tank capacity correctly to ensure more accurate tank level monitoring'}
                    />
                </div>

                <StyledSwitch
                    id="use_flow_meter_switch"
                    description="Use Flow Meter"
                    value={useFlowMeter}
                    setValue={setUseFlowMeter}
                />
                {/*<StyledSwitch*/}
                {/*    id="use_level_sensor_switch"*/}
                {/*    description="Use Level Sensor"*/}
                {/*    value={useLevelSensor}*/}
                {/*    setValue={setUseLevelSensor}*/}
                {/*/>*/}
                {useFlowMeter && (
                    <FlowMeterSettings
                        model={{
                            value: model,
                            setValue: onModalChange,
                            error: '',
                            setError: () => {
                            },
                        }}
                        port={{
                            value: flowMeterSlot,
                            setValue: setFlowMeterSlot,
                            error: '',
                            setError: () => {
                            },
                        }}
                        limit={{
                            value: limit,
                            setValue: setLimit,
                            error: limitError,
                            setError: setLimitError,
                        }}
                        name={{
                            value: flowMeterName,
                            setValue: setFlowMeterName,
                            error: flowMeterNameError,
                            setError: setFlowMeterNameError,
                        }}
                        custom={custom}
                        pulsesPerLitre={{
                            value: pulsesPerLitre,
                            setValue: setPulsesPerLitre,
                            error: pulsesPerLitreError,
                            setError: setPulsesPerLitreError,
                        }}
                        availablePorts={availableFlowMeterPorts}
                    />
                )}
                <div>
                    <StyledSwitch
                        id="virtual_tank_monitoring_switch"
                        description="Virtual Tank Monitoring"
                        value={virtualTankMonitoring}
                        setValue={setVirtualTankMonitoring}
                    />
                    {virtualTankMonitoring && (
                        <div>
                            <MemoTypography
                                memo="Set your current tank level percent"
                            />
                            <TankLevelPercentSlider
                                {...getTestID('tag_addnewtank_level_slider')}
                                name='new_tank'
                                value={fullness}
                                setValue={setFullness}
                            />
                        </div>
                    )}
                </div>

                {/*{useLevelSensor && (*/}
                {/*    <LevelSensorSettings*/}
                {/*        levelSensorConfiguration={{*/}
                {/*            value: levelSensorConfiguration,*/}
                {/*            setValue: setLevelSensorConfiguration,*/}
                {/*            error: '',*/}
                {/*            setError: () => {*/}
                {/*            },*/}
                {/*        }}*/}
                {/*        levelSensorProduct={levelSensorProduct}*/}
                {/*        setLevelSensorProduct={setLevelSensorProduct}*/}
                {/*        tankType={{*/}
                {/*            value: tankType,*/}
                {/*            setValue: setTankType,*/}
                {/*            error: '',*/}
                {/*            setError: () => {*/}
                {/*            },*/}
                {/*        }}*/}
                {/*        density={{*/}
                {/*            value: density,*/}
                {/*            setValue: setDensity,*/}
                {/*            error: '',*/}
                {/*            setError: () => {*/}
                {/*            },*/}
                {/*        }}*/}
                {/*        tattleID={{*/}
                {/*            value: tattleID,*/}
                {/*            setValue: setTattleID,*/}
                {/*            error: tattleIDError,*/}
                {/*            setError: setTattleIDError,*/}
                {/*        }}*/}
                {/*        selectedButton={{*/}
                {/*            value: tattlePort,*/}
                {/*            setValue: setTattlePort,*/}
                {/*            error: '',*/}
                {/*            setError: () => {*/}
                {/*            },*/}
                {/*        }}*/}
                {/*        height={{*/}
                {/*            value: height,*/}
                {/*            setValue: setHeight,*/}
                {/*            error: heightError,*/}
                {/*            setError: setHeightError,*/}
                {/*        }}*/}
                {/*        otodataApiKey={FuelLockStore.systemData.otodataKey}*/}
                {/*        setOtodataApiKey={setOtodataApiKey}*/}
                {/*        otodataDevice={otodataDevice}*/}
                {/*        setOtodataDevice={setOtodataDevice}*/}
                {/*        otodataDeviceOptions={otodataDeviceOptions}*/}
                {/*        setOtodataDeviceOptions={setOtodataDeviceOptions}*/}
                {/*        tattlePortButtons={tattlePortButtons}*/}
                {/*        setTattlePortButtons={setTattleButtons}*/}
                {/*        editingSensor*/}
                {/*        editingType={''}*/}
                {/*        otodataApiKeyError={otodataApiKeyError}*/}
                {/*        setOtodataApiKeyError={setOtodataApiKeyError}*/}
                {/*    />*/}
                {/*)}*/}
            </Container>
            {showUseFlowMeterModal && (
                <WarnFlowMeterModal
                    open
                    close={() => setShowUseFlowMeterModal(false)}
                    setUseFlowMeter={setUseFlowMeter}
                    setIgnoreFlowMeter={value => handleIgnoreFlowMeter(value)}
                />
            )}
        </BaseModal>
    );
});

export default AddTankModal;
