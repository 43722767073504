/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import IconButton from '@mui/material/IconButton';
import {MoreVert} from '@mui/icons-material';
import PopupMenu from './PopupMenu';
import getTestID from '../../helper/functions/getTestID';
import {observer} from 'mobx-react-lite';
import FuelLockStore from '../../stores/FuelLockStore';
import TooltipWithButton from '../common/TooltipWithButton';
import TooltipStore from '../../stores/TooltipStore';
import TooltipMessages from '../../helper/TooltipMessages';
import { MenuItem, Tooltip } from '@mui/material';

interface Props {
    iconButton: object,
    disabled?: boolean,
    setDashboardDevice: (label: string) => void,
}

const VirtualTankMonitoringPopup = observer((props: Props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeviceClick = (label: string) => {
        props.setDashboardDevice(label);
        handleClose();
    };

    return (
        <Tooltip
            open={TooltipStore.beginTooltips && TooltipStore.showDashboardMessages}
            arrow
            title={(
                <TooltipWithButton
                    id="data_filter_tooltip_button"
                    message={TooltipMessages.FILTER}
                    onClick={() => TooltipStore.setShowDashboardMessages(false, true)}
                />
            )}
        >
            <div>
                <IconButton
                    {...getTestID('virtual_tank_monitoring_device_button')}
                    sx={props.iconButton}
                    onClick={handleClick}
                    disabled={props.disabled}
                >
                    <MoreVert/>
                </IconButton>
                <PopupMenu
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                >
                    {FuelLockStore.systemData.deviceList.map(device => (
                        <MenuItem
                            {...getTestID(`device_${ device.id }_virtual_tank_monitoring_button`)}
                            key={`Device_${ device.id }`}
                            onClick={() => handleDeviceClick(device.siteName)}
                        >
                            {device.siteName}
                        </MenuItem>
                    ))}
                </PopupMenu>
            </div>
        </Tooltip>
    );
});

export default VirtualTankMonitoringPopup;
