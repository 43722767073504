/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useMemo} from 'react';
import {Button, LinearProgress, styled, Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {Brightness4, Brightness7} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import Constants from '../../helper/Constants';
import UserPopup from './UserPopup';
// import NotificationsPopup from './NotificationsPopup';
import DevicesPopup from './DevicesPopup';
import getTestID from '../../helper/functions/getTestID';
import TooltipWithButton from '../common/TooltipWithButton';
import TooltipStore from '../../stores/TooltipStore';
import TooltipMessages from '../../helper/TooltipMessages';
import FuelLockStore from '../../stores/FuelLockStore';
import Colors from '../../helper/Colors';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin: '16px',
    paddingLeft: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '800px',
});

const RightRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const iconButton = {
    marginLeft: '16px',
    padding: '3px',
};

interface Props {
    disableButtons?: boolean,
}

const Banner = observer((props: Props) => {
    const title = useMemo(() => {
        switch (WebStore.selectedDrawer) {
            case Constants.EQUIPMENT:
                return `${ Constants.EQUIPMENT } Management`;
            case Constants.EMPLOYEES:
                return 'Employee Management';
            case Constants.CONTRACTOR_PIN:
                return `${ Constants.CONTRACTOR_PIN } Management`;
            default:
                return WebStore.selectedDrawer;
        }
    }, [ WebStore.selectedDrawer ]);

    const breadcrumbClicked = (page: string) => {
        if (WebStore.currentPage === Constants.ADD_NEW_DEVICE && WebStore.activeStep !== 0) {
            WebStore.setIsLoseChangesConfirmationOpen(true);
        }
        else {
            while (WebStore.currentPage !== page) {
                WebStore.goToPreviousPage();
            }
        }
    };

    return (
        <div>
            {FuelLockStore.awaitingApi && (
                <LinearProgress color="primary"/>
            )}
            <Root>
                <RightRow>
                    <Typography
                        sx={{fontSize: '20px'}}
                        {...getTestID(`${ WebStore.selectedDrawer }_page`)}
                    >
                        {title}
                    </Typography>
                    {WebStore.selectedDrawer === Constants.DASHBOARD && (
                        <DevicesPopup
                            iconButton={iconButton}
                            disabled={props.disableButtons}
                        />
                    )}
                    {WebStore.pageQueue.map(page => (
                        <RightRow>
                            <Button
                                {...getTestID(`${ page }_breadcrumb`)}
                                onClick={() => breadcrumbClicked(page)}
                                sx={{ color: WebStore.lightMode ? Colors.text : Colors.textLight }}
                            >
                                {page}
                            </Button>
                            <Typography
                                fontSize={12}
                                color={WebStore.lightMode ? Colors.modalTitleLightMode : Colors.modalTitleDarkMode}
                            >
                                •
                            </Typography>
                        </RightRow>
                    ))}
                    <Typography
                        {...getTestID(`${ WebStore.currentPage }_breadcrumb`)}
                        color="primary"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            marginLeft: '16px',
                        }}
                    >
                        {WebStore.currentPage}
                    </Typography>
                </RightRow>
                <RightRow>
                    <Tooltip
                        open={TooltipStore.beginTooltips &&
                        !TooltipStore.showDashboardMessages &&
                        TooltipStore.showLightModeMessages}
                        arrow
                        title={(
                            <TooltipWithButton
                                id="theme_tooltip_button"
                                message={TooltipMessages.THEME}
                                onClick={() => TooltipStore.setShowLightModeMessages(false, true)}
                            />
                        )}
                    >
                        <IconButton
                            {...getTestID('light_dark_mode_button')}
                            onClick={() => WebStore.setLightMode()}
                            sx={iconButton}
                        >
                            {WebStore.lightMode ? <Brightness4/> : <Brightness7/>}
                        </IconButton>
                    </Tooltip>
                    <UserPopup
                        iconButton={iconButton}
                        disabled={props.disableButtons}
                    />
                </RightRow>
            </Root>
        </div>
    );
});

export default Banner;
