/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import TitleTypography from '../common/typography/TitleTypography';
import {IconButton, Skeleton, styled, Tab, Tabs} from '@mui/material';
import {Close} from '@mui/icons-material';
import getTestID from '../../helper/functions/getTestID';
import Constants from '../../helper/Constants';
import getDataById from '../../helper/functions/getDataById';
import FuelLockStore from '../../stores/FuelLockStore';
import EditTankSettings from './editTank/EditTankSettings';
import {TankList} from '../../types/components/ApiTypes';
import EditFlowMeter from './editTank/EditFlowMeter';
import EditVirtualTankMonitoring from './editTank/EditVirtualTankMonitoring';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

interface Props {
    editingIds: { deviceId: number, tankId: number };
    close: () => void;
}

const EditTank = observer((props: Props) => {
    const [ loading, setLoading ] = useState(true);
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ tank, setTank ] = useState<TankList>({
        id: 0,
        fullness: 0,
        flowMeter: null,
        capacity: 0,
        name: '',
        fuelSensor: null,
        recentRecordsByWorkerId: {},
        lowThreshold: null,
    });

    useEffect(() => {
        setLoading(true);
        const device = getDataById(FuelLockStore.systemData.deviceList, props.editingIds.deviceId);
        setTank(JSON.parse(JSON.stringify(getDataById(device.tankList, props.editingIds.tankId))));
        setLoading(false);
    }, [ props.editingIds, FuelLockStore.systemData.deviceList ]);

    const handleChange = (event: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    const renderTab = (id: string, label: string) => (
        <Tab
            {...getTestID(id)}
            label={label}
        />
    );

    const renderTabContent = () => {
        if (loading) {
            return (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                        width: '100%',
                        maxWidth: 'none',
                        height: '250px',
                        borderRadius: '12px',
                    }}
                />
            );
        }

        switch (selectedTab) {
            case 0:
                return (
                    <EditTankSettings
                        editingDeviceId={props.editingIds.deviceId}
                        tank={tank}
                    />
                );
            case 1:
                return (
                    <EditFlowMeter
                        editingDeviceId={props.editingIds.deviceId}
                        tank={tank}
                    />
                );
            case 2:
                return (
                    <EditVirtualTankMonitoring
                        editingDeviceId={props.editingIds.deviceId}
                        tank={tank}
                    />
                );
            default:
                return (<div/>);
        }
    };

    return (
        <Root>
            <Row>
                <TitleTypography title={`Tank Configuration: ${ tank.name }`}/>
                <IconButton
                    {...getTestID('close_edit_tank_button')}
                    onClick={props.close}
                >
                    <Close/>
                </IconButton>
            </Row>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                centered
            >
                {renderTab('tank_settings_tab', Constants.SETTINGS)}
                {renderTab('tank_flow_meter_tab', Constants.FLOW_METER)}
                {renderTab('virtual_tank_monitoring_tab', Constants.VIRTUAL_TANK_MONITORING)}
            </Tabs>
            {renderTabContent()}
        </Root>
    );
});

export default EditTank;
