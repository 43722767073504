import React, {useState} from 'react';
import {styled} from '@mui/material';
import DeviceTable from './tables/DeviceTable';
import TankTable from './tables/TankTable';
import {Device} from '../../types/components/ApiTypes';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

interface Props {
    setEditingDevice: (value: Device) => void;
    setEditingTankId: (value: { deviceId: number, tankId: number }) => void;
}

export default function DeviceOverview(props: Props) {
    const [ deviceSelectionModel, setDeviceSelectionModel ] = useState<number>(-1);
    const [ deviceSelectedName, setDeviceSelectedName ] = useState('');

    return (
        <Root>
            <div
                style={{
                    width: deviceSelectionModel === -1 ? '100%' : '49.5%',
                }}
            >
                <DeviceTable
                    selectionModel={deviceSelectionModel}
                    setSelectionModel={setDeviceSelectionModel}
                    setEditingDevice={props.setEditingDevice}
                    setDeviceSelectedName={setDeviceSelectedName}
                />
            </div>
            {deviceSelectionModel !== -1 && (
                <div
                    style={{width: '49.5%'}}
                    key={deviceSelectionModel}
                >
                    <TankTable
                        selectedDevice={deviceSelectionModel}
                        setEditingTankId={props.setEditingTankId}
                        selectedDeviceName={deviceSelectedName}
                    />
                </div>
            )}
        </Root>
    );
}
