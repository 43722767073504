/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Login from '../login/Login';
import {observer} from 'mobx-react-lite';
import LoginStore from '../../stores/LoginStore';
import FuelLockStore from '../../stores/FuelLockStore';
import ActivateAccount from '../login/activateAccount/ActivateAccount';
import ChangeTemporaryPassword from '../login/changeTemporaryPassword/ChangeTemporaryPassword';
import Desktop from './Desktop';

const Main = observer(() => {
    if (LoginStore.hasTemporaryPassword) {
        return (
            <ChangeTemporaryPassword/>
        );
    }

    if (LoginStore.loggedIn && FuelLockStore.systemData.personalDetails.accountIsActive) {
        return (
            <Desktop/>
        );
    }

    if (LoginStore.loggedIn && !FuelLockStore.systemData.personalDetails.accountIsActive) {
        return (
            <ActivateAccount/>
        );
    }

    return (
        <Login/>
    );
});

export default Main;
