/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {Button, styled, TextField, Typography} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import BaseModal from '../common/modal/BaseModal';
import StyledSwitch from '../common/input/StyledSwitch';
import FuelLockStore from '../../stores/FuelLockStore';
import {ContractorPin, DeviceAccessList, Employee, IGTResponse, SupplyPojo} from '../../types/components/ApiTypes';
import callPutAPI from '../../controllers/callPutAPI';
import callAPI from '../../controllers/callAPI';
import callPostAPI from '../../controllers/callPostAPI';
import determineStartingState from '../../helper/functions/determineStartingState';
import Constants from '../../helper/Constants';
import isPlainText from '../../helper/validator/isPlainText';
import SelectFieldInput from '../common/input/SelectFieldInput';
import cloneDeep from 'lodash/cloneDeep';
import TextFieldInput from '../common/input/TextFieldInput';
import SuccessMessages from '../../helper/SuccessMessages';
import getTestID from '../../helper/functions/getTestID';
import getSelectOptions from '../../helper/functions/getSelectOptions';
import SubmitButton from '../common/SubmitButton';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
    overflow:'scroll',
    height: '100%',
    maxHeight:'65vh',
});

interface Props {
    open: boolean,
    close: () => void,
    editing?: boolean,
    employeeName?: string;
    receiveNotifications?: boolean;
    holiday?: {
        start: number;
        end: number;
    } | null;
    pin?: string | null;
    employeeID?: number;
    devices?: DeviceAccessList[];
    contractorPins?: ContractorPin[];
    hideLinkedDevices?: boolean;
    hideHoliday?: boolean;
    hideContractorPinOptions?: boolean;
    customSubmit?: (newEmployee: { name: string; receiveNotifications: boolean; holiday: null; }) => void;
}

const AddEditEmployeeModal = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const getInitialPowerSupplyAccess = (supplyList: SupplyPojo[]) => {
        if (supplyList.length === 1) {
            return supplyList[0].order;
        }
        if (supplyList.length === 2) {
            return 3;
        }
        return -1;
    };

    const getInitialDeviceState = () => {
        const deviceList = FuelLockStore.systemData.deviceList;

        const data: DeviceAccessList[] = [];
        deviceList.forEach(device => {
            data.push({
                deviceID: device.id,
                deviceName: device.siteName,
                allowedToAccess: true,
                powerSupplyAccess: getInitialPowerSupplyAccess(device.supplyList),
            });
        });

        return data;
    };

    const getPowerSupplyList = () => {
        const deviceList = FuelLockStore.systemData.deviceList;

        let data = {};
        deviceList.forEach(device => {
            let powerSupplyData = {};

            for (let i = 0; i < device.supplyList.length; i += 1) {
                powerSupplyData = {
                    ...powerSupplyData,
                    [device.supplyList[i].order]: device.supplyList[i].name,
                };
            }

            if (device.supplyList.length === 2) {
                powerSupplyData = {
                    ...powerSupplyData,
                    3: Constants.BOTH,
                };
            }

            data = {
                ...data,
                [device.id]: powerSupplyData,
            };
        });

        return data;
    };

    const powerSupplyList = getPowerSupplyList();

    const [ name, setName ] = useState(determineStartingState(props.editing, props.employeeName, ''));
    const [ nameError, setNameError ] = useState('');
    const [ devices, setDevices ] = useState<DeviceAccessList[]>(determineStartingState(props.editing,
        props.devices ? JSON.parse(JSON.stringify(props.devices)) : undefined,
        getInitialDeviceState()));
    const [ addHoliday, setAddHoliday ] = useState(determineStartingState(props.editing,
        props.holiday !== undefined && props.holiday !== null, false));
    const [ vacationStart, setVacationStart ] = useState(determineStartingState(props.editing,
        (props.holiday !== undefined && props.holiday !== null) ? props.holiday.start : null,
        null));
    const [ vacationEnd, setVacationEnd ] = useState(determineStartingState(props.editing,
        (props.holiday !== undefined && props.holiday !== null) ? props.holiday.end : null,
        null));
    const [ vacationStartError, setVacationStartError ] = useState('');
    const [ vacationEndError, setVacationEndError ] = useState('');
    const [ getNotifications, setGetNotifications ] = useState(determineStartingState(props.editing,
        props.receiveNotifications, false));

    const [ contractorPins, setContractorPins ] = useState( props.contractorPins ?? [ {
        id: -1,
        name: '',
        pin: '',
    } ]);

    const [ contractorPinOptions, setContractorPinOptions ] = useState(getSelectOptions(
        FuelLockStore.systemData.contractorPinList,
        'name',
        'id'
    ));

    const determineNextDay = (startDate: Date, next: number) => {
        const nextDay = new Date(startDate);
        nextDay.setDate(nextDay.getDate() + next);
        return nextDay;
    };

    const isValid = () => {
        let valid = true;

        const newNameError = name.trim() !== '' ? isPlainText(name, 1, 'name') : 'Please enter a valid name.';
        setNameError(newNameError);

        if (props.customSubmit === undefined) {
            // TODO: validate and check power supply.
        }

        if (newNameError !== '') {
            valid = false;
        }

        if (addHoliday && vacationStart === null) {
            setVacationStartError('Start date must be selected.');
            valid = false;
        }

        if (addHoliday &&  vacationEnd === null) {
            setVacationEndError('End date must be selected.');
            valid = false;
        }

        return valid;
    };

    const submit = () => {
        if (isValid()) {
            const body = {
                id: -1,
                name,
                receiveNotifications: getNotifications,
                holiday: addHoliday ? {
                    start: new Date(vacationStart).getTime(),
                    end: new Date(vacationEnd).getTime(),
                } : null,
                pin: props.pin ? props.pin : null,
                deviceAccessList: devices,
                contractorPinList: contractorPins,
            };
            if (props.customSubmit) {
                props.customSubmit({
                    name,
                    receiveNotifications: getNotifications,
                    holiday: null,
                });
                props.close();
            } else if (props.editing) {
                if (props.employeeID && props.pin) {
                    body.id = props.employeeID;
                    body.pin = props.pin;
                }

                callPutAPI(`fuellock/v2/worker/${ body.id }`, body, (putWorkerResponse) => {
                    if (putWorkerResponse.status && putWorkerResponse.status === 'success') {
                        setTimeout(() => {
                            callAPI('fuellock/v2/worker', (getWorkersResponse: IGTResponse<Employee[]>) => {
                                if (getWorkersResponse.status === 'success') {
                                    FuelLockStore.setWorkerList(getWorkersResponse.data);
                                    props.close();
                                }
                                FuelLockStore.setAwaitingApi(false);
                            }, enqueueSnackbar);
                        }, 800);
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_EMPLOYEE);
            } else {
                callPostAPI('fuellock/v2/worker', body, (postWorkerResponse: IGTResponse<number>) => {
                    if (postWorkerResponse.status && postWorkerResponse.status === 'success') {
                        callAPI('fuellock/v2/worker', (getWorkersResponse: IGTResponse<Employee[]>) => {
                            FuelLockStore.setAwaitingApi(false);
                            if (getWorkersResponse.status === 'success') {
                                FuelLockStore.setWorkerList(getWorkersResponse.data);
                                props.close();
                            }
                        }, enqueueSnackbar);
                    }
                }, enqueueSnackbar, SuccessMessages.CREATE_EMPLOYEE);
            }
        }
    };

    const renderHoliday = () => (
        <div>
            <StyledSwitch
                id="add_holiday_switch"
                description="Add Holiday"
                value={addHoliday}
                setValue={setAddHoliday}
            />
            {addHoliday && (
                <div>
                    <DatePicker
                        {...getTestID('holiday_start_date')}
                        label="Start Date"
                        value={vacationStart}
                        onChange={(newValue) => {
                            setVacationStart(newValue);
                            setVacationStartError('');
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...getTestID('holiday_start_date_calendar')}
                                fullWidth
                                error={vacationStartError !== ''}
                                helperText={vacationStartError}
                            />
                        )}
                        minDate={moment(new Date())}
                        InputAdornmentProps={{...getTestID('employees_start_date_calendar_button')}}
                    />
                    <DatePicker
                        {...getTestID('holiday_end_date')}
                        label="End Date"
                        value={vacationEnd}
                        onChange={(newValue) => {
                            setVacationEnd(newValue);
                            setVacationEndError('');
                        }}
                        disabled={vacationStart === null}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...getTestID('holiday_end_date_calendar')}
                                fullWidth
                                error={vacationEndError !== ''}
                                helperText={vacationEndError}
                            />
                        )}
                        minDate={moment(determineNextDay(vacationStart, 1))}
                        InputAdornmentProps={{...getTestID('employees_end_date_calendar_button')}}
                    />
                </div>
            )}
        </div>
    );

    const updateDeviceAllowedToAccess = (index: number, value: boolean) => {
        const newData = JSON.parse(JSON.stringify(devices));
        newData[index].allowedToAccess = value;
        setDevices(newData);
    };

    const getPowerSupplyAccessValue = (device: DeviceAccessList) => {
        if (device.powerSupplyAccess === 2) {
            return powerSupplyList[device.deviceID][2];
        }
        if (device.powerSupplyAccess === 3) {
            return Constants.BOTH;
        }
        return powerSupplyList[device.deviceID][1];
    };

    const getPowerSupplyOptions = (device: DeviceAccessList) => {
        const powerSupply = powerSupplyList[device.deviceID];

        const options: { title: any; id: string; }[] = [];

        const keys = Object.keys(powerSupply);

        keys.forEach((key) => {
            options.push({
                title: powerSupply[key],
                id: `${ device.deviceName }_${ key }_power_supply_option`,
            });
        });
        return options;
    };

    const setPowerSupplyFromOptions = (device: DeviceAccessList, text: string, index: number) => {
        let value = -1;
        if (text === Constants.BOTH) {
            value = 3;
        }
        const powerSupply = powerSupplyList[device.deviceID];
        if (text === powerSupply[1]) {
            value = 1;
        } else if (text === powerSupply[2]) {
            value = 2;
        }

        const newData = cloneDeep(devices);
        newData[index].powerSupplyAccess = value;
        setDevices(newData);
    };

    const renderDevicePanel = (device: DeviceAccessList, index: number) => (
        <div key={device.deviceName}>
            <StyledSwitch
                id={`${ device.deviceName }_switch`}
                description={device.deviceName}
                value={device.allowedToAccess}
                setValue={value => updateDeviceAllowedToAccess(index, value)}
            />
            {device.allowedToAccess && (
                <SelectFieldInput
                    id={`${ device.deviceName }_power_supply_slelection`}
                    label="Power supply access"
                    field={{
                        value: getPowerSupplyAccessValue(device),
                        setValue: (text) => setPowerSupplyFromOptions(device, text, index),
                        error: '',
                        setError: () => {},
                    }}
                    options={getPowerSupplyOptions(device)}
                />
            )}
        </div>
    );

    useEffect(() => {
        setContractorPinOptions(getSelectOptions(
            FuelLockStore.systemData.contractorPinList,
            'name',
            'id'
        ));
    }, [ FuelLockStore.systemData.contractorPinList ]);

    const setContractorPinFromOptions = (value: string, index: number) => {
        const newContractorPin = FuelLockStore.systemData.contractorPinList.find( cp => cp.name === value);
        if (newContractorPin) {
            const newData = cloneDeep(JSON.parse(JSON.stringify(contractorPins)));
            newData[index] = newContractorPin;
            setContractorPins(newData);
        }
    };

    const onAddContractorPin = () => {
        if (contractorPins.length < 3) {
            setContractorPins(oldData => {
                const newData = cloneDeep(JSON.parse(JSON.stringify(oldData)));
                newData.push({
                    id: -1,
                    name: '',
                    pin: '',
                });
                return newData;
            });
        }
    };

    const onRemoveContractorPin = (index: number) => {
        setContractorPins(oldData => {
            const newData = cloneDeep(JSON.parse(JSON.stringify(oldData)));
            newData.splice(index, 1);
            return newData;
        });
    };

    const renderContractorPinPanel = (contractorPin: ContractorPin, index: number) => (
        <div
            key={`contractor_pin_${ index }`}
            style={{marginTop: '16px'}}
        >
            <SelectFieldInput
                id={`select_contractor_pin_${ index }`}
                label={Constants.CONTRACTOR_PIN}
                field={{
                    value: contractorPin.name,
                    setValue: (value: string) => setContractorPinFromOptions(value, index),
                    error: '',
                    setError: () => {
                    },
                }}
                options={contractorPinOptions}
            />
            <Button
                {...getTestID(`remove_contractor_pin_${ index }_button`)}
                onClick={() => onRemoveContractorPin(index)}
                sx={{marginTop: '-10px'}}
            >
                Remove { Constants.CONTRACTOR_PIN }
            </Button>
        </div>
    );

    return (
        <BaseModal
            id={props.editing ? 'edit_employee_modal' : 'add_employee_modal'}
            open={props.open}
            close={props.close}
            submit={submit}
            title={props.editing ? 'Edit Employee' : 'Add New Employee'}
            disableSubmitOnAwait
        >
            <Container>
                <TextFieldInput
                    id="employee_name_input"
                    label="Employee Name"
                    field={{
                        value: name,
                        setValue: setName,
                        error: nameError,
                        setError: setNameError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 21,
                        },
                    }}
                />
                {!props.hideLinkedDevices && (
                    <div>
                        <Typography>
                            Linked Devices
                        </Typography>
                        {devices.map((device, index) => renderDevicePanel(device, index))}
                    </div>
                )}

                {props.editing && !props.hideHoliday && renderHoliday()}
                <StyledSwitch
                    id="get_notifications_switch"
                    description="Get notifications"
                    value={getNotifications}
                    setValue={setGetNotifications}
                />
                {FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_CONTRACTOR_PIN &&
                    FuelLockStore.systemData.deviceList.length > 0 &&
                    !props.hideContractorPinOptions && (
                    <div>
                        {contractorPins.map((contractorPin, index) => renderContractorPinPanel(contractorPin, index))}
                        <SubmitButton
                            id='add_new_contractor_pin_button'
                            text={`Add ${ Constants.CONTRACTOR_PIN }`}
                            onClick={onAddContractorPin}
                            disabled={contractorPins.length >= 3}
                        />
                    </div>
                )}
            </Container>
        </BaseModal>
    );
});

export default AddEditEmployeeModal;
