/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import {Divider, styled} from '@mui/material';
import {Add} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import cloneDeep from 'lodash/cloneDeep';
import {useSnackbar} from 'notistack';
import TitleTypography from '../../common/typography/TitleTypography';
import Constants from '../../../helper/Constants';
import CheckboxRow from '../../common/input/CheckboxRow';
import AddEditEquipmentModal from '../../equipment/AddEditEquipmentModal';
import {TrackUnit} from '../../../types/components/ApiTypes';
import callAPI from '../../../controllers/callAPI';
import FuelLockStore from '../../../stores/FuelLockStore';
import getArrayOfAllowedEmployeeOrEquipmentIDs from '../../../helper/functions/getArrayOfAllowedEmployeeOrEquipmentIDs';
import getArrayOfAllowedEmployeeOrEquipmentNewIndices
    from '../../../helper/functions/getArrayOfAllowedEmployeeOrEquipmentNewIndices';
import getTestID from '../../../helper/functions/getTestID';
import SubmitButton from '../../common/SubmitButton';
import callPostAPI from '../../../controllers/callPostAPI';
import LoadingGrid from '../../common/LoadingGrid';

const Center = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
});

const SubmitPadding = styled('div')({
    paddingTop: '16px',
});

interface NewEquipment {
    hwID: number,
    name: string,
    trackUnit: TrackUnit,
}

interface Props {
    allowedEquipmentIDs?: number[],
    setAllowedEquipmentIDs?: (value: number[]) => void,
    allowedEquipmentIndices?: number[],
    setAllowedEquipmentIndices?: (value: number[]) => void,
    newEquipmentList?: NewEquipment[],
    setNewEquipmentList?: (value: {
        hwID: number,
        name: string,
        trackUnit: TrackUnit,
    }[]) => void,
    editingDeviceId?: number,
    width?: string,
    update?: boolean,
}

interface EquipmentChangeList {
    id: number;
    title: string;
    checked: boolean;
    isNew: boolean;
}

const AddEquipmentToDevice = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ open, setOpen ] = useState(false);
    const [ loading, setLoading ] = React.useState(false);
    const [ editing, setEditing ] = React.useState<{ index: number, equipment: NewEquipment } | null>(null);

    const getStartingData = () => {
        const equipmentList = FuelLockStore.systemData.equipmentList;
        const newData: EquipmentChangeList[] = [];

        equipmentList.forEach((equipment: { id: any; name: any; }) => {
            newData.push({
                id: equipment.id,
                title: equipment.name,
                checked: true,
                isNew: false,
            });
        });

        if (props.newEquipmentList) {
            props.newEquipmentList.forEach((equipment, index) => {
                newData.push({
                    id: index * -1,
                    title: equipment.name,
                    checked: true,
                    isNew: true,
                });
            });
        }

        return newData;
    };
    const [ data, setData ] = React.useState(getStartingData());
    const [ priorData, setPriorData ] = React.useState<EquipmentChangeList[]>([]);
    const [ disableButton, setDisableButton ] = React.useState(false);

    const retrieveEquipmentAccessData = () => {
        const equipmentList = FuelLockStore.systemData.equipmentList;
        const newData: { id: number; title: string; checked: boolean; isNew: boolean; }[] = [];

        equipmentList.forEach((equipment: { id: any; name: any; }) => {
            newData.push({
                id: equipment.id,
                title: equipment.name,
                checked: false,
                isNew: false,
            });
        });

        callAPI(`fuellock/v2/equipment/access/${ props.editingDeviceId }`, (receivedData) => {
            for (let i = 0; i < newData.length; i += 1) {
                newData[i].checked = receivedData.data.includes(newData[i].id);
            }
            setLoading(false);
            setData(newData);
            setPriorData(newData);
        }, enqueueSnackbar);
    };

    React.useEffect(() => {
        if (!props.newEquipmentList) {
            setLoading(true);
            retrieveEquipmentAccessData();
        } else {
            setData(getStartingData());
        }
    }, [ FuelLockStore.systemData.equipmentList ]);

    React.useEffect(() => {
        setData(getStartingData());
    }, [ props.newEquipmentList ]);

    const disableGold = () => {
        if (disableButton) {
            return true;
        }
        if (FuelLockStore.awaitingApi) {
            return true;
        }
        if (data.length != priorData.length) {
            return false;
        } else if (data.length == priorData.length) {
            for (let i = 0; i < data.length; i += 1) {
                if (data[i].checked != priorData[i].checked) {
                    return false;
                }
            }
        }
        return true;
    };

    const allSelected = () => {
        let numSelected = 0;
        for (let i = 0; i < data.length; i += 1) {
            if (data[i].checked) {
                numSelected += 1;
            }
        }
        return numSelected === data.length;
    };

    const areAllSelected = allSelected();

    const onSelectUnselectAllEquipment = () => {
        const updateChecked = !areAllSelected;
        setData((oldData) => {
            const newData = cloneDeep(oldData);
            for (let i = 0; i < newData.length; i += 1) {
                newData[i].checked = updateChecked;
            }
            if (props.setAllowedEquipmentIDs) {
                props.setAllowedEquipmentIDs(getArrayOfAllowedEmployeeOrEquipmentIDs(newData));
            }
            if (props.setAllowedEquipmentIndices) {
                props.setAllowedEquipmentIndices(getArrayOfAllowedEmployeeOrEquipmentNewIndices(newData));
            }
            return newData;
        });
    };

    const onCheckChange = (index: number) => {
        setData((oldData) => {
            const newData = cloneDeep(oldData);
            newData[index].checked = !newData[index].checked;
            if (props.setAllowedEquipmentIDs) {
                props.setAllowedEquipmentIDs(getArrayOfAllowedEmployeeOrEquipmentIDs(newData));
            }
            if (props.setAllowedEquipmentIndices) {
                props.setAllowedEquipmentIndices(getArrayOfAllowedEmployeeOrEquipmentNewIndices(newData));
            }
            return newData;
        });
    };

    const closeEditingEquipment = () => {
        setEditing(null);
    };

    const updateNewEquipmentAllowedIndices = () => {
        if (props.setAllowedEquipmentIndices && props.allowedEquipmentIndices) {
            const newData = cloneDeep(props.allowedEquipmentIndices);
            newData.push(newData.length);
            props.setAllowedEquipmentIndices(newData);
        }
    };

    const onNewEquipmentSubmit = (newEquipment: NewEquipment) => {
        updateNewEquipmentAllowedIndices();
        if (props.setNewEquipmentList && props.newEquipmentList) {
            const newData = cloneDeep(props.newEquipmentList);
            newData.push(newEquipment);
            props.setNewEquipmentList(newData);
        }
    };

    const onNewEquipmentEdit = (newEquipment: NewEquipment) => {
        if (props.setNewEquipmentList && props.newEquipmentList && editing) {
            const newData = cloneDeep(props.newEquipmentList);
            newData[editing.index] = newEquipment;
            props.setNewEquipmentList(newData);
            closeEditingEquipment();
        }
    };

    const deleteNewEquipment = (index: number) => {
        if (props.setNewEquipmentList && props.newEquipmentList) {
            const newData = cloneDeep(props.newEquipmentList);
            newData.splice(index, 1);
            props.setNewEquipmentList(newData);
        }
    };

    const determineAddedOrRemovedEquipments = () => {
        const equipmentAccessList: { added: number[], removed: number[] } = {
            added: [],
            removed: [],
        };

        for (let i = 0; i < data.length; i += 1) {
            if (!priorData[i].checked && data[i].checked) {
                equipmentAccessList.added.push(data[i].id);
            } else if (priorData[i].checked && !data[i].checked) {
                equipmentAccessList.removed.push(data[i].id);
            }
        }

        return equipmentAccessList;
    };

    const callRemoveEquipment = (equipmentIds: number[]) => {
        if (equipmentIds.length > 0) {
            const body = {
                intSet: equipmentIds,
            };
            callPostAPI(`fuellock/v2/equipment/access/remove/${ props.editingDeviceId }`, body,
                () => {
                    setPriorData(data);
                    setDisableButton(false);
                }, enqueueSnackbar, 'Successfully removed equipment access');
        } else {
            setPriorData(data);
            setDisableButton(false);
        }
    };

    const onUpdate = () => {
        const accessList = determineAddedOrRemovedEquipments();
        setDisableButton(true);
        if (accessList.added.length > 0) {
            const body = {
                intSet: accessList.added,
            };
            callPostAPI(`fuellock/v2/equipment/access/add/${ props.editingDeviceId }`, body,
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        callRemoveEquipment(accessList.removed);
                        FuelLockStore.setAwaitingApi(false);
                    }
                }, enqueueSnackbar, 'Successfully added equipment access');

        } else {
            callRemoveEquipment(accessList.removed);
        }
    };

    if (loading) {
        return (
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: props.width ? props.width : '75%',
                }}
            >
                <TitleTypography title={Constants.ADD_EQUIPMENT}/>

                <LoadingGrid
                    numColumns={1}
                    numRows={8}
                />
            </Paper>
        );
    }

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: props.width ? props.width : '75%',
            }}
        >
            <TitleTypography title={Constants.ADD_EQUIPMENT}/>

            <Center>
                <Button
                    {...getTestID(areAllSelected ? 'unselect_equipment_button'
                        : 'select_equipment_button')}
                    variant="contained"
                    onClick={onSelectUnselectAllEquipment}
                >
                    {areAllSelected ? 'Unselect All Existing Equipment' : 'Add All Existing Equipment'}
                </Button>
            </Center>

            <TitleTypography title="Existing Equipment"/>
            <Divider/>

            {data.map((equipment, index) => (
                <CheckboxRow
                    key={`${ equipment.title }_${ equipment.checked }`}
                    title={equipment.title}
                    checked={equipment.checked}
                    onChange={() => onCheckChange(index)}
                    isNew={equipment.isNew}
                    onEdit={() => {
                        if (props.newEquipmentList) {
                            const equipmentIndex = equipment.id * -1;
                            setEditing({
                                index: equipmentIndex,
                                equipment: props.newEquipmentList[equipmentIndex],
                            });
                        }
                    }}
                    onDelete={() => deleteNewEquipment(equipment.id * -1)}
                />
            ))}

            {props.update && (
                <SubmitPadding>
                    <SubmitButton
                        id="update_equipment_access_button"
                        text="Update Equipment Access"
                        onClick={onUpdate}
                        disabled={disableGold()}
                    />
                </SubmitPadding>
            )}
            {!props.update && (
                <Fab
                    {...getTestID('add_new_equipment_button')}
                    color="primary"
                    variant="extended"
                    onClick={() => setOpen(true)}
                    sx={{position: 'fixed'}}
                >
                    <Add/>
                    Equipment
                </Fab>)}
            {open && (
                <AddEditEquipmentModal
                    open
                    close={() => setOpen(false)}
                    customSubmit={onNewEquipmentSubmit}
                    newEquipmentList={props.newEquipmentList}
                />
            )}

            {editing && (
                <AddEditEquipmentModal
                    open
                    close={closeEditingEquipment}
                    isEdit
                    customSubmit={onNewEquipmentEdit}
                    newEquipmentList={props.newEquipmentList}
                    editingEquipment={editing.equipment}
                    editingIndex={editing.index}
                />
            )}
        </Paper>
    );
});

export default AddEquipmentToDevice;
