/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import {Device} from '../../../types/components/ApiTypes';
import AddNewDevice from '../wizard/AddNewDevice';
import isStringNotEmpty from '../../../helper/validator/isStringNotEmpty';
import isNumericInRange from '../../../helper/validator/isNumericInRange';
import callPutAPI from '../../../controllers/callPutAPI';
import callAPI from '../../../controllers/callAPI';
import FuelLockStore from '../../../stores/FuelLockStore';
import SuccessMessages from '../../../helper/SuccessMessages';

interface Props {
    editingDevice: Device;
}

const EditDevicePaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ name, setName ] = useState(props.editingDevice.siteName);
    const [ nameError, setNameError ] = useState('');
    const [ autoLockTime, setAutoLockTime ] = useState(props.editingDevice.autolockTime.toString());
    const [ autoLockTimeError, setAutoLockTimeError ] = useState('');

    const isValid = () => {
        let valid = true;

        const newNameError = isStringNotEmpty(name, 'name');
        setNameError(newNameError);

        const newAutoLockTimeError = isNumericInRange(autoLockTime, 5, 60, 0,
            'auto lock time');
        setAutoLockTimeError(newAutoLockTimeError);

        if (newNameError !== '' || newAutoLockTimeError !== '') {
            valid = false;
        }

        return valid;
    };

    const onUpdate = () => {
        if (isValid()) {
            const body = cloneDeep(props.editingDevice);
            body.siteName = name;
            body.autolockTime = Number(autoLockTime);

            callPutAPI(`fuellock/v2/device/${ props.editingDevice.id }`, body,
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        setTimeout(() => {
                            callAPI('fuellock/v2/dashboard', (receivedDashboardData) => {
                                if (receivedDashboardData.status === 'success') {
                                    FuelLockStore.setSystemData(receivedDashboardData.data);
                                }
                            }, enqueueSnackbar);
                        }, 700);
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_DEVICE);
        }
    };

    return (
        <AddNewDevice
            deviceName={{
                value: name,
                setValue: setName,
                error: nameError,
                setError: setNameError,
            }}
            serialNumber={{
                value: props.editingDevice.sn,
                setValue: () => {
                },
                error: '',
                setError: () => {
                },
            }}
            autoLockTime={{
                value: autoLockTime,
                setValue: setAutoLockTime,
                error: autoLockTimeError,
                setError: setAutoLockTimeError,
            }}
            serialNumberDisabled
            hideTitle
            onUpdate={onUpdate}
            width="auto"
        />
    );
});

export default EditDevicePaper;
