/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, { useState } from 'react';
import { styled, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import SubmitButton from '../../common/SubmitButton';
import FuelLockStore from '../../../stores/FuelLockStore';
import SuccessMessages from '../../../helper/SuccessMessages';
import TankLevelPercentSlider from '../../devices/tanks/TankLevelPercentSlider';
import { TankList } from '../../../types/components/ApiTypes';
import callPutAPI from '../../../controllers/callPutAPI';
import ErrorMessages from '../../../helper/ErrorMessages';
import { useEffect } from 'react';
import callAPI from '../../../controllers/callAPI';
import MemoTypography from '../../common/typography/MemoTypography';
import StyledSwitch from '../../common/input/StyledSwitch';
import TextFieldInput from '../../common/input/TextFieldInput';
import isNumericInRange from '../../../helper/validator/isNumericInRange';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    selectedDeviceId: number,
    selectedDeviceName: string,
    monitoredTanks: TankList[],
}
const Section = styled('div')({
    textAlign: 'center',
});

const TBody = styled('tbody')({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Row = styled('tr')({
    textAlign: 'center',
    width: '100%',
});

const StyledTypography = styled(Typography)(() => ({
    display: 'inline-block',
    fontSize: '14px',
    paddingTop: 20,

}));

const VirtualTankMonitoringPanel = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ tanks, setTanks ] = useState<TankList[]>([]);
    const [ updatedTanks, setUpdatedTanks ] = useState<TankList[]>([]);
    const [ lowAlert, setLowAlert ] = useState(false);
    const [ lowThreshold, setLowThreshold ] = useState('');
    const [ lowThresholdError, setLowThresholdError ] = useState('');
    const [ updateAlert, setUpdateAlert ] = useState(false);

    const isValid = () => {
        let valid = true;

        if (lowAlert) {
            const newLowThresholdError = isNumericInRange(lowThreshold, 0, 100, 0,
                'low threshold');
                setLowThresholdError(newLowThresholdError);

            if (newLowThresholdError !== '') {
                valid = false;
            }
        }
        return valid;
    };

    useEffect(() => {
        if (props.monitoredTanks) {
            setTanks(props.monitoredTanks);
        }

        //currently, all tanks of the same device will have the same lowThreshold
        if (props.monitoredTanks[0].lowThreshold && props.monitoredTanks[0].lowThreshold !== null) {
            setLowAlert(true);
            setLowThreshold(props.monitoredTanks[0].lowThreshold.toString());
        }
        else {
            setLowAlert(false);
            setLowThreshold('');
        }

    }, [ props.monitoredTanks, props.selectedDeviceId ]);

    const saveChangeButtonOnClick = () => {
        if (isValid()) {
            if (!lowAlert) {
                if (props.monitoredTanks[0].lowThreshold !== null) {
                    setUpdateAlert(true);
                }
            }
            else {
                if (props.monitoredTanks[0].lowThreshold === null ||
                    lowThreshold !== props.monitoredTanks[0].lowThreshold.toString()) {
                    setUpdateAlert(true);
                }
            }

            setUpdatedTanks(tanks);
        }
    };

    useEffect(() => {
        if ((updatedTanks !== null && updatedTanks.length > 0) || updateAlert) {
            let tankCount = 0;
            updatedTanks.forEach((updatedTank: TankList) => {
                tankCount++;
                const oldData = FuelLockStore.systemData.deviceList;
                const deviceIndex = FuelLockStore.systemData.deviceList.findIndex(a =>
                    a.id === props.selectedDeviceId);
                const tankIndex = FuelLockStore.systemData.deviceList[deviceIndex].tankList.findIndex(a =>
                    a.id === updatedTank.id);

                if (updatedTank.fullness !== oldData[deviceIndex].tankList[tankIndex].fullness || updateAlert) {
                    if (updateAlert) {
                        if (!lowAlert) {
                            updatedTank.lowThreshold = null;
                        }
                        else {
                            updatedTank.lowThreshold = Number(lowThreshold);
                        }
                    }

                    const updatedTankJSON = JSON.parse(JSON.stringify(updatedTank));

                    callPutAPI(`fuellock/v2/tank/${ updatedTankJSON.id }`, updatedTankJSON, (receivedServerData) => {

                        if (receivedServerData.status && receivedServerData.status !== 'success') {
                            FuelLockStore.setError(ErrorMessages.UNEXPECTED);
                        }
                        if (tankCount === updatedTanks.length) {
                            setTimeout(() => {
                                callAPI('fuellock/v2/dashboard', (receivedDashboardServerData) => {
                                    FuelLockStore.setSystemData(receivedDashboardServerData.data);
                                    if (updateAlert) {
                                        setUpdateAlert(false);
                                    }
                                }, enqueueSnackbar);
                            }, 300);
                        }
                    }, enqueueSnackbar, ` ${ SuccessMessages.UPDATE_TANK }: ${ updatedTankJSON.name }`);
                }
            });
        }

    }, [ updatedTanks, updateAlert ]);

    const setNewTankFullnessHelper = (tankId: number, newFullness: number) => {
        setTanks([ ...tanks.map((iTank) =>
            iTank.id === tankId ? { ...iTank,
                fullness: newFullness }
                : {...iTank}),
        ]);
    };

    const renderVirtualTankMonitoringPanel = () => {
        const TankMonitoringPanel: any[] = [];

        for (let i = 0; i < tanks.length; i++) {
            const setNewTankFullness = (value: number) => {
                setNewTankFullnessHelper(tanks[i].id, value);
            };

            TankMonitoringPanel.push(
                <Row key={tanks[i].name}>
                    <td
                        width='30%'
                    >
                        <StyledTypography
                            {...getTestID(`tag_${ tanks[i].name }_tank_monitoring`)}
                        >
                            {tanks[i].name}
                        </StyledTypography>
                    </td>
                    <td
                        width='70%'
                    >
                        <TankLevelPercentSlider
                            {...getTestID(`tag_${ tanks[i].name }_slider`)}
                            name={tanks[i].name}
                            value={tanks[i].fullness !== null ? tanks[i].fullness! : 0 }
                            setValue={setNewTankFullness}
                        />
                    </td>
                </Row>
            );
        }
        return TankMonitoringPanel;
    };

    return (
        <Section>
            <table
                width={'100%'}
            >
                <TBody>
                    {renderVirtualTankMonitoringPanel()}
                </TBody>
            </table>
            <MemoTypography
                memo="Use these sliders to reset your tank levels after tanks get refilled."
            />
            <div
                style={{
                    display: 'flex',
                    marginTop: 20,
                }}
            >
                <span
                    style={{
                        display: 'inline-block',
                    }}
                >
                    <StyledSwitch
                        id="low_alert_switch"
                        description=""
                        value={lowAlert}
                        setValue={setLowAlert}
                    />
                </span>
                <span
                    style={{
                        display: 'inline-block',
                        minWidth: '50%',
                    }}
                >
                    <TextFieldInput
                        id="low_threshold_input"
                        label={'Low Alert (%)'}
                        field={{
                            value: lowAlert ? lowThreshold : '',
                            setValue: setLowThreshold,
                            error: lowThresholdError,
                            setError: setLowThresholdError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                maxLength: 2,
                            },
                        }}
                    />
                </span>
            </div>

            <SubmitButton
                id="reset_tank_levels_button"
                text="Save Changes"
                onClick={saveChangeButtonOnClick}
                disabled={FuelLockStore.awaitingApi}
            />
        </Section>
    );
});

export default VirtualTankMonitoringPanel;
