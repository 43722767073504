/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export interface Employee {
    id: number,
    name: string,
    receiveNotifications: boolean,
    holiday?: {
        start: number,
        end: number,
    } | null,
    pin: string | null,
    deviceAccessList: DeviceAccessList[],
    contractorPinList: ContractorPin[],
    admin: boolean,
}

export interface ContractorPin {
    id: number,
    name: string,
    pin: string | null,
}

export interface ContractorPinSettings {
    deviceId: number,
    enabled: boolean,
    mandatoryEntry: boolean,
}

export interface DeviceAccessList {
    deviceID: number,
    deviceName: string,
    allowedToAccess: boolean,
    powerSupplyAccess: number,
}

export enum TrackUnit {
    km = 'KILOMETERS',
    miles = 'MILES',
    hours = 'HOURS',
}

export interface Equipment {
    id: number,
    hwID: number,
    name: string,
    trackUnit: TrackUnit,
}

export interface SystemData {
    customField: CustomFieldPojo,
    minFirmwareVersion: number,
    deviceList: Device[],
    workerList: Employee[],
    equipmentList: Equipment[],
    contractorPinList: ContractorPin[],
    flowMeterTypeList: FlowMeterType[],
    personalDetails: PersonalDetails,
    preferences: Preferences,
    tankTypes: TankType[]
    densityList: Density[],
    liquidUnitList: LiquidUnit[],
    otodataKey: string | null,
}

export interface Device {
    id: number,
    lastSync: number,
    siteName: string,
    sn: string,
    supplyList: SupplyList[],
    tankList: TankList[],
    autolockTime: number,
    activeHours: ActiveHours,
    contractorPinSettings: ContractorPinSettings,
    contractorPinList: ContractorPin[],
    smartLockTime?: number,
}

export interface SupplyList {
    id: number,
    name: string,
    order: number,
}

export interface TankList {
    id: number,
    fullness: number | null,
    name: string,
    flowMeter: FlowMeter | null,
    capacity: number,
    fuelSensor: LevelSensor | null,
    recentRecordsByWorkerId: object,
    lowThreshold: number | null,
}

export interface FlowMeter {
    id: number,
    flowMeterTypeId: number,
    flowMeterSlot: number,
    name: string,
    litreLimit: number,
    custom: boolean
}

export interface LevelSensor {
    id: number,
    serial: string,
    tankHeight: number,
    density: string,
    tankType: string,
    calibrateable: boolean,
    brand: string,
    sensorType: string,
    portNumber: number,
    accountType: string,
}

export interface ActiveHours {
    mondayHours: AvailableHours,
    tuesdayHours: AvailableHours,
    wednesdayHours: AvailableHours,
    thursdayHours: AvailableHours,
    fridayHours: AvailableHours,
    saturdayHours: AvailableHours,
    sundayHours: AvailableHours,
    inactiveDays: InActiveDay[],
}

export interface AvailableHours {
    id: number,
    start: string,
    end: string,
}

export interface InActiveDay {
    id?: number,
    blockDay: string,
    deleting: boolean,
}

export interface FlowMeterType {
    id: number,
    name: string,
    custom: boolean,
    pulsesPerLitre: number
}

export interface PersonalDetails {
    customerID: number,
    firstName: string,
    lastName: string,
    companyName: string,
    displayName: string,
    primaryPhone: string,
    postalCode: string,
    address: string,
    city: string,
    country: string,
    province: string,
    subscribedToEmails: boolean,
    accountIsActive: boolean,
    accountLockedOut: boolean,
    termsOfServiceAccepted: boolean,
}

export interface Preferences {
    liquidUnitId: number,
    equipmentEntryMandatory: boolean,
    odometerEntryMandatory: boolean,
}

export interface TankType {
    key: string,
    value: string,
}

export interface Density {
    id: string,
    name: string
}

export interface LiquidUnit {
    id: string,
    name: string,
}

export interface Phone {
    id: number,
    countryCode: string,
    areaCode: string,
    phone: string,
    phoneType: string,
}

export enum Theme {
    dark = 'dark',
    light = 'light',
}

export enum XAxisType {
    dateTime = 'datetime',
    numeric = 'numeric',
    category = 'category',
}

export interface IGTResponse<Type> {
    message: string,
    displayMessage: boolean,
    status: string,
    data: Type,
    dataType: string
    messageTitle?: string,
}

export interface InputFieldProps {
    value: string,
    setValue: (newValue: any) => void,
    error: string,
    setError: (newError: string) => void,
}

export interface ActiveHoursProps {
    value: string,
    setValue: (newValue: string) => void,
}

export interface ValidateFieldProps {
    value: string,
    setError: (newError: string) => void,
}

export interface SupplyPojo {
    id: number,
    name: string,
    order: number,
}

export interface TankPojo {
    id: number,
    fullness: number | null,
    name: string,
    flowMeter: FlowMeter | null,
    capacity: number,
    fuelSensor: LevelSensor | null,
    recentRecordsByWorkerId: object,
    lowThreshold: number | null,
}

//the refresh webservice ends up sending the entity class. We need like, 2 of these properties, but this whole object is sent and was cast to type "any" for some reason.
export interface OtoDataDeviceEntity {
    id: number;
    tankId: number;
    lastLevel: number;
    lastRead: Date;
    serialNumber: string;
    email: string;
    capacity: number;
    name: string;
    status: string;
    type: string;
    lowAlarmThreshold: number;
    criticalLowAlarmThreshold: number;
    latitude: number;
    longitude: number;
    tankFormType: number;
    tankNumber: string;
    temperature: number;
    note: string;
    offset: number;
    product: string;
    readingTrouble: string;
    sensorTrouble: number;
    signalStrength: number;
    lastTwentyfourHour: number;
    lastFortyeightHour: number;
    lastSeventytwoHour: number;
    hoursToEmpty: number;
    highDrainAlarm: string;
    correctedVolume: number;
    batteryAlarm: string;
    batteryLevel: number;
    userId: number;
    measureHeight: number;
}

export interface BalancePojo {
    balance: number;
    currency: string;
    activationDate: number | null;
    expiryDate: number | null;
    dueDate: number;
}

export interface UsagePojo {
    authorized: boolean;
    deviceId: number;
    equipmentId: number;
    litres: number;
    odometerReading: number | null;
    tankId: number;
    transactionEndDate: Date;
    transactionStartDate: Date;
    workerId: number;
}

export interface ProcessedUsagePojo {
    authorized: boolean;
    device: string;
    equipment: string;
    litres: number;
    odometerReading: number | null;
    tank: string;
    transactionEndDate: Date;
    transactionStartDate: Date;
    worker: string;
}

export interface CustomFieldPojo {
    enabled: boolean;
    name: string;
    mandatoryEntry: boolean;
}