/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import ChangePinModal from '../../changePin/ChangePinModal';
import {useSnackbar} from 'notistack';
import callAPI from '../../../controllers/callAPI';
import callPostAPI from '../../../controllers/callPostAPI';
import SuccessMessages from '../../../helper/SuccessMessages';
import { isNumericPIN } from '../../../helper/validator/isNumeric';

interface Props {
    open: boolean,
    close: () => void,
}

function ChangeAdminPinModal(props: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const [ loading, setLoading ] = React.useState(true);
    const [ currentPin, setCurrentPin ] = React.useState('');

    React.useEffect(() => {
        callAPI('fuellock/v2/adminPin', (receivedData) => {
            setCurrentPin(receivedData.data);
            setLoading(false);
        }, enqueueSnackbar);
    }, []);

    const isValid = (pin: string) => {
        let valid = true;

        let pinError = isNumericPIN(pin, 4, 6);
        if (pinError !== '') {
            valid = false;
        } else if (currentPin === pin) {
            valid = false;
            pinError = 'You used this PIN recently. Please choose a different one';
        }
        if (pinError !== '') {
            enqueueSnackbar(pinError, {variant: 'error'});
        }

        return valid;
    };

    const submit = (pin: string) => {
        if (isValid(pin)) {
            callPostAPI('fuellock/v1/changeAdminPin',
                {newPin: pin},
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        props.close();
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_PIN);
        }
    };

    return (
        <ChangePinModal
            open={props.open}
            close={props.close}
            submit={submit}
            currentPin={currentPin}
            loading={loading}
        />
    );
}

export default ChangeAdminPinModal;
