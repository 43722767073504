/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled, Tooltip} from '@mui/material';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import SubmitButton from '../../common/SubmitButton';
import StyledSwitch from '../../common/input/StyledSwitch';
import getDataById from '../../../helper/functions/getDataById';
import FuelLockStore from '../../../stores/FuelLockStore';
import callPostAPI from '../../../controllers/callPostAPI';
import getSelectOptions from '../../../helper/functions/getSelectOptions';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import SuccessMessages from '../../../helper/SuccessMessages';
import TooltipWithButton from '../../common/TooltipWithButton';
import TooltipStore from '../../../stores/TooltipStore';
import TooltipMessages from '../../../helper/TooltipMessages';
import callAPI from '../../../controllers/callAPI';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const AccountPreferencesPanel = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const unit = getDataById(FuelLockStore.systemData.liquidUnitList,
        FuelLockStore.systemData.preferences.liquidUnitId);
    const [ volumeUnits, setUnits ] = useState(unit.name);
    const [ equipmentMandatory, setEquipmentMandatory ] = useState(FuelLockStore.systemData.preferences
        .equipmentEntryMandatory);
    const [ odometerMandatory, setOdometerMandatory ] = useState(FuelLockStore.systemData.preferences
        .odometerEntryMandatory);

    const getOptions = () => getSelectOptions(
        FuelLockStore.systemData.liquidUnitList,
        'name',
        'volume_units'
    );

    const onSave = () => {
        const volumeUnitID = FuelLockStore.systemData.liquidUnitList.find(volumeUnit =>
            volumeUnit.name === volumeUnits);

        if (volumeUnitID) {
            const body = {
                liquidUnitId: Number(volumeUnitID.id),
                equipmentEntryMandatory: equipmentMandatory,
                odometerEntryMandatory: odometerMandatory,
            };
            const volumeUnitChange = body.liquidUnitId !== FuelLockStore.systemData.preferences.liquidUnitId;
            callPostAPI('fuellock/v2/updateAccountPreferences',
                body,
                (receivedData) => {
                    if (receivedData.status === 'success') {
                        if (volumeUnitChange) {
                            //update dashboard data to get tank capacity in new volume unit
                            callAPI('fuellock/v2/dashboard', (receivedServerData) => {
                                FuelLockStore.setSystemData(receivedServerData.data);
                            }, enqueueSnackbar);
                        } else {
                            //@ts-ignore
                            FuelLockStore.setPreferences(body);
                        }
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_ACCOUNT_PREFERENCES
            );
        }
    };

    const selectHelperText = 'Changing this setting will affect volumes for your alerts, reports, and app display ' +
        'information';

    return (
        <Paper>
            <Root>
                <SelectFieldInput
                    id="select_volume_units"
                    label="Select volume units"
                    field={{
                        value: volumeUnits,
                        setValue: setUnits,
                        error: '',
                        setError: () => {
                        },
                    }}
                    options={getOptions()}
                    helperText={selectHelperText}
                />

                <Tooltip
                    open={TooltipStore.beginTooltips &&
                    !TooltipStore.showDashboardMessages &&
                    !TooltipStore.showLightModeMessages &&
                    TooltipStore.showAccountPreferencesMessages}
                    arrow
                    title={(
                        <TooltipWithButton
                            id="account_preferences_tooltip_button"
                            message={TooltipMessages.ODOMETER}
                            onClick={() => TooltipStore.setShowAccountPreferencesMessages(false, true)}
                        />
                    )}
                >
                    <div>
                        <StyledSwitch
                            id="equipment_entry_mandatory_switch"
                            description="Equipment Entry Mandatory"
                            value={equipmentMandatory}
                            setValue={setEquipmentMandatory}
                        />
                        <StyledSwitch
                            id="odometer_entry_mandatory_switch"
                            description="Odometer Entry Mandatory"
                            value={odometerMandatory}
                            setValue={setOdometerMandatory}
                        />
                    </div>
                </Tooltip>

                <SubmitButton
                    id="save_account_preferences_button"
                    text="Save Account Preferences"
                    onClick={onSave}
                />
            </Root>
        </Paper>
    );
});

export default AccountPreferencesPanel;
