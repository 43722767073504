/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import {useSnackbar} from 'notistack';
import BaseModal from '../../common/modal/BaseModal';
import isPasswordValid from '../../../helper/validator/isPasswordValid';
import callPostAPI from '../../../controllers/callPostAPI';
import SuccessMessages from '../../../helper/SuccessMessages';
import PasswordField from '../../common/input/PasswordField';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
}

function ChangeAccountPasswordModal(props: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const [ oldPassword, setOldPassword ] = React.useState('');
    const [ oldPasswordError, setOldPasswordError ] = React.useState('');
    const [ newPassword, setNewPassword ] = React.useState('');
    const [ newPasswordError, setNewPasswordError ] = React.useState('');
    const [ retypeNewPassword, setRetypeNewPassword ] = React.useState('');
    const [ retypePasswordError, setRetypePasswordError ] = React.useState('');

    const isValid = () => {
        let valid = true;

        const oldPassError = isPasswordValid(oldPassword);
        setOldPasswordError(oldPassError);
        const passwordError = isPasswordValid(newPassword);
        setNewPasswordError(passwordError);
        const newRetypePasswordError = isPasswordValid(retypeNewPassword);
        setRetypePasswordError(newRetypePasswordError);

        if (newPassword !== '' && retypeNewPassword !== '' && newPassword !== retypeNewPassword) {
            setNewPasswordError('Password fields do not match');
            setRetypePasswordError('Password fields do not match');
            valid = false;
        }

        if (newPassword !== '' && oldPassword !== '' && newPassword === oldPassword) {
            setNewPasswordError('You used this password recently. Please choose a different one');
            valid = false;
        }

        if (retypeNewPassword !== '' && oldPassword !== '' && retypeNewPassword === oldPassword) {
            setRetypePasswordError('You used this password recently. Please choose a different one');
            valid = false;
        }

        if (oldPassError !== '' || newPasswordError !== '' || newRetypePasswordError !== '') {
            valid = false;
        }

        return valid;
    };

    const submit = () => {
        if (isValid()) {
            callPostAPI('fuellock/v2/changePassword',
                {
                    oldPassword,
                    newPassword,
                },
                (receivedData) => {
                    if (receivedData.status === 'success') {
                        props.close();
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_PASSWORD);
        }
    };

    return (
        <BaseModal
            id="change_account_password_modal"
            open={props.open}
            close={props.close}
            submit={submit}
            title="Change Account Password"
            disableSubmitOnAwait
        >
            <Container>
                <PasswordField
                    id="old_password_input"
                    label="Old Password"
                    field={{
                        value: oldPassword,
                        setValue: setOldPassword,
                        error: oldPasswordError,
                        setError: setOldPasswordError,
                    }}
                />
                <PasswordField
                    id="new_password_input"
                    label="New Password"
                    field={{
                        value: newPassword,
                        setValue: setNewPassword,
                        error: newPasswordError,
                        setError: setNewPasswordError,
                    }}
                />
                <PasswordField
                    id="retype_new_password_input"
                    label="Retype New Password"
                    field={{
                        value: retypeNewPassword,
                        setValue: setRetypeNewPassword,
                        error: retypePasswordError,
                        setError: setRetypePasswordError,
                    }}
                />
            </Container>
        </BaseModal>
    );
}

export default ChangeAccountPasswordModal;
