/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import SubmitButton from '../../common/SubmitButton';
import Paper from '@mui/material/Paper';
import getTestID from '../../../helper/functions/getTestID';
import {observer} from 'mobx-react-lite';
import FuelLockStore from '../../../stores/FuelLockStore';

const Title = styled('div')({
    padding: '16px',
    borderRadiusTopRight: '5px',
    borderRadiusTopLeft: '5px',
    paddingBottom: '0px',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

interface Props {
    id: string,
    open: boolean,
    close?: () => void,
    title: string,
    children: object,
    submit: () => void,
    submitButtonText?: string,
    cancelButtonText?: string,
    onCancel?: () => void,
    disableSubmitOnAwait?: boolean,
    disabledButton?: boolean,
}

const BaseModal = observer((props: Props) => (
    <Modal
            open={props.open}
            onClose={props.close}
    >
        <Paper
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    outline: 'none',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: 0,
                    margin:0,
                }}
        >
            <Title>
                <Typography
                    {...getTestID('popup_title')}
                    color="inherit"
                >
                    {props.title}
                </Typography>
            </Title>
            {props.children}
            <ButtonRow>
                {props.close
                ?   (
                    <Button
                        {...getTestID(`${ props.id }_cancel_button`)}
                        color="secondary"
                        sx={{marginRight: '16px'}}
                        onClick={props.onCancel ? props.onCancel : props.close}
                    >
                        {props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
                    </Button>
                    ) : null }
                <SubmitButton
                        id={`${ props.id }_submit_button`}
                        text={props.submitButtonText ? props.submitButtonText : 'Submit'}
                        onClick={props.submit}
                        disabled={(props.disableSubmitOnAwait && FuelLockStore.awaitingApi) || props.disabledButton}
                />
            </ButtonRow>
        </Paper>
    </Modal>
    ));

export default BaseModal;
