/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import ContentPanel from './ContentPanel';
import EmployeeTable from '../employees/EmployeeTable';
import AddEditEmployeeModal from '../employees/AddEditEmployeeModal';
import TooltipStore from '../../stores/TooltipStore';
import {observer} from 'mobx-react-lite';
import TooltipAddFab from '../common/TooltipAddFab';
import TooltipMessages from '../../helper/TooltipMessages';

const Root = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    height: '100%',
});

const Employees = observer(() => {
    const [ addOpen, setAddOpen ] = useState(false);

    return (
        <ContentPanel>
            <div>
                <Root>
                    <EmployeeTable/>
                </Root>
                <TooltipAddFab
                    tooltipId="add_new_employees_tooltip_button"
                    tooltipOpen={TooltipStore.showAddEmployeeMessages}
                    tooltipTitle={TooltipMessages.ADD_EMPLOYEE}
                    closeTooltip={() => TooltipStore.setShowAddEmployeeMessages(false, true)}
                    fabId="add_new_employee_button"
                    onFabClick={() => setAddOpen(true)}
                    fabTitle="Employee"
                />
                {addOpen && (
                    <AddEditEmployeeModal
                        open={addOpen}
                        close={() => setAddOpen(false)}
                    />
                )}
            </div>
        </ContentPanel>
    );
});

export default Employees;
