/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Tooltip} from '@mui/material';
import Fab from '@mui/material/Fab';
import {Add} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import getTestID from '../../helper/functions/getTestID';
import TooltipStore from '../../stores/TooltipStore';
import TooltipWithButton from '../common/TooltipWithButton';

interface Props {
    tooltipId: string,
    tooltipOpen: boolean,
    tooltipTitle: string,
    closeTooltip: () => void,
    fabId: string,
    onFabClick: () => void,
    fabTitle: string,
}

const TooltipAddFab = observer((props: Props) => (
    <Tooltip
            open={TooltipStore.beginTooltips &&
            !TooltipStore.showDashboardMessages &&
            !TooltipStore.showLightModeMessages &&
            props.tooltipOpen}
            arrow
            title={(
                <TooltipWithButton
                    id={props.tooltipId}
                    message={props.tooltipTitle}
                    onClick={props.closeTooltip}
                />
            )}
    >
        <Fab
                {...getTestID(props.fabId)}
                color="primary"
                variant="extended"
                onClick={props.onFabClick}
        >
            <Add/>
            {props.fabTitle}
        </Fab>
    </Tooltip>
    ));

export default TooltipAddFab;
