/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {DataGridPro, GridRowsProp, GridColDef, GridRowId} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import {Add, Delete, Edit} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {observer} from 'mobx-react-lite';
import cloneDeep from 'lodash/cloneDeep';
import {useSnackbar} from 'notistack';
import TitleTypography from '../../common/typography/TitleTypography';
import DeleteModal from '../../common/modal/DeleteModal';
import AddTankModal from '../tanks/AddTankModal';
import FuelLockStore from '../../../stores/FuelLockStore';
import callDeleteAPI from '../../../controllers/callDeleteAPI';
import getTestID from '../../../helper/functions/getTestID';
import SuccessMessages from '../../../helper/SuccessMessages';
import {Device} from '../../../types/components/ApiTypes';
import getDataById from '../../../helper/functions/getDataById';
import {styled, Typography} from '@mui/material';
import Constants from '../../../helper/Constants';

interface Props {
    selectedDevice: GridRowId;
    setEditingTankId: (value: { deviceId: number, tankId: number }) => void;
    selectedDeviceName: string;
}

const StyledTypography = styled(Typography)(() => ({
    fontSize: '14px',
}));

const TankTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ newTankOpen, setNewTankOpen ] = useState(false);

    const determineTankData = () => {
        const devices = FuelLockStore.systemData.deviceList;
        const tankData: any[] = [];

        for (let i = 0; i < devices.length; i++) {
            const device = devices[i];
            if (device.id === props.selectedDevice) {
                device.tankList.forEach((tank: any) => {
                    const flowMeter = tank.flowMeter && getDataById(FuelLockStore.systemData.flowMeterTypeList,
                        tank.flowMeter.flowMeterTypeId);
                    tankData.push({
                        id: tank.id,
                        name: tank.name,
                        capacity: tank.capacity,
                        flowMeterTypeId: tank.flowMeter ? flowMeter.name : null,
                    });
                });

                break;
            }
        }
        return tankData;
    };

    const [ rows, setRows ] = useState<GridRowsProp>([]);
    const [ remove, setRemove ] = useState<{ id: number | null, name: string }>({
        id: null,
        name: '',
    });

    useEffect(() => {
        const newData = determineTankData();
        setRows(newData);
    }, [ FuelLockStore.systemData.deviceList, props.selectedDevice, newTankOpen ]);

    const onDeleteClick = (id: number, name: string) => {
        setRemove({
            id,
            name,
        });
    };

    const onCloseDelete = () => {
        setRemove({
            id: null,
            name: '',
        });
    };

    const onDelete = () => {
        if (remove) {
            callDeleteAPI(`fuellock/v2/tank/${ remove.id }`,
                (receivedServerData) => {
                    if (receivedServerData.status === 'success') {
                        const oldData: Device[] = JSON.parse(JSON.stringify(FuelLockStore.systemData.deviceList));
                        const deviceIndex = oldData.findIndex(item => item.id === Number(props.selectedDevice));
                        const index = oldData[deviceIndex].tankList.findIndex(item => item.id === remove.id);
                        const newData = cloneDeep(oldData);
                        newData[deviceIndex].tankList.splice(index, 1);
                        FuelLockStore.setDeviceData(newData);
                    }
                }, enqueueSnackbar, SuccessMessages.DELETE_TANK);
            onCloseDelete();
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            disableReorder: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
                >
                    <IconButton
                        {...getTestID(`${ props.selectedDeviceName }_${ params.row.name }_edit`)}
                        color="secondary"
                        onClick={() => props.setEditingTankId({
                            deviceId: Number(props.selectedDevice),
                            tankId: params.row.id,
                        })}
                    >
                        <Edit/>
                    </IconButton>
                    <IconButton
                        {...getTestID(`${ props.selectedDeviceName }_${ params.row.name }_delete`)}
                        color="secondary"
                        onClick={() => onDeleteClick(params.row.id, params.row.name)}
                    >
                        <Delete/>
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params => (
                <StyledTypography {...getTestID(`${ props.selectedDeviceName }_${ params.row.name }`)}>
                    {params.row.name}
                </StyledTypography>
            )),
        },
        {
            field: 'capacity',
            headerName: 'Capacity',
            width: 150,
            renderCell: (params => (
                <StyledTypography
                    {...getTestID(`${ props.selectedDeviceName }_${ params.row.name }_capacity`)}
                >
                    {params.row.capacity}
                </StyledTypography>
            )),
        },
        {
            field: 'flowMeterTypeId',
            headerName: 'Flow Meter',
            width: 150,
            renderCell: (params => (
                <StyledTypography
                    {...getTestID(`${ props.selectedDeviceName }_${ params.row.name }_flowmeter`)}
                >
                    {params.row.flowMeterTypeId ? params.row.flowMeterTypeId : 'Not Specified'}
                </StyledTypography>
            )),
        },
    ];

    return (
        <div
            style={{
                height: window.innerHeight - 200,
                width: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '36px',
                }}
            >
                <TitleTypography title="Tank Configuration"/>
                {rows.length < 4 && (
                    <Button
                        {...getTestID('add_tank_button')}
                        startIcon={<Add/>}
                        color="secondary"
                        onClick={() => setNewTankOpen(true)}
                    >
                        Add New Tank
                    </Button>
                )}
            </div>

            <DataGridPro
                rows={rows}
                columns={columns}
                disableColumnSelector
            />
            {remove.id !== null && (
                <DeleteModal
                    id="delete_tank_modal"
                    open
                    close={onCloseDelete}
                    delete={onDelete}
                    description={remove.name}
                    secondaryDescription={Constants.HISTORICAL_DATA_MESSAGE}
                />
            )}
            {newTankOpen && (
                <AddTankModal
                    deviceID={Number(props.selectedDevice)}
                    id="add_tank_modal"
                    open
                    close={() => setNewTankOpen(false)}
                />
            )}
        </div>
    );
});

export default TankTable;
