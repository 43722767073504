/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import ContentPanel from './ContentPanel';
import BillingInformationPanel from '../billingInformation/BillingInformationPanel';
import CurrentPlanPanel from '../billingInformation/CurrentPlanPanel';
import InvoiceHistory from '../billingInformation/InvoiceHistory';
import {observer} from 'mobx-react-lite';
import PayNowPanel from '../billingInformation/PayNowPanel';
import WebStore from '../../stores/WebStore';
import Constants from '../../helper/Constants';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
});

const RightSide = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const BillingInformation = observer(() => {
    const renderContentPanel = () => {
        switch (WebStore.currentPage) {
            case Constants.MAIN:
                return (
                    <Root>
                        <RightSide>
                            <CurrentPlanPanel/>
                            <BillingInformationPanel/>
                        </RightSide>

                        <InvoiceHistory/>
                    </Root>
                );
            case Constants.PAY_NOW:
                return <PayNowPanel/>;
            default:
                return <div/>;
        }
    };

    return (
        <ContentPanel>
            {renderContentPanel()}
        </ContentPanel>
    );
});

export default BillingInformation;
