import React, {useEffect, useMemo, useState} from 'react';
import {styled, Tooltip, Typography} from '@mui/material';
import {DataGridPro, GridRowsProp, GridColDef} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import {Delete, Edit, LockOpen} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import Constants from '../../../helper/Constants';
import DeleteModal from '../../common/modal/DeleteModal';
import callDeleteAPI from '../../../controllers/callDeleteAPI';
import callAPI from '../../../controllers/callAPI';
import FuelLockStore from '../../../stores/FuelLockStore';
import {Device} from '../../../types/components/ApiTypes';
import getDataById from '../../../helper/functions/getDataById';
import getTestID from '../../../helper/functions/getTestID';
import SuccessMessages from '../../../helper/SuccessMessages';
import TooltipStore from '../../../stores/TooltipStore';
import TooltipWithButton from '../../common/TooltipWithButton';
import TooltipMessages from '../../../helper/TooltipMessages';

const StyledTypography = styled(Typography)(() => ({
    fontSize: '14px',
}));

interface Props {
    selectionModel: number,
    setSelectionModel: (value: number) => void,
    setEditingDevice: (value: Device) => void,
    setDeviceSelectedName: (value: string) => void,
}

const DeviceTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const determineDeviceData = () => {
        const devices = FuelLockStore.systemData.deviceList;
        const deviceData: any[] = [];

        devices.forEach((device: any) => {
            deviceData.push({
                id: device.id,
                siteName: device.siteName,
                sn: device.sn,
                autolockTime: device.autolockTime,
            });
        });
        return deviceData;
    };

    const [ rows, setRows ] = useState<GridRowsProp>([]);
    const [ remove, setRemove ] = useState<{ id: number | null, name: string }>({
        id: null,
        name: '',
    });

    useEffect(() => {
        setRows(determineDeviceData());
    }, [ FuelLockStore.systemData.deviceList ]);

    const onEditClick = (id: number) => {
        props.setEditingDevice(getDataById(FuelLockStore.systemData.deviceList, id));
    };

    const onDeleteClick = (id: number, name: string) => {
        setRemove({
            id,
            name,
        });
    };

    const onCloseDelete = () => {
        setRemove({
            id: null,
            name: '',
        });
    };

    const onDelete = () => {
        if (remove) {
            callDeleteAPI(`fuellock/v2/device/${ remove.id }`, (receivedServerData) => {
                if (receivedServerData.status === 'success') {
                    setTimeout(() => {
                        callAPI('fuellock/v2/dashboard', (receivedDashboardData) => {
                            if (receivedDashboardData.status === 'success') {
                                FuelLockStore.setSystemData(receivedDashboardData.data);
                            }
                        }, enqueueSnackbar);
                    }, 700);
                }
            }, enqueueSnackbar, SuccessMessages.DELETE_DEVICE);
            onCloseDelete();
        }
    };

    const onUnlockClick = (id: number) => {
        FuelLockStore.setConfirm({
            title: Constants.REMOTE_UNLOCK_DEVICE_MODAL_TITLE,
            message: Constants.REMOTE_UNLOCK_DEVICE_MODAL_MESSAGE,
            onConfirm: () => {
                setTimeout(() => {
                    callAPI(`fuellock/v2/device/unlock/${ id }`, () => {
                        FuelLockStore.setConfirm({
                            title: '',
                            message: '',
                            onConfirm: () => {
                            },
                        });
                    }, enqueueSnackbar);
                }, 300);
            },
        });
    };

    const columns: GridColDef[] = useMemo(() => ([
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            disableReorder: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
                >
                    <Tooltip
                        open={rows.length > 0 && params.row.id === rows[0].id &&
                        TooltipStore.beginTooltips &&
                        !TooltipStore.showDashboardMessages &&
                        !TooltipStore.showLightModeMessages &&
                        !TooltipStore.showAddDeviceMessages &&
                        TooltipStore.showDeviceMessages}
                        arrow
                        title={(
                            <TooltipWithButton
                                id="edit_device_tooltip_button"
                                message={TooltipMessages.EDIT_DEVICE}
                                onClick={() => TooltipStore.setShowDeviceMessages(false, true)}
                            />
                        )}
                    >
                        <IconButton
                            {...getTestID(`${ params.row.siteName }_edit_device`)}
                            color="secondary"
                            onClick={() => onEditClick(params.row.id)}
                        >
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        {...getTestID(`${ params.row.siteName }_delete_device`)}
                        color="secondary"
                        onClick={() => onDeleteClick(params.row.id, params.row.siteName)}
                    >
                        <Delete/>
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'siteName',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => (
                <StyledTypography {...getTestID(params.row.siteName)}>
                    {params.row.siteName}
                </StyledTypography>
            ),
        },
        {
            field: 'autolockTime',
            headerName: 'Auto Lock Times',
            width: 150,
            renderCell: (params) => (
                <StyledTypography {...getTestID(`${ params.row.siteName }_autolock_time`)}>
                    {params.row.autolockTime} min
                </StyledTypography>
            ),
        },
        {
            field: 'unlock',
            headerName: '',
            renderCell: (params) => (
                <Button
                    {...getTestID(`${ params.row.siteName }_unlock_device_button`)}
                    color="secondary"
                    startIcon={<LockOpen/>}
                    onClick={() => onUnlockClick(params.row.id)}
                >
                    {Constants.UNLOCK}
                </Button>
            ),
        },
        {
            field: 'tanks',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Tooltip
                    open={rows.length > 0 && params.row.id === rows[0].id &&
                    TooltipStore.beginTooltips &&
                    !TooltipStore.showDashboardMessages &&
                    !TooltipStore.showLightModeMessages &&
                    !TooltipStore.showAddDeviceMessages &&
                    !TooltipStore.showDeviceMessages &&
                    TooltipStore.showTankDeviceMessages}
                    arrow
                    title={(
                        <TooltipWithButton
                            id="tank_tooltip_button"
                            message={TooltipMessages.TANK_CONFIG}
                            onClick={() => TooltipStore.setShowTankDeviceMessages(false, true)}
                        />
                    )}
                >
                    <Button
                        {...getTestID(`${ params.row.siteName }_device_tanks_button`)}
                        color="secondary"
                        onClick={() => {
                            props.setSelectionModel(params.row.id);
                            props.setDeviceSelectedName(params.row.siteName);
                        }}
                    >
                        Tank Configuration
                    </Button>
                </Tooltip>
            ),
        },
    ]), [
        TooltipStore.beginTooltips,
        TooltipStore.showDashboardMessages,
        TooltipStore.showLightModeMessages,
        TooltipStore.showAddDeviceMessages,
        TooltipStore.showDeviceMessages,
        TooltipStore.showTankDeviceMessages,
    ]);

    return (
        <div
            style={{
                height: window.innerHeight - 200,
                width: '100%',
            }}
        >
            <div style={{height: '36px'}}/>

            <DataGridPro
                rows={rows}
                columns={columns}
                selectionModel={props.selectionModel}
                disableSelectionOnClick
                disableColumnSelector
            />
            {remove.id !== null && (
                <DeleteModal
                    id="delete_device_modal"
                    open
                    close={onCloseDelete}
                    delete={onDelete}
                    description={remove.name}
                    secondaryDescription={'Deleting this device will lose all data. ' +
                    'Before you delete, you can access this data from your excel reports.'}
                />
            )}
        </div>
    );
});

export default DeviceTable;
