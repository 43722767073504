/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Skeleton} from '@mui/material';
import Grid from '@mui/material/Grid';

interface Props {
    numColumns: number,
    numRows: number,
}

const LoadingGrid = (props: Props) => (
    <Grid
        container
        spacing={props.numColumns}
    >
        {[ ...Array(props.numColumns * props.numRows) ].map((e, i) => (
            <Grid
                key={`grid_${ i }`}
                item
                xs={12 / props.numColumns}
            >
                <Skeleton
                    variant="text"
                    sx={{
                        width: '100%',
                        maxWidth: 'none',
                        height: '50px',
                    }}
                    animation="wave"
                />
            </Grid>
        ))}
    </Grid>
);

export default LoadingGrid;
