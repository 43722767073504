/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {Button, styled, Typography} from '@mui/material';
import BaseModal from '../common/modal/BaseModal';
import callAPI from '../../controllers/callAPI';
import {useSnackbar} from 'notistack';
import LoadingModal from '../common/modal/LoadingModal';
import TextFieldInput from '../common/input/TextFieldInput';
import getTestID from '../../helper/functions/getTestID';
import { isNumericPIN } from '../../helper/validator/isNumeric';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

const Center = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

interface Props {
    open: boolean,
    close: () => void,
    submit: (newPin: string) => void,
    employeeName?: string,
    currentPin: string | number,
    loading?: boolean,
}

function ChangePinModal(props: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const [ pin, setPin ] = useState('');
    const [ pinError, setPinError ] = useState('');

    const isValid = () => {
        let valid = true;

        let error = isNumericPIN(pin, 4, 6);
        if (error !== '') {
            valid = false;
        } else if (props.currentPin === pin) {
            valid = false;
            error = 'You used this PIN recently. Please choose a different one';
        }
        setPinError(error);
        return valid;
    };

    const submit = () => {
        if (isValid()) {
            props.submit(pin);
            props.close();
        }
    };

    const generateRandomPin = () => {
        callAPI('fuellock/v2/generateRandomPin',
            ((receivedServerData) => {
                setPin(receivedServerData.data);
            }), enqueueSnackbar);
    };

    if (props.loading) {
        return (
            <LoadingModal/>
        );
    }

    return (
        <BaseModal
            id="change_pin"
            open={props.open}
            close={props.close}
            submit={submit}
            title={props.employeeName ? `New PIN for: ${ props.employeeName }` : 'New PIN'}
            disableSubmitOnAwait
        >
            <Container>
                <Typography
                    sx={{paddingBottom: '16px'}}
                >
                    Fuel Lock™ recommends using system generated random pins.
                </Typography>
                <Typography>Your Current PIN:</Typography>
                <Typography
                    {...getTestID('current_pin')}
                    sx={{
                        fontSize: '18px',
                        paddingBottom: '16px',
                    }}
                >
                    {props.currentPin}
                </Typography>
                <TextFieldInput
                    id="new_pin_input"
                    label="Your New Pin"
                    field={{
                        value: pin,
                        setValue: setPin,
                        error: pinError,
                        setError: setPinError,
                    }}
                    textFieldProps={{
                        placeholder: 'e.g., 8675',
                        inputProps: {
                            minLength: 4,
                            maxLength: 6,
                        },
                    }}
                />

                <Center>
                    <Button
                        {...getTestID('generate_random_pin_button')}
                        onClick={generateRandomPin}
                    >
                        Generate New Random PIN
                    </Button>
                </Center>
            </Container>
        </BaseModal>
    );
}

export default ChangePinModal;
