/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import TextFieldInput from './TextFieldInput';
import PasswordField from './PasswordField';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    textFieldProps?: object,
    type?: string,
}

function GridTextFieldInput(props: Props) {
    return (
        <Grid
            item
            xs={6}
        >
            {props.type === 'password' ? (
                <PasswordField
                    field={props.field}
                    id={props.id}
                    label={props.label}
                />
            ) : (
                <TextFieldInput
                    id={props.id}
                    label={props.label}
                    field={props.field}
                    textFieldProps={props.textFieldProps}
                />
            )}
        </Grid>
    );
}

export default GridTextFieldInput;
