import React from 'react';
import ReactDOM from 'react-dom';
import {LicenseInfo} from '@mui/x-data-grid-pro';
import './index.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import  { initializeApp } from 'firebase/app';
import ReactGA from 'react-ga';

LicenseInfo.setLicenseKey(
    '9fc41b91d3579162ed53ab63d7b4819aT1JERVI6MzEzNDAsRVhQSVJZPTE2NjY1MzYzNzcwMDAsS0VZVkVSU0lPTj0x'
);

initializeApp({
    apiKey: 'AIzaSyAAp9RErBIFOXoyEeQvXoqi6doivrnJWMQ',
    authDomain: 'fuellock-7dc86.firebaseapp.com',
    databaseURL: 'https://fuellock-7dc86.firebaseio.com',
    projectId: 'fuellock-7dc86',
    storageBucket: 'fuellock-7dc86.appspot.com',
    messagingSenderId: '469591412166',
    appId: '1:469591412166:web:82863dd52d1f3c746bb6c7',
});

const googleAnalyticsId = 'G-00EH9832Q9';
if (process.env.REACT_APP_SIM === undefined) {
    ReactGA.initialize(googleAnalyticsId);
}

ReactDOM.render(
    <LocalizationProvider dateAdapter={DateAdapter}>
        <App/>
    </LocalizationProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
