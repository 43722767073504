/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Divider, MenuItem, Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {MoreVert} from '@mui/icons-material';
import PopupMenu from './PopupMenu';
import getTestID from '../../helper/functions/getTestID';
import {observer} from 'mobx-react-lite';
import FuelLockStore from '../../stores/FuelLockStore';
import Constants from '../../helper/Constants';
import WebStore from '../../stores/WebStore';
import TooltipWithButton from '../common/TooltipWithButton';
import TooltipStore from '../../stores/TooltipStore';
import TooltipMessages from '../../helper/TooltipMessages';

interface Props {
    iconButton: object,
    disabled?: boolean
}

const DevicesPopup = observer((props: Props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeviceClick = (label: string) => {
        WebStore.setDashboardDevice(label);
        handleClose();
    };

    const handleTimeRangeClick = (label: string) => {
        WebStore.setAllTilesTimeRange(label);
        handleClose();
    };

    return (
        <Tooltip
            open={TooltipStore.beginTooltips && TooltipStore.showDashboardMessages}
            arrow
            title={(
                <TooltipWithButton
                    id="data_filter_tooltip_button"
                    message={TooltipMessages.FILTER}
                    onClick={() => TooltipStore.setShowDashboardMessages(false, true)}
                />
            )}
        >
            <div>
                <IconButton
                    {...getTestID('dashboard_device_button')}
                    sx={props.iconButton}
                    onClick={handleClick}
                    disabled={props.disabled}
                >
                    <MoreVert/>
                </IconButton>
                <PopupMenu
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                >
                    <MenuItem
                        {...getTestID('all_devices_dashboard_button')}
                        onClick={() => handleDeviceClick(Constants.ALL_DEVICES)}
                        selected={Constants.ALL_DEVICES === WebStore.dashboardDevice}
                    >
                        {Constants.ALL_DEVICES}
                    </MenuItem>
                    <Divider/>
                    {FuelLockStore.systemData.deviceList.map(device => (
                        <MenuItem
                            {...getTestID(`device_${ device.id }_dashboard_button`)}
                            key={`Device_${ device.id }`}
                            onClick={() => handleDeviceClick(device.siteName)}
                            selected={device.siteName === WebStore.dashboardDevice}
                        >
                            {device.siteName}
                        </MenuItem>
                    ))}
                    <Divider/>
                    <MenuItem
                        {...getTestID('daily_dashboard_button')}
                        onClick={() => handleTimeRangeClick(Constants.DAILY)}
                    >
                        {Constants.DAILY}
                    </MenuItem>
                    <MenuItem
                        {...getTestID('weekly_dashboard_button')}
                        onClick={() => handleTimeRangeClick(Constants.WEEKLY)}
                    >
                        {Constants.WEEKLY}
                    </MenuItem>
                    <MenuItem
                        {...getTestID('monthly_dashboard_button')}
                        onClick={() => handleTimeRangeClick(Constants.MONTHLY)}
                    >
                        {Constants.MONTHLY}
                    </MenuItem>
                </PopupMenu>
            </div>
        </Tooltip>
    );
});

export default DevicesPopup;
