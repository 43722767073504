/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import Constants from '../Constants';

const getProvinceList = (country: string) => {
    const provinceOptions: { title: string; id: string; }[] = [];

    if (country) {
        const provinces = Constants.COUNTRY_LIST[country];

        if (provinces) {
            provinces.forEach((province: string) => {
                provinceOptions.push({
                    title: province,
                    id: `${ province.replace(/\s/g, '_').toLowerCase() }_state_option`,
                });
            });
        }
    }

    return provinceOptions;
};

export default getProvinceList;
