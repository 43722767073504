/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import AddEmployeesToDevice from '../wizard/AddEmployeesToDevice';
import {Device} from '../../../types/components/ApiTypes';

interface Props {
    editingDevice: Device,
}

const EditEmployeeAccess = (props: Props) => (
    <AddEmployeesToDevice
        editingDeviceId={props.editingDevice.id}
        width="auto"
        update
    />
);

export default EditEmployeeAccess;
