/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {Typography, styled} from '@mui/material';
import ContentPanel from './ContentPanel';
import PersonalDetails from '../settings/adminAccount/personalDetails/PersonalDetails';
import SubmitButton from '../common/SubmitButton';
import ChangeAdminPinModal from '../settings/adminAccount/ChangeAdminPinModal';
import ChangeAccountPasswordModal from '../settings/adminAccount/ChangeAccountPasswordModal';
import Constants from '../../helper/Constants';
import isValidCode from '../../helper/validator/isValidCode';
import callDeleteAPI from '../../controllers/callDeleteAPI';
import { useSnackbar } from 'notistack';
import SuccessMessages from '../../helper/SuccessMessages';
import BaseModal from '../common/modal/BaseModal';
import TextFieldInput from '../common/input/TextFieldInput';
import FuelLockStore from '../../stores/FuelLockStore';
import LoginStore from '../../stores/LoginStore';
import WebStore from '../../stores/WebStore';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const Center = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '16px',
});

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

enum ModalType {
    NONE,
    CONFIRM,
    CODE_ENTRY,
}

function AdminAccount() {
    const {enqueueSnackbar} = useSnackbar();
    const [ pinOpen, setPinOpen ] = useState(false);
    const [ passwordOpen, setPasswordOpen ] = useState(false);

    const generateRandomCode = () => {
        let generatedConfirmCode = '';
        for (var i = 0; i < 4; i++) {
            generatedConfirmCode += Math.floor(Math.random() * 10).toString();
        }
        return generatedConfirmCode;
    };

    const [ code, setCode ] = React.useState(generateRandomCode());
    const [ codeInput, setCodeInput ] = React.useState('');
    const [ codeError, setCodeError ] = React.useState('');
    const [ modalType, setModalType ] = React.useState(ModalType.NONE);
    const [ modalMessage, setModalMessage ] = React.useState('');
    const [ modalConfirm, setModalConfirm ] = React.useState('');
    const [ modalCancel, setModalCancel ] = React.useState('');

    const isValid = () => {
        const error = isValidCode(codeInput, 4, code, Constants.DELETE_ACCOUNT_ERROR);
        setCodeError(error);
        return error === '';
    };

    const onCloseModal = () => {
        setModalMessage('');
        setCodeInput('');
        setCodeError('');
        setModalType(ModalType.NONE);
    };

    const onDeleteAccount = () => {
        switch (modalType) {
            case ModalType.NONE:
                setModalMessage(Constants.DELETE_ACCOUNT_CONFIRM);
                setModalConfirm(Constants.YES);
                setModalCancel(Constants.NO);
                setModalType(ModalType.CONFIRM);
                break;
            case ModalType.CONFIRM:
                setCode(generateRandomCode());
                setModalMessage(Constants.DELETE_ACCOUNT_CODE_ENTRY);
                setModalConfirm(Constants.DELETE_ACCOUNT);
                setModalCancel(Constants.CANCEL);
                setModalType(ModalType.CODE_ENTRY);
                break;
            case ModalType.CODE_ENTRY:
                if (isValid()) {
                    onCloseModal();
                    callDeleteAPI('fuellock/v2/account', (receivedServerData) => {
                        if (receivedServerData.status === 'success') {
                            WebStore.setSelectedDrawer(Constants.DASHBOARD);
                            WebStore.setAllTilesTimeRange(Constants.MONTHLY);
                            WebStore.setDashboardDevice(Constants.ALL_DEVICES);
                            LoginStore.setEmail('', true);
                            LoginStore.setToken('', true);
                            LoginStore.setUdd(-1, true);
                            FuelLockStore.presetFuelLockStore();
                            LoginStore.setLoadingData(true);
                            LoginStore.setLoggedIn(false);
                        }
                    }, enqueueSnackbar, SuccessMessages.DELETE_ACCOUNT);
                }
                break;
        }
    };

    return (
        <ContentPanel>
            <Root>
                <PersonalDetails/>
                <Center>
                    <SubmitButton
                        id="change_admin_pin_button"
                        text="Change admin PIN"
                        onClick={() => setPinOpen(true)}
                    />
                </Center>
                <Center>
                    <SubmitButton
                        id="change_account_password_button"
                        text="Change account password"
                        onClick={() => setPasswordOpen(true)}
                    />
                </Center>
                {pinOpen && (
                    <ChangeAdminPinModal
                        open={pinOpen}
                        close={() => setPinOpen(false)}
                    />
                )}
                {passwordOpen && (
                    <ChangeAccountPasswordModal
                        open={passwordOpen}
                        close={() => setPasswordOpen(false)}
                    />
                )}
                <BaseModal
                    id='delete_account_modal'
                    open={modalType !== ModalType.NONE}
                    close={onCloseModal}
                    submit={onDeleteAccount}
                    onCancel={onCloseModal}
                    title={Constants.WARNING_TITLE}
                    submitButtonText={modalConfirm}
                    cancelButtonText={modalCancel}
                >
                    <Container>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {modalMessage}
                        </Typography>
                        {modalType === ModalType.CODE_ENTRY && (
                            <>
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 24,
                                    }}
                                >
                                    {code}
                                </Typography>
                                <TextFieldInput
                                    id='verification_code_input'
                                    label={'Enter the code from above'}
                                    field={{
                                        value: codeInput,
                                        setValue: setCodeInput,
                                        error: codeError,
                                        setError: setCodeError,
                                    }}
                                    textFieldProps={{
                                        inputProps: {
                                            maxLength: 4,
                                            max: 9999,
                                        },
                                        type: 'number',
                                    }}
                                />
                            </>
                        )}
                    </Container>
                </BaseModal>
                <Center>
                    <SubmitButton
                        id='delete_account_button'
                        text={Constants.DELETE_ACCOUNT}
                        onClick={() => onDeleteAccount()}
                    />
                </Center>
            </Root>
        </ContentPanel>
    );
}

export default AdminAccount;
