/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import Typography from '@mui/material/Typography';

const StyledTypography = styled(Typography)(({theme}) => ({
    // @ts-ignore
    color: theme.palette.primary.titleText,
    fontSize: '20px',
}));

interface Props {
    title: string,
}

function TitleTypography(props: Props) {
    return (
        <StyledTypography>
            {props.title}
        </StyledTypography>
    );
}

export default TitleTypography;
