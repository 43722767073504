/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import cloneDeep from 'lodash/cloneDeep';
import {useSnackbar} from 'notistack';
import TitleTypography from '../common/typography/TitleTypography';
import Constants from '../../helper/Constants';
import AddNewDevice from './wizard/AddNewDevice';
import TankConfiguration from './wizard/TankConfiguration';
import PowerSupplyDetails from './wizard/PowerSupplyDetails';
import DeviceActiveHours from './wizard/DeviceActiveHours';
import AddEmployeesToDevice from './wizard/AddEmployeesToDevice';
import AddEquipmentToDevice from './wizard/AddEquipmentToDevice';
import isStringNotEmpty from '../../helper/validator/isStringNotEmpty';
import isValidSerialNumber from '../../helper/validator/isValidSerialNumber';
import isNumericInRange from '../../helper/validator/isNumericInRange';
import FuelLockStore from '../../stores/FuelLockStore';
import {Employee, Equipment, InActiveDay, TrackUnit} from '../../types/components/ApiTypes';
import {Tanks} from '../../types/components/EquipmentTypes';
import isFlowMeterValid from '../../helper/validator/isFlowMeterValid';
import isLevelSensorValid from '../../helper/validator/isLevelSensorValid';
import callAPI from '../../controllers/callAPI';
import callPostAPI from '../../controllers/callPostAPI';
import determineActiveHours from '../../helper/functions/determineActiveHours';
import determineInactiveDays from '../../helper/functions/determineInactiveDays';
import determineTankList from '../../helper/functions/determineTankList';
import AddedDeviceModal from '../common/modal/AddedDeviceModal';
import WebStore from '../../stores/WebStore';
import WarnFlowMeterModal from '../common/modal/WarnFlowMeterModal';
import determineSupplyList from '../../helper/functions/powerSupply/determineSupplyList';
import isPowerSupplyPageValid from '../../helper/complexValidators/isPowerSupplyPageValid';
import getTestID from '../../helper/functions/getTestID';
import SuccessMessages from '../../helper/SuccessMessages';
import getFlowMeterType from '../../helper/functions/getFlowMeterType';
import ConfirmationModal from '../common/modal/ConfirmationModal';

const steps = [
    Constants.ADD_NEW_DEVICE,
    Constants.TANK_CONFIGURATION,
    Constants.POWER_SUPPLY_DETAILS,
    Constants.DEVICE_ACTIVE_HOURS,
    Constants.ADD_EMPLOYEES,
    Constants.ADD_EQUIPMENT,
];

interface Props {
    setAdd: (value: boolean) => void,
}

const DeviceWizard = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const getAllowedIDs = (dataSource: Employee[] | Equipment[]) => {
        const newData: number[] = [];

        dataSource.forEach(dataPoint => {
            newData.push(dataPoint.id);
        });
        return newData;
    };

    const getAllowedEmployeeIDs = () => getAllowedIDs(FuelLockStore.systemData.workerList);

    const getAllowedEquipmentIDs = () => getAllowedIDs(FuelLockStore.systemData.equipmentList);

    const [ newDevicePage, setNewDevicePage ] = useState({
        deviceName: '',
        deviceNameError: '',
        serialNumber: '',
        serialNumberError: '',
        autoLockTime: '',
        autoLockTimeError: '',
    });

    const [ tanks, setTanks ] = useState<Tanks[]>([ {
        name: '',
        nameError: '',
        model: '',
        limit: '0',
        limitError: '',
        flowMeterName: '',
        flowMeterNameError: '',
        flowMeterSlot: '1',
        custom: false,
        pulsesPerLitre: '',
        pulsesPerLitreError: '',
        tattleID: '',
        tattleIDError: '',
        tattlePort: '',
        type: '',
        density: '',
        height: '',
        heightError: '',
        capacity: '',
        capacityError: '',
        useFlowMeter: false,
        useLevelSensor: false,
        levelSensorConfiguration: '',
        levelSensorProduct: '',
        otodataApiKey: '',
        otodataApiKeyError: '',
        otodataDevice: '',
        ignoreFlowMeter: false,
        showUseFlowMeterModal: false,
        virtualTankMonitoring: false,
        fullness: null,
        lowThreshold: null,
    } ]);
    const [ showUseFlowMeterModal, setShowUseFlowMeterModal ] = useState(false);
    const [ modalTank, setModalTank ] = useState(-1);
    const [ reasonFlowMeterModal, setReasonFlowMeterModal ] = useState('');
    const [ virtualTankMonitoring, setVirtualTankMonitoring ] = useState(false);
    const [ fullness, setFullness ] = useState(null);

    const [ powerSupplyPage, setPowerSupplyPage ] = useState({
        powerSupplyOne: 'Power Supply 1',
        powerSupplyOneError: '',
        enableOne: true,
        powerSupplyTwo: 'Power Supply 2',
        powerSupplyTwoError: '',
        enableTwo: false,
    });

    const defaultStart = '12:00 am';
    const defaultEnd = '12:00 am';
    const [ activeHoursPage, setActiveHoursPage ] = useState({
        mondayStart: defaultStart,
        mondayEnd: defaultEnd,
        tuesdayStart: defaultStart,
        tuesdayEnd: defaultEnd,
        wednesdayStart: defaultStart,
        wednesdayEnd: defaultEnd,
        thursdayStart: defaultStart,
        thursdayEnd: defaultEnd,
        fridayStart: defaultStart,
        fridayEnd: defaultEnd,
        saturdayStart: defaultStart,
        saturdayEnd: defaultEnd,
        sundayStart: defaultStart,
        sundayEnd: defaultEnd,
        inactiveDays: [],
    });

    const [ allowedEmployeeIDs, setAllowedEmployeeIDs ] = useState<number[]>(getAllowedEmployeeIDs());
    const [ allowedEmployeeIndices, setAllowedEmployeeIndices ] = useState<number[]>([]);
    const [ newEmployeeList, setNewEmployeeList ] = useState<{
        name: string,
        receiveNotifications: boolean,
        holiday: null,
    }[]>([]);

    const [ allowedEquipmentIDs, setAllowedEquipmentIDs ] = useState<number[]>(getAllowedEquipmentIDs());
    const [ allowedEquipmentIndices, setAllowedEquipmentIndices ] = useState<number[]>([]);
    const [ newEquipmentList, setNewEquipmentList ] = useState<{
        hwID: number,
        name: string,
        trackUnit: TrackUnit,
    }[]>([]);

    const [ success, setSuccess ] = useState(false);

    const updateNewDevicePage = (value: string, key: string) => {
        setNewDevicePage({
            ...newDevicePage,
            [key]: value,
        });
    };

    const isNewDevicePageValid = () => {
        const newNameError = isStringNotEmpty(newDevicePage.deviceName, 'name');
        const newSerialNumberError = isValidSerialNumber(newDevicePage.serialNumber);
        const newAutoLockTimeError = isNumericInRange(newDevicePage.autoLockTime, 5, 60, 0,
            'auto lock time');

        setNewDevicePage({
            ...newDevicePage,
            deviceNameError: newNameError,
            serialNumberError: newSerialNumberError,
            autoLockTimeError: newAutoLockTimeError,
        });

        return newNameError === '' && newSerialNumberError === '' && newAutoLockTimeError === '';
    };

    const updateTanksPage = (value: any, key: string, index: number) => {
        setTanks(prevState => {
            const newData = cloneDeep(prevState);
            if (key === 'model' && value !== Constants.CUSTOM_FLOW_METER && typeof value === 'string') {
                const flType = getFlowMeterType(value);
                newData[index].type = flType.id.toString();
                newData[index].custom = flType.custom;
                newData[index].pulsesPerLitre = flType.pulsesPerLitre.toString();
                newData[index].flowMeterName = value;
            } else if (key === 'model' && value === Constants.CUSTOM_FLOW_METER) {
                newData[index].type = '';
                newData[index].custom = true;
                newData[index].pulsesPerLitre = '';
                newData[index].flowMeterName = '';
            }
            newData[index][key] = value;

            if (key === 'virtualTankMonitoring') {
                newData[index].virtualTankMonitoring = value;
                setVirtualTankMonitoring(true);
            }

            if (key === 'fullness') {
                newData[index].fullness = value;
                setFullness(value);
            }

            return newData;
        });
    };

    const onAddTank = () => {
        setTanks(oldData => {
            const newData = cloneDeep(oldData);
            newData.push({
                name: '',
                nameError: '',
                model: '',
                limit: '0',
                limitError: '',
                flowMeterName: '',
                flowMeterNameError: '',
                flowMeterSlot: '',
                custom: false,
                pulsesPerLitre: '',
                pulsesPerLitreError: '',
                tattleID: '',
                tattleIDError: '',
                tattlePort: '',
                type: '',
                density: '',
                height: '',
                heightError: '',
                capacity: '',
                capacityError: '',
                useFlowMeter: false,
                useLevelSensor: false,
                levelSensorConfiguration: '',
                levelSensorProduct: '',
                otodataApiKey: '',
                otodataApiKeyError: '',
                otodataDevice: '',
                ignoreFlowMeter: false,
                showUseFlowMeterModal: false,
                virtualTankMonitoring,
                fullness,
                lowThreshold: null,
            });
            return newData;
        });
    };

    const onRemoveTank = (index: number) => {
        setTanks(oldData => {
            const newData = cloneDeep(oldData);
            newData.splice(index, 1);
            return newData;
        });
    };

    const isTanksNameUnique = (testName: string, index: number) => {
        for (let i = 0; i < tanks.length; i += 1) {
            if (tanks[i].name === testName && i !== index) {
                return 'Please enter a unique tank name.';
            }
        }

        return '';
    };

    const isTanksPageValid = () => {
        let valid = true;
        const newTanks = cloneDeep(tanks);

        tanks.forEach((tank, index) => {
            let newNameError = isStringNotEmpty(tank.name, 'name');
            if (newNameError === '') {
                newNameError = isTanksNameUnique(tank.name, index);
            }
            newTanks[index].nameError = newNameError;

            if (newNameError !== '') {
                valid = false;
            }

            const newCapacityError = isNumericInRange(tank.capacity, 0, 4294967295, 0,
                'capacity');
            newTanks[index].capacityError = newCapacityError;

            if (newCapacityError !== '') {
                valid = false;
            }

            if (tank.useLevelSensor) {
                const levelSensorValid = isLevelSensorValid(tank);
                newTanks[index].heightError = levelSensorValid.heightError;
                if (!levelSensorValid.valid) {
                    valid = false;
                }
            }

            if (tank.useFlowMeter) {
                const flowMeterValid = isFlowMeterValid(tank);
                newTanks[index].flowMeterNameError = flowMeterValid.flowMeterNameError;
                newTanks[index].pulsesPerLitreError = flowMeterValid.pulsesPerLitreError;
                newTanks[index].limitError = flowMeterValid.limitError;

                if (!flowMeterValid.valid) {
                    valid = false;
                }
            } else if (!tank.ignoreFlowMeter && valid) {
                valid = false;
                setModalTank(index);
                setReasonFlowMeterModal(Constants.NEXT);
                setShowUseFlowMeterModal(true);
            }
        });

        setTanks(newTanks);
        return valid;
    };

    const updatePowerSupplyPage = (value: string | boolean, key: string) => {
        setPowerSupplyPage({
            ...powerSupplyPage,
            [key]: value,
        });
    };

    const updateActiveHoursPage = (value: string | InActiveDay[], key: string) => {
        setActiveHoursPage({
            ...activeHoursPage,
            [key]: value,
        });
    };

    const getPowerSupplyAccess = () => {
        if (powerSupplyPage.enableOne && powerSupplyPage.enableTwo) {
            return 'both';
        }
        if (powerSupplyPage.enableTwo) {
            return 'two';
        }
        if (powerSupplyPage.enableOne) {
            return 'one';
        }
        return 0;
    };

    const getActiveHours = () => ({
        mondayHours: {
            start: determineActiveHours(activeHoursPage.mondayStart),
            end: determineActiveHours(activeHoursPage.mondayEnd),
        },
        tuesdayHours: {
            start: determineActiveHours(activeHoursPage.tuesdayStart),
            end: determineActiveHours(activeHoursPage.tuesdayEnd),
        },
        wednesdayHours: {
            start: determineActiveHours(activeHoursPage.wednesdayStart),
            end: determineActiveHours(activeHoursPage.wednesdayEnd),
        },
        thursdayHours: {
            start: determineActiveHours(activeHoursPage.thursdayStart),
            end: determineActiveHours(activeHoursPage.thursdayEnd),
        },
        fridayHours: {
            start: determineActiveHours(activeHoursPage.fridayStart),
            end: determineActiveHours(activeHoursPage.fridayEnd),
        },
        saturdayHours: {
            start: determineActiveHours(activeHoursPage.saturdayStart),
            end: determineActiveHours(activeHoursPage.saturdayEnd),
        },
        sundayHours: {
            start: determineActiveHours(activeHoursPage.sundayStart),
            end: determineActiveHours(activeHoursPage.sundayEnd),
        },
        inactiveDays: determineInactiveDays(activeHoursPage.inactiveDays),
    });

    const submitDevice = async () => {
        await determineTankList(tanks, enqueueSnackbar).then((tankListReturn) => {
            const body = {
                device: {
                    siteName: newDevicePage.deviceName,
                    sn: newDevicePage.serialNumber,
                    autolockTime: newDevicePage.autoLockTime,
                    tankList: tankListReturn,
                    supplyList: determineSupplyList(powerSupplyPage),
                    activeHours: getActiveHours(),
                },
                accessEmployeeIdSet: allowedEmployeeIDs,
                accessNewEmployeeIndexSet: allowedEmployeeIndices,
                newEmployeeList,
                employeePowerSupplyAccess: getPowerSupplyAccess(),
                accessEquipIdSet: allowedEquipmentIDs,
                accessNewEquipIndexSet: allowedEquipmentIndices,
                newEquipmentList,
            };

            callPostAPI('fuellock/v2/device/wizard', body, (receivedData) => {
                if (receivedData.status === 'success') {
                    callAPI('fuellock/v2/dashboard', (receivedServerData) => {
                        FuelLockStore.setSystemData(receivedServerData.data);
                        setSuccess(true);
                    }, enqueueSnackbar);
                }
            }, enqueueSnackbar, SuccessMessages.CREATE_DEVICE);
        });
    };

    const handleNext = () => {
        if (WebStore.activeStep < steps.length - 1 && (WebStore.activeStep === 0 && isNewDevicePageValid() ||
            WebStore.activeStep === 1 && isTanksPageValid() ||
            WebStore.activeStep === 2 && isPowerSupplyPageValid(powerSupplyPage, setPowerSupplyPage) ||
            WebStore.activeStep === 3 ||
            WebStore.activeStep === 4
        )) {
            WebStore.setActiveStep(WebStore.activeStep + 1);
        } else if (WebStore.activeStep === 5) {
            submitDevice();
        }
    };

    const handleBack = () => {
        WebStore.setActiveStep(WebStore.activeStep - 1);
    };

    const handleIgnoreFlowMeter = (value: boolean) => {
        setTanks(prevState => {
                const newData = cloneDeep(prevState);
                newData[modalTank].ignoreFlowMeter = value;
                return newData;
            });
        setModalTank(-1);
        if (reasonFlowMeterModal === Constants.ADD) {
            onAddTank();
        } else if (reasonFlowMeterModal === Constants.NEXT) {
            WebStore.setActiveStep(WebStore.activeStep + 1);
        }
        setReasonFlowMeterModal('');
    };

    const confirmationSubmit = () => {
        WebStore.setActiveStep(0);
        WebStore.goToPreviousPage();
        if (WebStore.selectedDrawerAfterConfirmation !== '') {
            WebStore.setSelectedDrawer(WebStore.selectedDrawerAfterConfirmation);
            WebStore.setSelectedDrawerAfterConfirmation('');
        }
    };

    const renderPaper = () => {
        switch (steps[WebStore.activeStep]) {
            case Constants.ADD_NEW_DEVICE:
                return (
                    <AddNewDevice
                        deviceName={{
                            value: newDevicePage.deviceName,
                            setValue: (value) => updateNewDevicePage(value, 'deviceName'),
                            error: newDevicePage.deviceNameError,
                            setError: (value) => updateNewDevicePage(value, 'deviceNameError'),
                        }}
                        serialNumber={{
                            value: newDevicePage.serialNumber,
                            setValue: (value) => updateNewDevicePage(value, 'serialNumber'),
                            error: newDevicePage.serialNumberError,
                            setError: (value) => updateNewDevicePage(value, 'serialNumberError'),
                        }}
                        autoLockTime={{
                            value: newDevicePage.autoLockTime,
                            setValue: (value) => updateNewDevicePage(value, 'autoLockTime'),
                            error: newDevicePage.autoLockTimeError,
                            setError: (value) => updateNewDevicePage(value, 'autoLockTimeError'),
                        }}
                    />
                );
            case Constants.TANK_CONFIGURATION:
                return (
                    <TankConfiguration
                        tanks={tanks}
                        updateTank={updateTanksPage}
                        addTank={onAddTank}
                        deleteTank={onRemoveTank}
                        showUseFlowMeterModal={showUseFlowMeterModal}
                        setShowUseFlowMeterModal={setShowUseFlowMeterModal}
                        modalTank={modalTank}
                        setModalTank={setModalTank}
                        setReasonFlowMeterModal={setReasonFlowMeterModal}
                        // eslint-disable-next-line
                        setVirtualTankMonitoring={(value: boolean) => updateTanksPage(value, 'virtualTankMonitoring', modalTank)}
                        setFullness={(value: number) => updateTanksPage(value, 'fullness', modalTank)}
                    />
                );
            case Constants.POWER_SUPPLY_DETAILS:
                return (
                    <PowerSupplyDetails
                        powerSupplyOne={{
                            value: powerSupplyPage.powerSupplyOne,
                            setValue: (value: string) => updatePowerSupplyPage(value, 'powerSupplyOne'),
                            error: powerSupplyPage.powerSupplyOneError,
                            setError: (value: string) => updatePowerSupplyPage(value, 'powerSupplyOneError'),
                        }}
                        powerSupplyTwo={{
                            value: powerSupplyPage.powerSupplyTwo,
                            setValue: (value: string) => updatePowerSupplyPage(value, 'powerSupplyTwo'),
                            error: powerSupplyPage.powerSupplyTwoError,
                            setError: (value: string) => updatePowerSupplyPage(value, 'powerSupplyTwoError'),
                        }}
                        enableTwo={powerSupplyPage.enableTwo}
                        setEnableTwo={(value: boolean) => updatePowerSupplyPage(value, 'enableTwo')}
                    />
                );
            case Constants.DEVICE_ACTIVE_HOURS:
                return (
                    <DeviceActiveHours
                        mondayStart={{
                            value: activeHoursPage.mondayStart,
                            setValue: (value: string) => updateActiveHoursPage(value, 'mondayStart'),
                        }}
                        mondayEnd={{
                            value: activeHoursPage.mondayEnd,
                            setValue: (value: string) => updateActiveHoursPage(value, 'mondayEnd'),
                        }}
                        tuesdayStart={{
                            value: activeHoursPage.tuesdayStart,
                            setValue: (value: string) => updateActiveHoursPage(value, 'tuesdayStart'),
                        }}
                        tuesdayEnd={{
                            value: activeHoursPage.tuesdayEnd,
                            setValue: (value: string) => updateActiveHoursPage(value, 'tuesdayEnd'),
                        }}
                        wednesdayStart={{
                            value: activeHoursPage.wednesdayStart,
                            setValue: (value: string) => updateActiveHoursPage(value, 'wednesdayStart'),
                        }}
                        wednesdayEnd={{
                            value: activeHoursPage.wednesdayEnd,
                            setValue: (value: string) => updateActiveHoursPage(value, 'wednesdayEnd'),
                        }}
                        thursdayStart={{
                            value: activeHoursPage.thursdayStart,
                            setValue: (value: string) => updateActiveHoursPage(value, 'thursdayStart'),
                        }}
                        thursdayEnd={{
                            value: activeHoursPage.thursdayEnd,
                            setValue: (value: string) => updateActiveHoursPage(value, 'thursdayEnd'),
                        }}
                        fridayStart={{
                            value: activeHoursPage.fridayStart,
                            setValue: (value: string) => updateActiveHoursPage(value, 'fridayStart'),
                        }}
                        fridayEnd={{
                            value: activeHoursPage.fridayEnd,
                            setValue: (value: string) => updateActiveHoursPage(value, 'fridayEnd'),
                        }}
                        saturdayStart={{
                            value: activeHoursPage.saturdayStart,
                            setValue: (value: string) => updateActiveHoursPage(value, 'saturdayStart'),
                        }}
                        saturdayEnd={{
                            value: activeHoursPage.saturdayEnd,
                            setValue: (value: string) => updateActiveHoursPage(value, 'saturdayEnd'),
                        }}
                        sundayStart={{
                            value: activeHoursPage.sundayStart,
                            setValue: (value: string) => updateActiveHoursPage(value, 'sundayStart'),
                        }}
                        sundayEnd={{
                            value: activeHoursPage.sundayEnd,
                            setValue: (value: string) => updateActiveHoursPage(value, 'sundayEnd'),
                        }}
                        inactiveDays={activeHoursPage.inactiveDays}
                        setInactiveDays={(value: InActiveDay[]) => updateActiveHoursPage(value, 'inactiveDays')}
                    />
                );
            case Constants.ADD_EMPLOYEES:
                return (
                    <AddEmployeesToDevice
                        allowedEmployeeIDs={allowedEmployeeIDs}
                        setAllowedEmployeeIDs={setAllowedEmployeeIDs}
                        allowedEmployeeIndices={allowedEmployeeIndices}
                        setAllowedEmployeeIndices={setAllowedEmployeeIndices}
                        newEmployeeList={newEmployeeList}
                        setNewEmployeeList={setNewEmployeeList}
                    />
                );
            case Constants.ADD_EQUIPMENT:
                return (
                    <AddEquipmentToDevice
                        allowedEquipmentIDs={allowedEquipmentIDs}
                        setAllowedEquipmentIDs={setAllowedEquipmentIDs}
                        allowedEquipmentIndices={allowedEquipmentIndices}
                        setAllowedEquipmentIndices={setAllowedEquipmentIndices}
                        newEquipmentList={newEquipmentList}
                        setNewEquipmentList={setNewEquipmentList}
                    />
                );
            default:
                return (
                    <Paper>
                        <TitleTypography title={steps[WebStore.activeStep]}/>
                    </Paper>
                );
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >

            <Stepper
                sx={{
                    paddingBottom: '24px',
                }}
                activeStep={WebStore.activeStep}
                alternativeLabel
            >
                {steps.map((label, i) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step
                            {...getTestID(`Step_${ i + 1 }`)}
                            key={label}
                            {...stepProps}
                        >
                            <StepLabel
                                {...labelProps}
                                {...getTestID(label)}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {renderPaper()}

            <div>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Button
                        {...getTestID('back_button')}
                        color="secondary"
                        disabled={WebStore.activeStep === 0}
                        onClick={handleBack}
                        sx={{mr: 1}}
                        variant="contained"
                    >
                        Back
                    </Button>
                    <Button
                        {...getTestID('cancel_button')}
                        color="secondary"
                        onClick={() => props.setAdd(false)}
                        sx={{mr: 1}}
                    >
                        Cancel
                    </Button>

                    <Button
                        {...getTestID('next_button')}
                        onClick={handleNext}
                        variant="contained"
                        disabled={FuelLockStore.awaitingApi}
                    >
                        {WebStore.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>

                {success && (
                    <AddedDeviceModal
                        open
                        close={() => {
                            setSuccess(false);
                            props.setAdd(false);
                        }}
                        submit={() => {
                            setSuccess(false);
                            WebStore.setSelectedDrawer(Constants.DASHBOARD);
                            WebStore.setCurrentPage(Constants.MAIN);
                            WebStore.setActiveStep(0);
                            WebStore.setPageQueue([] as string[]);
                        }}
                        deviceName={newDevicePage.deviceName}
                    />
                )}

                {showUseFlowMeterModal && (
                    <WarnFlowMeterModal
                        open
                        close={() => setShowUseFlowMeterModal(false)}
                        setUseFlowMeter={value => updateTanksPage(value, 'useFlowMeter', modalTank)}
                        setIgnoreFlowMeter={value => handleIgnoreFlowMeter(value)}
                    />
                )}

                {WebStore.isLoseChangesConfirmationOpen && (
                    <ConfirmationModal
                        id="exit_add_bin_wizard_confirmation"
                        open
                        close={() => WebStore.setIsLoseChangesConfirmationOpen(false)}
                        title="Warning"
                        submit={confirmationSubmit}
                        message="Are you sure you want to exit the Add Device wizard? All unsaved changes will be lost."
                    />
                )}
            </div>
        </div>
    );
});

export default DeviceWizard;
