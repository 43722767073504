/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Tanks} from '../../types/components/EquipmentTypes';
import isNumericInRange from './isNumericInRange';
import isAlphanumeric from './isAlphanumeric';

const isLevelSensorValid = (tank: Tanks) => {
    const isValid = {
        valid: true,
        heightError: isNumericInRange(tank.height, 0, 1000, 3, 'height'),
        capacityError: isNumericInRange(tank.capacity, 0, 4294967295, 0, 'capacity'),
        tattleIDError: isAlphanumeric(tank.tattleID, 14, 'tattle ID'),
        otodataApiKeyError: isAlphanumeric(tank.otodataApiKeyError, 64, 'otodata key'),
    };

    if (isValid.heightError !== '' || isValid.capacityError !== '') {
        isValid.valid = false;
    }
    return isValid;
};

export default isLevelSensorValid;
