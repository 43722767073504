/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import {
    Build,
    CreditCard,
    Dashboard, Info,
    Notifications, People,
    Person,
    Settings,
    TableChart,
    Tune,
    Web
} from '@mui/icons-material';
import DrawerButton from './DrawerButton';
import DrawerHeader from './DrawerHeader';
import Constants from '../../helper/Constants';
import DrawerUserInformation from './DrawerUserInformation';
import FuelLockStore from '../../stores/FuelLockStore';

const Root = styled('div')(({theme}) => ({
    // @ts-ignore
    backgroundColor: theme.palette.primary.drawerBackground,
    // @ts-ignore
    borderRightColor: theme.palette.primary.drawerUserBackground,
    borderRightStyle: 'solid',
    borderRightWidth: '2px',
    height: '100%',
    minWidth: '270px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const Column = styled('div')({
    flexDirection: 'column',
});

const rotate = {
    'WebkitTransform': 'rotate(90deg)',
    'MozTransform': 'rotate(90deg)',
    'MsTransform': 'rotate(90deg)',
    'OTransform': 'rotate(90deg)',
    transform: 'rotate(90deg)',
};

interface Props {
    disableButtons?: boolean,
}

function Drawer(props: Props) {
    const [ drawerOpen, setDrawerOpen ] = useState(true);
    const [ settingsOpen, setSettingsOpen ] = useState(false);
    const submenuPadding = '32px';

    return (
        <Root
            style={!drawerOpen ? {minWidth: '105px'} : {}}
        >
            <Column>
                <DrawerHeader
                    setOpen={() => setDrawerOpen(!drawerOpen)}
                    open={drawerOpen}
                />
                {drawerOpen && (
                    <DrawerUserInformation/>
                )}
                {!drawerOpen && (
                    <Divider/>
                )}

                <DrawerButton
                    id="dashboard"
                    icon={<Dashboard/>}
                    text={Constants.DASHBOARD}
                    drawerOpen={drawerOpen}
                    disabled={props.disableButtons}
                />
                <DrawerButton
                    id="equipment"
                    icon={<Build/>}
                    text={Constants.EQUIPMENT}
                    drawerOpen={drawerOpen}
                    disabled={props.disableButtons}
                />
                <DrawerButton
                    id="employees"
                    icon={<People/>}
                    text={Constants.EMPLOYEES}
                    drawerOpen={drawerOpen}
                    disabled={props.disableButtons}
                />
                {FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_CONTRACTOR_PIN &&
                    FuelLockStore.systemData.deviceList.length > 0 && (
                    <DrawerButton
                        id="contractor_pin"
                        icon={<People/>}
                        text={Constants.CONTRACTOR_PIN}
                        drawerOpen={drawerOpen}
                        disabled={props.disableButtons}
                    />
                )}
                <DrawerButton
                    id="devices"
                    icon={<Web/>}
                    text={Constants.DEVICES}
                    drawerOpen={drawerOpen}
                    disabled={props.disableButtons}
                />
                <DrawerButton
                    id="settings"
                    icon={<Settings/>}
                    text={Constants.SETTINGS}
                    drawerOpen={drawerOpen}
                    expandable
                    expandOpen={settingsOpen}
                    setExpandedState={() => setSettingsOpen(!settingsOpen)}
                    disabled={props.disableButtons}
                />
                {settingsOpen && (
                    <div>
                        <DrawerButton
                            id="admin_account"
                            icon={<Person/>}
                            text={Constants.ADMIN_ACCOUNT}
                            drawerOpen={drawerOpen}
                            paddingLeft={submenuPadding}
                            disabled={props.disableButtons}
                        />
                        <DrawerButton
                            id="notifications"
                            icon={<Notifications/>}
                            text={Constants.NOTIFICATIONS}
                            drawerOpen={drawerOpen}
                            paddingLeft={submenuPadding}
                            disabled={props.disableButtons}
                        />
                        <DrawerButton
                            id="account_preferences"
                            icon={<Tune sx={rotate}/>}
                            text={Constants.ACCOUNT_PREFERENCES}
                            drawerOpen={drawerOpen}
                            paddingLeft={submenuPadding}
                            disabled={props.disableButtons}
                        />
                        <DrawerButton
                            id="information"
                            icon={<Info/>}
                            text={Constants.INFORMATION}
                            drawerOpen={drawerOpen}
                            paddingLeft={submenuPadding}
                            disabled={props.disableButtons}
                        />
                    </div>
                )}
                <DrawerButton
                    id="reports"
                    icon={<TableChart/>}
                    text={Constants.REPORTS}
                    drawerOpen={drawerOpen}
                    disabled={props.disableButtons}
                />
                <DrawerButton
                    id="billing_and_payment"
                    icon={<CreditCard/>}
                    text={Constants.BILLING_AND_PAYMENT}
                    drawerOpen={drawerOpen}
                    disabled={props.disableButtons}
                />
            </Column>
            <Typography
                sx={{marginLeft: '16px'}}
            >
                v{ process.env.REACT_APP_VERSION }
            </Typography>
        </Root>
    );
}

export default Drawer;
