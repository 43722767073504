/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getDataById = (dataSource: any, idToFind: number | string) => {
    const index = dataSource.findIndex((item: any) =>
        item.id === idToFind);
    return dataSource[index];
};

export default getDataById;
