/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const determineMonthsPrior = (monthsAgo: number) => {
    const priorDate = new Date();
    priorDate.setMonth(priorDate.getMonth() - monthsAgo);
    return priorDate;
};

export default determineMonthsPrior;
