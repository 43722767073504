/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import { styled } from '@mui/material';
import Slider from '@mui/material/Slider';
import getTestID from '../../../helper/functions/getTestID';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    marginBottom: 10,
    marginRight: 20,
    marginLeft: 5,
});

interface Props {
    name: string,
    value: number | null,
    setValue: (value: number) => void,
}

function TankLevelPercentSlider(props: Props) {
    const handleChange = (event: any, newValue: any) => {
        props.setValue(newValue);
    };

    // the tank level percents showed up on the device screen seems to be truncated to integer portion
    // so trunc is used here instead of round for consistency
    const valueLabel = props.value != null ? `${ Math.trunc(props.value) }%` : '0%';

    return (
        <Row>
            <Slider
                {...getTestID(`tag_${ props.name }_slider_percent_value`)}
                value={ props.value != null ? props.value : 0 }
                onChange={handleChange}
                valueLabelDisplay="on"
                valueLabelFormat={valueLabel}
            />
        </Row>
    );
}

export default TankLevelPercentSlider;
