/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import { MenuItem } from '@mui/material';
import Constants from '../../../helper/Constants';
import getTestID from '../../../helper/functions/getTestID';
import PopupMenu from '../../banner/PopupMenu';

interface Props {
    anchorEl: Element | null,
    open: boolean,
    handleClose: () => void,
    selected: string,
    setSelected: (value: string) => void,
    hasYearly?: boolean,
    id: string,
}

function ChartMenu(props: Props) {
    const renderMenuItem = (value: string) => (
        <MenuItem
            selected={props.selected === value}
            onClick={() => {
                props.setSelected(value);
                props.handleClose();
            }}
            {...getTestID(`${ props.id }_${ value }`)}
        >
            {value}
        </MenuItem>
    );

    return (
        <PopupMenu
            anchorEl={props.anchorEl}
            open={props.open}
            handleClose={props.handleClose}
        >
            {renderMenuItem(Constants.DAILY)}
            {renderMenuItem(Constants.WEEKLY)}
            {renderMenuItem(Constants.MONTHLY)}
            {props.hasYearly && (
                renderMenuItem(Constants.YEARLY)
            )}
        </PopupMenu>
    );
}

export default ChartMenu;
