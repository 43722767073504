/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useMemo, useState} from 'react';
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Constants from '../../helper/Constants';
import {Delete, Edit} from '@mui/icons-material';
import DeleteModal from '../common/modal/DeleteModal';
import FuelLockStore from '../../stores/FuelLockStore';
import IconButton from '@mui/material/IconButton';
import callDeleteAPI from '../../controllers/callDeleteAPI';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import getTestID from '../../helper/functions/getTestID';
import SuccessMessages from '../../helper/SuccessMessages';
import {styled, Tooltip, Typography} from '@mui/material';
import TooltipStore from '../../stores/TooltipStore';
import TooltipWithButton from '../common/TooltipWithButton';
import TooltipMessages from '../../helper/TooltipMessages';
import { ContractorPin } from '../../types/components/ApiTypes';
import AddEditContractorPinModal from './AddEditContractorPinModal';
import callAPI from '../../controllers/callAPI';

const StyledTypography = styled(Typography)(() => ({
    fontSize: '14px',
}));

interface ContractorPinRow {
    showPin: boolean
    id: number
    name: string
    pin: string | null
}

const ContractorPinTable = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const getStartingData = () => {
        const contractorPinList = FuelLockStore.systemData.contractorPinList;
        const newData: ContractorPinRow[] = [];

        contractorPinList.forEach((contractorPin) => {
            newData.push({
                ...contractorPin,
                showPin: false,
            });
        });

        return newData;
    };

    const [ rows, setRows ] = useState<ContractorPinRow[]>(getStartingData());
    const [ remove, setRemove ] = useState<{ id: number | null, name: string }>({
        id: null,
        name: '',
    });
    const [ editing, setEditing ] = useState<ContractorPin | null>(null);

    const updateData = () => {
        const contractorPinList = FuelLockStore.systemData.contractorPinList;
        setRows(prevState => {
            const newData = cloneDeep(prevState);
            const deleteIndices: number[] = [];

            newData.forEach((contractorPin, index) => {
                const dataIndex = contractorPinList.findIndex(item => item.id === contractorPin.id);
                if (dataIndex === -1) {
                    deleteIndices.push(index);
                }
            });

            for (let i = deleteIndices.length - 1; i >= 0; i--) {
                newData.splice(deleteIndices[i], 1);
            }

            contractorPinList.forEach(contractorPin => {
                const index = newData.findIndex(item => item.id === contractorPin.id);
                if (index !== -1) {
                    newData[index] = {
                        ...contractorPin,
                        showPin: prevState[index].showPin,
                    };
                } else {
                    newData.push({
                        ...contractorPin,
                        showPin: false,
                    });
                }
            });
            return newData;
        });
    };

    useEffect(() => {
        updateData();
    }, [ FuelLockStore.systemData.contractorPinList ]);

    const onShowHidePinClick = (id: number) => {
        setRows(prevState => {
            const index = prevState.findIndex((item: any) =>
                item.id === id);
            prevState[index].showPin = !prevState[index].showPin;
            return prevState;
        });
    };

    const onEditClick = (contractorPin: ContractorPin) => {
        setEditing(contractorPin);
    };

    const onDeleteClick = (id: number, name: string) => {
        setRemove({
            id,
            name,
        });
    };

    const onCloseDelete = () => {
        setRemove({
            id: null,
            name: '',
        });
    };

    const onDelete = () => {
        if (remove) {
            callDeleteAPI(`fuellock/v2/contractorPin/${ remove.id }`, (receivedServerData) => {
                FuelLockStore.setAwaitingApi(false);
                if (receivedServerData.status === 'success') {
                    callAPI('fuellock/v2/dashboard', receivedData => {
                        FuelLockStore.setSystemData(receivedData.data);
                    }, enqueueSnackbar);
                }
            }, enqueueSnackbar, SuccessMessages.DELETE_CONTRACTOR_PIN);
            onCloseDelete();
        }
    };

    const columns: GridColDef[] = useMemo(() => ([
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            disableReorder: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
                >
                    <Tooltip
                        open={rows.length > 0 && params.row.id === rows[0].id &&
                        TooltipStore.beginTooltips &&
                        !TooltipStore.showDashboardMessages &&
                        !TooltipStore.showLightModeMessages &&
                        !TooltipStore.showAddContractorPinMessages &&
                        TooltipStore.showContractorPinMessages}
                        arrow
                        title={(
                            <TooltipWithButton
                                id="edit_contractor_pin_tooltip_button"
                                message={TooltipMessages.EDIT_CONTRACTOR_PIN}
                                onClick={() => TooltipStore.setShowContractorPinMessages(false, true)}
                            />
                        )}
                    >
                        <IconButton
                            {...getTestID(`${ params.row.name }_edit_contractor_pin_button`)}
                            color="secondary"
                            onClick={() => onEditClick(params.row)}
                        >
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        {...getTestID(`${ params.row.name }_delete_contractor_pin_button`)}
                        color="secondary"
                        onClick={() => onDeleteClick(params.row.id, params.row.name)}
                    >
                        <Delete/>
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => (
                <StyledTypography {...getTestID(params.row.name)}>
                    {params.row.name}
                </StyledTypography>
            ),
        },
        {
            field: 'pin',
            headerName: 'Pin',
            width: 150,
            renderCell: (params) => {
                let pin = '••••';
                if (params.row.pin === null) {
                    pin = 'No Pin Specified';
                }
                if (params.row.showPin) {
                    pin = params.row.pin;
                }
                return (
                    <StyledTypography {...getTestID(`${ params.row.name }_pin`)}>
                        {pin}
                    </StyledTypography>
                );
            },
        },
        {
            field: 'showPin',
            headerName: '',
            renderCell: (params) => {
                if (params.row.pin) {
                    return (
                        <Button
                            {...getTestID(`${ params.row.name }_show_hide_pin_button`)}
                            color="secondary"
                            onClick={() => onShowHidePinClick(params.row.id)}
                        >
                            {params.row.showPin ? 'Hide Pin' : 'Show Pin'}
                        </Button>
                    );
                }
                return (
                    <div/>
                );
            },
        },
    ]), [
        TooltipStore.beginTooltips,
        TooltipStore.showDashboardMessages,
        TooltipStore.showLightModeMessages,
        TooltipStore.showAddContractorPinMessages,
        TooltipStore.showContractorPinMessages,
    ]);

    return (
        <div
            style={{
                height: window.innerHeight - 150,
                width: '100%',
            }}
        >
            <DataGridPro
                rows={rows}
                columns={columns}
                disableColumnSelector
            />
            {remove.id !== null && (
                <DeleteModal
                    id="delete_contractor_pin_modal"
                    open
                    close={onCloseDelete}
                    delete={onDelete}
                    description={remove.name}
                    secondaryDescription={Constants.HISTORICAL_DATA_MESSAGE}
                />
            )}

            {editing !== null && (
                <AddEditContractorPinModal
                    open
                    close={() => setEditing(null)}
                    editing
                    contractorPinName={editing.name}
                    contractorPinID={editing.id}
                    pin={editing.pin}
                />
            )}
        </div>
    );
});

export default ContractorPinTable;
