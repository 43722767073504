/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import FuelLockStore from '../../stores/FuelLockStore';
import {Device} from '../../types/components/ApiTypes';
import getDataById from '../functions/getDataById';

const isTankNameUnique = (name: string, deviceID: number) => {
    const device: Device = getDataById(FuelLockStore.systemData.deviceList, deviceID);

    const tankList = device.tankList;

    for (let i = 0; i < tankList.length; i += 1) {
        if (tankList[i].name === name) {
            return 'Please enter a unique tank name.';
        }
    }

    return '';
};

export default isTankNameUnique;
