/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {ProcessedUsagePojo} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import determineMonthsPrior from './determineMonthsPrior';

const determineUsageDataInRange = (timeRange: string,
                                   data: ProcessedUsagePojo[]) => {
    if (timeRange === Constants.DAILY) {
        const today = new Date();
        return data.filter(el => el.transactionStartDate.toDateString() === today.toDateString());
    }
    const end = new Date();
    const start = timeRange === Constants.WEEKLY
        ? new Date(new Date().setDate(new Date().getDate() - 7))
        : new Date(determineMonthsPrior(1));

    return data.filter(el => el.transactionStartDate.getTime() >= start.getTime() &&
        el.transactionStartDate.getTime() <= end.getTime());

};

export default determineUsageDataInRange;
