import fuelLockStore from '../../stores/FuelLockStore';
import { FlowMeterType } from '../../types/components/ApiTypes';

const getFlowMeterTypeById = (id: number): FlowMeterType => {
    const index = fuelLockStore.systemData.flowMeterTypeList.findIndex((item: FlowMeterType) => item.id === id);
    const flowMeterType = fuelLockStore.systemData.flowMeterTypeList[index];
    return {
        id: flowMeterType.id,
        name: flowMeterType.name,
        custom: flowMeterType.custom,
        pulsesPerLitre: flowMeterType.pulsesPerLitre,
    };
};

export default getFlowMeterTypeById;
