/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useEffect, useState} from 'react';
import {FormHelperText, styled} from '@mui/material';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import SubmitButton from '../../common/SubmitButton';
import StyledSwitch from '../../common/input/StyledSwitch';
import FuelLockStore from '../../../stores/FuelLockStore';
import callPostAPI from '../../../controllers/callPostAPI';
import SuccessMessages from '../../../helper/SuccessMessages';
import Constants from '../../../helper/Constants';
import { ContractorPinSettings, Device } from '../../../types/components/ApiTypes';
import { cloneDeep } from 'lodash';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import callAPI from '../../../controllers/callAPI';

interface Props {
    selectedDevice: Device,
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ContractorPinPanel = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ selectedDevice, setSelectedDevice ] = useState(props.selectedDevice);
    const [ contractorPinEnabled, setContractorPinEnabled ] =
        useState(props.selectedDevice.contractorPinSettings.enabled);
    const [ contractorPinEntryMandatory, setContractorPinEntryMandatory ] =
        useState(props.selectedDevice.contractorPinSettings.mandatoryEntry);
    const [ switchFieldDisable, setSwitchFieldDisabled ] =
        useState (!props.selectedDevice.contractorPinSettings.enabled);

    useEffect(() => {
        setContractorPinEnabled(selectedDevice.contractorPinSettings.enabled);
        setContractorPinEntryMandatory(selectedDevice.contractorPinSettings.mandatoryEntry);
    }, [ selectedDevice ]);

    useEffect(() => {
        setSwitchFieldDisabled(!contractorPinEnabled);
        if ( !contractorPinEnabled) {
            setContractorPinEntryMandatory(false);
        }
    }, [ contractorPinEnabled ]);

    const onSave = () => {
        const enableChange = contractorPinEnabled !== selectedDevice.contractorPinSettings.enabled;
        const mandatoryChange = contractorPinEntryMandatory !== selectedDevice.contractorPinSettings.mandatoryEntry;

        if (enableChange || mandatoryChange) {
            const newContractorPinSettings: ContractorPinSettings = {
                deviceId: selectedDevice.id,
                enabled: contractorPinEnabled,
                mandatoryEntry: contractorPinEntryMandatory,
            };

            callPostAPI('fuellock/v1/contractorPin/settings', newContractorPinSettings,
                (receivedData) => {
                    if (receivedData.status === 'success') {
                        if (enableChange) {
                            callAPI('fuellock/v2/dashboard',
                                receivedServerData => {
                                    FuelLockStore.setDeviceData(receivedServerData.data.deviceList);
                                }, enqueueSnackbar);
                        } else {
                            const newData = cloneDeep(JSON.parse(JSON.stringify(FuelLockStore.systemData.deviceList)));
                            const selecetedDeviceIndex = FuelLockStore.systemData.deviceList.findIndex(
                                device => device.id === selectedDevice.id);
                            if (selecetedDeviceIndex !== -1) {
                                newData[selecetedDeviceIndex].contractorPinSettings = newContractorPinSettings;
                                FuelLockStore.setDeviceData(newData);
                            }
                        }
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_CONTRACTOR_PIN
            );
        }
    };

    const getSelectedDeviceTitle = (device: Device) => {
        if (device) {
            return `${ device.siteName } - ${ device.sn }`;
        }
        return '';
    };

    const [ selectedDeviceTitle, setSelectedDeviceTitle ] = useState(getSelectedDeviceTitle(props.selectedDevice));

    const getDeviceOptions = () => {
        const options: { title: any; id: string; }[] = [];
        FuelLockStore.systemData.deviceList.forEach((device) => {
            options.push({
                title: getSelectedDeviceTitle(device),
                id: `${ device.id }_contractor_pin_option`,
            });
        });
        return options;
    };

    const setSelectedDeviceFromOptions = (text: string) => {
        const name = text.split('-')[0].trim();
        const deviceOption = FuelLockStore.systemData.deviceList.find(device => device.siteName === name);
        if (deviceOption) {
            setSelectedDevice(deviceOption);
            setSelectedDeviceTitle(getSelectedDeviceTitle(deviceOption));
        }
    };

    return (
        <Paper>
            <Root>
                <div>
                    <SelectFieldInput
                        id="select_device"
                        label='Select Device'
                        field={{
                            value: selectedDeviceTitle,
                            setValue: (value: string) => setSelectedDeviceFromOptions(value),
                            error: '',
                            setError: () => {
                            },
                        }}
                        options={getDeviceOptions()}
                    />
                </div>
                <div style={{marginBottom: '10px'}}>
                    <StyledSwitch
                        id="contractor_pin_enabled_switch"
                        description={`Enable ${ Constants.CONTRACTOR_PIN }`}
                        value={contractorPinEnabled}
                        setValue={setContractorPinEnabled}
                    />
                </div>
                <div>
                    <FormHelperText>
                        When enabled, Contractor ID numbers will need to be entered to continue
                        the login process on the Fuel Lock device.
                        Contractor ID's can be managed from the side menu label.
                    </FormHelperText>
                </div>
                <div>
                    <StyledSwitch
                        id="contractor_pin_entry_mandatory_switch"
                        description={`${ Constants.CONTRACTOR_PIN } Entry Mandatory`}
                        value={contractorPinEntryMandatory}
                        setValue={setContractorPinEntryMandatory}
                        disabled={switchFieldDisable}
                    />
                </div>
                <SubmitButton
                    id="save_contractor_pin_settings_button"
                    text="Save Changes"
                    onClick={onSave}
                    disabled={FuelLockStore.awaitingApi}
                />
            </Root>
        </Paper>
    );
});

export default ContractorPinPanel;
