/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Typography} from '@mui/material';
import BaseModal from '../common/modal/BaseModal';
import getTestID from '../../helper/functions/getTestID';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    submit: () => void,
}

function TransferredDeviceModal(props: Props) {
    const submit = () => {
        props.submit();
        props.close();
    };

    return (
        <BaseModal
            id="device_transferred"
            open={props.open}
            submit={submit}
            onCancel={submit}
            title="Device successfully transferred."
            submitButtonText="Go to Dashboard"
        >
            <Container>
                <Typography {...getTestID('popup_message')}>
                    Settings will sync to your new device and will be in-effect next call-in.
                </Typography>
            </Container>
        </BaseModal>
    );
}

export default TransferredDeviceModal;