/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, { useState } from 'react';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import CenterScreen from '../../common/CenterScreen';
import SubmitButton from '../../common/SubmitButton';
import isPasswordValid from '../../../helper/validator/isPasswordValid';
import getTestID from '../../../helper/functions/getTestID';
import SuccessMessages from '../../../helper/SuccessMessages';
import FuelLockStore from '../../../stores/FuelLockStore';
import logout from '../../../helper/functions/logout';
import PasswordField from '../../common/input/PasswordField';
import callPutAPI from '../../../controllers/callPutAPI';
import BaseModal from '../../common/modal/BaseModal';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

const Container2 = styled('div')({
    width: '350px',
    padding: '16px',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

const ChangeTemporaryPassword = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const url = new URL(window.location.href);
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordError, setNewPasswordError ] = useState('');
    const [ retypeNewPassword, setRetypeNewPassword ] = useState('');
    const [ retypePasswordError, setRetypePasswordError ] = useState('');
    const [ error, setError ] = useState('');
    const [ passwordReset, setPasswordReset ] = useState(false);

    const isValid = () => {
        let valid = true;
        if (newPassword !== retypeNewPassword) {
            setError('Password fields do not match');
            valid = false;
        }
        const passwordError = isPasswordValid(newPassword);
        setNewPasswordError(passwordError);
        const newRetypePasswordError = isPasswordValid(retypeNewPassword);
        setRetypePasswordError(newRetypePasswordError);

        if (newPasswordError !== '' || newRetypePasswordError !== '') {
            valid = false;
        }

        return valid;
    };

    const resetURL = () => {
        url.searchParams.delete('accountEmail');
        url.searchParams.delete('token');
        window.location.assign(url);
        logout();
    };

    const submit = () => {
        const body = {
            accountEmail: url.searchParams.get('accountEmail'),
            token: url.searchParams.get('token'),
            newPassword,
        };

        if (isValid()) {
            setError('');
            callPutAPI('fuellock/v2/password/reset', body,
                (receivedData) => {
                    if (receivedData.status === 'success') {
                        setPasswordReset(true);
                    }
                }, enqueueSnackbar, SuccessMessages.UPDATE_PASSWORD);
        }
    };

    const onCancel = () => {
        resetURL();
    };

    return (
        <CenterScreen>
            <Paper
                sx={{
                    width: '25%',
                    minWidth: '350px',
                }}
            >
                <Container>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            paddingBottom: '32px',
                        }}
                    >
                        Password Reset
                    </Typography>

                    <Typography
                        sx={{
                            paddingBottom: '16px',
                        }}
                    >
                        The system received a password reset request from you. Please enter your new password.
                    </Typography>

                    <PasswordField
                        id="change_password_new_password_input"
                        label="New Password"
                        field={{
                            value: newPassword,
                            setValue: setNewPassword,
                            error: newPasswordError,
                            setError: setNewPasswordError,
                        }}
                    />

                    <PasswordField
                        id="change_password_confirm_new_password_input"
                        label="Retype New Password"
                        field={{
                            value: retypeNewPassword,
                            setValue: setRetypeNewPassword,
                            error: retypePasswordError,
                            setError: setRetypePasswordError,
                        }}
                    />

                    {error !== '' && (
                        <Typography color="error">
                            {error}
                        </Typography>
                    )}

                    <ButtonRow>
                        <Button
                            {...getTestID('cancel_temporary_password_button')}
                            color="secondary"
                            sx={{ marginRight: '16px' }}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <SubmitButton
                            id="submit_update_temp_password_button"
                            text="Update Password"
                            onClick={submit}
                            disabled={FuelLockStore.awaitingApi}
                        />
                    </ButtonRow>
                    <BaseModal
                        id="password_reset_modal"
                        open={passwordReset}
                        submit={resetURL}
                        title="Password Reset Successfully"
                        submitButtonText="Continue to Login"
                    >
                        <Container2>
                            <Typography {...getTestID('password_reset_modal_msg_1')}>
                                Your password has been successfully reset.
                            </Typography>
                            <Typography {...getTestID('password_reset_modal_msg_2')}>
                                You will now be redirected to the Login page.
                            </Typography>
                        </Container2>
                    </BaseModal>
                </Container>
            </Paper>
        </CenterScreen>
    );
});

export default ChangeTemporaryPassword;
