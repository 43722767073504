/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';
import {observer} from 'mobx-react-lite';
import CenterScreen from '../../common/CenterScreen';
import SubmitButton from '../../common/SubmitButton';
import callAPI from '../../../controllers/callAPI';
import TextFieldInput from '../../common/input/TextFieldInput';
import FuelLockStore from '../../../stores/FuelLockStore';
import isNumeric from '../../../helper/validator/isNumeric';
import LoginStore from '../../../stores/LoginStore';
import getTestID from '../../../helper/functions/getTestID';
import logout from '../../../helper/functions/logout';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

const ActivateAccount = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const [ activationCode, setActivationCode ] = useState('');
    const [ activationCodeError, setActivationCodeError ] = useState('');

    const isValid = () => {
        let valid = true;

        const newActivationCodeError = isNumeric(activationCode, 6, '6-digit code');

        if (newActivationCodeError !== '') {
            valid = false;
        }
        setActivationCodeError(newActivationCodeError);

        return valid;
    };

    const onActivate = () => {
        if (isValid()) {
            callAPI(`fuellock/v2/activateAccount/${ activationCode }`,
                (receivedData) => {
                    if (receivedData.status === 'success') {
                        FuelLockStore.setAccountIsActive(true);
                        WebStore.setOverDue(false);
                        WebStore.setSelectedDrawer(Constants.DEVICES);
                        WebStore.goToNewPage(Constants.ADD_NEW_DEVICE);
                    }
                }, enqueueSnackbar);
        }
    };

    const onResendCode = () => {
        callAPI('fuellock/v2/resendActivationCode',
            () => {
            }, enqueueSnackbar);
    };

    const onCancel = () => {
        logout();
    };

    return (
        <CenterScreen>
            <Paper
                sx={{
                    width: '33%',
                    minWidth: '444px',
                }}
            >
                <Container>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            paddingBottom: '32px',
                        }}
                    >
                        Activate Your Account
                    </Typography>

                    <Typography
                        sx={{
                            paddingBottom: '16px',
                        }}
                    >
                        An activation code has been sent to: {LoginStore.email}. Enter the code here.
                    </Typography>
                    <Typography>
                        Please note some mail services may take longer than others, and remember to check your email's
                        {' '}junk folder too.
                    </Typography>

                    <TextFieldInput
                        id="activation_code_input"
                        label="6-digit Code from Email"
                        field={{
                            value: activationCode,
                            setValue: setActivationCode,
                            error: activationCodeError,
                            setError: setActivationCodeError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                maxLength: 6,
                            },
                        }}
                    />

                    <Button
                        {...getTestID('resend_code_button')}
                        color="secondary"
                        onClick={onResendCode}
                    >
                        Send Again
                    </Button>

                    <ButtonRow>
                        <Button
                            {...getTestID('cancel_activate_account_button')}
                            color="secondary"
                            sx={{marginRight: '16px'}}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <SubmitButton
                            id="activate_account_button"
                            text="Activate Account"
                            onClick={onActivate}
                        />
                    </ButtonRow>
                </Container>
            </Paper>
        </CenterScreen>
    );
});

export default ActivateAccount;
