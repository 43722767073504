/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {ValidateFieldProps} from '../../types/components/ApiTypes';
import isStringNotEmpty from '../validator/isStringNotEmpty';
import isPlainText from '../validator/isPlainText';
import isNumeric from '../validator/isNumeric';

export default function isPersonalDetailsFormValid(firstName: ValidateFieldProps,
                                                   lastName: ValidateFieldProps,
                                                   companyName: ValidateFieldProps,
                                                    deviceAdminDisplayName: ValidateFieldProps,
                                                   phoneNumber: ValidateFieldProps) {
    const newFirstNameError = isStringNotEmpty(firstName.value, 'first name');
    firstName.setError(newFirstNameError);
    const newLastNameError = isStringNotEmpty(lastName.value, 'last name');
    lastName.setError(newLastNameError);
    const newCompanyNameError = isStringNotEmpty(companyName.value, 'company name');
    companyName.setError(newCompanyNameError);
    const newDeviceAdminDisplayNameError = isPlainText(deviceAdminDisplayName.value, 1,
        'admin display name');
    deviceAdminDisplayName.setError(newDeviceAdminDisplayNameError);
    const newPhoneNumberError = isNumeric(phoneNumber.value, 10, '10-digit phone number');
    phoneNumber.setError(newPhoneNumberError);

    return (newFirstNameError === '' && newLastNameError === '' && newCompanyNameError === '' &&
        newDeviceAdminDisplayNameError === '' && newPhoneNumberError === '');
}
