/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import TextField from '@mui/material/TextField';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    textFieldProps?: object,
    onBlur?: (value: string) => void;
}

function TextFieldInput(props: Props) {
    // @ts-ignore
    const onBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onBlur) {
            props.onBlur(event.target.value);
        }
    };

    return (
        <TextField
            {...getTestID(props.id)}
            fullWidth
            label={props.label}
            value={props.field.value}
            onChange={(event) => props.field.setValue(event.target.value)}
            error={props.field.error !== ''}
            helperText={props.field.error}
            {...props.textFieldProps}
            onBlur={onBlur}
        />
    );
}

export default TextFieldInput;
