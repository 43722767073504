/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import getTestID from '../../helper/functions/getTestID';

const Root = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

interface Props {
    id: string,
    text: string,
    onClick: () => void;
    disabled?: boolean;
}

function SubmitButton(props: Props) {
    return (
        <Root>
            <Button
                {...getTestID(props.id)}
                color="primary"
                variant="contained"
                onClick={props.onClick}
                disabled={props.disabled}
            >
                {props.text}
            </Button>
        </Root>
    );
}

export default SubmitButton;
