/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled} from '@mui/material';

const Root = styled('div')(({ theme} ) => ({
    display: 'flex',
    // @ts-ignore
    backgroundColor: theme.palette.primary.background,
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
}));

interface Props {
    children: object,
}

function CenterScreen(props: Props) {
    return (
        <Root>
            {props.children}
        </Root>
    );
}

export default CenterScreen;
