/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React, {useState} from 'react';
import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import BaseModal from '../common/modal/BaseModal';
import Constants from '../../helper/Constants';
import {Equipment, IGTResponse, TrackUnit} from '../../types/components/ApiTypes';
import FuelLockStore from '../../stores/FuelLockStore';
import determineStartingState from '../../helper/functions/determineStartingState';
import getDataById from '../../helper/functions/getDataById';
import callAPI from '../../controllers/callAPI';
import isPlainText from '../../helper/validator/isPlainText';
import isNumericInRange from '../../helper/validator/isNumericInRange';
import callPostAPI from '../../controllers/callPostAPI';
import callPutAPI from '../../controllers/callPutAPI';
import getNewId from '../../helper/functions/getNewId';
import TextFieldInput from '../common/input/TextFieldInput';
import getTestID from '../../helper/functions/getTestID';
import SelectFieldInput from '../common/input/SelectFieldInput';
import SuccessMessages from '../../helper/SuccessMessages';

const Container = styled('div')({
    width: '350px',
    padding: '16px',
});

const Center = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '10px',
});

interface NewEquipment {
    hwID: number,
    name: string,
    trackUnit: TrackUnit,
}

interface Props {
    open: boolean,
    close: () => void,
    isEdit?: boolean,
    editingId?: number,
    customSubmit?: (newEquipment: NewEquipment) => void;
    newEquipmentList?: {
        hwID: number,
        name: string,
        trackUnit: TrackUnit,
    }[],
    editingEquipment?: NewEquipment,
    editingIndex?: number,
}

const AddEditEquipmentModal = observer((props: Props) => {
    const getEditing = () => {
        if (props.isEdit && props.editingId) {
            return getDataById(FuelLockStore.systemData.equipmentList, props.editingId);
        }

        if (props.isEdit && props.editingEquipment) {
            return props.editingEquipment;
        }

        return null;
    };

    const {enqueueSnackbar} = useSnackbar();
    const equipment: Equipment = getEditing();
    const [ name, setName ] = useState(determineStartingState(props.isEdit, equipment && equipment.name,
        ''));
    const [ nameError, setNameError ] = useState('');
    const [ id, setId ] = useState(determineStartingState(props.isEdit, equipment && equipment.hwID.toString(),
        ''));
    const [ idError, setIdError ] = useState('');
    const [ track, setTrack ] = useState(determineStartingState(props.isEdit,
        equipment && equipment.trackUnit === TrackUnit.km ? Constants.TRACK_KMS
            : (equipment && equipment.trackUnit === TrackUnit.hours
                ? Constants.TRACK_HOURS : Constants.TRACK_MILES), Constants.TRACK_HOURS));

    const equipmentIdMaxValue =
        FuelLockStore.systemData.minFirmwareVersion >= Constants.MIN_FW_VERSION_32BIT_EQUIPMENT_ID ? 999999999 : 65534;

    const onUseNextIdClick = () => {
        callAPI('fuellock/v2/nextAvailableEquipmentId',
            ((receivedServerData) => {
                setId(receivedServerData.data.toString());
            }), enqueueSnackbar);
    };

    const getTrackUnit = () => {
        if (track === Constants.TRACK_KMS) {
            return TrackUnit.km;
        }
        if (track === Constants.TRACK_HOURS) {
            return TrackUnit.hours;
        }
        return TrackUnit.miles;
    };

    const isValid = () => {
        let valid = true;

        const newNameError = name.trim() !== '' ? isPlainText(name, 1, 'name') : 'Please enter a valid name.';
        setNameError(newNameError);
        let newIdError = isNumericInRange(id, 1, equipmentIdMaxValue, 0, 'ID', false);
        if (newIdError === '' && props.newEquipmentList && props.newEquipmentList.length > 0) {
            for (let i = 0; i < props.newEquipmentList.length; i += 1) {
                if (props.newEquipmentList[i].hwID === Number(id) && i !== props.editingIndex) {
                    newIdError = 'ID is already in use.';
                    break;
                }
            }
        }
        setIdError(newIdError);

        if (newNameError !== '' || newIdError) {
            valid = false;
        }

        return valid;
    };

    const submit = () => {
        if (isValid()) {
            if (props.isEdit && props.editingId) {
                const body = {
                    id: props.editingId,
                    hwID: id,
                    name,
                    trackUnit: getTrackUnit(),
                };

                callPutAPI(`fuellock/v2/equipment/${ body.id }`, body,
                    (receivedServerData: IGTResponse<number>) => {
                        if (receivedServerData.status && receivedServerData.status === 'success') {
                            const newData = JSON.parse(JSON.stringify(FuelLockStore.systemData.equipmentList));
                            const index = newData.findIndex((item: Equipment) => item.id === body.id);
                            if (index !== -1) {
                                newData[index] = body;
                                FuelLockStore.setEquipmentData(newData);
                                props.close();
                            }
                        }
                    }, enqueueSnackbar, SuccessMessages.UPDATE_EQUIPMENT, false);
            } else {
                const body = {
                    id: getNewId(FuelLockStore.systemData.equipmentList),
                    hwID: id,
                    name,
                    trackUnit: getTrackUnit(),
                };
                if (props.customSubmit) {
                    props.customSubmit({
                        hwID: Number(body.hwID),
                        name,
                        trackUnit: body.trackUnit,
                    });
                    props.close();
                } else {
                    callPostAPI('fuellock/v2/equipment', body, (postEquipResponse: IGTResponse<number>) => {
                        if (postEquipResponse.status && postEquipResponse.status === 'success') {
                            const newData = JSON.parse(JSON.stringify(FuelLockStore.systemData.equipmentList));
                            const newbody = body;
                            newbody.id = postEquipResponse.data;
                            newData.push(newbody);
                            FuelLockStore.setEquipmentData(newData);
                            props.close();
                        }
                    }, enqueueSnackbar, SuccessMessages.CREATE_EQUIPMENT,false);
                }
            }
        }
    };

    return (
        <BaseModal
            id="add_equipment_modal"
            open={props.open}
            close={props.close}
            submit={submit}
            title={!props.isEdit ? 'Add New Equipment' : 'Edit Equipment'}
            disableSubmitOnAwait
        >
            <Container>
                <TextFieldInput
                    id="equipment_name_input"
                    label="Equipment Name"
                    field={{
                        value: name,
                        setValue: setName,
                        error: nameError,
                        setError: setNameError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 21,
                        },
                    }}
                />
                <TextFieldInput
                    id="equipment_id_input"
                    label="Equipment ID"
                    field={{
                        value: id,
                        setValue: setId,
                        error: idError,
                        setError: setIdError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            max: equipmentIdMaxValue,
                        },
                        type: 'number',
                    }}
                />
                <Center>
                    <Button
                        {...getTestID('use_next_id_button')}
                        color="secondary"
                        onClick={onUseNextIdClick}
                    >
                        Use Next Available ID
                    </Button>
                </Center>

                <SelectFieldInput
                    id="tracking_selection"
                    label="Tracking"
                    field={{
                        value: track,
                        setValue: setTrack,
                        error: '',
                        setError: () => {},
                    }}
                    options={[
                        {
                            title: Constants.TRACK_KMS,
                            id: 'track_kms_option',
                        },
                        {
                            title: Constants.TRACK_HOURS,
                            id: 'track_hours_option',
                        },
                        {
                            title: Constants.TRACK_MILES,
                            id: 'track_miles_option',
                        },
                    ]}
                />
            </Container>
        </BaseModal>
    );
});

export default AddEditEquipmentModal;
